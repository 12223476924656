import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import { FormControl, Input, TableCell } from "@mui/material";
import { width } from "@mui/system";
import { BorderBottom } from "@mui/icons-material";

export const CustomFormControl = styled(Input)`
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #1e1e1e;
  background-color: #eeeeee;
  background-clip: padding-box;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  @media screen and (min-width: 992px) {
    width: 100%;
  }

  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
`;

export const CustomRadio = styled("span")`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: #219b8e;
  border: 1px solid #e9e9e9;
`;

export const CustomRadioChecked = styled("span")`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: #e9e9e9;
  border: 1px solid #e9e9e9;
`;

export const BpIcon = styled("span")`
  border-radius: 5px;
  width: 20px;
  height: 20px;
  background-color: red;
  border: 1.5px solid #ededed;
`;

export const BpCheckedIcon = styled(BpIcon)`
  border: 1.5px solid var(--secondary-color3);
  &::before {
    display: block;
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%233ADEBB'/%3E%3C/svg%3E");
    content: "";
  }
`;

export const BpIcon2 = styled("span")`
  border-radius: 5px;
  width: 20px;
  height: 20px;
  background-color: #eeeeee;
  border: 1.5px solid #dddddd;
`;

export const BpCheckedIcon2 = styled(BpIcon)`
  border: 1.5px solid var(--secondary-color3);
  &::before {
    display: block;
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%233ADEBB'/%3E%3C/svg%3E");
    content: "";
  }
`;

export const TableCellBorder = styled(TableCell)`
  padding: 18px !important;
  font-family: "Montserrat", sans-serif !important;
  background-color: transparent !important;
  border: 1px solid #f0f0f0 !important;
  border-bottom: 1px solid #f0f0f0 !important;
  border-top: 1px solid #f0f0f0 !important;
  vertical-align: top !important;
`;

export const TableCellBorderLeft = styled(TableCell)`
  padding: 18px !important;
  font-family: "Montserrat", sans-serif !important;
  background-color: transparent !important;
  border-top: 1px solid #f0f0f0 !important;
  border-left: 1px solid #f0f0f0 !important;
  border-bottom: 1px solid #f0f0f0 !important;
  vertical-align: top !important;
`;

export const TableCellBorderRight = styled(TableCell)`
  padding: 18px !important;
  font-family: "Montserrat", sans-serif !important;
  background-color: transparent !important;
  border-top: 1px solid #f0f0f0 !important;
  border-right: 1px solid #f0f0f0 !important;
  border-bottom: 1px solid #f0f0f0 !important;
  vertical-align: top !important;
`;

const config = {
  theReceptionCounter: {
    // padding: "40px 0",
  },
  theReceptionCounterHeader: {
    padding: "10px 0",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.05)",
  },
  thSHFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  thSHLabel: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    // width: "20%",
    cursor: "pointer",
    // width: "100%",
  },
  theSHName: {
    // width: "fit-content",
    width: "50%",
  },
  theSHIcon: {
    fontSize: "34px",
    display: "flex",
    color: "##211D4E",
  },
  theReceptionCounterBody: {
    margin: "30px 0 190px",
  },
  theField: {
    position: "relative",
    zIndex: "1",
  },
  theAbsoluteIcon: {
    position: "absolute",
    left: "25px",
    top: "50%",
    transform: "translate(0, -50%)",
    zIndex: "2",
  },
  theIcon20: {
    width: "20px",
    height: "20px",
    "& img": {
      width: "100%",
      height: "20px",
    },
  },
  theSuccessTime: {
    backgroundColor: "#88FFE6",
    width: "100px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "3px",
    margin: "0 auto",
  },
  theFailTime: {
    backgroundColor: "#F9F9F9",
    width: "100px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "3px",
    margin: "0 auto",
  },
  thePagination: {
    margin: "20px 0 0",
  },
  theIcon20: {
    width: "20px",
    height: "20px",
    "& img": {
      width: "100%",
      height: "20px",
    },
  },
  theActive: {
    padding: "1rem",
    borderRadius: "6px",
    width: "100%",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    color: "#FFF",
    // boxShadow: "1px 4px 6px 0px rgba(0,0,0,0.10)",
    background: "#219B8E",
    marginRight: "14px",
  },
  theNoActive: {
    padding: "1rem",
    borderRadius: "6px",
    width: "100%",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    color: "#5A567B",
    background: "#EDEDED",
    // boxShadow: "unset",
    marginRight: "14px",
  },
  theTable: {
    display: "table",
    width: "100%",
    borderCollapse: "collapse",
    borderSpacing: 0,
  },
  theTableCell: {
    display: "table-cell",
    fontSize: "18px",
    lineHeight: 1.43,
    letterSpacing: ".01071em",
    padding: "16px 0",
    marginRight: "16px",
    // width: "200px"
    // width: "50%"
  },
  theTableCell2: {
    // display: "table-cell",
    fontSize: "18px",
    lineHeight: 1.43,
    letterSpacing: ".01071em",
    // margin: "0 16px",
    verticalAlign: "middle",
    width: "280px",
  },

  _theTableCell2: {
    // display: "table-cell",
    fontSize: "18px",
    lineHeight: 1.43,
    letterSpacing: ".01071em",
    // margin: "0 16px",
    verticalAlign: "middle",
    // width: "350px"
  },

  ///

  thePricing: {
    display: "table",
    width: "100%",
    borderCollapse: "collapse",
    borderSpacing: 0,
  },

  thePriceBox: {
    display: "table-cell",
    fontSize: ".875rem",
    lineHeight: 1.43,
    letterSpacing: ".01071em",
    padding: "16px",
    border: "1px solid #eaeaea",
    background: "#fff",
    verticalAlign: "middle",
    minWidth: "180px",
  },

  thePriceBox_: {
    display: "table-cell",
    fontSize: ".875rem",
    lineHeight: 1.43,
    letterSpacing: ".01071em",
    padding: "16px",
    // border: "1px solid #eaeaea",
    // background: "#fff"
  },

  thePriceBoxA: {
    display: "table-cell",
    // fontSize: ".875rem",
    lineHeight: 1.43,
    letterSpacing: ".01071em",
    // padding: "16px",
    // border: "1px solid #eaeaea",
    background: "#DDD",
  },

  thePriceBoxB: {
    display: "table-cell",
    fontSize: ".875rem",
    lineHeight: 1.43,
    letterSpacing: ".01071em",
    padding: "16px",
    // border: "1px solid #eaeaea",
    background: "#DDD",
  },

  thePriceBoxC: {
    display: "table-cell",
    fontSize: ".875rem",
    lineHeight: 1.43,
    letterSpacing: ".01071em",
    padding: "16px",
    // border: "1px solid #eaeaea",
    background: "#DDD",
  },

  // NEW QUESTIONAIRE
  theActiveLine: {
    borderBottom: "2px solid #3ADEBB",
    display: "block",
    color: "#211D4E",
    fontWeight: "600",
    padding: ".4rem 0",
    marginRight: "2rem",
    cursor: "pointer",
  },

  theDefault: {
    borderBottom: "2px solid transparent",
    display: "block",
    color: "#5A567B",
    fontWeight: "500",
    padding: ".4rem 0",
    marginRight: "2rem",
    cursor: "pointer",
  },
  checkboxLabel: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    margin: "0 1rem 0 0",
  },
  checkBoxLabel1: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    width: "100%",
  },
  checkBoxLabel2: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    margin: "1rem 3rem 1rem 0",
  },
  checkBoxLabel3: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    margin: "1rem 3rem .5rem 0",
  },
  checkBoxLabel4: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    margin: "0rem 3rem .5rem 0",
  },
  checkBoxLabel5: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    margin: "0rem 3rem 1rem 0",
  },
  styledCheckbox: {
    appearance: "none",
    width: "18px",
    height: "18px",
    border: "2px solid #ccc",
    borderRadius: "3px",
    marginRight: "8px",
    cursor: "pointer",
    transition: "border-color 0.3s, background-color 0.3s",
    color: "#3ADEBB",

    "&:checked": {
      borderColor: "#3ADEBB",
      color: "#3ADEBB",
      // backgroundColor: '#00cc00',
    },
    "&:checked::before": {
      content: '"\\2713"',
      display: "table-cell",
      width: "18px",
      height: "18px",
      textAlign: "center",
      lineHeight: "18px",
      // color: 'white',
      color: "#3ADEBB",
      fontWeight: "bold",
      verticalAlign: "middle",
    },
  },
  theButton: {
    width: "140px",
  },
  theButtonFlex: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "2rem 0 0",
  },
  theHead: {
    color: "#7c7996",
    backgroundColor: "#fff",
    textAlign: "center !important",
  },
  theCenter: {
    textAlign: "center !important",
  },
  theLine: {
    height: "2px",
    backgroundColor: "#d9d9d9",
    margin: "1rem 0",
  },
  theCheckIn: {
    backgroundColor: "#3ADEBB",
    width: "100px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "3px",
    margin: "0 auto",
    padding: "6px 26px",
  },
  theInCheckIn: {
    backgroundColor: "#5A567B",
    width: "100px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "3px",
    margin: "0 auto",
    padding: "6px 26px",
  },
  theBSBox: {
    width: "240px",
    height: "56px",
    fontSize: "16px",
    textAlign: "center",
    color: "#5A567B",
    backgroundColor: "#EDEDED",
    fontWeight: "600",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    margin: "0 10px 20px 0",
    cursor: "pointer"
  },
  theBSBoxActive: {
    width: "240px",
    height: "56px",
    fontSize: "16px",
    textAlign: "center",
    color: "#211D4E",
    backgroundColor: "#fff",
    fontWeight: "600",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    margin: "0 10px 20px 0",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.2)",
    cursor: "pointer"
  },
  theHead: {
    backgroundColor: "#fff !important",
    boxShadow: "inset 0px 2px 2px 1px rgba(0, 0, 0, 0.05) !important",
    // borderRadius: "5px"
    borderTopRightRadius: "5px !important",
    borderTopLeftRadius: "5px !important",
  },
  theBody: {
    backgroundColor: "#f6f6f6",
  },
  theBSBox1: {
    width: "150px",
    height: "56px",
    fontSize: "16px",
    textAlign: "center",
    color: "#5A567B",
    backgroundColor: "#EDEDED",
    fontWeight: "600",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    margin: "10px 5px 10px 5px",
    cursor: "pointer",
  },
  theBSBoxActive1: {
    width: "150px",
    height: "56px",
    fontSize: "16px",
    textAlign: "center",
    color: "#fff",
    backgroundColor: "#3ADEBB",
    fontWeight: "600",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    margin: "10px 5px 10px 5px",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
  },
  theBSBoxActive2: {
    width: "100%",
    height: "56px",
    fontSize: "16px",
    textAlign: "center",
    color: "#211D4E",
    backgroundColor: "#fff",
    fontWeight: "600",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    margin: "0 10px 20px 0",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.2)",
    cursor: "pointer"
  },
};

export default makeStyles(config);
