import styled, { keyframes } from "styled-components";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import { FormControl, Input } from "@mui/material";
import { width } from "@mui/system";


export const CustomFormControl = styled(Input)`
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #1e1e1e;
  background-color: #eeeeee;
  background-clip: padding-box;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  @media screen and (min-width: 992px) {
    width: 100%;
  }

  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
`

const config = {
  theNavbar: {
    backgroundColor: "#219B8E",
    padding: "26px 0",
  },
  theNbFlex: {
    display: 'flex',
    alignItems: "center",
    justifyContent: 'space-between',

    // ...md("md", {
    //   justifyContent: "center",
    // }),
  },
  theLogo: {
    position: "relative",
    width: "200px",
    height: "100%",

    ...md("sm", {
      width: "258px",
      height: "72px",
    }),

    "& img": {
      width: "100%",
      // height: "30",
    },
  },
  theCount: {
    width: "60%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  theCountMob: {
    // width: "60%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  theCountBox: {
    width: "fit-content",
    padding: "0 20px"
  },
  theButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  theHeaderButton: {
    width: "fit-content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid #fff",
    padding: "6px 32px",
    borderRadius: "4px",
    margin: "0 20px",
    cursor: "pointer",
    backgroundColor: "transparent",
  },
  theHeaderButton2: {
    width: "fit-content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid #fff",
    padding: "6px 32px",
    borderRadius: "4px",
    // margin: "10px 32px",
    cursor: "pointer",
    backgroundColor: "#fff",
  },
  theIcon20: {
    width: "20px",
    height: '20px',
    '& img': {
      width: "100%",
      height: "20px"
    }
  },
};

export default makeStyles(config);
