import React, { useState, useEffect } from "react";
import {
    Container,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Pagination,
    Box,
} from "@mui/material";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import Loader from "react-js-loader";
import toast from "react-simple-toasts";
import Header from "../../../components/Header/Header";
import Paragraph from "../../../components/Typography/Paragraph";
import FormInput from "../../../components/FormInput/FormInput";
import Button from "../../../components/Button/Button";
import { themePlate } from "../../../components/Theme/Theme";
import useStyles from "../styles";
import "../styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SearchIcon from "../../../assets/images/Search Icon.svg";
import CheckoutIcon from "../../../assets/images/Checkout Icon.svg";


export default function VisionOcular() {
    const classes = useStyles();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [receptionList, setReceptionList] = useState([]);

    //Pagination
    const [page, setPage] = React.useState(1);
    const [totalCount, setTotalCount] = React.useState(1);

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    useEffect(() => { }, [page]);

    useEffect(() => {
        if (!window.location.hash) {
            window.location = window.location + "#loaded";
            window.location.reload();
        }
    }, []);


    return (
        <React.Fragment>
            {loading ? (
                <div style={{ margin: "auto" }}>
                    <Loader
                        type="bubble-loop"
                        bgColor={themePlate().secondaryThree}
                        size={50}
                    />
                </div>
            ) : (
                <React.Fragment>
                    <Header />
                    <div className={classes.theReceptionCounter}>
                        <div className={classes.theReceptionCounterHeader}>
                            <Container maxWidth="xl" className="customContainer">
                                <div className={classes.thSHFlex}>
                                    <div
                                        className={classes.thSHLabel}
                                        style={{ width: "auto" }}
                                    // onClick={() => navigate("../dashboard")}
                                    >
                                        <Box
                                            sx={{
                                                background: "#5A567B",
                                                color: "#FFF",
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                padding: "14px 27px",
                                                borderRadius: "5px",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div className={classes.theIcon20}>
                                                <img src={CheckoutIcon} alt={CheckoutIcon} />
                                            </div>
                                            <Paragraph margin="0 0 0 10px">Check Out</Paragraph>
                                        </Box>
                                    </div>
                                    <div style={{ marginLeft: "33%", marginRight: "auto" }}>
                                        <Paragraph
                                            size="24px"
                                            color={themePlate().primaryTwo}
                                            bold="600"
                                            textTransform="upperCase"
                                            margin="0"
                                        >
                                            VISION & OCULAR TENSION TEST
                                        </Paragraph>
                                    </div>
                                    <div className={classes.theSHFField}>
                                        <div className={classes.theField}>
                                            <FormInput
                                                className={classes.theBorderRadius}
                                                name="search"
                                                placeholder="Search..."
                                                fontWeight="500"
                                                textColor={themePlate().secondaryTwo}
                                                border="1px solid #eeeeee"
                                                bg="transparent"
                                                padding="12px 25px 12px 65px"
                                                mb="0"
                                                size="18px"
                                            />
                                            <div className={classes.theAbsoluteIcon}>
                                                <div className={classes.theIcon20}>
                                                    <img src={SearchIcon} alt={SearchIcon} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </div>
                        <div className={classes.theReceptionCounterBody}>
                            <Container maxWidth="xl">
                                <div className={classes.theTable}>
                                    <TableContainer>
                                        <Table
                                            sx={
                                                {
                                                    // minWidth: 650
                                                }
                                            }
                                            aria-label="simple table"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className="theLabelCellHead" colSpan={9}>
                                                        <Paragraph
                                                            size="24px"
                                                            bold="600"
                                                            color="#FFFFFF"
                                                            margin="24px 0 0"
                                                            textTransform="uppercase"
                                                        >
                                                            WAITING LIST
                                                        </Paragraph>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className="">
                                                        <Paragraph
                                                            center
                                                            size="14px"
                                                            bold="500"
                                                            color="#FFFFFF"
                                                        >
                                                            Seq No.
                                                        </Paragraph>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Paragraph size="14px" bold="500" color="#FFFFFF">
                                                            Name
                                                        </Paragraph>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Paragraph size="14px" bold="500" color="#FFFFFF">
                                                            Age
                                                        </Paragraph>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Paragraph
                                                            center
                                                            size="14px"
                                                            bold="500"
                                                            color="#FFFFFF"
                                                        >
                                                            Gender
                                                        </Paragraph>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Paragraph
                                                            center
                                                            size="14px"
                                                            bold="500"
                                                            color="#FFFFFF"
                                                        >
                                                            Package
                                                        </Paragraph>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Paragraph
                                                            center
                                                            size="14px"
                                                            bold="500"
                                                            color="#FFFFFF"
                                                        >
                                                            Waiting At
                                                        </Paragraph>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Paragraph
                                                            center
                                                            size="14px"
                                                            bold="500"
                                                            color="#FFFFFF"
                                                        >
                                                            Check In At
                                                        </Paragraph>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Paragraph
                                                            center
                                                            size="14px"
                                                            bold="500"
                                                            color="#FFFFFF"
                                                        >
                                                            Check Out At
                                                        </Paragraph>
                                                    </TableCell>
                                                    <TableCell className=""></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow
                                                    key="123"
                                                    sx={{
                                                        "&:last-child td, &:last-child th": {
                                                            border: 0,
                                                        },
                                                    }}
                                                    onClick={() => navigate("../visionoculardetail")}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <TableCell>
                                                        <Paragraph
                                                            center
                                                            color="#211D4E"
                                                            size="16px"
                                                            bold="500"
                                                        >
                                                            123
                                                        </Paragraph>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Paragraph
                                                            left
                                                            color="#211D4E"
                                                            size="16px"
                                                            bold="600"
                                                        >
                                                            Siew Wen Hui
                                                        </Paragraph>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Paragraph color="#211D4E" size="16px" bold="500">
                                                            28
                                                        </Paragraph>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Paragraph
                                                            center
                                                            color="#211D4E"
                                                            size="16px"
                                                            bold="500"
                                                        >
                                                            Female
                                                        </Paragraph>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Paragraph
                                                            center
                                                            color="#211D4E"
                                                            size="16px"
                                                            bold="500"
                                                        >
                                                            12
                                                        </Paragraph>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div
                                                        // className={
                                                        //   row.check_in_at != "-"
                                                        //     ? classes.theSuccessTime
                                                        //     : classes.theFailTime
                                                        // }
                                                        >
                                                            <Paragraph
                                                                center
                                                                // color={
                                                                //   row.check_in_at != "-"
                                                                //     ? themePlate().primaryTwo
                                                                //     : "#A8A8A8"
                                                                // }
                                                                size="16px"
                                                                bold="600"
                                                                letterSpacing="0.05em"
                                                            >
                                                                {/* {row.check_in_at != "-" ? row.checkin : "-"} */}
                                                                -
                                                            </Paragraph>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div
                                                        // className={
                                                        //   row.check_in_at != "-"
                                                        //     ? classes.theSuccessTime
                                                        //     : classes.theFailTime
                                                        // }
                                                        >
                                                            <Paragraph
                                                                center
                                                                // color={
                                                                //   row.check_in_at != "-"
                                                                //     ? themePlate().primaryTwo
                                                                //     : "#A8A8A8"
                                                                // }
                                                                size="16px"
                                                                bold="600"
                                                                letterSpacing="0.05em"
                                                            >
                                                                {/* {row.check_in_at != "-" ? row.checkin : "-"} */}
                                                                -
                                                            </Paragraph>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div
                                                        // className={
                                                        //   row.check_in_at != "-"
                                                        //     ? classes.theSuccessTime
                                                        //     : classes.theFailTime
                                                        // }
                                                        >
                                                            <Paragraph
                                                                center
                                                                // color={
                                                                //   row.check_in_at != "-"
                                                                //     ? themePlate().primaryTwo
                                                                //     : "#A8A8A8"
                                                                // }
                                                                size="16px"
                                                                bold="600"
                                                                letterSpacing="0.05em"
                                                            >
                                                                {/* {row.check_in_at != "-" ? row.checkin : "-"} */}
                                                                -
                                                            </Paragraph>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className={classes.theCheckIn}>
                                                            <Paragraph
                                                                center
                                                                color="#fff"
                                                                size="16px"
                                                                bold="600"
                                                                letterSpacing="0.05em"
                                                            >
                                                                Check In
                                                            </Paragraph>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    {/* {totalCount != 0 &&
											<div className={classes.thePagination}>
													<div>
															<Pagination
																	count={totalCount}
																	variant="outlined"
																	page={page}
																	onChange={handleChangePage}
															/>
													</div>
											</div>
									} */}
                                </div>
                                <br />
                                <br />
                                <br />
                                <div className={classes.theTable}>
                                    <TableContainer>
                                        <Table
                                            sx={
                                                {
                                                    // minWidth: 650
                                                }
                                            }
                                            aria-label="simple table"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className="theLabelCellHead" colSpan={9}>
                                                        <Paragraph
                                                            size="24px"
                                                            bold="600"
                                                            color="#FFFFFF"
                                                            margin="24px 0 0"
                                                            textTransform="uppercase"
                                                        >
                                                            Check OUT LIST
                                                        </Paragraph>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className="">
                                                        <Paragraph
                                                            center
                                                            size="14px"
                                                            bold="500"
                                                            color="#FFFFFF"
                                                        >
                                                            Seq No.
                                                        </Paragraph>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Paragraph size="14px" bold="500" color="#FFFFFF">
                                                            Name
                                                        </Paragraph>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Paragraph size="14px" bold="500" color="#FFFFFF">
                                                            Age
                                                        </Paragraph>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Paragraph
                                                            center
                                                            size="14px"
                                                            bold="500"
                                                            color="#FFFFFF"
                                                        >
                                                            Gender
                                                        </Paragraph>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Paragraph
                                                            center
                                                            size="14px"
                                                            bold="500"
                                                            color="#FFFFFF"
                                                        >
                                                            Package
                                                        </Paragraph>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Paragraph
                                                            center
                                                            size="14px"
                                                            bold="500"
                                                            color="#FFFFFF"
                                                        >
                                                            Waiting At
                                                        </Paragraph>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Paragraph
                                                            center
                                                            size="14px"
                                                            bold="500"
                                                            color="#FFFFFF"
                                                        >
                                                            Check In At
                                                        </Paragraph>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Paragraph
                                                            center
                                                            size="14px"
                                                            bold="500"
                                                            color="#FFFFFF"
                                                        >
                                                            Check Out At
                                                        </Paragraph>
                                                    </TableCell>
                                                    <TableCell className=""></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow
                                                    key="123"
                                                    sx={{
                                                        "&:last-child td, &:last-child th": {
                                                            border: 0,
                                                        },
                                                    }}
                                                    onClick={() => navigate("../visionoculardetail")}
                                                    // onClick={() => navigate("../reception-member-profile/" + row.appointment_id)}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <TableCell>
                                                        <Paragraph
                                                            center
                                                            color="#211D4E"
                                                            size="16px"
                                                            bold="500"
                                                        >
                                                            123
                                                        </Paragraph>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Paragraph
                                                            left
                                                            color="#211D4E"
                                                            size="16px"
                                                            bold="600"
                                                        >
                                                            Siew Wen Hui
                                                        </Paragraph>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Paragraph color="#211D4E" size="16px" bold="500">
                                                            28
                                                        </Paragraph>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Paragraph
                                                            center
                                                            color="#211D4E"
                                                            size="16px"
                                                            bold="500"
                                                        >
                                                            Female
                                                        </Paragraph>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Paragraph
                                                            center
                                                            color="#211D4E"
                                                            size="16px"
                                                            bold="500"
                                                        >
                                                            12
                                                        </Paragraph>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div
                                                        // className={
                                                        //   row.check_in_at != "-"
                                                        //     ? classes.theSuccessTime
                                                        //     : classes.theFailTime
                                                        // }
                                                        >
                                                            <Paragraph
                                                                center
                                                                // color={
                                                                //   row.check_in_at != "-"
                                                                //     ? themePlate().primaryTwo
                                                                //     : "#A8A8A8"
                                                                // }
                                                                size="16px"
                                                                bold="600"
                                                                letterSpacing="0.05em"
                                                            >
                                                                {/* {row.check_in_at != "-" ? row.checkin : "-"} */}
                                                                -
                                                            </Paragraph>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div
                                                        // className={
                                                        //   row.check_in_at != "-"
                                                        //     ? classes.theSuccessTime
                                                        //     : classes.theFailTime
                                                        // }
                                                        >
                                                            <Paragraph
                                                                center
                                                                // color={
                                                                //   row.check_in_at != "-"
                                                                //     ? themePlate().primaryTwo
                                                                //     : "#A8A8A8"
                                                                // }
                                                                size="16px"
                                                                bold="600"
                                                                letterSpacing="0.05em"
                                                            >
                                                                {/* {row.check_in_at != "-" ? row.checkin : "-"} */}
                                                                -
                                                            </Paragraph>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div
                                                        // className={
                                                        //   row.check_in_at != "-"
                                                        //     ? classes.theSuccessTime
                                                        //     : classes.theFailTime
                                                        // }
                                                        >
                                                            <Paragraph
                                                                center
                                                                // color={
                                                                //   row.check_in_at != "-"
                                                                //     ? themePlate().primaryTwo
                                                                //     : "#A8A8A8"
                                                                // }
                                                                size="16px"
                                                                bold="600"
                                                                letterSpacing="0.05em"
                                                            >
                                                                {/* {row.check_in_at != "-" ? row.checkin : "-"} */}
                                                                -
                                                            </Paragraph>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className={classes.theInCheckIn}>
                                                            <Paragraph
                                                                center
                                                                color="#fff"
                                                                size="16px"
                                                                bold="600"
                                                                letterSpacing="0.05em"
                                                            >
                                                                Check In
                                                            </Paragraph>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    {/* {totalCount != 0 &&
											<div className={classes.thePagination}>
													<div>
															<Pagination
																	count={totalCount}
																	variant="outlined"
																	page={page}
																	onChange={handleChangePage}
															/>
													</div>
											</div>
									} */}
                                </div>
                            </Container>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}
