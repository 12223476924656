import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Dialog,
  MenuItem,
  Select,
  Box,
} from "@mui/material";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import Loader from "react-js-loader";
import toast from "react-simple-toasts";
import Header from "../../../components/Header/Header";
import Paragraph from "../../../components/Typography/Paragraph";
import FormInput from "../../../components/FormInput/FormInput";
import Button from "../../../components/Button/Button";
import { themePlate } from "../../../components/Theme/Theme";
import useStyles, {
  CustomFormControl,
  FormInputSelect,
  TableCellBorder,
  TableCellBorderLeft,
  TableCellBorderRight,
} from "../styles";
import "../styles.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DoctorImg from "../../../assets/images/doctor.jpg";
import SearchIcon from "../../../assets/images/Search Icon.svg";

import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import Questionaire from "../Questionaire/Questionaire";
import { ErrorRounded } from "@mui/icons-material";
export default function SonographyDetail() {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState(0);

  const handleChangeTab = (txt) => {
    setTabs(txt);
  };

  const [newTab, setNewTab] = useState(3);
  const handleChangeNewTab = (newTab) => {
    setNewTab(newTab);
  };

  const [openAxillary, setOpenAxillary] = React.useState(false);

  const handleOpenAxillary = () => {
    setOpenAxillary(true);
  };

  const [axTab, setAxTab] = React.useState(0);

  const handleChangeAxTab = (newTab) => {
    setAxTab(newTab);
  };

  const [searchKey, setSearchKey] = useState("");

  const handleChangeSearch = (e) => {
    setSearchKey(e.target.value);
  };

  return (
    <React.Fragment>
      {loading ? (
        <div style={{ margin: "auto" }}>
          <Loader
            type="bubble-loop"
            bgColor={themePlate().secondaryThree}
            size={50}
          />
        </div>
      ) : (
        <React.Fragment>
          <Header />
          <Box sx={{ background: "#211D4E", padding: "1rem" }}>
            <Container
              maxWidth="xl"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  display: "flex",
                  width: "50%",
                }}
              >
                <Paragraph color="#FFF" bold="700">
                  001
                </Paragraph>
                <Paragraph color="#FFF" bold="700">
                  Siew Wen Hui
                </Paragraph>
                <Paragraph color="#FFF" bold="700">
                  1995-05-02
                </Paragraph>
                <Paragraph color="#FFF" bold="700">
                  28/F
                </Paragraph>
                <Paragraph color="#FFF" bold="700">
                  950502-06-1234
                </Paragraph>
              </Box>
              <Box
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  display: "flex",
                  width: "30%",
                }}
              >
                <Paragraph color="#FFF" bold="700">
                  0401 1010 0600 0001
                </Paragraph>
                <Paragraph color="#FFF" bold="700">
                  K2
                </Paragraph>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ borderRight: "3px solid #FFF" }}>
                    <Paragraph color="#FFF" bold="700" margin="0 8px 0 0">
                      BM
                    </Paragraph>
                  </Box>

                  <Box sx={{ borderRight: "3px solid #FFF" }}>
                    <Paragraph color="#FFF" bold="700" margin="0 8px">
                      EN
                    </Paragraph>
                  </Box>
                  <Box>
                    <Paragraph color="#FFF" bold="700" margin="0 0 0 8px">
                      CH
                    </Paragraph>
                  </Box>
                </Box>
                <ErrorRounded sx={{ color: "#F9C234" }} />
              </Box>
            </Container>
          </Box>

          <div className={classes.theReceptionCounter}>
            <div className={classes.theReceptionCounterHeader}>
              <Container maxWidth="xl" className="customContainer">
                <div className={classes.thSHFlex}>
                  <div
                    className={classes.thSHLabel}
                    style={{ width: "auto" }}
                    // onClick={() => navigate("../dashboard")}
                  >
                    <div
                      className={classes.thSHLabel}
                      onClick={() => navigate(-1)}
                    >
                      <div className={classes.theSHIcon}>
                        <ChevronLeftIcon fontSize="34px" />
                      </div>
                      <div>
                        <Paragraph
                          size="18px"
                          color={themePlate().primaryThree}
                          bold="500"
                          textTransform="capitalize"
                          margin="0"
                        >
                          Back
                        </Paragraph>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <Paragraph
                      size="24px"
                      color={themePlate().primaryTwo}
                      bold="600"
                      textTransform="upperCase"
                      margin="0"
                    >
                      Sonography
                    </Paragraph>
                  </div>
                  <div className={classes.theSHFField}>
                    <Button
                      label="Exit Station"
                      color="#fff"
                      bg="#3ADEBB"
                      br="4px"
                    />
                  </div>
                </div>
              </Container>
            </div>
          </div>
          <br />
          <Container maxWidth="xxl">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={2}>
                <Box
                  sx={{
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
                    borderRadius: "5px",
                    padding: "1rem",
                    background: "#fff",
                    width: "60%",
                    margin: "0 auto",
                    cursor: "pointer",
                  }}
                >
                  <Paragraph color="#211D4E" bold="600" center>
                    Medical
                    <br />
                    History
                  </Paragraph>
                </Box>
                <br />
                <Box
                  sx={{
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
                    borderRadius: "5px",
                    padding: "1rem",
                    background: "#fff",
                    width: "60%",
                    margin: "0 auto",
                    cursor: "pointer",
                  }}
                >
                  <Paragraph color="#211D4E" bold="600" center>
                    Allergy / Drug
                    <br />
                    Hx
                  </Paragraph>
                </Box>
                <br />
                <Box
                  sx={{
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
                    borderRadius: "5px",
                    padding: "1rem",
                    background: "#fff",
                    width: "60%",
                    margin: "0 auto",
                    cursor: "pointer",
                  }}
                >
                  <Paragraph color="#211D4E" bold="600" center>
                    Personal
                    <br />
                    Complaint
                  </Paragraph>
                </Box>
                <br />
                <Box
                  sx={{
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
                    borderRadius: "5px",
                    padding: "1rem",
                    background: "#fff",
                    width: "60%",
                    margin: "0 auto",
                    cursor: "pointer",
                  }}
                >
                  <Paragraph color="#211D4E" bold="600" center>
                    View
                    <br />
                    Comment
                  </Paragraph>
                </Box>
                <br />
              </Grid>
              <Grid item xs={12} sm={12} md={9}>
                <Box
                  sx={{
                    borderTopRightRadius: "4px",
                    borderTopLeftRadius: "4px",
                    padding: "1rem 2rem",
                    background: "#fff",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <div
                      className={
                        tabs == 0 ? classes.theBSBoxActive : classes.theBSBox
                      }
                      onClick={() => handleChangeTab(0)}
                    >
                      Exam. Report
                    </div>
                    <div
                      className={
                        tabs == 1 ? classes.theBSBoxActive : classes.theBSBox
                      }
                      onClick={() => handleChangeTab(1)}
                    >
                      Add On Service
                    </div>
                    <div
                      className={
                        tabs == 2 ? classes.theBSBoxActive : classes.theBSBox
                      }
                      onClick={() => handleChangeTab(2)}
                    >
                      Comment & Suggestion
                    </div>
                  </Box>

                  <Box>
                    {tabs == 0 && (
                      <Box>
                        <Box
                          sx={{
                            height: "2px",
                            width: "100%",
                            backgroundColor: "#EDEDED",
                          }}
                        />
                        <Box
                          style={{
                            display: "flex",
                            backgroundColor: "#f6f6f6",
                            padding: ".5rem",
                          }}
                        >
                          <div
                            className={
                              newTab == 3
                                ? classes.theBSBoxActive1
                                : classes.theBSBox1
                            }
                            onClick={() => handleChangeNewTab(3)}
                          >
                            Abdomen
                          </div>
                          <div
                            className={
                              newTab == 4
                                ? classes.theBSBoxActive1
                                : classes.theBSBox1
                            }
                            onClick={() => handleChangeNewTab(4)}
                          >
                            Breast
                          </div>
                          <div
                            className={
                              newTab == 5
                                ? classes.theBSBoxActive1
                                : classes.theBSBox1
                            }
                            onClick={() => handleChangeNewTab(5)}
                          >
                            Carotid
                          </div>
                          <div
                            className={
                              newTab == 6
                                ? classes.theBSBoxActive1
                                : classes.theBSBox1
                            }
                            onClick={() => handleChangeNewTab(6)}
                          >
                            DVT
                          </div>
                          <div
                            className={
                              newTab == 7
                                ? classes.theBSBoxActive1
                                : classes.theBSBox1
                            }
                            onClick={() => handleChangeNewTab(7)}
                          >
                            Elasto
                          </div>
                          <div
                            className={
                              newTab == 8
                                ? classes.theBSBoxActive1
                                : classes.theBSBox1
                            }
                            onClick={() => handleChangeNewTab(8)}
                          >
                            Gynaecology
                          </div>
                          <div
                            className={
                              newTab == 9
                                ? classes.theBSBoxActive1
                                : classes.theBSBox1
                            }
                            onClick={() => handleChangeNewTab(9)}
                          >
                            Prostate
                          </div>
                          <div
                            className={
                              newTab == 10
                                ? classes.theBSBoxActive1
                                : classes.theBSBox1
                            }
                            onClick={() => handleChangeNewTab(10)}
                          >
                            Scrotum
                          </div>
                          <div
                            className={
                              newTab == 11
                                ? classes.theBSBoxActive1
                                : classes.theBSBox1
                            }
                            onClick={() => handleChangeNewTab(11)}
                          >
                            Thyroid
                          </div>
                          <div
                            className={
                              newTab == 12
                                ? classes.theBSBoxActive1
                                : classes.theBSBox1
                            }
                            onClick={() => handleChangeNewTab(12)}
                          >
                            Others
                          </div>
                        </Box>
                        <Box
                          sx={{
                            height: "2px",
                            width: "100%",
                            backgroundColor: "#EDEDED",
                          }}
                        />

                        {newTab == 3 && (
                          <>
                            <Box
                              sx={{
                                display: "table",
                                width: "100%",
                                borderCollapse: "collapse",
                                borderSpacing: 0,
                              }}
                            >
                              <Box sx={{ display: "table-row-group" }}>
                                <Box className={classes.theTableCell}>
                                  <Box
                                    sx={{
                                      padding: "0 1rem",
                                      width: "max-content",
                                    }}
                                  >
                                    <Paragraph
                                      color="#219B8E"
                                      bold="600"
                                      margin="0 0 1rem 0"
                                    >
                                      Abdomen
                                    </Paragraph>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Liver
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Intrahepatic Bile Duct
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Common Bile Duct
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Gall Bladdar
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Right Kidney
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Left Kidney
                                      </Paragraph>
                                    </Box>{" "}
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Hepatic Portal Vein
                                      </Paragraph>
                                    </Box>{" "}
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Pancreas
                                      </Paragraph>
                                    </Box>{" "}
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Spleen and Others
                                      </Paragraph>
                                    </Box>
                                  </Box>
                                </Box>

                                <Box
                                  sx={{
                                    position: "relative",
                                    width: "1000px",
                                    overflowX: "scroll",
                                    display: "flex",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      position: "relative",

                                      display: "flex",
                                    }}
                                  >
                                    <Box
                                      className={classes.theTableCell}
                                      sx={{ width: "auto" }}
                                    >
                                      <Box
                                        sx={{
                                          background: "#DDD",
                                          padding: "16px",
                                        }}
                                      >
                                        <Paragraph
                                          color="#211d4e"
                                          bold="600"
                                          margin="0 0 1rem 0"
                                          center
                                        >
                                          Current
                                        </Paragraph>

                                        <Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="-"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="-">
                                                  -
                                                </MenuItem>
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="-"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="-">
                                                  -
                                                </MenuItem>
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="-"
                                                // padding="8px 20px"
                                                // mb="0"
                                              ><MenuItem value="-">
                                              -
                                            </MenuItem>
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="-"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="-">
                                                  -
                                                </MenuItem>
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="-"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="-">
                                                  -
                                                </MenuItem>
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="-"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="-">
                                                  -
                                                </MenuItem>
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="-"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="-">
                                                  -
                                                </MenuItem>
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="-"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="-">
                                                  -
                                                </MenuItem>
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="-"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="-">
                                                  -
                                                </MenuItem>
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box
                                      className={classes.theTableCell}
                                      sx={{ width: "auto" }}
                                    >
                                      <Box
                                        sx={{
                                          background: "#DDD",
                                          padding: "16px",
                                        }}
                                      >
                                        <Paragraph
                                          color="#211d4e"
                                          bold="600"
                                          margin="0 0 1rem 0"
                                          center
                                        >
                                          22-09-2022
                                        </Paragraph>

                                        <Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="-"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="-">
                                                  -
                                                </MenuItem>
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="-"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="-">
                                                  -
                                                </MenuItem>
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="-"
                                                // padding="8px 20px"
                                                // mb="0"
                                              ><MenuItem value="-">
                                              -
                                            </MenuItem>
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="-"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="-">
                                                  -
                                                </MenuItem>
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="-"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="-">
                                                  -
                                                </MenuItem>
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="-"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="-">
                                                  -
                                                </MenuItem>
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="-"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="-">
                                                  -
                                                </MenuItem>
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="-"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="-">
                                                  -
                                                </MenuItem>
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="-"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="-">
                                                  -
                                                </MenuItem>
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <br />
                            <div className={classes.theButtonFlex}>
                              <div className={classes.theButton}>
                                <Button
                                  label="Save"
                                  bg="#3adebb"
                                  color="#FFF"
                                  br="6px"
                                  size="24px"
                                  bold="600"
                                  pd=".8rem 4rem"
                                  hvbg="#3adebb"
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {newTab == 4 && (
                          <>
                            <Paragraph
                              bold="600"
                              color="#219b8e"
                              margin="1rem 0"
                            >
                              Overview
                            </Paragraph>

                            <div className={classes.theField}>
                              <FormInput
                                className={classes.theBorderRadius}
                                name="search"
                                placeholder="Search..."
                                fontWeight="500"
                                textColor={themePlate().secondaryTwo}
                                border="1px solid #eeeeee"
                                bg="transparent"
                                padding="12px 25px 12px 65px"
                                mb="0"
                                size="18px"
                              />
                              <div className={classes.theAbsoluteIcon}>
                                <div className={classes.theIcon20}>
                                  <img src={SearchIcon} alt={SearchIcon} />
                                </div>
                              </div>
                            </div>
                            <br />
                            <div style={{ height: "200px" }}>
                              <TableContainer sx={{ height: "200px" }}>
                                <Table>
                                  <TableHead className={classes.theHead}>
                                    <TableRow>
                                      <TableCell>No.</TableCell>
                                      <TableCell>Content</TableCell>
                                      <TableCell></TableCell>
                                      <TableCell></TableCell>
                                    </TableRow>
                                  </TableHead>

                                  <TableBody className={classes.theBody}>
                                    <TableRow>
                                      <TableCell>01</TableCell>
                                      <TableCell>
                                        Lorem ipsum dolor sit amet; consectetur
                                        adipiscing elit; sed do eiusmod tempor
                                        incididunt
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          textAlign: "right",
                                          color: "#219b8e",
                                        }}
                                      >
                                        Edit
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          textAlign: "right",
                                          color: "red",
                                        }}
                                      >
                                        Delete
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>

                            <Box
                              sx={{
                                display: "flex",
                                padding: "1rem",
                              }}
                            >
                              <div className={classes.theBSBoxActive2}>
                                Add New Comment
                              </div>
                              <div
                                className={classes.theBSBoxActive2}
                                onClick={() => handleOpenAxillary()}
                              >
                                Add New Axillary
                              </div>
                            </Box>

                            <Paragraph
                              bold="600"
                              color="#219b8e"
                              margin="0 0 1rem 0"
                            >
                              Other Comment
                            </Paragraph>

                            <FormInput
                              boxshadow="none !important"
                              name="replaced_name"
                              placeholder="Type in your message & recommendation"
                              bg="#f6f6f6"
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              multiline
                              row={10}
                            />

                            <br />
                            <div className={classes.theButtonFlex}>
                              <div className={classes.theButton}>
                                <Button
                                  label="Save"
                                  bg="#3adebb"
                                  color="#FFF"
                                  br="6px"
                                  size="24px"
                                  bold="600"
                                  pd=".8rem 4rem"
                                  hvbg="#3adebb"
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {newTab == 5 && (
                          <>
                            <Box
                              sx={{
                                display: "table",
                                width: "100%",
                                borderCollapse: "collapse",
                                borderSpacing: 0,
                              }}
                            >
                              <Box sx={{ display: "table-row-group" }}>
                                <Box className={classes.theTableCell}>
                                  <Box
                                    sx={{
                                      padding: "0 1rem",
                                      width: "max-content",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        paddingTop: "1rem",
                                        visibility: "visible",
                                      }}
                                    >
                                      <Paragraph
                                        color="#219B8E"
                                        bold="600"
                                        margin="0 0 1rem 0"
                                        style={{ visibility: "visible" }}
                                      >
                                        &nbsp;
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph
                                        color="#219B8E"
                                        bold="600"
                                        margin="0 0 1rem 0"
                                      >
                                        Gray Scale Imaging
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Mean Intima-media Thickness (CM)
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Plaque / Others
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "1rem 0 0 0",
                                      }}
                                    >
                                      <Paragraph
                                        color="#219B8E"
                                        bold="600"
                                        margin="0 0 1rem 0"
                                      >
                                        Doppler Measurement
                                      </Paragraph>
                                      <Box
                                        sx={{
                                          padding: "16px 0",
                                          position: "relative",
                                          height: "1.4375em",
                                          boxSizing: "content-box",
                                          display: "block",
                                          marginBottom: ".8rem",
                                        }}
                                      >
                                        <Paragraph color="#211D4E" bold="700">
                                          CCA-PSV (CM/S)
                                        </Paragraph>
                                      </Box>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        ICA-PSV(CM/S)
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        ICA-EDV (CM/S)
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        ICA/CCA PSV ratio
                                      </Paragraph>
                                    </Box>{" "}
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Va PSV (CM/S)
                                      </Paragraph>
                                    </Box>
                                  </Box>
                                </Box>

                                <Box
                                  sx={{
                                    position: "relative",
                                    width: "1000px",
                                    overflowX: "scroll",
                                    display: "flex",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      position: "relative",

                                      display: "flex",
                                    }}
                                  >
                                    <Box
                                      className={classes.theTableCell}
                                      sx={{ width: "auto" }}
                                    >
                                      <Box
                                        sx={{
                                          background: "#DDD",
                                          padding: "16px",
                                        }}
                                      >
                                        <Paragraph
                                          color="#211d4e"
                                          bold="600"
                                          margin="0 0 1rem 0"
                                          center
                                        >
                                          Current
                                        </Paragraph>

                                        <Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              style={{
                                                display: "flex",
                                                paddingTop: "1rem",
                                              }}
                                            >
                                              <Paragraph
                                                color="#211d4e"
                                                bold="600"
                                                margin="0 0 1rem 0"
                                                style={{ width: "50%" }}
                                                center
                                              >
                                                Right
                                              </Paragraph>
                                              <Paragraph
                                                color="#211d4e"
                                                bold="600"
                                                margin="0 0 1rem 0"
                                                style={{ width: "50%" }}
                                                center
                                              >
                                                Left
                                              </Paragraph>
                                            </Box>

                                            <Box sx={{ paddingTop: "1rem" }}>
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  width: "100%",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <Box sx={{ width: "130px" }}>
                                                  <FormInput
                                                    bg="#FFF"
                                                    number
                                                    textcolor="#211d4e"
                                                    fontWeight="600"
                                                    textalign="center"
                                                    name="hb"
                                                    boxshadow="unset"
                                                    placeholder="-"
                                                  />
                                                </Box>

                                                <Box sx={{ width: "130px" }}>
                                                  <FormInput
                                                    bg="#FFF"
                                                    number
                                                    textcolor="#211d4e"
                                                    fontWeight="600"
                                                    textalign="center"
                                                    name="hb"
                                                    boxshadow="unset"
                                                    placeholder="-"
                                                  />
                                                </Box>
                                              </Box>

                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  width: "100%",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <Box sx={{ width: "130px" }}>
                                                  <FormInput
                                                    bg="#FFF"
                                                    number
                                                    textcolor="#211d4e"
                                                    fontWeight="600"
                                                    textalign="center"
                                                    name="hb"
                                                    boxshadow="unset"
                                                    placeholder="-"
                                                  />
                                                </Box>

                                                <Box sx={{ width: "130px" }}>
                                                  <FormInput
                                                    bg="#FFF"
                                                    number
                                                    textcolor="#211d4e"
                                                    fontWeight="600"
                                                    textalign="center"
                                                    name="hb"
                                                    boxshadow="unset"
                                                    placeholder="-"
                                                  />
                                                </Box>
                                              </Box>
                                            </Box>
                                          </Box>

                                          <Box sx={{ paddingTop: "1rem" }}>
                                            <Box
                                              className={classes.theTableCell2}
                                              sx={{ marginTop: "2.1rem" }}
                                            >
                                              <Box sx={{ width: "100%" }}>
                                                <FormInput
                                                  bg="#FFF"
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="hb"
                                                  boxshadow="unset"
                                                  placeholder="-"
                                                  // style={{ marginRight: "1rem" }}
                                                />
                                              </Box>
                                              <Box sx={{ width: "100%" }}>
                                                <FormInput
                                                  bg="#FFF"
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="hb"
                                                  boxshadow="unset"
                                                  placeholder="-"
                                                  // style={{ marginRight: "1rem" }}
                                                />
                                              </Box>
                                              <Box sx={{ width: "100%" }}>
                                                <FormInput
                                                  bg="#FFF"
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="hb"
                                                  boxshadow="unset"
                                                  placeholder="-"
                                                  // style={{ marginRight: "1rem" }}
                                                />
                                              </Box>
                                              <Box sx={{ width: "100%" }}>
                                                <FormInput
                                                  bg="#FFF"
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="hb"
                                                  boxshadow="unset"
                                                  placeholder="-"
                                                  // style={{ marginRight: "1rem" }}
                                                />
                                              </Box>
                                              <Box sx={{ width: "100%" }}>
                                                <FormInput
                                                  bg="#FFF"
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="hb"
                                                  boxshadow="unset"
                                                  placeholder="-"
                                                  // style={{ marginRight: "1rem" }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box
                                      className={classes.theTableCell}
                                      sx={{ width: "auto" }}
                                    >
                                      <Box
                                        sx={{
                                          background: "#DDD",
                                          padding: "16px",
                                        }}
                                      >
                                        <Paragraph
                                          color="#211d4e"
                                          bold="600"
                                          margin="0 0 1rem 0"
                                          center
                                        >
                                          Current
                                        </Paragraph>

                                        <Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              style={{
                                                display: "flex",
                                                paddingTop: "1rem",
                                              }}
                                            >
                                              <Paragraph
                                                color="#211d4e"
                                                bold="600"
                                                margin="0 0 1rem 0"
                                                style={{ width: "50%" }}
                                                center
                                              >
                                                Right
                                              </Paragraph>
                                              <Paragraph
                                                color="#211d4e"
                                                bold="600"
                                                margin="0 0 1rem 0"
                                                style={{ width: "50%" }}
                                                center
                                              >
                                                Left
                                              </Paragraph>
                                            </Box>

                                            <Box sx={{ paddingTop: "1rem" }}>
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  width: "100%",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <Box sx={{ width: "130px" }}>
                                                  <FormInput
                                                    bg="#FFF"
                                                    number
                                                    textcolor="#211d4e"
                                                    fontWeight="600"
                                                    textalign="center"
                                                    name="hb"
                                                    boxshadow="unset"
                                                    placeholder="-"
                                                  />
                                                </Box>

                                                <Box sx={{ width: "130px" }}>
                                                  <FormInput
                                                    bg="#FFF"
                                                    number
                                                    textcolor="#211d4e"
                                                    fontWeight="600"
                                                    textalign="center"
                                                    name="hb"
                                                    boxshadow="unset"
                                                    placeholder="-"
                                                  />
                                                </Box>
                                              </Box>

                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  width: "100%",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <Box sx={{ width: "130px" }}>
                                                  <FormInput
                                                    bg="#FFF"
                                                    number
                                                    textcolor="#211d4e"
                                                    fontWeight="600"
                                                    textalign="center"
                                                    name="hb"
                                                    boxshadow="unset"
                                                    placeholder="-"
                                                  />
                                                </Box>

                                                <Box sx={{ width: "130px" }}>
                                                  <FormInput
                                                    bg="#FFF"
                                                    number
                                                    textcolor="#211d4e"
                                                    fontWeight="600"
                                                    textalign="center"
                                                    name="hb"
                                                    boxshadow="unset"
                                                    placeholder="-"
                                                  />
                                                </Box>
                                              </Box>
                                            </Box>
                                          </Box>

                                          <Box sx={{ paddingTop: "1rem" }}>
                                            <Box
                                              className={classes.theTableCell2}
                                              sx={{ marginTop: "2.1rem" }}
                                            >
                                              <Box sx={{ width: "100%" }}>
                                                <FormInput
                                                  bg="#FFF"
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="hb"
                                                  boxshadow="unset"
                                                  placeholder="-"
                                                  // style={{ marginRight: "1rem" }}
                                                />
                                              </Box>
                                              <Box sx={{ width: "100%" }}>
                                                <FormInput
                                                  bg="#FFF"
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="hb"
                                                  boxshadow="unset"
                                                  placeholder="-"
                                                  // style={{ marginRight: "1rem" }}
                                                />
                                              </Box>
                                              <Box sx={{ width: "100%" }}>
                                                <FormInput
                                                  bg="#FFF"
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="hb"
                                                  boxshadow="unset"
                                                  placeholder="-"
                                                  // style={{ marginRight: "1rem" }}
                                                />
                                              </Box>
                                              <Box sx={{ width: "100%" }}>
                                                <FormInput
                                                  bg="#FFF"
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="hb"
                                                  boxshadow="unset"
                                                  placeholder="-"
                                                  // style={{ marginRight: "1rem" }}
                                                />
                                              </Box>
                                              <Box sx={{ width: "100%" }}>
                                                <FormInput
                                                  bg="#FFF"
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="hb"
                                                  boxshadow="unset"
                                                  placeholder="-"
                                                  // style={{ marginRight: "1rem" }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Paragraph
                              bold="600"
                              color="#219b8e"
                              margin="0 0 1rem 0"
                            >
                              Other Comment
                            </Paragraph>

                            <FormInput
                              boxshadow="none !important"
                              name="replaced_name"
                              placeholder="Type in your message & recommendation"
                              bg="#f6f6f6"
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              multiline
                              row={10}
                            />
                            <br />
                            <div className={classes.theButtonFlex}>
                              <div className={classes.theButton}>
                                <Button
                                  label="Save"
                                  bg="#3adebb"
                                  color="#FFF"
                                  br="6px"
                                  size="24px"
                                  bold="600"
                                  pd=".8rem 4rem"
                                  hvbg="#3adebb"
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {newTab == 6 && (
                          <>
                            <Box
                              sx={{
                                display: "table",
                                width: "100%",
                                borderCollapse: "collapse",
                                borderSpacing: 0,
                              }}
                            >
                              <Box sx={{ display: "table-row-group" }}>
                                <Box className={classes.theTableCell}>
                                  <Box
                                    sx={{
                                      padding: "0 1rem",
                                      width: "max-content",
                                    }}
                                  >
                                    <Paragraph
                                      color="#219B8E"
                                      bold="600"
                                      margin="0 0 1rem 0"
                                    >
                                      Sample
                                    </Paragraph>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Sample
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Sample
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Sample
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Sample
                                      </Paragraph>
                                    </Box>
                                  </Box>
                                </Box>

                                <Box
                                  sx={{
                                    position: "relative",
                                    width: "1000px",
                                    overflowX: "scroll",
                                    display: "flex",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      position: "relative",

                                      display: "flex",
                                    }}
                                  >
                                    <Box
                                      className={classes.theTableCell}
                                      sx={{ width: "auto" }}
                                    >
                                      <Box
                                        sx={{
                                          background: "#DDD",
                                          padding: "16px",
                                        }}
                                      >
                                        <Paragraph
                                          color="#211d4e"
                                          bold="600"
                                          margin="0 0 1rem 0"
                                          center
                                        >
                                          Current
                                        </Paragraph>

                                        <Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Box
                                    className={classes.theTableCell}
                                    sx={{ width: "auto" }}
                                  >
                                    <Box
                                      sx={{
                                        background: "#DDD",
                                        padding: "16px",
                                      }}
                                    >
                                      <Paragraph
                                        color="#211d4e"
                                        bold="600"
                                        margin="0 0 1rem 0"
                                        center
                                      >
                                        22-09-2022
                                      </Paragraph>

                                      <Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box sx={{ width: "100%" }}>
                                            <FormInput
                                              fontWeight="600"
                                              textColor={
                                                themePlate().primaryThree
                                              }
                                              border="5px"
                                              bg="#fff"
                                              select
                                              value="Normal"
                                              // padding="8px 20px"
                                              // mb="0"
                                            >
                                              <MenuItem value="Normal">
                                                Normal
                                              </MenuItem>
                                              <MenuItem value="Normalx">
                                                Normal
                                              </MenuItem>
                                            </FormInput>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Paragraph
                              bold="600"
                              color="#219b8e"
                              margin="0 0 1rem 0"
                            >
                              Other Comment
                            </Paragraph>

                            <FormInput
                              boxshadow="none !important"
                              name="replaced_name"
                              placeholder="Type in your message & recommendation"
                              bg="#f6f6f6"
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              multiline
                              row={10}
                            />
                            <br />
                            <div className={classes.theButtonFlex}>
                              <div className={classes.theButton}>
                                <Button
                                  label="Save"
                                  bg="#3adebb"
                                  color="#FFF"
                                  br="6px"
                                  size="24px"
                                  bold="600"
                                  pd=".8rem 4rem"
                                  hvbg="#3adebb"
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {newTab == 7 && (
                          <>
                            <Box
                              sx={{
                                display: "table",
                                width: "100%",
                                borderCollapse: "collapse",
                                borderSpacing: 0,
                              }}
                            >
                              <Box sx={{ display: "table-row-group" }}>
                                <Box className={classes.theTableCell}>
                                  <Box
                                    sx={{
                                      padding: "0 1rem",
                                      width: "max-content",
                                    }}
                                  >
                                    <Paragraph
                                      color="#219B8E"
                                      bold="600"
                                      margin="0 0 1rem 0"
                                    >
                                      Sample
                                    </Paragraph>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Sample
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Sample
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Sample
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Sample
                                      </Paragraph>
                                    </Box>
                                  </Box>
                                </Box>

                                <Box
                                  sx={{
                                    position: "relative",
                                    width: "1000px",
                                    overflowX: "scroll",
                                    display: "flex",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      position: "relative",

                                      display: "flex",
                                    }}
                                  >
                                    <Box
                                      className={classes.theTableCell}
                                      sx={{ width: "auto" }}
                                    >
                                      <Box
                                        sx={{
                                          background: "#DDD",
                                          padding: "16px",
                                        }}
                                      >
                                        <Paragraph
                                          color="#211d4e"
                                          bold="600"
                                          margin="0 0 1rem 0"
                                          center
                                        >
                                          Current
                                        </Paragraph>

                                        <Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Box
                                    className={classes.theTableCell}
                                    sx={{ width: "auto" }}
                                  >
                                    <Box
                                      sx={{
                                        background: "#DDD",
                                        padding: "16px",
                                      }}
                                    >
                                      <Paragraph
                                        color="#211d4e"
                                        bold="600"
                                        margin="0 0 1rem 0"
                                        center
                                      >
                                        22-09-2022
                                      </Paragraph>

                                      <Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box sx={{ width: "100%" }}>
                                            <FormInput
                                              fontWeight="600"
                                              textColor={
                                                themePlate().primaryThree
                                              }
                                              border="5px"
                                              bg="#fff"
                                              select
                                              value="Normal"
                                              // padding="8px 20px"
                                              // mb="0"
                                            >
                                              <MenuItem value="Normal">
                                                Normal
                                              </MenuItem>
                                              <MenuItem value="Normalx">
                                                Normal
                                              </MenuItem>
                                            </FormInput>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Paragraph
                              bold="600"
                              color="#219b8e"
                              margin="0 0 1rem 0"
                            >
                              Other Comment
                            </Paragraph>

                            <FormInput
                              boxshadow="none !important"
                              name="replaced_name"
                              placeholder="Type in your message & recommendation"
                              bg="#f6f6f6"
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              multiline
                              row={10}
                            />
                            <br />
                            <div className={classes.theButtonFlex}>
                              <div className={classes.theButton}>
                                <Button
                                  label="Save"
                                  bg="#3adebb"
                                  color="#FFF"
                                  br="6px"
                                  size="24px"
                                  bold="600"
                                  pd=".8rem 4rem"
                                  hvbg="#3adebb"
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {newTab == 8 && (
                          <>
                            <Box
                              sx={{
                                display: "table",
                                width: "100%",
                                borderCollapse: "collapse",
                                borderSpacing: 0,
                              }}
                            >
                              <Box sx={{ display: "table-row-group" }}>
                                <Box className={classes.theTableCell}>
                                  <Box
                                    sx={{
                                      padding: "0 1rem",
                                      width: "max-content",
                                    }}
                                  >
                                    <Paragraph
                                      color="#219B8E"
                                      bold="600"
                                      margin="0 0 1rem 0"
                                    >
                                      Uterus
                                    </Paragraph>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Shape / Size
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        IUCD
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Uterine Cyst
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Nabothian Cyst
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Fibroid
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Endometrium
                                      </Paragraph>
                                    </Box>
                                    <Paragraph
                                      color="#219B8E"
                                      bold="600"
                                      margin="0 0 1rem 0"
                                    >
                                      Ovary
                                    </Paragraph>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Right Ovary Size
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Right Ovarian Cyst
                                      </Paragraph>
                                    </Box>

                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Left Ovary Size
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Left Ovarian Cyst
                                      </Paragraph>
                                    </Box>
                                    <Paragraph
                                      color="#219B8E"
                                      bold="600"
                                      margin="0 0 1rem 0"
                                    >
                                      Cul-de-sac
                                    </Paragraph>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Fluid Accumulation
                                      </Paragraph>
                                    </Box>
                                  </Box>
                                </Box>

                                <Box
                                  sx={{
                                    position: "relative",
                                    width: "1000px",
                                    overflowX: "scroll",
                                    display: "flex",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      position: "relative",
                                      display: "flex",
                                    }}
                                  >
                                    <Box
                                      className={classes.theTableCell}
                                      sx={{ width: "auto" }}
                                    >
                                      <Box
                                        sx={{
                                          background: "#DDD",
                                          padding: "16px",
                                        }}
                                      >
                                        <Paragraph
                                          color="#211d4e"
                                          bold="600"
                                          margin="0 0 1rem 0"
                                          center
                                        >
                                          Current
                                        </Paragraph>

                                        <Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  fontWeight="600"
                                                  textColor={
                                                    themePlate().primaryThree
                                                  }
                                                  border="5px"
                                                  bg="#fff"
                                                  select
                                                  // style={{ marginRight: "1rem" }}
                                                  value="Normal"
                                                  // padding="8px 20px"
                                                  // mb="0"
                                                >
                                                  <MenuItem value="Normal">
                                                    Normal
                                                  </MenuItem>
                                                  <MenuItem value="Anteverted">
                                                    Anteverted
                                                  </MenuItem>
                                                  <MenuItem value="Retroverted">
                                                    Retroverted
                                                  </MenuItem>
                                                  <MenuItem value="Atrophy">
                                                    Atrophy
                                                  </MenuItem>
                                                  <MenuItem value="Enlarge">
                                                    Enlarge
                                                  </MenuItem>
                                                </FormInput>
                                              </Box>

                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "53%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="-"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="-">-</MenuItem>
                                                <MenuItem value="Nil">
                                                  Nil
                                                </MenuItem>
                                                <MenuItem value="Present">
                                                  Present
                                                </MenuItem>
                                                <MenuItem value="Displaced">
                                                  Displaced
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  fontWeight="600"
                                                  textColor={
                                                    themePlate().primaryThree
                                                  }
                                                  border="5px"
                                                  bg="#fff"
                                                  select
                                                  // style={{ marginRight: "1rem" }}
                                                  value="Normal"
                                                  // padding="8px 20px"
                                                  // mb="0"
                                                >
                                                  <MenuItem value="Normal">
                                                    Normal
                                                  </MenuItem>
                                                  <MenuItem value="Normalx">
                                                    Normal
                                                  </MenuItem>
                                                </FormInput>
                                              </Box>

                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "53%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  fontWeight="600"
                                                  textColor={
                                                    themePlate().primaryThree
                                                  }
                                                  border="5px"
                                                  bg="#fff"
                                                  select
                                                  // style={{ marginRight: "1rem" }}
                                                  value="Normal"
                                                  // padding="8px 20px"
                                                  // mb="0"
                                                >
                                                  <MenuItem value="Normal">
                                                    Normal
                                                  </MenuItem>
                                                  <MenuItem value="Normalx">
                                                    Normal
                                                  </MenuItem>
                                                </FormInput>
                                              </Box>

                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "53%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  fontWeight="600"
                                                  textColor={
                                                    themePlate().primaryThree
                                                  }
                                                  border="5px"
                                                  bg="#fff"
                                                  select
                                                  // style={{ marginRight: "1rem" }}
                                                  value="Normal"
                                                  // padding="8px 20px"
                                                  // mb="0"
                                                >
                                                  <MenuItem value="Normal">
                                                    Normal
                                                  </MenuItem>
                                                  <MenuItem value="Normalx">
                                                    Normal
                                                  </MenuItem>
                                                </FormInput>
                                              </Box>

                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "53%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  fontWeight="600"
                                                  textColor={
                                                    themePlate().primaryThree
                                                  }
                                                  border="5px"
                                                  bg="#fff"
                                                  select
                                                  // style={{ marginRight: "1rem" }}
                                                  value="Normal"
                                                  // padding="8px 20px"
                                                  // mb="0"
                                                >
                                                  <MenuItem value="Normal">
                                                    Normal
                                                  </MenuItem>
                                                  <MenuItem value="Tickened">
                                                    Tickened
                                                  </MenuItem>
                                                </FormInput>
                                              </Box>

                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "53%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                width: "100%",
                                                marginTop: "2.5rem",
                                              }}
                                            >
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Enlarged">
                                                  Enlarged
                                                </MenuItem>
                                                <MenuItem value="Atrophy">
                                                  Atrophy
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  fontWeight="600"
                                                  textColor={
                                                    themePlate().primaryThree
                                                  }
                                                  border="5px"
                                                  bg="#fff"
                                                  select
                                                  // style={{ marginRight: "1rem" }}
                                                  value="Normal"
                                                  // padding="8px 20px"
                                                  // mb="0"
                                                >
                                                  <MenuItem value="Normal">
                                                    Normal
                                                  </MenuItem>
                                                  <MenuItem value="Normalx">
                                                    Normal
                                                  </MenuItem>
                                                </FormInput>
                                              </Box>

                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "53%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  fontWeight="600"
                                                  textColor={
                                                    themePlate().primaryThree
                                                  }
                                                  border="5px"
                                                  bg="#fff"
                                                  select
                                                  // style={{ marginRight: "1rem" }}
                                                  value="Normal"
                                                  // padding="8px 20px"
                                                  // mb="0"
                                                >
                                                  <MenuItem value="Normal">
                                                    Normal
                                                  </MenuItem>
                                                  <MenuItem value="Normalx">
                                                    Normal
                                                  </MenuItem>
                                                </FormInput>
                                              </Box>

                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "53%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                width: "100%",
                                                marginTop: "2.2rem",
                                              }}
                                            >
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Box
                                    className={classes.theTableCell}
                                    sx={{ width: "auto" }}
                                  >
                                    <Box
                                      sx={{
                                        background: "#DDD",
                                        padding: "16px",
                                      }}
                                    >
                                      <Paragraph
                                        color="#211d4e"
                                        bold="600"
                                        margin="0 0 1rem 0"
                                        center
                                      >
                                        22-09-2022
                                      </Paragraph>

                                      <Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Anteverted">
                                                  Anteverted
                                                </MenuItem>
                                                <MenuItem value="Retroverted">
                                                  Retroverted
                                                </MenuItem>
                                                <MenuItem value="Atrophy">
                                                  Atrophy
                                                </MenuItem>
                                                <MenuItem value="Enlarge">
                                                  Enlarge
                                                </MenuItem>
                                              </FormInput>
                                            </Box>

                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "53%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box sx={{ width: "100%" }}>
                                            <FormInput
                                              fontWeight="600"
                                              textColor={
                                                themePlate().primaryThree
                                              }
                                              border="5px"
                                              bg="#fff"
                                              select
                                              value="-"
                                              // padding="8px 20px"
                                              // mb="0"
                                            >
                                              <MenuItem value="-">-</MenuItem>
                                              <MenuItem value="Nil">
                                                Nil
                                              </MenuItem>
                                              <MenuItem value="Present">
                                                Present
                                              </MenuItem>
                                              <MenuItem value="Displaced">
                                                Displaced
                                              </MenuItem>
                                            </FormInput>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>

                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "53%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>

                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "53%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>

                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "53%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Tickened">
                                                  Tickened
                                                </MenuItem>
                                              </FormInput>
                                            </Box>

                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "53%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              width: "100%",
                                              marginTop: "2.5rem",
                                            }}
                                          >
                                            <FormInput
                                              fontWeight="600"
                                              textColor={
                                                themePlate().primaryThree
                                              }
                                              border="5px"
                                              bg="#fff"
                                              select
                                              value="Normal"
                                              // padding="8px 20px"
                                              // mb="0"
                                            >
                                              <MenuItem value="Normal">
                                                Normal
                                              </MenuItem>
                                              <MenuItem value="Enlarged">
                                                Enlarged
                                              </MenuItem>
                                              <MenuItem value="Atrophy">
                                                Atrophy
                                              </MenuItem>
                                            </FormInput>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>

                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "53%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box sx={{ width: "100%" }}>
                                            <FormInput
                                              fontWeight="600"
                                              textColor={
                                                themePlate().primaryThree
                                              }
                                              border="5px"
                                              bg="#fff"
                                              select
                                              value="Normal"
                                              // padding="8px 20px"
                                              // mb="0"
                                            >
                                              <MenuItem value="Normal">
                                                Normal
                                              </MenuItem>
                                              <MenuItem value="Normalx">
                                                Normal
                                              </MenuItem>
                                            </FormInput>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>

                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "53%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              width: "100%",
                                              marginTop: "2.2rem",
                                            }}
                                          >
                                            <FormInput
                                              fontWeight="600"
                                              textColor={
                                                themePlate().primaryThree
                                              }
                                              border="5px"
                                              bg="#fff"
                                              select
                                              value="Normal"
                                              // padding="8px 20px"
                                              // mb="0"
                                            >
                                              <MenuItem value="Normal">
                                                Normal
                                              </MenuItem>
                                              <MenuItem value="Normalx">
                                                Normal
                                              </MenuItem>
                                            </FormInput>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Paragraph
                              bold="600"
                              color="#219b8e"
                              margin="1rem 0"
                            >
                              Other Comment
                            </Paragraph>

                            <FormInput
                              boxshadow="none !important"
                              name="replaced_name"
                              placeholder="Type in your message & recommendation"
                              bg="#f6f6f6"
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              multiline
                              row={10}
                            />
                            <br />
                            <div className={classes.theButtonFlex}>
                              <div className={classes.theButton}>
                                <Button
                                  label="Save"
                                  bg="#3adebb"
                                  color="#FFF"
                                  br="6px"
                                  size="24px"
                                  bold="600"
                                  pd=".8rem 4rem"
                                  hvbg="#3adebb"
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {newTab == 9 && (
                          <>
                            <Box
                              sx={{
                                display: "table",
                                width: "100%",
                                borderCollapse: "collapse",
                                borderSpacing: 0,
                              }}
                            >
                              <Box sx={{ display: "table-row-group" }}>
                                <Box className={classes.theTableCell}>
                                  <Box
                                    sx={{
                                      padding: "0 1rem",
                                      width: "max-content",
                                    }}
                                  >
                                    <Paragraph
                                      color="#219B8E"
                                      bold="600"
                                      margin="0 0 1rem 0"
                                    >
                                      Prostate
                                    </Paragraph>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Shape / Size
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Volume
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Cyst
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Mass
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Calcification
                                      </Paragraph>
                                    </Box>
                                  </Box>
                                </Box>

                                <Box
                                  sx={{
                                    position: "relative",
                                    width: "1000px",
                                    overflowX: "scroll",
                                    display: "flex",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      position: "relative",
                                      display: "flex",
                                    }}
                                  >
                                    <Box
                                      className={classes.theTableCell}
                                      sx={{ width: "auto" }}
                                    >
                                      <Box
                                        sx={{
                                          background: "#DDD",
                                          padding: "16px",
                                        }}
                                      >
                                        <Paragraph
                                          color="#211d4e"
                                          bold="600"
                                          margin="0 0 1rem 0"
                                          center
                                        >
                                          Current
                                        </Paragraph>

                                        <Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "85px" }}>
                                                <FormInput
                                                  bg="#FFF"
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="hb"
                                                  boxshadow="unset"
                                                  padding="16px .4rem"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",
                                                          width: "63%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                              <Box sx={{ width: "85px" }}>
                                                <FormInput
                                                  bg="#FFF"
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="hb"
                                                  boxshadow="unset"
                                                  padding="16px .4rem"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "63%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                              <Box sx={{ width: "85px" }}>
                                                <FormInput
                                                  bg="#FFF"
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="hb"
                                                  boxshadow="unset"
                                                  padding="16px .4rem"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "63%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  fontWeight="600"
                                                  textColor={
                                                    themePlate().primaryThree
                                                  }
                                                  border="5px"
                                                  bg="#fff"
                                                  select
                                                  // style={{ marginRight: "1rem" }}
                                                  value="Normal"
                                                  // padding="8px 20px"
                                                  // mb="0"
                                                >
                                                  <MenuItem value="Normal">
                                                    Normal
                                                  </MenuItem>
                                                  <MenuItem value="Enlarged">
                                                    Enlarged
                                                  </MenuItem>
                                                </FormInput>
                                              </Box>

                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "63%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  fontWeight="600"
                                                  textColor={
                                                    themePlate().primaryThree
                                                  }
                                                  border="5px"
                                                  bg="#fff"
                                                  select
                                                  // style={{ marginRight: "1rem" }}
                                                  value="Single"
                                                  // padding="8px 20px"
                                                  // mb="0"
                                                >
                                                  <MenuItem value="Single">
                                                    Single
                                                  </MenuItem>
                                                  <MenuItem value="Multiple">
                                                    Multiple
                                                  </MenuItem>
                                                </FormInput>
                                              </Box>

                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "63%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  fontWeight="600"
                                                  textColor={
                                                    themePlate().primaryThree
                                                  }
                                                  border="5px"
                                                  bg="#fff"
                                                  select
                                                  // style={{ marginRight: "1rem" }}
                                                  value="Single"
                                                  // padding="8px 20px"
                                                  // mb="0"
                                                >
                                                  <MenuItem value="Single">
                                                    Single
                                                  </MenuItem>
                                                  <MenuItem value="Multiple">
                                                    Multiple
                                                  </MenuItem>
                                                </FormInput>
                                              </Box>

                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "63%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  fontWeight="600"
                                                  textColor={
                                                    themePlate().primaryThree
                                                  }
                                                  border="5px"
                                                  bg="#fff"
                                                  select
                                                  // style={{ marginRight: "1rem" }}
                                                  value="Central"
                                                  // padding="8px 20px"
                                                  // mb="0"
                                                >
                                                  <MenuItem value="Central">
                                                    Central
                                                  </MenuItem>
                                                  <MenuItem value="Rt">
                                                    Rt
                                                  </MenuItem>
                                                  <MenuItem value="Lt">
                                                    Lt
                                                  </MenuItem>
                                                </FormInput>
                                              </Box>

                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "63%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Box
                                    className={classes.theTableCell}
                                    sx={{ width: "auto" }}
                                  >
                                    <Box
                                      sx={{
                                        background: "#DDD",
                                        padding: "16px",
                                      }}
                                    >
                                      <Paragraph
                                        color="#211d4e"
                                        bold="600"
                                        margin="0 0 1rem 0"
                                        center
                                      >
                                        Current
                                      </Paragraph>

                                      <Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "85px" }}>
                                              <FormInput
                                                bg="#FFF"
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="hb"
                                                boxshadow="unset"
                                                padding="16px .4rem"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",
                                                        width: "63%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                            <Box sx={{ width: "85px" }}>
                                              <FormInput
                                                bg="#FFF"
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="hb"
                                                boxshadow="unset"
                                                padding="16px .4rem"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "63%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                            <Box sx={{ width: "85px" }}>
                                              <FormInput
                                                bg="#FFF"
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="hb"
                                                boxshadow="unset"
                                                padding="16px .4rem"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "63%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Enlarged">
                                                  Enlarged
                                                </MenuItem>
                                              </FormInput>
                                            </Box>

                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "63%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Single"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Single">
                                                  Single
                                                </MenuItem>
                                                <MenuItem value="Multiple">
                                                  Multiple
                                                </MenuItem>
                                              </FormInput>
                                            </Box>

                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "63%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Single"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Single">
                                                  Single
                                                </MenuItem>
                                                <MenuItem value="Multiple">
                                                  Multiple
                                                </MenuItem>
                                              </FormInput>
                                            </Box>

                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "63%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Central"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Central">
                                                  Central
                                                </MenuItem>
                                                <MenuItem value="Rt">
                                                  Rt
                                                </MenuItem>
                                                <MenuItem value="Lt">
                                                  Lt
                                                </MenuItem>
                                              </FormInput>
                                            </Box>

                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "63%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Paragraph
                              bold="600"
                              color="#219b8e"
                              margin="0 0 1rem 0"
                            >
                              Other Comment
                            </Paragraph>

                            <FormInput
                              boxshadow="none !important"
                              name="replaced_name"
                              placeholder="Type in your message & recommendation"
                              bg="#f6f6f6"
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              multiline
                              row={10}
                            />
                            <br />
                            <div className={classes.theButtonFlex}>
                              <div className={classes.theButton}>
                                <Button
                                  label="Save"
                                  bg="#3adebb"
                                  color="#FFF"
                                  br="6px"
                                  size="24px"
                                  bold="600"
                                  pd=".8rem 4rem"
                                  hvbg="#3adebb"
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {newTab == 10 && (
                          <>
                            <Box
                              sx={{
                                display: "table",
                                width: "100%",
                                borderCollapse: "collapse",
                                borderSpacing: 0,
                              }}
                            >
                              <Box sx={{ display: "table-row-group" }}>
                                <Box className={classes.theTableCell}>
                                  <Box
                                    sx={{
                                      padding: "0 1rem",
                                      width: "max-content",
                                    }}
                                  >
                                    <Paragraph
                                      color="#219B8E"
                                      bold="600"
                                      margin="0 0 1rem 0"
                                    >
                                      RT Testis
                                    </Paragraph>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Size
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Echopattern
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Epididymis
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Hydocele
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Varicole
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Calcification
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Solid Nodule
                                      </Paragraph>
                                    </Box>{" "}
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Cyst
                                      </Paragraph>
                                    </Box>{" "}
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Undescended / Ectopic Testic
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Scrotal Sac Stone
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Diffuse / Focal Enlargement
                                      </Paragraph>
                                    </Box>
                                    <Paragraph
                                      color="#219B8E"
                                      bold="600"
                                      margin="0 0 1rem 0"
                                    >
                                      LT Testis
                                    </Paragraph>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Size
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Echopattern
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Epididymis
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Hydocele
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Varicole
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Calcification
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Solid Nodule
                                      </Paragraph>
                                    </Box>{" "}
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Cyst
                                      </Paragraph>
                                    </Box>{" "}
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Undescended / Ectopic Testic
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Scrotal Sac Stone
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Diffuse / Focal Enlargement
                                      </Paragraph>
                                    </Box>
                                  </Box>
                                </Box>

                                <Box
                                  sx={{
                                    position: "relative",
                                    width: "1000px",
                                    overflowX: "scroll",
                                    display: "flex",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      position: "relative",

                                      display: "flex",
                                    }}
                                  >
                                    <Box
                                      className={classes.theTableCell}
                                      sx={{ width: "auto" }}
                                    >
                                      <Box
                                        sx={{
                                          background: "#DDD",
                                          padding: "16px",
                                        }}
                                      >
                                        <Paragraph
                                          color="#211d4e"
                                          bold="600"
                                          margin="0 0 1rem 0"
                                          center
                                        >
                                          Current
                                        </Paragraph>

                                        <Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  fontWeight="600"
                                                  textColor={
                                                    themePlate().primaryThree
                                                  }
                                                  border="5px"
                                                  bg="#fff"
                                                  select
                                                  // style={{ marginRight: "1rem" }}
                                                  value="Normal"
                                                  // padding="8px 20px"
                                                  // mb="0"
                                                >
                                                  <MenuItem value="Normal">
                                                    Normal
                                                  </MenuItem>
                                                  <MenuItem value="Enlarged">
                                                    Enlarged
                                                  </MenuItem>
                                                  <MenuItem value="Atrophy">
                                                    Atrophy
                                                  </MenuItem>
                                                </FormInput>
                                              </Box>

                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "63%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Homogenous"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Homogenous">
                                                  Homogenous
                                                </MenuItem>
                                                <MenuItem value="Heterogenous">
                                                  Heterogenous
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  fontWeight="600"
                                                  textColor={
                                                    themePlate().primaryThree
                                                  }
                                                  border="5px"
                                                  bg="#fff"
                                                  select
                                                  // style={{ marginRight: "1rem" }}
                                                  value="Normal"
                                                  // padding="8px 20px"
                                                  // mb="0"
                                                >
                                                  <MenuItem value="Normal">
                                                    Normal
                                                  </MenuItem>
                                                  <MenuItem value="Enlarged">
                                                    Enlarged
                                                  </MenuItem>
                                                </FormInput>
                                              </Box>

                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "63%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Absent"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Absent">
                                                  Absent
                                                </MenuItem>
                                                <MenuItem value="Present">
                                                  Present
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  fontWeight="600"
                                                  textColor={
                                                    themePlate().primaryThree
                                                  }
                                                  border="5px"
                                                  bg="#fff"
                                                  select
                                                  // style={{ marginRight: "1rem" }}
                                                  value="Absent"
                                                  // padding="8px 20px"
                                                  // mb="0"
                                                >
                                                  <MenuItem value="Absent">
                                                    Absent
                                                  </MenuItem>
                                                  <MenuItem value="Single">
                                                    Single
                                                  </MenuItem>
                                                  <MenuItem value="Multiple Microlithiasis">
                                                    Multiple Microlithiasis
                                                  </MenuItem>
                                                </FormInput>
                                              </Box>
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "63%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  fontWeight="600"
                                                  textColor={
                                                    themePlate().primaryThree
                                                  }
                                                  border="5px"
                                                  bg="#fff"
                                                  select
                                                  // style={{ marginRight: "1rem" }}
                                                  value="Absent"
                                                  // padding="8px 20px"
                                                  // mb="0"
                                                >
                                                  <MenuItem value="Absent">
                                                    Absent
                                                  </MenuItem>
                                                  <MenuItem value="Single">
                                                    Single
                                                  </MenuItem>
                                                  <MenuItem value="Multiple">
                                                    Multiple
                                                  </MenuItem>
                                                </FormInput>
                                              </Box>
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "63%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  fontWeight="600"
                                                  textColor={
                                                    themePlate().primaryThree
                                                  }
                                                  border="5px"
                                                  bg="#fff"
                                                  select
                                                  // style={{ marginRight: "1rem" }}
                                                  value="Normal"
                                                  // padding="8px 20px"
                                                  // mb="0"
                                                >
                                                  <MenuItem value="Normal">
                                                    Normal
                                                  </MenuItem>
                                                  <MenuItem value="Normalx">
                                                    Normal
                                                  </MenuItem>
                                                </FormInput>
                                              </Box>

                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "63%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  fontWeight="600"
                                                  textColor={
                                                    themePlate().primaryThree
                                                  }
                                                  border="5px"
                                                  bg="#fff"
                                                  select
                                                  // style={{ marginRight: "1rem" }}
                                                  value="Normal"
                                                  // padding="8px 20px"
                                                  // mb="0"
                                                >
                                                  <MenuItem value="Normal">
                                                    Normal
                                                  </MenuItem>
                                                  <MenuItem value="Normalx">
                                                    Normal
                                                  </MenuItem>
                                                </FormInput>
                                              </Box>

                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "63%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>

                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                                marginTop: "3rem",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  fontWeight="600"
                                                  textColor={
                                                    themePlate().primaryThree
                                                  }
                                                  border="5px"
                                                  bg="#fff"
                                                  select
                                                  // style={{ marginRight: "1rem" }}
                                                  value="Normal"
                                                  // padding="8px 20px"
                                                  // mb="0"
                                                >
                                                  <MenuItem value="Normal">
                                                    Normal
                                                  </MenuItem>
                                                  <MenuItem value="Enlarged">
                                                    Enlarged
                                                  </MenuItem>
                                                  <MenuItem value="Atrophy">
                                                    Atrophy
                                                  </MenuItem>
                                                </FormInput>
                                              </Box>

                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "63%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Homogenous"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Homogenous">
                                                  Homogenous
                                                </MenuItem>
                                                <MenuItem value="Heterogenous">
                                                  Heterogenous
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  fontWeight="600"
                                                  textColor={
                                                    themePlate().primaryThree
                                                  }
                                                  border="5px"
                                                  bg="#fff"
                                                  select
                                                  // style={{ marginRight: "1rem" }}
                                                  value="Normal"
                                                  // padding="8px 20px"
                                                  // mb="0"
                                                >
                                                  <MenuItem value="Normal">
                                                    Normal
                                                  </MenuItem>
                                                  <MenuItem value="Enlarged">
                                                    Enlarged
                                                  </MenuItem>
                                                </FormInput>
                                              </Box>

                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "63%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Absent"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Absent">
                                                  Absent
                                                </MenuItem>
                                                <MenuItem value="Present">
                                                  Present
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  fontWeight="600"
                                                  textColor={
                                                    themePlate().primaryThree
                                                  }
                                                  border="5px"
                                                  bg="#fff"
                                                  select
                                                  // style={{ marginRight: "1rem" }}
                                                  value="Absent"
                                                  // padding="8px 20px"
                                                  // mb="0"
                                                >
                                                  <MenuItem value="Absent">
                                                    Absent
                                                  </MenuItem>
                                                  <MenuItem value="Single">
                                                    Single
                                                  </MenuItem>
                                                  <MenuItem value="Multiple Microlithiasis">
                                                    Multiple Microlithiasis
                                                  </MenuItem>
                                                </FormInput>
                                              </Box>
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "63%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  fontWeight="600"
                                                  textColor={
                                                    themePlate().primaryThree
                                                  }
                                                  border="5px"
                                                  bg="#fff"
                                                  select
                                                  // style={{ marginRight: "1rem" }}
                                                  value="Absent"
                                                  // padding="8px 20px"
                                                  // mb="0"
                                                >
                                                  <MenuItem value="Absent">
                                                    Absent
                                                  </MenuItem>
                                                  <MenuItem value="Single">
                                                    Single
                                                  </MenuItem>
                                                  <MenuItem value="Multiple">
                                                    Multiple
                                                  </MenuItem>
                                                </FormInput>
                                              </Box>
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "63%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  fontWeight="600"
                                                  textColor={
                                                    themePlate().primaryThree
                                                  }
                                                  border="5px"
                                                  bg="#fff"
                                                  select
                                                  // style={{ marginRight: "1rem" }}
                                                  value="Normal"
                                                  // padding="8px 20px"
                                                  // mb="0"
                                                >
                                                  <MenuItem value="Normal">
                                                    Normal
                                                  </MenuItem>
                                                  <MenuItem value="Normalx">
                                                    Normal
                                                  </MenuItem>
                                                </FormInput>
                                              </Box>

                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "63%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  fontWeight="600"
                                                  textColor={
                                                    themePlate().primaryThree
                                                  }
                                                  border="5px"
                                                  bg="#fff"
                                                  select
                                                  // style={{ marginRight: "1rem" }}
                                                  value="Normal"
                                                  // padding="8px 20px"
                                                  // mb="0"
                                                >
                                                  <MenuItem value="Normal">
                                                    Normal
                                                  </MenuItem>
                                                  <MenuItem value="Normalx">
                                                    Normal
                                                  </MenuItem>
                                                </FormInput>
                                              </Box>

                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "63%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Box
                                    className={classes.theTableCell}
                                    sx={{ width: "auto" }}
                                  >
                                    <Box
                                      sx={{
                                        background: "#DDD",
                                        padding: "16px",
                                      }}
                                    >
                                      <Paragraph
                                        color="#211d4e"
                                        bold="600"
                                        margin="0 0 1rem 0"
                                        center
                                      >
                                        22-09-2022
                                      </Paragraph>

                                      <Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Enlarged">
                                                  Enlarged
                                                </MenuItem>
                                                <MenuItem value="Atrophy">
                                                  Atrophy
                                                </MenuItem>
                                              </FormInput>
                                            </Box>

                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "63%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box sx={{ width: "100%" }}>
                                            <FormInput
                                              fontWeight="600"
                                              textColor={
                                                themePlate().primaryThree
                                              }
                                              border="5px"
                                              bg="#fff"
                                              select
                                              // style={{ marginRight: "1rem" }}
                                              value="Homogenous"
                                              // padding="8px 20px"
                                              // mb="0"
                                            >
                                              <MenuItem value="Homogenous">
                                                Homogenous
                                              </MenuItem>
                                              <MenuItem value="Heterogenous">
                                                Heterogenous
                                              </MenuItem>
                                            </FormInput>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Enlarged">
                                                  Enlarged
                                                </MenuItem>
                                              </FormInput>
                                            </Box>

                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "63%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box sx={{ width: "100%" }}>
                                            <FormInput
                                              fontWeight="600"
                                              textColor={
                                                themePlate().primaryThree
                                              }
                                              border="5px"
                                              bg="#fff"
                                              select
                                              // style={{ marginRight: "1rem" }}
                                              value="Absent"
                                              // padding="8px 20px"
                                              // mb="0"
                                            >
                                              <MenuItem value="Absent">
                                                Absent
                                              </MenuItem>
                                              <MenuItem value="Present">
                                                Present
                                              </MenuItem>
                                            </FormInput>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box sx={{ width: "100%" }}>
                                            <FormInput
                                              fontWeight="600"
                                              textColor={
                                                themePlate().primaryThree
                                              }
                                              border="5px"
                                              bg="#fff"
                                              select
                                              // style={{ marginRight: "1rem" }}
                                              value="Normal"
                                              // padding="8px 20px"
                                              // mb="0"
                                            >
                                              <MenuItem value="Normal">
                                                Normal
                                              </MenuItem>
                                              <MenuItem value="Normalx">
                                                Normal
                                              </MenuItem>
                                            </FormInput>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Absent"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Absent">
                                                  Absent
                                                </MenuItem>
                                                <MenuItem value="Single">
                                                  Single
                                                </MenuItem>
                                                <MenuItem value="Multiple Microlithiasis">
                                                  Multiple Microlithiasis
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "63%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Absent"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Absent">
                                                  Absent
                                                </MenuItem>
                                                <MenuItem value="Single">
                                                  Single
                                                </MenuItem>
                                                <MenuItem value="Multiple">
                                                  Multiple
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "63%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>

                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "63%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box sx={{ width: "100%" }}>
                                            <FormInput
                                              fontWeight="600"
                                              textColor={
                                                themePlate().primaryThree
                                              }
                                              border="5px"
                                              bg="#fff"
                                              select
                                              // style={{ marginRight: "1rem" }}
                                              value="Normal"
                                              // padding="8px 20px"
                                              // mb="0"
                                            >
                                              <MenuItem value="Normal">
                                                Normal
                                              </MenuItem>
                                              <MenuItem value="Normalx">
                                                Normal
                                              </MenuItem>
                                            </FormInput>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>

                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "63%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box sx={{ width: "100%" }}>
                                            <FormInput
                                              fontWeight="600"
                                              textColor={
                                                themePlate().primaryThree
                                              }
                                              border="5px"
                                              bg="#fff"
                                              select
                                              // style={{ marginRight: "1rem" }}
                                              value="Normal"
                                              // padding="8px 20px"
                                              // mb="0"
                                            >
                                              <MenuItem value="Normal">
                                                Normal
                                              </MenuItem>
                                              <MenuItem value="Normalx">
                                                Normal
                                              </MenuItem>
                                            </FormInput>
                                          </Box>
                                        </Box>

                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                              marginTop: "3rem",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Enlarged">
                                                  Enlarged
                                                </MenuItem>
                                                <MenuItem value="Atrophy">
                                                  Atrophy
                                                </MenuItem>
                                              </FormInput>
                                            </Box>

                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "63%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box sx={{ width: "100%" }}>
                                            <FormInput
                                              fontWeight="600"
                                              textColor={
                                                themePlate().primaryThree
                                              }
                                              border="5px"
                                              bg="#fff"
                                              select
                                              // style={{ marginRight: "1rem" }}
                                              value="Homogenous"
                                              // padding="8px 20px"
                                              // mb="0"
                                            >
                                              <MenuItem value="Homogenous">
                                                Homogenous
                                              </MenuItem>
                                              <MenuItem value="Heterogenous">
                                                Heterogenous
                                              </MenuItem>
                                            </FormInput>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Enlarged">
                                                  Enlarged
                                                </MenuItem>
                                              </FormInput>
                                            </Box>

                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "63%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box sx={{ width: "100%" }}>
                                            <FormInput
                                              fontWeight="600"
                                              textColor={
                                                themePlate().primaryThree
                                              }
                                              border="5px"
                                              bg="#fff"
                                              select
                                              // style={{ marginRight: "1rem" }}
                                              value="Absent"
                                              // padding="8px 20px"
                                              // mb="0"
                                            >
                                              <MenuItem value="Absent">
                                                Absent
                                              </MenuItem>
                                              <MenuItem value="Present">
                                                Present
                                              </MenuItem>
                                            </FormInput>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box sx={{ width: "100%" }}>
                                            <FormInput
                                              fontWeight="600"
                                              textColor={
                                                themePlate().primaryThree
                                              }
                                              border="5px"
                                              bg="#fff"
                                              select
                                              // style={{ marginRight: "1rem" }}
                                              value="Normal"
                                              // padding="8px 20px"
                                              // mb="0"
                                            >
                                              <MenuItem value="Normal">
                                                Normal
                                              </MenuItem>
                                              <MenuItem value="Normalx">
                                                Normal
                                              </MenuItem>
                                            </FormInput>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Absent"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Absent">
                                                  Absent
                                                </MenuItem>
                                                <MenuItem value="Single">
                                                  Single
                                                </MenuItem>
                                                <MenuItem value="Multiple Microlithiasis">
                                                  Multiple Microlithiasis
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "63%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Absent"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Absent">
                                                  Absent
                                                </MenuItem>
                                                <MenuItem value="Single">
                                                  Single
                                                </MenuItem>
                                                <MenuItem value="Multiple">
                                                  Multiple
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "63%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>

                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "63%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box sx={{ width: "100%" }}>
                                            <FormInput
                                              fontWeight="600"
                                              textColor={
                                                themePlate().primaryThree
                                              }
                                              border="5px"
                                              bg="#fff"
                                              select
                                              // style={{ marginRight: "1rem" }}
                                              value="Normal"
                                              // padding="8px 20px"
                                              // mb="0"
                                            >
                                              <MenuItem value="Normal">
                                                Normal
                                              </MenuItem>
                                              <MenuItem value="Normalx">
                                                Normal
                                              </MenuItem>
                                            </FormInput>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>

                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "63%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box sx={{ width: "100%" }}>
                                            <FormInput
                                              fontWeight="600"
                                              textColor={
                                                themePlate().primaryThree
                                              }
                                              border="5px"
                                              bg="#fff"
                                              select
                                              // style={{ marginRight: "1rem" }}
                                              value="Normal"
                                              // padding="8px 20px"
                                              // mb="0"
                                            >
                                              <MenuItem value="Normal">
                                                Normal
                                              </MenuItem>
                                              <MenuItem value="Normalx">
                                                Normal
                                              </MenuItem>
                                            </FormInput>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Paragraph
                              bold="600"
                              color="#219b8e"
                              margin="0 0 1rem 0"
                            >
                              Other Comment
                            </Paragraph>

                            <FormInput
                              boxshadow="none !important"
                              name="replaced_name"
                              placeholder="Type in your message & recommendation"
                              bg="#f6f6f6"
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              multiline
                              row={10}
                            />
                            <br />
                            <div className={classes.theButtonFlex}>
                              <div className={classes.theButton}>
                                <Button
                                  label="Save"
                                  bg="#3adebb"
                                  color="#FFF"
                                  br="6px"
                                  size="24px"
                                  bold="600"
                                  pd=".8rem 4rem"
                                  hvbg="#3adebb"
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {newTab == 11 && (
                          <>
                            <Box
                              sx={{
                                display: "table",
                                width: "100%",
                                borderCollapse: "collapse",
                                borderSpacing: 0,
                              }}
                            >
                              <Box sx={{ display: "table-row-group" }}>
                                <Box className={classes.theTableCell}>
                                  <Box
                                    sx={{
                                      padding: "0 1rem",
                                      width: "max-content",
                                    }}
                                  >
                                    <Paragraph
                                      color="#219B8E"
                                      bold="600"
                                      margin="0 0 1rem 0"
                                    >
                                      Thyroid
                                    </Paragraph>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Size
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Echotexture
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Vascularity
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Nodule
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Cyst
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Calcification
                                      </Paragraph>
                                    </Box>
                                    <Box
                                      sx={{
                                        padding: "16px 0",
                                        position: "relative",
                                        height: "1.4375em",
                                        boxSizing: "content-box",
                                        display: "block",
                                        marginBottom: ".8rem",
                                      }}
                                    >
                                      <Paragraph color="#211D4E" bold="700">
                                        Cervical Lymph Node
                                      </Paragraph>
                                    </Box>
                                  </Box>
                                </Box>

                                <Box
                                  sx={{
                                    position: "relative",
                                    width: "1000px",
                                    overflowX: "scroll",
                                    display: "flex",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      position: "relative",

                                      display: "flex",
                                    }}
                                  >
                                    <Box
                                      className={classes.theTableCell}
                                      sx={{ width: "auto" }}
                                    >
                                      <Box
                                        sx={{
                                          background: "#DDD",
                                          padding: "16px",
                                        }}
                                      >
                                        <Paragraph
                                          color="#211d4e"
                                          bold="600"
                                          margin="0 0 1rem 0"
                                          center
                                        >
                                          Current
                                        </Paragraph>

                                        <Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  fontWeight="600"
                                                  textColor={
                                                    themePlate().primaryThree
                                                  }
                                                  border="5px"
                                                  bg="#fff"
                                                  select
                                                  // style={{ marginRight: "1rem" }}
                                                  value="Normal"
                                                  // padding="8px 20px"
                                                  // mb="0"
                                                >
                                                  <MenuItem value="Normal">
                                                    Normal
                                                  </MenuItem>
                                                  <MenuItem value="Enlarged">
                                                    Enlarged
                                                  </MenuItem>
                                                </FormInput>
                                              </Box>
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "63%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Homogenous"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Homogenous">
                                                  Homogenous
                                                </MenuItem>
                                                <MenuItem value="Heterogenous">
                                                  Heterogenous
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  fontWeight="600"
                                                  textColor={
                                                    themePlate().primaryThree
                                                  }
                                                  border="5px"
                                                  bg="#fff"
                                                  select
                                                  // style={{ marginRight: "1rem" }}
                                                  value="Normal"
                                                  // padding="8px 20px"
                                                  // mb="0"
                                                >
                                                  <MenuItem value="Normal">
                                                    Normal
                                                  </MenuItem>
                                                  <MenuItem value="Normalx">
                                                    Normal
                                                  </MenuItem>
                                                </FormInput>
                                              </Box>
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "63%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  fontWeight="600"
                                                  textColor={
                                                    themePlate().primaryThree
                                                  }
                                                  border="5px"
                                                  bg="#fff"
                                                  select
                                                  // style={{ marginRight: "1rem" }}
                                                  value="Single"
                                                  // padding="8px 20px"
                                                  // mb="0"
                                                >
                                                  <MenuItem value="Single">
                                                    Single
                                                  </MenuItem>
                                                  <MenuItem value="Multiple">
                                                    Multiple
                                                  </MenuItem>
                                                </FormInput>
                                              </Box>
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "63%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  fontWeight="600"
                                                  textColor={
                                                    themePlate().primaryThree
                                                  }
                                                  border="5px"
                                                  bg="#fff"
                                                  select
                                                  // style={{ marginRight: "1rem" }}
                                                  value="Normal"
                                                  // padding="8px 20px"
                                                  // mb="0"
                                                >
                                                  <MenuItem value="Normal">
                                                    Normal
                                                  </MenuItem>
                                                  <MenuItem value="Normalx">
                                                    Normal
                                                  </MenuItem>
                                                </FormInput>
                                              </Box>
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "63%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  bg="#FFF"
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="hb"
                                                  boxshadow="unset"
                                                  placeholder="-"
                                                  // style={{ marginRight: "1rem" }}
                                                />
                                              </Box>
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "63%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>

                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  fontWeight="600"
                                                  textColor={
                                                    themePlate().primaryThree
                                                  }
                                                  border="5px"
                                                  bg="#fff"
                                                  select
                                                  // style={{ marginRight: "1rem" }}
                                                  value="RT"
                                                  // padding="8px 20px"
                                                  // mb="0"
                                                >
                                                  <MenuItem value="RT">
                                                    RT
                                                  </MenuItem>
                                                  <MenuItem value="LT">
                                                    LT
                                                  </MenuItem>
                                                </FormInput>
                                              </Box>
                                              <Box sx={{ width: "130px" }}>
                                                <FormInput
                                                  number
                                                  textcolor="#211d4e"
                                                  fontWeight="600"
                                                  textalign="center"
                                                  name="height"
                                                  boxshadow="unset"
                                                  bg="#F9F9F9"
                                                  mb="0"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <Paragraph
                                                        style={{
                                                          fontWeight: "400",

                                                          width: "63%",
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        cm
                                                      </Paragraph>
                                                    ),
                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Box
                                    className={classes.theTableCell}
                                    sx={{ width: "auto" }}
                                  >
                                    <Box
                                      sx={{
                                        background: "#DDD",
                                        padding: "16px",
                                      }}
                                    >
                                      <Paragraph
                                        color="#211d4e"
                                        bold="600"
                                        margin="0 0 1rem 0"
                                        center
                                      >
                                        22-09-2022
                                      </Paragraph>

                                      <Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "63%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box sx={{ width: "100%" }}>
                                            <FormInput
                                              fontWeight="600"
                                              textColor={
                                                themePlate().primaryThree
                                              }
                                              border="5px"
                                              bg="#fff"
                                              select
                                              // style={{ marginRight: "1rem" }}
                                              value="Normal"
                                              // padding="8px 20px"
                                              // mb="0"
                                            >
                                              <MenuItem value="Normal">
                                                Normal
                                              </MenuItem>
                                              <MenuItem value="Normalx">
                                                Normal
                                              </MenuItem>
                                            </FormInput>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "63%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "63%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "63%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                bg="#FFF"
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="hb"
                                                boxshadow="unset"
                                                placeholder="-"
                                                // style={{ marginRight: "1rem" }}
                                              />
                                            </Box>
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "63%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>

                                        <Box className={classes.theTableCell2}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                // style={{ marginRight: "1rem" }}
                                                value="Normal"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="Normal">
                                                  Normal
                                                </MenuItem>
                                                <MenuItem value="Normalx">
                                                  Normal
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                            <Box sx={{ width: "130px" }}>
                                              <FormInput
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="height"
                                                boxshadow="unset"
                                                bg="#F9F9F9"
                                                mb="0"
                                                InputProps={{
                                                  endAdornment: (
                                                    <Paragraph
                                                      style={{
                                                        fontWeight: "400",

                                                        width: "63%",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      cm
                                                    </Paragraph>
                                                  ),
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Paragraph
                              bold="600"
                              color="#219b8e"
                              margin="0 0 1rem 0"
                            >
                              Other Comment
                            </Paragraph>

                            <FormInput
                              boxshadow="none !important"
                              name="replaced_name"
                              placeholder="Type in your message & recommendation"
                              bg="#f6f6f6"
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              multiline
                              row={10}
                            />
                            <br />
                            <div className={classes.theButtonFlex}>
                              <div className={classes.theButton}>
                                <Button
                                  label="Save"
                                  bg="#3adebb"
                                  color="#FFF"
                                  br="6px"
                                  size="24px"
                                  bold="600"
                                  pd=".8rem 4rem"
                                  hvbg="#3adebb"
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {newTab == 12 && (
                          <>
                            <Paragraph
                              bold="600"
                              color="#219b8e"
                              margin="1rem 0"
                            >
                              Other Comment
                            </Paragraph>

                            <FormInput
                              boxshadow="none !important"
                              name="replaced_name"
                              placeholder="Type in your message & recommendation"
                              bg="#f6f6f6"
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              multiline
                              row={10}
                            />
                            <br />
                            <div className={classes.theButtonFlex}>
                              <div className={classes.theButton}>
                                <Button
                                  label="Save"
                                  bg="#3adebb"
                                  color="#FFF"
                                  br="6px"
                                  size="24px"
                                  bold="600"
                                  pd=".8rem 4rem"
                                  hvbg="#3adebb"
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {/* <div className={classes.theButtonFlex}>
														<div className={classes.theButton}>
																<Button
																		// onClick={handleSaveComment}
																		label="Save"
																		color="#fff"
																		hvbg="#3ADEBB"
																		bg="#3ADEBB"
																		size="16px"
																		bold="700"
																		pd="10px 23px"
																		br="3px"
																		boxshadow="0px 3px 15px rgba(33, 155, 142, 0.25)"
																/>
														</div>
												</div> */}
                      </Box>
                    )}

                    {tabs == 1 && (
                      <Box sx={{ padding: "1rem 0" }}>
                        <Paragraph
                          bold="600"
                          color="#219b8e"
                          margin="0 0 1rem 0"
                        >
                          Add On Service
                        </Paragraph>

                        <Box
                          sx={{
                            borderRadius: "5px",
                            border: "2px solid #EDEDED",
                            // padding: "0 1rem",
                          }}
                        >
                          <Box
                            sx={{
                              background: "#FBFBFB",
                              padding: "1rem",
                            }}
                          >
                            <Paragraph color="#5A567B" bold="600">
                              Optional Items
                            </Paragraph>
                          </Box>
                          <Box sx={{ padding: "1rem" }}>
                            <div className={classes.theField}>
                              <FormInput
                                className={classes.theBorderRadius}
                                name="search"
                                placeholder="Search..."
                                fontWeight="500"
                                textColor={themePlate().secondaryTwo}
                                border="1px solid #eeeeee"
                                bg="transparent"
                                padding="12px 25px 12px 65px"
                                mb="0"
                                size="18px"
                              />
                              <div className={classes.theAbsoluteIcon}>
                                <div className={classes.theIcon20}>
                                  <img src={SearchIcon} alt={SearchIcon} />
                                </div>
                              </div>
                            </div>
                            <br />
                            <TableContainer style={{ height: "400px" }}>
                              <Table>
                                <TableHead sx={{ background: "transparent" }}>
                                  <TableRow>
                                    <TableCell sx={{ color: "#5A567B" }}>
                                      Items
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        textAlign: "center",
                                        color: "#5A567B",
                                      }}
                                    >
                                      Price
                                    </TableCell>

                                    <TableCell
                                      sx={{ textAlign: "center" }}
                                    ></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>
                                      <div
                                        style={{ display: "flex" }}
                                        className={classes.theWidth}
                                      >
                                        <Paragraph
                                          margin="0 0 .2rem 0"
                                          bold="600"
                                          size="14px"
                                          className="theEllipsis"
                                        >
                                          Gynecological Sonography
                                        </Paragraph>
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        textAlign: "center",
                                        fontWeight: "600",
                                      }}
                                    >
                                      120
                                    </TableCell>
                                    <TableCell>
                                      <a
                                        style={{
                                          color: "#219B8E",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Delete
                                      </a>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <div
                                        style={{ display: "flex" }}
                                        className={classes.theWidth}
                                      >
                                        <Paragraph
                                          margin="0 0 .2rem 0"
                                          bold="600"
                                          size="14px"
                                          className="theEllipsis"
                                        >
                                          Body Composition Test
                                        </Paragraph>
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        textAlign: "center",
                                        fontWeight: "600",
                                      }}
                                    >
                                      150
                                    </TableCell>
                                    <TableCell>
                                      <a
                                        style={{
                                          color: "#219B8E",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Delete
                                      </a>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <div
                                        style={{ display: "flex" }}
                                        className={classes.theWidth}
                                      >
                                        <Paragraph
                                          margin="0 0 .2rem 0"
                                          bold="600"
                                          size="14px"
                                          className="theEllipsis"
                                        >
                                          CA15-3
                                        </Paragraph>
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        textAlign: "center",
                                        fontWeight: "600",
                                      }}
                                    >
                                      60
                                    </TableCell>
                                    <TableCell>
                                      <a
                                        style={{
                                          color: "#219B8E",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Delete
                                      </a>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <div
                                        style={{ display: "flex" }}
                                        className={classes.theWidth}
                                      >
                                        <Paragraph
                                          margin="0 0 .2rem 0"
                                          bold="600"
                                          size="14px"
                                          className="theEllipsis"
                                        >
                                          CA125
                                        </Paragraph>
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        textAlign: "center",
                                        fontWeight: "600",
                                      }}
                                    >
                                      60
                                    </TableCell>
                                    <TableCell>
                                      <a
                                        style={{
                                          color: "#219B8E",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Delete
                                      </a>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <div
                                        style={{ display: "flex" }}
                                        className={classes.theWidth}
                                      >
                                        <Paragraph
                                          margin="0 0 .2rem 0"
                                          bold="600"
                                          size="14px"
                                          className="theEllipsis"
                                        >
                                          Homocysteine
                                        </Paragraph>
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        textAlign: "center",
                                        fontWeight: "600",
                                      }}
                                    >
                                      78
                                    </TableCell>
                                    <TableCell>
                                      <a
                                        style={{
                                          color: "#219B8E",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Delete
                                      </a>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <div
                                        style={{ display: "flex" }}
                                        className={classes.theWidth}
                                      >
                                        <Paragraph color="211D4E" bold="700">
                                          Total Item Selected: 6
                                        </Paragraph>
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <div
                                        style={{ display: "flex" }}
                                        className={classes.theWidth}
                                      >
                                        <Paragraph color="211D4E" bold="700">
                                          Total Amount: 500
                                        </Paragraph>
                                      </div>
                                    </TableCell>
                                    <TableCell></TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </Box>

                        <br />
                        <div className={classes.theButtonFlex}>
                          <div className={classes.theButton}>
                            <Button
                              label="Save"
                              bg="#3adebb"
                              color="#FFF"
                              br="6px"
                              size="24px"
                              bold="600"
                              pd=".8rem 4rem"
                              hvbg="#3adebb"
                            />
                          </div>
                        </div>
                      </Box>
                    )}
                    {tabs == 2 && (
                      <>
                        <Paragraph bold="600" color="#219b8e" margin="1rem 0">
                          Overview
                        </Paragraph>

                        <div className={classes.theField}>
                          <FormInput
                            className={classes.theBorderRadius}
                            name="search"
                            placeholder="Search..."
                            fontWeight="500"
                            textColor={themePlate().secondaryTwo}
                            border="1px solid #eeeeee"
                            bg="transparent"
                            padding="12px 25px 12px 65px"
                            mb="0"
                            size="18px"
                          />
                          <div className={classes.theAbsoluteIcon}>
                            <div className={classes.theIcon20}>
                              <img src={SearchIcon} alt={SearchIcon} />
                            </div>
                          </div>
                        </div>
                        <br />
                        <div style={{ height: "200px" }}>
                          <TableContainer sx={{ height: "200px" }}>
                            <Table>
                              <TableHead className={classes.theHead}>
                                <TableRow>
                                  <TableCell>No.</TableCell>
                                  <TableCell>Content</TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody className={classes.theBody}>
                                <TableRow>
                                  <TableCell>01</TableCell>
                                  <TableCell>
                                    Lorem ipsum dolor sit amet; consectetur
                                    adipiscing elit; sed do eiusmod tempor
                                    incididunt
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      textAlign: "right",
                                      color: "#219b8e",
                                    }}
                                  >
                                    Edit
                                  </TableCell>
                                  <TableCell
                                    sx={{ textAlign: "right", color: "red" }}
                                  >
                                    Delete
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>

                        <br />
                        <div className={classes.theButtonFlex}>
                          <div className={classes.theButton}>
                            <Button
                              label="Save"
                              bg="#3adebb"
                              color="#FFF"
                              br="6px"
                              size="24px"
                              bold="600"
                              pd=".8rem 4rem"
                              hvbg="#3adebb"
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={1}></Grid>
            </Grid>
          </Container>
        </React.Fragment>
      )}

      <Dialog
        onClose={() => setOpenAxillary(false)}
        open={openAxillary}
        // PaperProps={{ sx: { width: "1200px!important" } }}
        fullWidth={true}
        maxWidth="xl"
      >
        <div className={classes.theModal}>
          <Container maxWidth="xxl">
            <Box
              sx={{
                borderTopRightRadius: "4px",
                borderTopLeftRadius: "4px",
                padding: "1rem 2rem",
                background: "#fff",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <div
                  className={
                    axTab == 0 ? classes.theBSBoxActive : classes.theBSBox
                  }
                  onClick={() => handleChangeAxTab(0)}
                >
                  Comment
                </div>
                <div
                  className={
                    axTab == 1 ? classes.theBSBoxActive : classes.theBSBox
                  }
                  onClick={() => handleChangeAxTab(1)}
                >
                  Size & Location
                </div>
                <div
                  className={
                    axTab == 2 ? classes.theBSBoxActive : classes.theBSBox
                  }
                  onClick={() => handleChangeAxTab(2)}
                >
                  Free Hand & Birads
                </div>
              </Box>
              <Box
                sx={{
                  height: "2px",
                  width: "100%",
                  backgroundColor: "#EDEDED",
                }}
              />
              <Box>
                {axTab == 0 && (
                  <Box sx={{ padding: "1rem 0" }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} md={6}>
                        <div className={classes.theSearchBox}>
                          <div className={classes.theSL1}>
                            <FormInput
                              className={classes.theBorderRadius}
                              name="replaced_name"
                              placeholder="Search..."
                              value={searchKey}
                              onChange={handleChangeSearch}
                              bg="#f6f6f6"
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              padding="12px 37px"
                              mb="0"
                            />
                          </div>
                        </div>
                        <br />
                        <TableContainer sx={{ height: "200px" }}>
                          <Table>
                            <TableHead className={classes.theHead}>
                              <TableRow>
                                <TableCell>No.</TableCell>
                                <TableCell>Content</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody className={classes.theBody}>
                              <TableRow>
                                <TableCell>01</TableCell>
                                <TableCell>
                                  Lorem ipsum dolor sit amet; consectetur
                                  adipiscing elit; sed do eiusmod tempor
                                  incididunt
                                </TableCell>
                                <TableCell
                                  sx={{ textAlign: "right", color: "#219b8e" }}
                                >
                                  Edit
                                </TableCell>
                                <TableCell
                                  sx={{ textAlign: "right", color: "red" }}
                                >
                                  Delete
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Paragraph bold="600" color="#219b8e" margin="1rem 0">
                          Overview
                        </Paragraph>
                        <FormInput
                          rows="5"
                          multiline
                          // bg="#f6f6f6"

                          fontWeight="600"
                          textColor={themePlate().primaryThree}
                          border="1px solid #EDEDED"
                          padding="0"
                          mb="0"
                          boxShadow="none"
                        />

                        <br />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            label="Confirm"
                            bg="#3adebb"
                            color="#FFF"
                            br="6px"
                            size="24px"
                            bold="600"
                            pd=".8rem 4rem"
                            hvbg="#3adebb"
														style={{marginRight: "1rem"}}
                          />
													<Button
                            label="Cancel"
                            bg="#EDEDED"
                            color="#918FA7"
                            br="6px"
                            size="24px"
                            bold="600"
                            pd=".8rem 4rem"
                            hvbg="#3adebb"
														onClick={()=>setOpenAxillary(false)}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}

                {tabs == 1 && (
                  <Box sx={{ padding: "1rem 0" }}>
                    <Paragraph bold="600" color="#219b8e" margin="0 0 1rem 0">
                      Add On Service
                    </Paragraph>

                    <Box
                      sx={{
                        borderRadius: "5px",
                        border: "2px solid #EDEDED",
                        // padding: "0 1rem",
                      }}
                    >
                      <Box
                        sx={{
                          background: "#FBFBFB",
                          padding: "1rem",
                        }}
                      >
                        <Paragraph color="#5A567B" bold="600">
                          Optional Items
                        </Paragraph>
                      </Box>
                      <Box sx={{ padding: "1rem" }}>
                        <div className={classes.theField}>
                          <FormInput
                            className={classes.theBorderRadius}
                            name="search"
                            placeholder="Search..."
                            fontWeight="500"
                            textColor={themePlate().secondaryTwo}
                            border="1px solid #eeeeee"
                            bg="transparent"
                            padding="12px 25px 12px 65px"
                            mb="0"
                            size="18px"
                          />
                          <div className={classes.theAbsoluteIcon}>
                            <div className={classes.theIcon20}>
                              <img src={SearchIcon} alt={SearchIcon} />
                            </div>
                          </div>
                        </div>
                        <br />
                        <TableContainer style={{ height: "400px" }}>
                          <Table>
                            <TableHead sx={{ background: "transparent" }}>
                              <TableRow>
                                <TableCell sx={{ color: "#5A567B" }}>
                                  Items
                                </TableCell>
                                <TableCell
                                  sx={{
                                    textAlign: "center",
                                    color: "#5A567B",
                                  }}
                                >
                                  Price
                                </TableCell>

                                <TableCell
                                  sx={{ textAlign: "center" }}
                                ></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <div
                                    style={{ display: "flex" }}
                                    className={classes.theWidth}
                                  >
                                    <Paragraph
                                      margin="0 0 .2rem 0"
                                      bold="600"
                                      size="14px"
                                      className="theEllipsis"
                                    >
                                      Gynecological Sonography
                                    </Paragraph>
                                  </div>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    textAlign: "center",
                                    fontWeight: "600",
                                  }}
                                >
                                  120
                                </TableCell>
                                <TableCell>
                                  <a
                                    style={{
                                      color: "#219B8E",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Delete
                                  </a>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <div
                                    style={{ display: "flex" }}
                                    className={classes.theWidth}
                                  >
                                    <Paragraph
                                      margin="0 0 .2rem 0"
                                      bold="600"
                                      size="14px"
                                      className="theEllipsis"
                                    >
                                      Body Composition Test
                                    </Paragraph>
                                  </div>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    textAlign: "center",
                                    fontWeight: "600",
                                  }}
                                >
                                  150
                                </TableCell>
                                <TableCell>
                                  <a
                                    style={{
                                      color: "#219B8E",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Delete
                                  </a>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <div
                                    style={{ display: "flex" }}
                                    className={classes.theWidth}
                                  >
                                    <Paragraph
                                      margin="0 0 .2rem 0"
                                      bold="600"
                                      size="14px"
                                      className="theEllipsis"
                                    >
                                      CA15-3
                                    </Paragraph>
                                  </div>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    textAlign: "center",
                                    fontWeight: "600",
                                  }}
                                >
                                  60
                                </TableCell>
                                <TableCell>
                                  <a
                                    style={{
                                      color: "#219B8E",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Delete
                                  </a>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <div
                                    style={{ display: "flex" }}
                                    className={classes.theWidth}
                                  >
                                    <Paragraph
                                      margin="0 0 .2rem 0"
                                      bold="600"
                                      size="14px"
                                      className="theEllipsis"
                                    >
                                      CA125
                                    </Paragraph>
                                  </div>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    textAlign: "center",
                                    fontWeight: "600",
                                  }}
                                >
                                  60
                                </TableCell>
                                <TableCell>
                                  <a
                                    style={{
                                      color: "#219B8E",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Delete
                                  </a>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <div
                                    style={{ display: "flex" }}
                                    className={classes.theWidth}
                                  >
                                    <Paragraph
                                      margin="0 0 .2rem 0"
                                      bold="600"
                                      size="14px"
                                      className="theEllipsis"
                                    >
                                      Homocysteine
                                    </Paragraph>
                                  </div>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    textAlign: "center",
                                    fontWeight: "600",
                                  }}
                                >
                                  78
                                </TableCell>
                                <TableCell>
                                  <a
                                    style={{
                                      color: "#219B8E",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Delete
                                  </a>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <div
                                    style={{ display: "flex" }}
                                    className={classes.theWidth}
                                  >
                                    <Paragraph color="211D4E" bold="700">
                                      Total Item Selected: 6
                                    </Paragraph>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div
                                    style={{ display: "flex" }}
                                    className={classes.theWidth}
                                  >
                                    <Paragraph color="211D4E" bold="700">
                                      Total Amount: 500
                                    </Paragraph>
                                  </div>
                                </TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Box>

                    <br />
                    <div className={classes.theButtonFlex}>
                      <div className={classes.theButton}>
                        <Button
                          // onClick={handleSaveComment}
                          label="Save"
                          color="#fff"
                          hvbg="#3ADEBB"
                          bg="#3ADEBB"
                          size="16px"
                          bold="700"
                          pd="10px 23px"
                          br="3px"
                          boxshadow="0px 3px 15px rgba(33, 155, 142, 0.25)"
                        />
                      </div>
                    </div>
                  </Box>
                )}
                {tabs == 2 && (
                  <>
                    <Paragraph bold="600" color="#219b8e" margin="1rem 0">
                      Overview
                    </Paragraph>

                    <div className={classes.theField}>
                      <FormInput
                        className={classes.theBorderRadius}
                        name="search"
                        placeholder="Search..."
                        fontWeight="500"
                        textColor={themePlate().secondaryTwo}
                        border="1px solid #eeeeee"
                        bg="transparent"
                        padding="12px 25px 12px 65px"
                        mb="0"
                        size="18px"
                      />
                      <div className={classes.theAbsoluteIcon}>
                        <div className={classes.theIcon20}>
                          <img src={SearchIcon} alt={SearchIcon} />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div style={{ height: "200px" }}>
                      <TableContainer sx={{ height: "200px" }}>
                        <Table>
                          <TableHead className={classes.theHead}>
                            <TableRow>
                              <TableCell>No.</TableCell>
                              <TableCell>Content</TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody className={classes.theBody}>
                            <TableRow>
                              <TableCell>01</TableCell>
                              <TableCell>
                                Lorem ipsum dolor sit amet; consectetur
                                adipiscing elit; sed do eiusmod tempor
                                incididunt
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "right",
                                  color: "#219b8e",
                                }}
                              >
                                Edit
                              </TableCell>
                              <TableCell
                                sx={{ textAlign: "right", color: "red" }}
                              >
                                Delete
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>

                    <br />
                    <div className={classes.theButtonFlex}>
                      <div className={classes.theButton}>
                        <Button
                          // onClick={handleSaveComment}
                          label="Save"
                          color="#fff"
                          hvbg="#3ADEBB"
                          bg="#3ADEBB"
                          size="16px"
                          bold="700"
                          pd="10px 23px"
                          br="3px"
                          boxshadow="0px 3px 15px rgba(33, 155, 142, 0.25)"
                        />
                      </div>
                    </div>
                  </>
                )}
              </Box>
            </Box>
          </Container>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
