import React from "react";
import FormLabel from "../Typography/FormLabel";
import { FormGroup, TextInput } from "./styles";

const FormInput = ({
  children,
  textLabelColor,
  bold,
  label,
  margin,
  bg,
  textcolor,
  mb,
  border,
  fontWeight,
  padding,
  size,
  boxshadow,
  textalign,
  ...props
}) => {
  return (
    <FormGroup mb={mb}>
      {label != null &&
        <FormLabel textLabelColor={textLabelColor} bold={bold} margin={margin}>
          {label}
        </FormLabel>
      }
      <TextInput
        // onKeyPress={}
        bg={bg} textalign={textalign} textcolor={textcolor} boxshadow={boxshadow} border={border} fontWeight={fontWeight} padding={padding} size={size} {...props}>
        {children}
      </TextInput>
    </FormGroup>
  );
};

export default FormInput;
