import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Radio,
  RadioGroup,
  Checkbox,
  Box,
  FormControlLabel,
  TextField,
  MenuItem,
} from "@mui/material";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import Loader from "react-js-loader";
import toast from "react-simple-toasts";
import Header from "../../../components/Header/Header";
import Paragraph from "../../../components/Typography/Paragraph";
import FormInput from "../../../components/FormInput/FormInput";
import Button from "../../../components/Button/Button";
import { themePlate } from "../../../components/Theme/Theme";
import useStyles, {
  CustomRadio,
  CustomRadioChecked,
  BpIcon,
  BpCheckedIcon,
  BpIcon2,
  BpCheckedIcon2,
} from "./styles";
import "./styles.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SearchIcon from "../../../assets/images/Search Icon.svg";
import CheckoutIcon from "../../../assets/images/Checkout Icon.svg";
import { add_questionnaire_1, get_appointment_detail, get_reception_list, member_detail, random_appointment } from "../../../api/API";
import { CheckCircle, ErrorRounded } from "@mui/icons-material";

export default function Questionaire() {
  const classes = useStyles();
  const navigate = useNavigate();

  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [receptionList, setReceptionList] = useState([]);
  const [memberData, setMemberData] = useState({});

  //Pagination
  const [page, setPage] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState(1);
  const [appt, setAppt] = React.useState({});

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handlegetData = async () => {
    // setLoading(true);

    var resMember = await member_detail({
      appointment_id: params.appointment_id
    });
    if (resMember.status) {
      setMemberData(resMember.data[0]);
    }

    var resAppt = await get_appointment_detail({
      appointment_id: params.appointment_id
    });

    if (resAppt) {
      setAppt(resAppt.data);
    }
  };

  useEffect(() => {
    handlegetData();
  }, [page]);

  useEffect(() => {
    if (!window.location.hash) {
      window.location = window.location + "#loaded";
      window.location.reload();
    }
  }, []);

  const [showPassword3, setShowPassword3] = useState(false);
  const [passwordState, setPasswordState] = useState(false);

  const handleGenerate = async () => {
    // setLoading(true);

    // var resGenerate = await random_appointment({});
    // if (resGenerate.status) {
    //   setLoading(false);
    //   handlegetData();
    // }
  };

  const handleSaveComment = async () => {
    console.log(selection);
    var f1 = ["CFM001", "CFM002", "Aids", "Smear", "HQN001", "HQN002", "HQN003", "HQN004", "HQN005", "HQN006", "HQN007", "HQN008", "HQN009", "HQN010", "HQN011", "HQN013A", "HQN013B", "HQN013C", "HQN013D", "HQN014", "HQN015", "HQN016", "HQN017", "HQN018", "HQN019", "HQN020", "HQN021", "HQN022A", "HQN022B", "HQN022C", "HQN022D", "HQN022", "HQN023", "HQN024", "HQN025", "HQN026", "HQN027", "HQN028", "HQN029", "HQN030", "HQN031", "HQN032", "HQN033", "HQN034", "HQN035", "HQN036", "HQN037", "HQN038", "HQN039", "HQN040", "HQN041", "HQN042", "HQN043", "HQN044", "HQN045", "HQN046", "HQN047", "HQN048", "HQN049A", "HQN049B", "HQN049C", "HQN049D", "HQN049E", "HQN049F", "HQN050A", "HQN050B", "HQN050C", "HQN050D", "HQN050E", "HQN050F", "HQN050G", "HQN052A", "HQN052B", "HQN052C", "HQN052D", "HQN052E", "HQN052F", "HQN052G", "HQN052H", "HQN052I", "HQN052J", "HQN052K", "HQN052L", "HQN052M", "HQN052N", "HQN052O", "HQN052P", "HQN052Q", "HQN052R", "HQN052S", "HQN052T", "HQN052U", "HQN052V", "HQN052W", "HQN053", "HQN053A", "HQN053B", "HQN054", "HQN055A", "HQN055F", "HQN055G", "HQN055H", "HQN055I", "HQN055C", "HQN055D", "HQN056", "HQN057", "HQN058", "HQN059", "HQN060A", "HQN060B", "HQN060C", "HQN060D", "HQN060E", "HQN060F"]
    var f2 = ["HQN061", "HQN062", "HQN063", "HQN064", "HQN065", "HQN066", "HQN069", "HQN071A", "HQN071B", "HQN071C", "HQN071D", "HQN071E", "HQN071F", "HQN071G", "HQN072A", "HQN072B", "HQN072C", "HQN072D", "HQN072E", "HQN072F", "HQN072G", "HQN072H", "HQN072I", "HQN073", "HQN074A", "HQN074B", "HQN074C", "HQN075", "HQN076", "HQN077A", "HQN077B", "HQN077C", "HQN077D", "HQN077E", "HQN078", "HQN079", "HQN080", "HQN081", "HQN082", "HQN083A", "HQN083B", "HQN083C", "HQN083D", "HQN083E", "HQN084A", "HQN084B", "HQN084C", "HQN084D", "HQN085A", "HQN085B", "HQN085C", "HQN086", "HQN087", "HQN088A", "HQN088B", "HQN088C", "HQN088D", "HQN088E", "HQN088F", "HQN089A", "HQN089B", "HQN089C", "HQN089D", "HQN089E", "HQN089F", "HQN089G", "HQN089H", "HQN090A", "HQN090B", "HQN090C", "HQN090D", "HQN090E", "HQN090F", "HQN090G", "HQN090H", "HQN091", "HQN092", "HQN093A", "HQN093B", "HQN093C", "HQN093D", "HQN094", "HQN094B", "HQN095", "HQN096", "HQN097", "HQN097A", "HQN098", "HQN099", "HQN100", "HQN101", "HQN102", "HQN103", "HQN104", "HQN105", "HQN106A", "HQN106B", "HQN106C", "HQN106D", "HQN106E", "HQN107A", "HQN107B", "HQN107C", "HQN107D", "HQN107E", "HQN107F", "HQN108A", "HQN108B", "HQN108C", "HQN108D", "HQN112A", "HQN112B", "HQN112C", "HQN112D", "HQN112E", "HQN112F"];
    var _data = {};
    for (var i = 0; i < f1.length; i++) {
      _data[f1[i]] = selection[f1[i]];
    }
    _data['member_id'] = params.member_id;
    _data['appointment_id'] = params.appointment_id;

    var res = await add_questionnaire_1(_data);
    toast("Saved");
  };
  //

  const [tabs, setTabs] = React.useState(0);

  const handleChangeTabs = (txt) => {
    setTabs(txt);
  };

  const CustomRadioButton = (props) => {
    return (
      <Radio
        sx={{
          "&:hover": {
            background: "transparent",
          },
        }}
        disableRipple
        // color="default"
        checkedIcon={<CustomRadio />}
        icon={<CustomRadioChecked />}
        {...props}
      />
    );
  };

  const CustomCheckBox = (props) => {
    return (
      <Checkbox
        sx={{
          "&:hover": { background: "transparent" },
        }}
        disableRipple
        // color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        inputProps={{ "aria-label": "Checkbox demo" }}
        {...props}
      />
    );
  };

  const CustomCheckBox2 = (props) => {
    return (
      <Checkbox
        sx={{
          "&:hover": { bgcolor: "transparent" },
        }}
        disableRipple
        // color="default"
        checkedIcon={<BpCheckedIcon2 />}
        icon={<BpIcon2 />}
        inputProps={{ "aria-label": "Checkbox demo" }}
        {...props}
      />
    );
  };

  const [fasting, setFasting] = React.useState(0);
  const [urinetest, setUrineTest] = React.useState(0);
  const [hivtest, setHivTest] = React.useState(0);
  const [blood, setBlood] = React.useState(0);
  const [punturedSite, setPunturedSite] = React.useState("RT");

  const [selection, setSelection] = React.useState({
    // CFM001: "Meat",
    // CFM002: "A",
    // Aids: "Y",
    // Smear: "N",
    // HQN001: "Chinese",
    // HQN002: "Chinese",
    // HQN003: "Chinese",
    // HQN004: "Chinese",
    // HQN005: "Chinese",
    // HQN006: "Chinese",
    // HQN007: "Single",
    // HQN008: "Illiteracy",
    // HQN009: "None",
    // HQN010: "None",
    // HQN011: "None",
    // HQN013A: "No",
    // HQN013B: "No",
    // HQN013C: "No",
    // HQN013D: "No",
    // HQN014: "Blank",
    // HQN015: "Less",
    // HQN016: "Less",
    // HQN017: "Blank",
    // HQN018: "Less",
    // HQN019: "No",
    // HQN020: "Blank",
    // HQN021: "Blank",
    // HQN022A: "No",
    // HQN022B: "No",
    // HQN022C: "No",
    // HQN022D: "No",
    // HQN022: "Y",
    // HQN023: "Y",
    // HQN024: "Y",
    // HQN025: "Y",
    // HQN026: "Y",
    // HQN027: "Y",
    // HQN028: "Y",
    // HQN029: "Y",
    // HQN030: "1",
    // HQN031: "1",
    // HQN032: "Never",
    // HQN033: "Lifestock",
    // HQN034: "Never",
    // HQN035: "Not",
    // HQN036: "Never",
    // HQN037: "Never",
    // HQN038: "Never",
    // HQN039: "Never",
    // HQN040: "Never",
    // HQN041: "Never",
    // HQN042: "Never",
    // HQN043: "Less",
    // HQN044: "Never",
    // HQN045: "None",
    // HQN046: "Never",
    // HQN047: "Not",
    // HQN048: "Never",
    // HQN049A: "No",
    // HQN049B: "No",
    // HQN049C: "No",
    // HQN049D: "No",
    // HQN049E: "No",
    // HQN049F: "No",
    // HQN050A: "No",
    // HQN050B: "No",
    // HQN050C: "No",
    // HQN050D: "No",
    // HQN050E: "No",
    // HQN050F: "No",
    // HQN050G: "No",
    // HQN052A: "No",
    // HQN052B: "No",
    // HQN052C: "No",
    // HQN052D: "No",
    // HQN052E: "No",
    // HQN052F: "No",
    // HQN052G: "No",
    // HQN052H: "No",
    // HQN052I: "No",
    // HQN052J: "No",
    // HQN052K: "No",
    // HQN052L: "No",
    // HQN052M: "No",
    // HQN052N: "No",
    // HQN052O: "No",
    // HQN052P: "No",
    // HQN052Q: "No",
    // HQN052R: "No",
    // HQN052S: "No",
    // HQN052T: "No",
    // HQN052U: "No",
    // HQN052V: "No",
    // HQN052W: "No",
    // HQN053: "N",
    // HQN053A: "Pure",
    // HQN053B: "Less",
    // HQN054: "Mostly",
    // HQN055A: "No",
    // HQN055F: "No",
    // HQN055G: "No",
    // HQN055H: "No",
    // HQN055I: "No",
    // HQN055C: "No",
    // HQN055D: "No",
    // HQN056: "Not",
    // HQN057: "Less",
    // HQN058: "No",
    // HQN059: "Less",
    // HQN060A: "No",
    // HQN060B: "No",
    // HQN060C: "No",
    // HQN060D: "No",
    // HQN060E: "No",
    // HQN060F: "No",
    // HQN061: "N",
    // HQN062: "N",
    // HQN063: "N",
    // HQN064: "N",
    // HQN065: "N",
    // HQN066: "Normal",
    // HQN069: "N",
    // HQN071A: "No",
    // HQN071B: "No",
    // HQN071C: "No",
    // HQN071D: "No",
    // HQN071E: "No",
    // HQN071F: "No",
    // HQN071G: "No",
    // HQN072A: "No",
    // HQN072B: "No",
    // HQN072C: "No",
    // HQN072D: "No",
    // HQN072E: "No",
    // HQN072F: "No",
    // HQN072G: "No",
    // HQN072H: "No",
    // HQN072I: "No",
    // HQN073: "Normal",
    // HQN074A: "No",
    // HQN074B: "No",
    // HQN074C: "No",
    // HQN075: "N",
    // HQN076: "N",
    // HQN077A: "No",
    // HQN077B: "No",
    // HQN077C: "No",
    // HQN077D: "No",
    // HQN077E: "No",
    // HQN078: "N",
    // HQN079: "N",
    // HQN080: "N",
    // HQN081: "N",
    // HQN082: "N",
    // HQN083A: "No",
    // HQN083B: "No",
    // HQN083C: "No",
    // HQN083D: "No",
    // HQN083E: "No",
    // HQN084A: "No",
    // HQN084B: "No",
    // HQN084C: "No",
    // HQN084D: "No",
    // HQN085A: "No",
    // HQN085B: "No",
    // HQN085C: "No",
    // HQN086: "N",
    // HQN087: "N",
    // HQN088A: "No",
    // HQN088B: "No",
    // HQN088C: "No",
    // HQN088D: "No",
    // HQN088E: "No",
    // HQN088F: "No",
    // HQN089A: "No",
    // HQN089B: "No",
    // HQN089C: "No",
    // HQN089D: "No",
    // HQN089E: "No",
    // HQN089F: "No",
    // HQN089G: "No",
    // HQN089H: "No",
    // HQN090A: "No",
    // HQN090B: "No",
    // HQN090C: "No",
    // HQN090D: "No",
    // HQN090E: "No",
    // HQN090F: "No",
    // HQN090G: "No",
    // HQN090H: "No",
    // HQN091: "No",
    // HQN092: "1",
    // HQN093A: "No",
    // HQN093B: "No",
    // HQN093C: "No",
    // HQN093D: "No",
    // HQN094: "N",
    // HQN094B: "N",
    // HQN095: "Normal",
    // HQN096: "1",
    // HQN097: "1",
    // HQN097A: "1",
    // HQN098: "N",
    // HQN099: "0",
    // HQN100: "0",
    // HQN101: "N",
    // HQN102: "No",
    // HQN103: "N",
    // HQN104: "N",
    // HQN105: "N",
    // HQN106A: "No",
    // HQN106B: "No",
    // HQN106C: "No",
    // HQN106D: "No",
    // HQN106E: "No",
    // HQN107A: "No",
    // HQN107B: "No",
    // HQN107C: "No",
    // HQN107D: "No",
    // HQN107E: "No",
    // HQN107F: "No",
    // HQN108A: "No",
    // HQN108B: "No",
    // HQN108C: "No",
    // HQN108D: "No",
    // HQN112A: "No",
    // HQN112B: "No",
    // HQN112C: "No",
    // HQN112D: "No",
    // HQN112E: "No",
    // HQN112F: "No",
    CFM001: "-",
    CFM002: "-",
    Aids: "-",
    Smear: "-",
    HQN001: "-",
    HQN002: "-",
    HQN003: "-",
    HQN004: "-",
    HQN005: "-",
    HQN006: "-",
    HQN007: "-",
    HQN008: "-",
    HQN009: "-",
    HQN010: "-",
    HQN011: "-",
    HQN013A: "-",
    HQN013B: "-",
    HQN013C: "-",
    HQN013D: "-",
    HQN014: "-",
    HQN015: "-",
    HQN016: "-",
    HQN017: "-",
    HQN018: "-",
    HQN019: "-",
    HQN020: "-",
    HQN021: "-",
    HQN022A: "-",
    HQN022B: "-",
    HQN022C: "-",
    HQN022D: "-",
    HQN022: "-",
    HQN023: "-",
    HQN024: "-",
    HQN025: "-",
    HQN026: "-",
    HQN027: "-",
    HQN028: "-",
    HQN029: "-",
    HQN030: "-",
    HQN031: "-",
    HQN032: "-",
    HQN033: "-",
    HQN034: "-",
    HQN035: "-",
    HQN036: "-",
    HQN037: "-",
    HQN038: "-",
    HQN039: "-",
    HQN040: "-",
    HQN041: "-",
    HQN042: "-",
    HQN043: "-",
    HQN044: "-",
    HQN045: "-",
    HQN046: "-",
    HQN047: "-",
    HQN048: "-",
    HQN049A: "-",
    HQN049B: "-",
    HQN049C: "-",
    HQN049D: "-",
    HQN049E: "-",
    HQN049F: "-",
    HQN050A: "-",
    HQN050B: "-",
    HQN050C: "-",
    HQN050D: "-",
    HQN050E: "-",
    HQN050F: "-",
    HQN050G: "-",
    HQN052A: "-",
    HQN052B: "-",
    HQN052C: "-",
    HQN052D: "-",
    HQN052E: "-",
    HQN052F: "-",
    HQN052G: "-",
    HQN052H: "-",
    HQN052I: "-",
    HQN052J: "-",
    HQN052K: "-",
    HQN052L: "-",
    HQN052M: "-",
    HQN052N: "-",
    HQN052O: "-",
    HQN052P: "-",
    HQN052Q: "-",
    HQN052R: "-",
    HQN052S: "-",
    HQN052T: "-",
    HQN052U: "-",
    HQN052V: "-",
    HQN052W: "-",
    HQN053: "-",
    HQN053A: "-",
    HQN053B: "-",
    HQN054: "-",
    HQN055A: "-",
    HQN055F: "-",
    HQN055G: "-",
    HQN055H: "-",
    HQN055I: "-",
    HQN055C: "-",
    HQN055D: "-",
    HQN056: "-",
    HQN057: "-",
    HQN058: "-",
    HQN059: "-",
    HQN060A: "-",
    HQN060B: "-",
    HQN060C: "-",
    HQN060D: "-",
    HQN060E: "-",
    HQN060F: "-",
    HQN061: "-",
    HQN062: "-",
    HQN063: "-",
    HQN064: "-",
    HQN065: "-",
    HQN066: "-",
    HQN069: "-",
    HQN071A: "-",
    HQN071B: "-",
    HQN071C: "-",
    HQN071D: "-",
    HQN071E: "-",
    HQN071F: "-",
    HQN071G: "-",
    HQN072A: "-",
    HQN072B: "-",
    HQN072C: "-",
    HQN072D: "-",
    HQN072E: "-",
    HQN072F: "-",
    HQN072G: "-",
    HQN072H: "-",
    HQN072I: "-",
    HQN073: "-",
    HQN074A: "-",
    HQN074B: "-",
    HQN074C: "-",
    HQN075: "-",
    HQN076: "-",
    HQN077A: "-",
    HQN077B: "-",
    HQN077C: "-",
    HQN077D: "-",
    HQN077E: "-",
    HQN078: "-",
    HQN079: "-",
    HQN080: "-",
    HQN081: "-",
    HQN082: "-",
    HQN083A: "-",
    HQN083B: "-",
    HQN083C: "-",
    HQN083D: "-",
    HQN083E: "-",
    HQN084A: "-",
    HQN084B: "-",
    HQN084C: "-",
    HQN084D: "-",
    HQN085A: "-",
    HQN085B: "-",
    HQN085C: "-",
    HQN086: "-",
    HQN087: "-",
    HQN088A: "-",
    HQN088B: "-",
    HQN088C: "-",
    HQN088D: "-",
    HQN088E: "-",
    HQN088F: "-",
    HQN089A: "-",
    HQN089B: "-",
    HQN089C: "-",
    HQN089D: "-",
    HQN089E: "-",
    HQN089F: "-",
    HQN089G: "-",
    HQN089H: "-",
    HQN090A: "-",
    HQN090B: "-",
    HQN090C: "-",
    HQN090D: "-",
    HQN090E: "-",
    HQN090F: "-",
    HQN090G: "-",
    HQN090H: "-",
    HQN091: "-",
    HQN092: "-",
    HQN093A: "-",
    HQN093B: "-",
    HQN093C: "-",
    HQN093D: "-",
    HQN094: "-",
    HQN094B: "-",
    HQN095: "-",
    HQN096: "-",
    HQN097: "-",
    HQN097A: "-",
    HQN098: "-",
    HQN099: "-",
    HQN100: "-",
    HQN101: "-",
    HQN102: "-",
    HQN103: "-",
    HQN104: "-",
    HQN105: "-",
    HQN106A: "-",
    HQN106B: "-",
    HQN106C: "-",
    HQN106D: "-",
    HQN106E: "-",
    HQN107A: "-",
    HQN107B: "-",
    HQN107C: "-",
    HQN107D: "-",
    HQN107E: "-",
    HQN107F: "-",
    HQN108A: "-",
    HQN108B: "-",
    HQN108C: "-",
    HQN108D: "-",
    HQN112A: "-",
    HQN112B: "-",
    HQN112C: "-",
    HQN112D: "-",
    HQN112E: "-",
    HQN112F: "-"

  });

  const handleChange = (field, value) => {
    setSelection({
      ...selection,
      [field]: value,
    });
  };

  return (
    <React.Fragment>
      {loading ? (
        <div style={{ margin: "auto" }}>
          <Loader
            type="bubble-loop"
            bgColor={themePlate().secondaryThree}
            size={50}
          />
        </div>
      ) : (
        <React.Fragment>
          <Header />
          <Box sx={{ background: "#211D4E", padding: "1rem" }}>
            <Container
              maxWidth="xl"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  display: "flex",
                  width: "50%",
                }}
              >
                <Paragraph color="#FFF" bold="700">
                  {appt.seq_no}
                </Paragraph>
                <Paragraph color="#FFF" bold="700">
                  {memberData.member_fullname}
                </Paragraph>
                <Paragraph color="#FFF" bold="700">
                  {memberData.birthday}
                </Paragraph>
                <Paragraph color="#FFF" bold="700">
                  {memberData.age}/{memberData.gender}
                </Paragraph>
                <Paragraph color="#FFF" bold="700">
                  {memberData.identity_card_no}
                </Paragraph>
              </Box>
              {/* <Box
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  display: "flex",
                  width: "30%",
                }}
              >
                <Paragraph color="#FFF" bold="700">
                  0401 1010 0600 0001
                </Paragraph>
                <Paragraph color="#FFF" bold="700">
                  K2
                </Paragraph>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ borderRight: "3px solid #FFF" }}>
                    <Paragraph color="#FFF" bold="700" margin="0 8px 0 0">
                      BM
                    </Paragraph>
                  </Box>

                  <Box sx={{ borderRight: "3px solid #FFF" }}>
                    <Paragraph color="#FFF" bold="700" margin="0 8px">
                      EN
                    </Paragraph>
                  </Box>
                  <Box>
                    <Paragraph color="#FFF" bold="700" margin="0 0 0 8px">
                      CH
                    </Paragraph>
                  </Box>
                </Box>
                <ErrorRounded sx={{ color: "#F9C234" }} />
              </Box> */}
            </Container>
          </Box>
          <div className={classes.theReceptionCounter}>
            <div className={classes.theReceptionCounterHeader}>
              <Container
                maxWidth="xl"
                className="customContainer"
                sx={{ display: "flex" }}
              >
                <div
                  className={classes.thSHFlex}
                  style={{ marginRight: "100px" }}
                >
                  <div
                    className={classes.thSHLabel}
                    onClick={() => navigate("../dashboard")}
                  >
                    <div className={classes.theSHIcon}>
                      <ChevronLeftIcon fontSize="34px" />
                    </div>
                    <div>
                      <Paragraph
                        size="18px"
                        color={themePlate().primaryThree}
                        bold="500"
                        textTransform="capitalize"
                        margin="0"
                      >
                        Back
                      </Paragraph>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
            <div className={classes.theReceptionCounterBody}>
              <Container maxWidth="xl">
                <>
                  <Box
                    sx={{
                      padding: "1rem 3rem",
                      background: "#FFF",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                      borderBottom: "2px solid #E9E9E9",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginBottom: "1rem",
                      }}
                    >
                      <a
                        className={
                          tabs == 0 ? classes.theActiveLine : classes.theDefault
                        }
                        onClick={() => handleChangeTabs(0)}
                      >
                        Basic Information
                      </a>
                      <a
                        className={
                          tabs == 1 ? classes.theActiveLine : classes.theDefault
                        }
                        onClick={() => handleChangeTabs(1)}
                      >
                        Lifestyle Habits
                      </a>
                      <a
                        className={
                          tabs == 2 ? classes.theActiveLine : classes.theDefault
                        }
                        onClick={() => handleChangeTabs(2)}
                      >
                        Eating Habits
                      </a>
                      <a
                        className={
                          tabs == 3 ? classes.theActiveLine : classes.theDefault
                        }
                        onClick={() => handleChangeTabs(3)}
                      >
                        Exercise Habits
                      </a>
                      <a
                        className={
                          tabs == 4 ? classes.theActiveLine : classes.theDefault
                        }
                        onClick={() => handleChangeTabs(4)}
                      >
                        Sleep Quality & Stress
                      </a>
                      <a
                        className={
                          tabs == 5 ? classes.theActiveLine : classes.theDefault
                        }
                        onClick={() => handleChangeTabs(5)}
                      >
                        Physical Symptoms 1
                      </a>
                      <a
                        className={
                          tabs == 6 ? classes.theActiveLine : classes.theDefault
                        }
                        onClick={() => handleChangeTabs(6)}
                      >
                        Physical Symptoms 2
                      </a>
                      {/* <a
                        className={
                          tabs == 7 ? classes.theActiveLine : classes.theDefault
                        }
                        onClick={() => handleChangeTabs(7)}
                      >
                        Personal and Family History 1
                      </a>
                      <a
                        className={
                          tabs == 8 ? classes.theActiveLine : classes.theDefault
                        }
                        onClick={() => handleChangeTabs(8)}
                      >
                        Personal and Family History 2
                      </a>
                      <a
                        className={
                          tabs == 9 ? classes.theActiveLine : classes.theDefault
                        }
                        onClick={() => handleChangeTabs(9)}
                      >
                        Personal and Family History 3
                      </a> */}
                    </Box>

                    {tabs == 0 && (
                      <>
                        <Paragraph color="#219B8E" bold="600">
                          Medicines (Usually Take)
                        </Paragraph>

                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            1. The standard meal for the day of health exam:
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) => handleChange("CFM001", e.target.value)}
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.CFM001}
                              padding="8px 20px"
                              mb="0"
                              name="CFM001"
                            >
                              <MenuItem value="-">-</MenuItem>
                              <MenuItem value="Meat">Meat</MenuItem>
                              <MenuItem value="Vegetarian">Vegetarian</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            2. Your blood type is:
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("CFM002", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.CFM002}
                              padding="8px 20px"
                              mb="0"
                              name="CFM002"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="A">A</MenuItem>
                              <MenuItem value="B">B</MenuItem>
                              <MenuItem value="O">O</MenuItem>
                              <MenuItem value="AB">AB</MenuItem>
                              <MenuItem value="Unknown">Unknown</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            3. Human Immunodeficiency Virus Screening:
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("Aids", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.Aids}
                              padding="8px 20px"
                              mb="0"
                              name="Aids"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Y">Agree</MenuItem>
                              <MenuItem value="N">Disagree</MenuItem>

                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            4. Pap Smear Screening Test:
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("Smear", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.Smear}
                              padding="8px 20px"
                              mb="0"
                              name="Smear"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Y">Agree</MenuItem>
                              <MenuItem value="N">Disagree</MenuItem>

                            </FormInput>
                          </Box>
                        </Box>

                        <Paragraph color="#219B8E" bold="600" margin="2rem 0 0">
                          Basic Information
                        </Paragraph>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            1. Your father is:
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN001", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN001}
                              padding="8px 20px"
                              mb="0"
                              name="HQN001"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Chinese">Chinese</MenuItem>
                              <MenuItem value="Malay">Malay</MenuItem>
                              <MenuItem value="Indian">Indian</MenuItem>
                              <MenuItem value="Aborigine">Aborigine</MenuItem>
                              <MenuItem value="Malay">Malay</MenuItem>
                              <MenuItem value="Others">Others</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            2. Your paternal grandfather is:
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN002", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN002}
                              padding="8px 20px"
                              mb="0"
                              name="HQN002"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Chinese">Chinese</MenuItem>
                              <MenuItem value="Malay">Malay</MenuItem>
                              <MenuItem value="Indian">Indian</MenuItem>
                              <MenuItem value="Aborigine">Aborigine</MenuItem>
                              <MenuItem value="Malay">Malay</MenuItem>
                              <MenuItem value="Others">Others</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            3. Your paternal grandmother is:
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN003", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN003}
                              padding="8px 20px"
                              mb="0"
                              name="HQN003"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Chinese">Chinese</MenuItem>
                              <MenuItem value="Malay">Malay</MenuItem>
                              <MenuItem value="Indian">Indian</MenuItem>
                              <MenuItem value="Aborigine">Aborigine</MenuItem>
                              <MenuItem value="Malay">Malay</MenuItem>
                              <MenuItem value="Others">Others</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            4. Your mother is:
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN004", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN004}
                              padding="8px 20px"
                              mb="0"
                              name="HQN004"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Chinese">Chinese</MenuItem>
                              <MenuItem value="Malay">Malay</MenuItem>
                              <MenuItem value="Indian">Indian</MenuItem>
                              <MenuItem value="Aborigine">Aborigine</MenuItem>
                              <MenuItem value="Malay">Malay</MenuItem>
                              <MenuItem value="Others">Others</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            5. Your maternal grandfather is:
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN005", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN005}
                              padding="8px 20px"
                              mb="0"
                              name="HQN005"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Chinese">Chinese</MenuItem>
                              <MenuItem value="Malay">Malay</MenuItem>
                              <MenuItem value="Indian">Indian</MenuItem>
                              <MenuItem value="Aborigine">Aborigine</MenuItem>
                              <MenuItem value="Malay">Malay</MenuItem>
                              <MenuItem value="Others">Others</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            6. Your maternal grandmother is:
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN006", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN006}
                              padding="8px 20px"
                              mb="0"
                              name="HQN006"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Chinese">Chinese</MenuItem>
                              <MenuItem value="Malay">Malay</MenuItem>
                              <MenuItem value="Indian">Indian</MenuItem>
                              <MenuItem value="Aborigine">Aborigine</MenuItem>
                              <MenuItem value="Malay">Malay</MenuItem>
                              <MenuItem value="Others">Others</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            7. Marital status:
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN007", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN007}
                              padding="8px 20px"
                              mb="0"
                              name="HQN007"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Single">Single</MenuItem>
                              <MenuItem value="Married">Married, remarried, cohabiting</MenuItem>
                              <MenuItem value="Divorced">Divorced</MenuItem>
                              <MenuItem value="Widowed">Widowed</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            8. Education:
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN008", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN008}
                              padding="8px 20px"
                              mb="0"
                              name="HQN008"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Illiteracy">Illiteracy</MenuItem>
                              <MenuItem value="Elementary">Elementary</MenuItem>
                              <MenuItem value="Junior">Junior / Senior high</MenuItem>
                              <MenuItem value="Technical">Technical Institute</MenuItem>
                              <MenuItem value="College">College / University</MenuItem>
                              <MenuItem value="Postgraduate">Postgraduate or above</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            9. Present occupation:
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN009", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN009}
                              padding="8px 20px"
                              mb="0"
                              name="HQN009"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="None">None</MenuItem>
                              <MenuItem value="Student">Full-time Student</MenuItem>
                              <MenuItem value="Management">Management Personnel</MenuItem>
                              <MenuItem value="Specialist">Specialist</MenuItem>
                              <MenuItem value="MTechniciansalay">Technicians and assistant professionals</MenuItem>
                              <MenuItem value="Administrative">Administrative support staff</MenuItem>
                              <MenuItem value="Service">Service and sales staff</MenuItem>
                              <MenuItem value="Agriculture">Agriculture, forestery, fisheries and animal husbandary technical staff</MenuItem>
                              <MenuItem value="Technology">Technology and crafts-related workers</MenuItem>
                              <MenuItem value="operators">Plant and equipment, machine operators and assemblers</MenuItem>
                              <MenuItem value="Junior">Junior workers</MenuItem>
                              <MenuItem value="Professional">Professional soldiers</MenuItem>
                              <MenuItem value="Others">Others</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            10. Your annual income is about:
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN010", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN010}
                              padding="8px 20px"
                              mb="0"
                              name="HQN010"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="None">None</MenuItem>
                              <MenuItem value="10">{"< MYR30,000"}</MenuItem>
                              <MenuItem value="11">MYR30,001-50,000</MenuItem>
                              <MenuItem value="12">MYR50,001-100,000</MenuItem>
                              <MenuItem value="13">MYR100,001-150,000</MenuItem>
                              <MenuItem value="14">MYR150,001-200,000</MenuItem>
                              <MenuItem value="15">{"> MYR200,001"}</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            11. Your family annual income is about:
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN011", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN011}
                              padding="8px 20px"
                              mb="0"
                              name="HQN011"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="None">None</MenuItem>
                              <MenuItem value="15">{"< MYR60,000"}</MenuItem>
                              <MenuItem value="16">MYR60,001-100,000</MenuItem>
                              <MenuItem value="17">MYR100,001-150,000</MenuItem>
                              <MenuItem value="18">MYR150,001-200,000</MenuItem>
                              <MenuItem value="19`">{"> MYR200,001"}</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            12. Can any of the following be found at your:
                          </Paragraph>
                          <Box sx={{ width: "50%", display: "flex", alignItems: "center" }}>
                            <label className={classes.checkboxLabel}>
                              <input
                                type="checkbox"
                                name="HQN013A"
                                className={classes.styledCheckbox}
                                checked={selection["HQN013A"] === "Yes"}
                                onChange={(e) => {
                                  const value = e.target.checked ? "Yes" : "No";
                                  handleChange("HQN013A", value);
                                }}
                              />
                              Chemical Substances
                            </label>
                            <label className={classes.checkboxLabel}>
                              <input
                                type="checkbox"
                                name="HQN013B"
                                className={classes.styledCheckbox}
                                checked={selection["HQN013B"] === "Yes"}
                                onChange={(e) => {
                                  const value = e.target.checked ? "Yes" : "No";
                                  handleChange("HQN013B", value);
                                }}
                              />
                              Dust
                            </label>
                            <label className={classes.checkboxLabel}>
                              <input
                                type="checkbox"
                                name="HQN013C"
                                className={classes.styledCheckbox}
                                checked={selection["HQN013C"] === "Yes"}
                                onChange={(e) => {
                                  const value = e.target.checked ? "Yes" : "No";
                                  handleChange("HQN013C", value);
                                }}
                              />
                              Physical Hazards
                            </label>
                            <label className={classes.checkboxLabel}>
                              <input
                                type="checkbox"
                                name="HQN013D"
                                className={classes.styledCheckbox}
                                checked={selection["HQN013D"] === "Yes"}
                                onChange={(e) => {
                                  const value = e.target.checked ? "Yes" : "No";
                                  handleChange("HQN013D", value);
                                }}
                              />
                              None of The Above
                            </label>
                          </Box>
                        </Box>
                        <div className={classes.theButtonFlex}>
                          <div className={classes.theButton}>
                            <Button
                              onClick={handleSaveComment}
                              label="Save"
                              color="#fff"
                              hvbg="#3ADEBB"
                              bg="#3ADEBB"
                              size="16px"
                              bold="700"
                              pd="10px 23px"
                              br="3px"
                              boxshadow="0px 3px 15px rgba(33, 155, 142, 0.25)"
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {tabs == 1 && (
                      <>
                        <Paragraph color="#219B8E" bold="600">
                          Smoking Habits
                        </Paragraph>

                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            13. Do you smoke / vape?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN014", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN014}
                              padding="8px 20px"
                              mb="0"
                              name="HQN014"
                            >
                              <MenuItem value="-">-</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                              <MenuItem value="exposed">No, but I am usually exposed to secondhand smoke</MenuItem>
                              <MenuItem value="used">I used to, but not anymore</MenuItem>
                              <MenuItem value="Ocassionally">Ocassionally</MenuItem>
                              <MenuItem value="Everyday">Every day</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            14. How many years have you been smoking?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN015", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN015}
                              padding="8px 20px"
                              mb="0"
                              name="HQN015"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Less">Less than a year</MenuItem>
                              <MenuItem value="1">1-3 years</MenuItem>
                              <MenuItem value="2">3-5 years</MenuItem>
                              <MenuItem value="3">5-10 years</MenuItem>
                              <MenuItem value="4">10-20 years</MenuItem>
                              <MenuItem value="5">More than 20 years</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            15. For those who have quit smoking, how long have you quit?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN016", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN016}
                              padding="8px 20px"
                              mb="0"
                              name="HQN016"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Less">Less than a year</MenuItem>
                              <MenuItem value="1">1-3 years</MenuItem>
                              <MenuItem value="2">3-5 years</MenuItem>
                              <MenuItem value="3">5-10 years</MenuItem>
                              <MenuItem value="4">10-20 years</MenuItem>
                              <MenuItem value="5">More than 20 years</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            16. Average how many do you smoke per day:
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN017", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN017}
                              padding="8px 20px"
                              mb="0"
                              name="HQN017"
                            >
                              <MenuItem value="-">-</MenuItem>
                              <MenuItem value="Less">Less than half a pack</MenuItem>
                              <MenuItem value="Half">Half a pack to one pack</MenuItem>
                              <MenuItem value="More">More than one pack</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            17. How long after wake up in the morning do you smoke you first cigarette?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN018", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN018}
                              padding="8px 20px"
                              mb="0"
                              name="HQN018"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Less">Less than 5 minutes</MenuItem>
                              <MenuItem value="Half">5-30 minutes</MenuItem>
                              <MenuItem value="Quarter">31-60 minutes</MenuItem>
                              <MenuItem value="More">More than 60 minutes</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>


                        <Paragraph color="#219B8E" bold="600" margin="2rem 0 0">
                          Drinking Habits
                        </Paragraph>

                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            18. Do you consume alcohol?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN019", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN019}
                              padding="8px 20px"
                              mb="0"
                              name="HQN019"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="No">No or less than once a week</MenuItem>
                              <MenuItem value="1">I used to, but I have quit drinking</MenuItem>
                              <MenuItem value="2">1-2 times per week</MenuItem>
                              <MenuItem value="3">3-4 times per week</MenuItem>
                              <MenuItem value="4">5-6 times per week</MenuItem>
                              <MenuItem value="5">I drink every day</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            19. What is the amount you consume each time?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN020", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN020}
                              padding="8px 20px"
                              mb="0"
                              name="HQN020"
                            >
                              <MenuItem value="-">-</MenuItem>
                              <MenuItem value="1">Less than half a glass</MenuItem>
                              <MenuItem value="2">Half a glass to one glass</MenuItem>
                              <MenuItem value="3">Two or three glass</MenuItem>
                              <MenuItem value="3">Four glasses and more</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            20. How many years have you been drinking?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN021", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN021}
                              padding="8px 20px"
                              mb="0"
                              name="HQN021"
                            >
                              <MenuItem value="-">-</MenuItem>
                              <MenuItem value="1">Less than half a year</MenuItem>
                              <MenuItem value="2">1-3 years</MenuItem>
                              <MenuItem value="3">3-5 years</MenuItem>
                              <MenuItem value="3">5-10 years</MenuItem>
                              <MenuItem value="3">More than 10 years</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            21. What type of alcohol do you consume?
                          </Paragraph>
                          <Box sx={{ width: "50%", display: "flex", alignItems: "center" }}>
                            <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="HQN022A"
                                className={classes.styledCheckbox}
                                checked={selection["HQN022A"] === "Yes"}
                                onChange={(e) => {
                                  const value = e.target.checked ? "Yes" : "No";
                                  handleChange("HQN022A", value);
                                }}
                              />
                              {"<15%"}
                            </label>
                            <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="HQN022B"
                                className={classes.styledCheckbox}
                                checked={selection["HQN022B"] === "Yes"}
                                onChange={(e) => {
                                  const value = e.target.checked ? "Yes" : "No";
                                  handleChange("HQN022B", value);
                                }}
                              />
                              15-30%
                            </label>
                            <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="HQN022C"
                                className={classes.styledCheckbox}
                                checked={selection["HQN022C"] === "Yes"}
                                onChange={(e) => {
                                  const value = e.target.checked ? "Yes" : "No";
                                  handleChange("HQN022C", value);
                                }}
                              />
                              30-45%
                            </label>
                            <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="HQN022D"
                                className={classes.styledCheckbox}
                                checked={selection["HQN022D"] === "Yes"}
                                onChange={(e) => {
                                  const value = e.target.checked ? "Yes" : "No";
                                  handleChange("HQN022D", value);
                                }}
                              />
                              {">45%"}
                            </label>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            22. Do you think consuming alcohol is bad or feel guilty about it?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN022", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN022}
                              padding="8px 20px"
                              mb="0"
                              name="HQN022"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Y">Yes</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            23. Do you feel comfortable only after consuming one glass of wine before breakfast?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN023", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN023}
                              padding="8px 20px"
                              mb="0"
                              name="HQN023"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Y">Yes</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>


                        <Paragraph color="#219B8E" bold="600" margin="2rem 0 .5rem">
                          Habits of Oral Hygiene
                        </Paragraph>

                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            24. Does your oral cavity have any of the following situation?
                          </Paragraph>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            margin="0 0 0 2rem"
                            style={{ width: "37.5%" }}
                          >
                            a. Bleeding when brushing your teeth.
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN024", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN024}
                              padding="8px 20px"
                              mb="0"
                              name="HQN024"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Y">Yes</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            margin="0 0 0 2rem"
                            style={{ width: "37.5%" }}
                          >
                            b. Loose Teeth.
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN025", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN025}
                              padding="8px 20px"
                              mb="0"
                              name="HQN025"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Y">Yes</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            margin="0 0 0 2rem"
                            style={{ width: "37.5%" }}
                          >
                            c. Hyperemia of the gums.
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN026", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN026}
                              padding="8px 20px"
                              mb="0"
                              name="HQN026"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Y">Yes</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            margin="0 0 0 2rem"
                            style={{ width: "37.5%" }}
                          >
                            d. Dark red instead of common pink gums.
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN027", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN027}
                              padding="8px 20px"
                              mb="0"
                              name="HQN027"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Y">Yes</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            margin="0 0 0 2rem"
                            style={{ width: "37.5%" }}
                          >
                            e. Bad breath ocassionally.
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN028", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN028}
                              padding="8px 20px"
                              mb="0"
                              name="HQN028"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Y">Yes</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            margin="0 0 0 2rem"
                            style={{ width: "37.5%" }}
                          >
                            f. I use an interdental brush or dental floss after brushing my teeth.
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN029", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN029}
                              padding="8px 20px"
                              mb="0"
                              name="HQN029"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Y">Yes</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <div className={classes.theButtonFlex}>
                          <div className={classes.theButton}>
                            <Button
                              onClick={handleSaveComment}
                              label="Save"
                              color="#fff"
                              hvbg="#3ADEBB"
                              bg="#3ADEBB"
                              size="16px"
                              bold="700"
                              pd="10px 23px"
                              br="3px"
                              boxshadow="0px 3px 15px rgba(33, 155, 142, 0.25)"
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {tabs == 2 && (
                      <>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            25. What time is your last meal of the day?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN030", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN030}
                              padding="8px 20px"
                              mb="0"
                              name="HQN030"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="1">Before 17:00</MenuItem>
                              <MenuItem value="2">17:00-18:00</MenuItem>
                              <MenuItem value="3">18:00-19:00</MenuItem>
                              <MenuItem value="4">19:00-20:00</MenuItem>
                              <MenuItem value="5">20:00-21:00</MenuItem>
                              <MenuItem value="6">After 21:00</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>


                        <Paragraph color="#219B8E" bold="600" margin="2rem 0 .5rem">
                          Fat Consumption
                        </Paragraph>

                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            26. How often do you eat fried, deep fried, grilled or smoked food?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN031", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN031}
                              padding="8px 20px"
                              mb="0"
                              name="HQN031"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="1">Do not eat such food or less than once a week</MenuItem>
                              <MenuItem value="2">2-3 times a week</MenuItem>
                              <MenuItem value="3">4-5 times a week</MenuItem>
                              <MenuItem value="4">6 times a week or every day</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            27. Do you eat the fat or skin of the meats?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN032", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN032}
                              padding="8px 20px"
                              mb="0"
                              name="HQN032"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Never">Never</MenuItem>
                              <MenuItem value="Ocassionally">Ocassionally</MenuItem>
                              <MenuItem value="Usually">Usually</MenuItem>
                              <MenuItem value="Always">Always</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            28. Based on your eating habits, what is the most common main course that will you choose? (select one)
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN033", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN033}
                              padding="8px 20px"
                              mb="0"
                              name="HQN033"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Lifestock">Lifestock-beef, lamb, pork</MenuItem>
                              <MenuItem value="Poultry">Poultry-chickens, ducks, geese</MenuItem>
                              <MenuItem value="Seafood">Seafood-fish, shrimp</MenuItem>
                              <MenuItem value="Beans">Beans-tofu and dried bean curd</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            29. Do you often eat baked or cheese-like food?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN034", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN034}
                              padding="8px 20px"
                              mb="0"
                              name="HQN034"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Never">Never</MenuItem>
                              <MenuItem value="Ocassionally">Ocassionally</MenuItem>
                              <MenuItem value="Usually">Usually</MenuItem>
                              <MenuItem value="Always">Always</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>


                        <Paragraph color="#219B8E" bold="600" margin="2rem 0 .5rem">
                          The Amount Of Fibre in Vegetables And Fruits, Intake Amount Of Antioxidants
                        </Paragraph>

                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            30. How many servings of vegetables do you eat per day?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN035", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN035}
                              padding="8px 20px"
                              mb="0"
                              name="HQN035"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Not">Do not eat vegetables or less than half a bowl per day</MenuItem>
                              <MenuItem value="Half">Half a bowl to one bowl per day</MenuItem>
                              <MenuItem value="One">One bowl to one and a half bowl per day</MenuItem>
                              <MenuItem value="two">One and a half to two bowls per day</MenuItem>
                              <MenuItem value="More">More than two bowls per day</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            31. How often do you eat at least two portions of fruits?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN036", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN036}
                              padding="8px 20px"
                              mb="0"
                              name="HQN036"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Never">Never</MenuItem>
                              <MenuItem value="Ocassionally">Ocassionally</MenuItem>
                              <MenuItem value="Usually">Usually</MenuItem>
                              <MenuItem value="Always">Always</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            32. How often do you choose germ rice, brown rice, whole grain or whole wheat pasta?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN037", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN037}
                              padding="8px 20px"
                              mb="0"
                              name="HQN037"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Never">Never</MenuItem>
                              <MenuItem value="Ocassionally">Ocassionally</MenuItem>
                              <MenuItem value="Usually">Usually</MenuItem>
                              <MenuItem value="Always">Always</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            33. How often do you eat multi-colour vegetables and fruits?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN038", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN038}
                              padding="8px 20px"
                              mb="0"
                              name="HQN038"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Never">Never</MenuItem>
                              <MenuItem value="Ocassionally">Ocassionally</MenuItem>
                              <MenuItem value="Usually">Usually</MenuItem>
                              <MenuItem value="Always">Always</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            34. How often do you eat garlic, onions, leeks, green cauliflowers and other similar foods?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN039", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN039}
                              padding="8px 20px"
                              mb="0"
                              name="HQN039"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Never">Never</MenuItem>
                              <MenuItem value="Ocassionally">Ocassionally</MenuItem>
                              <MenuItem value="Usually">Usually</MenuItem>
                              <MenuItem value="Always">Always</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>


                        <Paragraph color="#219B8E" bold="600" margin="2rem 0 .5rem">
                          Salt Intake
                        </Paragraph>

                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            35. Do you usually add sauce or chilli sauce to your meals?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN040", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN040}
                              padding="8px 20px"
                              mb="0"
                              name="HQN040"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Never">Never</MenuItem>
                              <MenuItem value="Ocassionally">Ocassionally</MenuItem>
                              <MenuItem value="Usually">Usually</MenuItem>
                              <MenuItem value="Always">Always</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            36. Do you usually eat fermented and pickled food? E.g. pickled melons, sour plums, kimchi, pickled vegetables, pickled cabbage or fermented bean curd?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN041", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN041}
                              padding="8px 20px"
                              mb="0"
                              name="HQN041"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Never">Never</MenuItem>
                              <MenuItem value="Ocassionally">Ocassionally</MenuItem>
                              <MenuItem value="Usually">Usually</MenuItem>
                              <MenuItem value="Always">Always</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            37. Do you usually add soya sauce and spies to enhance the flavour of the food you cook?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN042", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN042}
                              padding="8px 20px"
                              mb="0"
                              name="HQN042"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Never">Never</MenuItem>
                              <MenuItem value="Ocassionally">Ocassionally</MenuItem>
                              <MenuItem value="Usually">Usually</MenuItem>
                              <MenuItem value="Always">Always</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>


                        <Paragraph color="#219B8E" bold="600" margin="2rem 0 .5rem">
                          Water Intake
                        </Paragraph>

                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            38. How much water do you consume per day?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN043", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN043}
                              padding="8px 20px"
                              mb="0"
                              name="HQN043"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Less">Less than 500cc</MenuItem>
                              <MenuItem value="1">500cc-1000cc</MenuItem>
                              <MenuItem value="2">1000cc-1500cc</MenuItem>
                              <MenuItem value="3">1500cc-2000cc</MenuItem>
                              <MenuItem value="4">2000cc and more</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            39. How often do you replace water with coffee, tea or drinks?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN044", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN044}
                              padding="8px 20px"
                              mb="0"
                              name="HQN044"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Never">Never</MenuItem>
                              <MenuItem value="Ocassionally">Ocassionally</MenuItem>
                              <MenuItem value="Usually">Usually</MenuItem>
                              <MenuItem value="Always">Always</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            40. How much caffeinated drinks do you consume per day?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN045", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN045}
                              padding="8px 20px"
                              mb="0"
                              name="HQN045"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="None">None</MenuItem>
                              <MenuItem value="1">1-3 cups per day</MenuItem>
                              <MenuItem value="2">4-6 cups per day</MenuItem>
                              <MenuItem value="3">1 cup per day</MenuItem>
                              <MenuItem value="4">2 cups per day</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>


                        <Paragraph color="#219B8E" bold="600" margin="2rem 0 .5rem">
                          Fine Sugar and Milk Intake
                        </Paragraph>

                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            41. How often do you drink bottled juice or sugarly drinks?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN046", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN046}
                              padding="8px 20px"
                              mb="0"
                              name="HQN046"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Never">Never</MenuItem>
                              <MenuItem value="Ocassionally">Ocassionally</MenuItem>
                              <MenuItem value="Usually">Usually</MenuItem>
                              <MenuItem value="Always">Always</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            42. How often do you eat sweet foods?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN047", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN047}
                              padding="8px 20px"
                              mb="0"
                              name="HQN047"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Not">Do not eat such foods or less than once a week</MenuItem>
                              <MenuItem value="1">2-3 times a week</MenuItem>
                              <MenuItem value="2">4-5 times a week</MenuItem>
                              <MenuItem value="3">6 times a week every day</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            43. How much milk / dairy products do you consume?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN048", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN048}
                              padding="8px 20px"
                              mb="0"
                              name="HQN048"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Never">Never / rarely</MenuItem>
                              <MenuItem value="Ocassionally">Ocassionally</MenuItem>
                              <MenuItem value="Once">Once per day</MenuItem>
                              <MenuItem value="More">More than twice per day</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>


                        <Paragraph color="#219B8E" bold="600" margin="2rem 0 1rem">
                          Eating Habits
                        </Paragraph>

                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            44. How many meals do you eat every day?
                          </Paragraph>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN049A"
                              className={classes.styledCheckbox}
                              checked={selection["HQN049A"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN049A", value);
                              }}
                            />
                            Breakfast
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN049B"
                              className={classes.styledCheckbox}
                              checked={selection["HQN049B"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN049B", value);
                              }}
                            />
                            Morning Snack
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN049C"
                              className={classes.styledCheckbox}
                              checked={selection["HQN049C"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN049C", value);
                              }}
                            />
                            Lunch
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN049D"
                              className={classes.styledCheckbox}
                              checked={selection["HQN049D"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN049D", value);
                              }}
                            />
                            Afternoon Snack
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN049E"
                              className={classes.styledCheckbox}
                              checked={selection["HQN049E"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN049E", value);
                              }}
                            />
                            Dinner
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN049F"
                              className={classes.styledCheckbox}
                              checked={selection["HQN049F"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN049F", value);
                              }}
                            />
                            Late Night Snack
                          </label>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            45. Which meals do you usually dine out?
                          </Paragraph>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN050A"
                              className={classes.styledCheckbox}
                              checked={selection["HQN050A"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN050A", value);
                              }}
                            />
                            None
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN050B"
                              className={classes.styledCheckbox}
                              checked={selection["HQN050B"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN050B", value);
                              }}
                            />
                            Breakfast
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN050C"
                              className={classes.styledCheckbox}
                              checked={selection["HQN050C"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN050C", value);
                              }}
                            />
                            Morning Snack
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN050D"
                              className={classes.styledCheckbox}
                              checked={selection["HQN050D"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN050D", value);
                              }}
                            />
                            Lunch
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN050E"
                              className={classes.styledCheckbox}
                              checked={selection["HQN050E"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN050E", value);
                              }}
                            />
                            Afternoon Snack
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN050F"
                              className={classes.styledCheckbox}
                              checked={selection["HQN050F"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN050F", value);
                              }}
                            />
                            Dinner
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN050G"
                              className={classes.styledCheckbox}
                              checked={selection["HQN050G"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN050G", value);
                              }}
                            />
                            Late Night Snack
                          </label>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            46. What type of nutrients or health foods do you usually eat?
                          </Paragraph>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN052A"
                              className={classes.styledCheckbox}
                              checked={selection["HQN052A"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN052A", value);
                              }}
                            />
                            None
                          </label>
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN052O"
                              className={classes.styledCheckbox}
                              checked={selection["HQN052O"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN052O", value);
                              }}
                            />
                            Algae
                          </label>
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN052F"
                              className={classes.styledCheckbox}
                              checked={selection["HQN052F"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN052F", value);
                              }}
                            />
                            Calcium
                          </label>
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN052I"
                              className={classes.styledCheckbox}
                              checked={selection["HQN052I"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN052I", value);
                              }}
                            />
                            Chitosan
                          </label>
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN052G"
                              className={classes.styledCheckbox}
                              checked={selection["HQN052G"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN052G", value);
                              }}
                            />
                            Iron
                          </label>
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN052O"
                              className={classes.styledCheckbox}
                              checked={selection["HQN052O"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN052O", value);
                              }}
                            />
                            Lactobacillus / Probiotics
                          </label>
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN052M"
                              className={classes.styledCheckbox}
                              checked={selection["HQN052M"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN052M", value);
                              }}
                            />
                            Lecithin
                          </label>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkBoxLabel4}>
                            <input
                              type="checkbox"
                              name="HQN052E"
                              className={classes.styledCheckbox}
                              checked={selection["HQN052E"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN052E", value);
                              }}
                            />
                            Multi-Vitamin
                          </label>
                          <label className={classes.checkBoxLabel4}>
                            <input
                              type="checkbox"
                              name="HQN052L"
                              className={classes.styledCheckbox}
                              checked={selection["HQN052L"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN052L", value);
                              }}
                            />
                            Phytochemicals o (turmeric extract, grapeseed extract etc)
                          </label>
                          <label className={classes.checkBoxLabel4}>
                            <input
                              type="checkbox"
                              name="HQN052P"
                              className={classes.styledCheckbox}
                              checked={selection["HQN052P"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN052P", value);
                              }}
                            />
                            Pollen
                          </label>
                          <label className={classes.checkBoxLabel4}>
                            <input
                              type="checkbox"
                              name="HQN052N"
                              className={classes.styledCheckbox}
                              checked={selection["HQN052N"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN052N", value);
                              }}
                            />
                            Propolis
                          </label>
                          <label className={classes.checkBoxLabel4}>
                            <input
                              type="checkbox"
                              name="HQN052J"
                              className={classes.styledCheckbox}
                              checked={selection["HQN052J"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN052J", value);
                              }}
                            />
                            Vegetable Fibre
                          </label>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkBoxLabel4}>
                            <input
                              type="checkbox"
                              name="HQN052B"
                              className={classes.styledCheckbox}
                              checked={selection["HQN052B"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN052B", value);
                              }}
                            />
                            Vitamin B
                          </label>
                          <label className={classes.checkBoxLabel4}>
                            <input
                              type="checkbox"
                              name="HQN052C"
                              className={classes.styledCheckbox}
                              checked={selection["HQN052C"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN052C", value);
                              }}
                            />
                            Vitamin C
                          </label>
                          <label className={classes.checkBoxLabel4}>
                            <input
                              type="checkbox"
                              name="HQN052S"
                              className={classes.styledCheckbox}
                              checked={selection["HQN052S"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN052S", value);
                              }}
                            />
                            Vitamin D
                          </label>
                          <label className={classes.checkBoxLabel4}>
                            <input
                              type="checkbox"
                              name="HQN052E"
                              className={classes.styledCheckbox}
                              checked={selection["HQN052E"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN052E", value);
                              }}
                            />
                            Vitamin E
                          </label>
                          <label className={classes.checkBoxLabel4}>
                            <input
                              type="checkbox"
                              name="HQN052H"
                              className={classes.styledCheckbox}
                              checked={selection["HQN052H"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN052H", value);
                              }}
                            />
                            Zinc
                          </label>
                          <label className={classes.checkBoxLabel4}>
                            <input
                              type="checkbox"
                              name="HQN052K"
                              className={classes.styledCheckbox}
                              checked={selection["HQN052K"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN052K", value);
                              }}
                            />
                            Fish Oil / Cod Liver Oil / Flax Seed Oil / Sancha Oil / Krill Oil
                          </label>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkBoxLabel5}>
                            <input
                              type="checkbox"
                              name="HQN052T"
                              className={classes.styledCheckbox}
                              checked={selection["HQN052T"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN052T", value);
                              }}
                            />
                            Collagen
                          </label>
                          <label className={classes.checkBoxLabel5}>
                            <input
                              type="checkbox"
                              name="HQN052U"
                              className={classes.styledCheckbox}
                              checked={selection["HQN052U"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN052U", value);
                              }}
                            />
                            Milk Thistle / Silymarin
                          </label>
                          <label className={classes.checkBoxLabel5}>
                            <input
                              type="checkbox"
                              name="HQN052V"
                              className={classes.styledCheckbox}
                              checked={selection["HQN052V"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN052V", value);
                              }}
                            />
                            Protein Powder
                          </label>
                          <label className={classes.checkBoxLabel5}>
                            <input
                              type="checkbox"
                              name="HQN052W"
                              className={classes.styledCheckbox}
                              checked={selection["HQN052W"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN052W", value);
                              }}
                            />
                            Glucosamine
                          </label>
                          <label className={classes.checkBoxLabel5}>
                            <input
                              type="checkbox"
                              name="HQN052R"
                              className={classes.styledCheckbox}
                              checked={selection["HQN052R"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN052R", value);
                              }}
                            />
                            Other Traditional Chinese Herbs
                          </label>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            47. Are you a full-day vegetarian?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN053", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN053}
                              padding="8px 20px"
                              mb="0"
                              name="HQN053"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                              <MenuItem value="Y">Yes</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            margin="0 0 0 2rem"
                            style={{ width: "37.5%" }}
                          >
                            a. What type of vegetarian?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN053A", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN053A}
                              padding="8px 20px"
                              mb="0"
                              name="HQN053A"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Pure">Pure vegan</MenuItem>
                              <MenuItem value="Ovo">Ovo-lacto vegetarian</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            margin="0 0 0 2rem"
                            style={{ width: "37.5%" }}
                          >
                            b. How long have you been a vegetarian.
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN053B", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN053B}
                              padding="8px 20px"
                              mb="0"
                              name="HQN053B"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Less">Less than a year</MenuItem>
                              <MenuItem value="1">1-3 years</MenuItem>
                              <MenuItem value="2">3-5 years</MenuItem>
                              <MenuItem value="3">5-10 years</MenuItem>
                              <MenuItem value="More">More than 10 years</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <div className={classes.theButtonFlex}>
                          <div className={classes.theButton}>
                            <Button
                              onClick={handleSaveComment}
                              label="Save"
                              color="#fff"
                              hvbg="#3ADEBB"
                              bg="#3ADEBB"
                              size="16px"
                              bold="700"
                              pd="10px 23px"
                              br="3px"
                              boxshadow="0px 3px 15px rgba(33, 155, 142, 0.25)"
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {tabs == 3 && (
                      <>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            48. What is the activity intensity of your daily work?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN054", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN054}
                              padding="8px 20px"
                              mb="0"
                              name="HQN054"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Mostly">Mostly sitting</MenuItem>
                              <MenuItem value="Frequent">Frequent repeated sitting and walking</MenuItem>
                              <MenuItem value="Standing">Mostly standing and walking</MenuItem>
                              <MenuItem value="Entire">Use of the muscles of the entire body is required</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            49. What kind of sports do you usually do? (select all that)
                          </Paragraph>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                            width: "70%",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              width: "50%",
                              alignItems: "center",
                            }}
                          >
                            <label className={classes.checkBoxLabel3}>
                              <input
                                type="checkbox"
                                name="HQN055A"
                                className={classes.styledCheckbox}
                                checked={selection["HQN055A"] === "Yes"}
                                onChange={(e) => {
                                  const value = e.target.checked ? "Yes" : "No";
                                  handleChange("HQN055A", value);
                                }}
                              />
                              Do not exercise
                            </label>

                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              width: "50%",
                              alignItems: "center",
                            }}
                          >
                            <label className={classes.checkBoxLabel3}>
                              <input
                                type="checkbox"
                                name="HQN055F"
                                className={classes.styledCheckbox}
                                checked={selection["HQN055F"] === "Yes"}
                                onChange={(e) => {
                                  const value = e.target.checked ? "Yes" : "No";
                                  handleChange("HQN055F", value);
                                }}
                              />
                              Mild-Aerobic (Cardiopulmonary) Exercise
                            </label>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                            width: "70%",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              width: "50%",
                              alignItems: "center",
                            }}
                          >
                            <label className={classes.checkBoxLabel4}>
                              <input
                                type="checkbox"
                                name="HQN055C"
                                className={classes.styledCheckbox}
                                checked={selection["HQN055C"] === "Yes"}
                                onChange={(e) => {
                                  const value = e.target.checked ? "Yes" : "No";
                                  handleChange("HQN055C", value);
                                }}
                              />
                              Anaerobic Exercise
                            </label>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              width: "50%",
                              alignItems: "center",
                            }}
                          >
                            <label className={classes.checkBoxLabel4}>
                              <input
                                type="checkbox"
                                name="HQN055G"
                                className={classes.styledCheckbox}
                                checked={selection["HQN055G"] === "Yes"}
                                onChange={(e) => {
                                  const value = e.target.checked ? "Yes" : "No";
                                  handleChange("HQN055G", value);
                                }}
                              />
                              Moderate-Aerobic (Cardiopulmonary) Exercise
                            </label>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                            width: "70%",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              width: "50%",
                              alignItems: "center",
                            }}
                          >
                            <label className={classes.checkBoxLabel4}>
                              <input
                                type="checkbox"
                                name="HQN055H"
                                className={classes.styledCheckbox}
                                checked={selection["HQN055H"] === "Yes"}
                                onChange={(e) => {
                                  const value = e.target.checked ? "Yes" : "No";
                                  handleChange("HQN055H", value);
                                }}
                              />
                              Heavy-Aerobic (Cardiopulmonary) Exercise
                            </label>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              width: "50%",
                              alignItems: "center",
                            }}
                          >
                            <label className={classes.checkBoxLabel4}>
                              <input
                                type="checkbox"
                                name="HQN055D"
                                className={classes.styledCheckbox}
                                checked={selection["HQN055D"] === "Yes"}
                                onChange={(e) => {
                                  const value = e.target.checked ? "Yes" : "No";
                                  handleChange("HQN055D", value);
                                }}
                              />
                              Stretching Exercise
                            </label>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                            width: "70%",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              width: "50%",
                              alignItems: "center",
                            }}
                          >
                            <label className={classes.checkBoxLabel5}>
                              <input
                                type="checkbox"
                                name="HQN055I"
                                className={classes.styledCheckbox}
                                checked={selection["HQN055I"] === "Yes"}
                                onChange={(e) => {
                                  const value = e.target.checked ? "Yes" : "No";
                                  handleChange("HQN055I", value);
                                }}
                              />
                              Intensive-Aerobic (Cardiopulmonary) Exercise
                            </label>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            50. How often do you do the sports mentioned above?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN056", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN056}
                              padding="8px 20px"
                              mb="0"
                              name="HQN056"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Not">Do not exercise or less than once a week</MenuItem>
                              <MenuItem value="1">Once a week</MenuItem>
                              <MenuItem value="2">2-3 times a week</MenuItem>
                              <MenuItem value="3">4-6 times a week</MenuItem>
                              <MenuItem value="More">More than once a day</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            51. On average, the duration you spent on the sports mentioned above over the past two weeks?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN057", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN057}
                              padding="8px 20px"
                              mb="0"
                              name="HQN057"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Less">Less than 0.5 hour</MenuItem>
                              <MenuItem value="1">0.5-1 hour</MenuItem>
                              <MenuItem value="2">1-2 hours</MenuItem>
                              <MenuItem value="3">4-6 times a week</MenuItem>
                              <MenuItem value="More">2 hours and more</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            52. Do you sweat and feel shortness of breath while exercising?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN058", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN058}
                              padding="8px 20px"
                              mb="0"
                              name="HQN058"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="No">No change</MenuItem>
                              <MenuItem value="Slightly">Slightly increased breathing rate</MenuItem>
                              <MenuItem value="Shortness">Shortness of breath</MenuItem>
                              <MenuItem value="Out">Out of breath</MenuItem>
                              <MenuItem value="More">2 hours and more</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <div className={classes.theButtonFlex}>
                          <div className={classes.theButton}>
                            <Button
                              onClick={handleSaveComment}
                              label="Save"
                              color="#fff"
                              hvbg="#3ADEBB"
                              bg="#3ADEBB"
                              size="16px"
                              bold="700"
                              pd="10px 23px"
                              br="3px"
                              boxshadow="0px 3px 15px rgba(33, 155, 142, 0.25)"
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {tabs == 4 && (
                      <>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            53. What is your average sleeping hours at night?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN059", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN059}
                              padding="8px 20px"
                              mb="0"
                              name="HQN059"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Less">Less than 4 hours</MenuItem>
                              <MenuItem value="1">4-6 hours</MenuItem>
                              <MenuItem value="2">6-7 hours</MenuItem>
                              <MenuItem value="3">7-8 hours</MenuItem>
                              <MenuItem value="4">8-9 hours</MenuItem>
                              <MenuItem value="More">9 hours and more</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            54. Have you had any of the following situations in the past month when falling?
                          </Paragraph>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN060C"
                              className={classes.styledCheckbox}
                              checked={selection["HQN060C"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN060C", value);
                              }}
                            />
                            Able To Sleep But Easily Awakened
                          </label>
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN060E"
                              className={classes.styledCheckbox}
                              checked={selection["HQN060E"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN060E", value);
                              }}
                            />
                            Deep Sleep
                          </label>
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN060A"
                              className={classes.styledCheckbox}
                              checked={selection["HQN060A"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN060A", value);
                              }}
                            />
                            Difficult To Sleep
                          </label>
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN060D"
                              className={classes.styledCheckbox}
                              checked={selection["HQN060D"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN060D", value);
                              }}
                            />
                            Dream-Distressed Sleep
                          </label>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkBoxLabel5}>
                            <input
                              type="checkbox"
                              name="HQN060B"
                              className={classes.styledCheckbox}
                              checked={selection["HQN060B"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN060B", value);
                              }}
                            />
                            Require Sleeping Pills Or Sedatives
                          </label>
                          <label className={classes.checkBoxLabel5}>
                            <input
                              type="checkbox"
                              name="HQN060F"
                              className={classes.styledCheckbox}
                              checked={selection["HQN060F"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN060F", value);
                              }}
                            />
                            Woke Up Tired
                          </label>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            55. Do you snore loudly?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN061", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN061}
                              padding="8px 20px"
                              mb="0"
                              name="HQN061"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                              <MenuItem value="Y">Yes</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            56. Do you often feel tired, exhausted or even drowsy during the day?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN062", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN062}
                              padding="8px 20px"
                              mb="0"
                              name="HQN062"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                              <MenuItem value="Y">Yes</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            57. Have other people noticed that you briefly stop breathing during sleep?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN063", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN063}
                              padding="8px 20px"
                              mb="0"
                              name="HQN063"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                              <MenuItem value="Y">Yes</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            58. Have you been tired and unable to get anything done in the past two weeks?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN064", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN064}
                              padding="8px 20px"
                              mb="0"
                              name="HQN064"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                              <MenuItem value="Rarely">Rarely</MenuItem>
                              <MenuItem value="Ocassionally">Ocassionally</MenuItem>
                              <MenuItem value="Usually">Usually</MenuItem>
                              <MenuItem value="Always">Always</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            59. Have you been feeling low, depressed or sad in the past two weeks?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN065", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN065}
                              padding="8px 20px"
                              mb="0"
                              name="HQN065"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                              <MenuItem value="Rarely">Rarely</MenuItem>
                              <MenuItem value="Ocassionally">Ocassionally</MenuItem>
                              <MenuItem value="Usually">Usually</MenuItem>
                              <MenuItem value="Always">Always</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <div className={classes.theButtonFlex}>
                          <div className={classes.theButton}>
                            <Button
                              onClick={handleSaveComment}
                              label="Save"
                              color="#fff"
                              hvbg="#3ADEBB"
                              bg="#3ADEBB"
                              size="16px"
                              bold="700"
                              pd="10px 23px"
                              br="3px"
                              boxshadow="0px 3px 15px rgba(33, 155, 142, 0.25)"
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {tabs == 5 && (
                      <>
                        <Paragraph color="#219B8E" bold="600" margin="2rem 0 1rem">
                          Basic Questions
                        </Paragraph>

                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            60. How do you feel about your current health?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN066", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN066}
                              padding="8px 20px"
                              mb="0"
                              name="HQN066"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Normal">Normal</MenuItem>
                              <MenuItem value="Not">Not bad</MenuItem>
                              <MenuItem value="Bad">Bad</MenuItem>
                              <MenuItem value="Very">Very bad</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            61. Have you lost 3-4 kg or more over the past three months?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN069", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN069}
                              padding="8px 20px"
                              mb="0"
                              name="HQN069"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                              <MenuItem value="Y">Yes</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            62. Do you have allergies? (select all that apply)
                          </Paragraph>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN071A"
                              className={classes.styledCheckbox}
                              checked={selection["HQN071A"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN071A", value);
                              }}
                            />
                            No
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN071F"
                              className={classes.styledCheckbox}
                              checked={selection["HQN071F"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN071F", value);
                              }}
                            />
                            Drug Allergy
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN071B"
                              className={classes.styledCheckbox}
                              checked={selection["HQN071B"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN071B", value);
                              }}
                            />
                            Eye Allergy
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN071E"
                              className={classes.styledCheckbox}
                              checked={selection["HQN071E"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN071E", value);
                              }}
                            />
                            Food Allergy
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN071C"
                              className={classes.styledCheckbox}
                              checked={selection["HQN071C"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN071C", value);
                              }}
                            />
                            Nose Allergy
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN071D"
                              className={classes.styledCheckbox}
                              checked={selection["HQN071D"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN071D", value);
                              }}
                            />
                            Skin Allergy
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN071G"
                              className={classes.styledCheckbox}
                              checked={selection["HQN071G"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN071G", value);
                              }}
                            />
                            Other Allergy
                          </label>
                        </Box>


                        <Paragraph color="#219B8E" bold="600" margin="2rem 0 1rem">
                          Eyes
                        </Paragraph>

                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            63. Do your eyes have any of the following symptoms? (select all that apply)
                          </Paragraph>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN072A"
                              className={classes.styledCheckbox}
                              checked={selection["HQN072A"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN072A", value);
                              }}
                            />
                            No
                          </label>
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN072B"
                              className={classes.styledCheckbox}
                              checked={selection["HQN072B"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN072B", value);
                              }}
                            />
                            Dry
                          </label>
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN072C"
                              className={classes.styledCheckbox}
                              checked={selection["HQN072C"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN072C", value);
                              }}
                            />
                            Excessive Training
                          </label>
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN072H"
                              className={classes.styledCheckbox}
                              checked={selection["HQN072H"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN072H", value);
                              }}
                            />
                            Flashes
                          </label>
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN072I"
                              className={classes.styledCheckbox}
                              checked={selection["HQN072I"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN072I", value);
                              }}
                            />
                            Floating Objects (Floaters)
                          </label>
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN072D"
                              className={classes.styledCheckbox}
                              checked={selection["HQN072D"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN072D", value);
                              }}
                            />
                            Itchiness
                          </label>
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN072F"
                              className={classes.styledCheckbox}
                              checked={selection["HQN072F"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN072F", value);
                              }}
                            />
                            Pain
                          </label>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkBoxLabel5}>
                            <input
                              type="checkbox"
                              name="HQN072E"
                              className={classes.styledCheckbox}
                              checked={selection["HQN072E"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN072E", value);
                              }}
                            />
                            Reduced Vision
                          </label>
                          <label className={classes.checkBoxLabel5}>
                            <input
                              type="checkbox"
                              name="HQN072G"
                              className={classes.styledCheckbox}
                              checked={selection["HQN072G"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN072G", value);
                              }}
                            />
                            Visual Distortion
                          </label>
                        </Box>


                        <Paragraph color="#219B8E" bold="600" margin="2rem 0 1rem">
                          Ears
                        </Paragraph>

                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            64. How is your hearing?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN073", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN073}
                              padding="8px 20px"
                              mb="0"
                              name="HQN073"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Normal">Both ears are normal</MenuItem>
                              <MenuItem value="Left">Hearing loss of left ear</MenuItem>
                              <MenuItem value="Right">Hearing loss of right ear</MenuItem>
                              <MenuItem value="Both">Hearing loss of both ears</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            65. Does your ear canal have the following symptoms? (select all that apply)
                          </Paragraph>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN074A"
                              className={classes.styledCheckbox}
                              checked={selection["HQN074A"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN074A", value);
                              }}
                            />
                            No
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN074C"
                              className={classes.styledCheckbox}
                              checked={selection["HQN074C"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN074C", value);
                              }}
                            />
                            Itchiness
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN074B"
                              className={classes.styledCheckbox}
                              checked={selection["HQN074B"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN074B", value);
                              }}
                            />
                            Retention Of Fluid (Discharge)
                          </label>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            66. Do you experience dizziness?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN075", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN075}
                              padding="8px 20px"
                              mb="0"
                              name="HQN075"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                              <MenuItem value="Y">Yes</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            66. Do you experience ringing in your ears?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN076", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN076}
                              padding="8px 20px"
                              mb="0"
                              name="HQN076"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                              <MenuItem value="Y">Yes</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>


                        <Paragraph color="#219B8E" bold="600" margin="2rem 0 1rem">
                          Nose
                        </Paragraph>

                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            67. Do you have any abnormalities of your nose? (select all that apply)
                          </Paragraph>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN077A"
                              className={classes.styledCheckbox}
                              checked={selection["HQN077A"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN077A", value);
                              }}
                            />
                            No
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN077B"
                              className={classes.styledCheckbox}
                              checked={selection["HQN077B"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN077B", value);
                              }}
                            />
                            Allergy
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN077C"
                              className={classes.styledCheckbox}
                              checked={selection["HQN077C"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN077C", value);
                              }}
                            />
                            Nasal Obstructive / Backflow
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN077D"
                              className={classes.styledCheckbox}
                              checked={selection["HQN077D"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN077D", value);
                              }}
                            />
                            Nose Bleed
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN077E"
                              className={classes.styledCheckbox}
                              checked={selection["HQN077E"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN077E", value);
                              }}
                            />
                            Other
                          </label>
                        </Box>


                        <Paragraph color="#219B8E" bold="600" margin="2rem 0 1rem">
                          Throat
                        </Paragraph>

                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            68. Do you experience swallowing problems?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN078", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN078}
                              padding="8px 20px"
                              mb="0"
                              name="HQN078"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                              <MenuItem value="Y">Yes, within the past month</MenuItem>
                              <MenuItem value="T">Yes, within the past three months</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            69. Do you cough constantly?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN079", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN079}
                              padding="8px 20px"
                              mb="0"
                              name="HQN079"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                              <MenuItem value="Y">Yes, within the past month</MenuItem>
                              <MenuItem value="T">Yes, within the past three months</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            70. Do you cough up sputum?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN080", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN080}
                              padding="8px 20px"
                              mb="0"
                              name="HQN080"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                              <MenuItem value="Y">Yes, within the past month</MenuItem>
                              <MenuItem value="T">Yes, within the past three months</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            71. Is there blood in sputum?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN081", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN081}
                              padding="8px 20px"
                              mb="0"
                              name="HQN081"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                              <MenuItem value="Y">Yes, within the past month</MenuItem>
                              <MenuItem value="T">Yes, within the past three months</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            72. Do you have abnormal lumps in the neck?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN082", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN082}
                              padding="8px 20px"
                              mb="0"
                              name="HQN082"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                              <MenuItem value="Y">Yes</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>


                        <Paragraph color="#219B8E" bold="600" margin="2rem 0 1rem">
                          Oval Cavity
                        </Paragraph>

                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            74. Do you feel you have abnormalities of the oral mucosa?
                          </Paragraph>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN083A"
                              className={classes.styledCheckbox}
                              checked={selection["HQN083A"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN083A", value);
                              }}
                            />
                            No
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN083C"
                              className={classes.styledCheckbox}
                              checked={selection["HQN083C"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN083C", value);
                              }}
                            />
                            Abnormal Colour Patches
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN083E"
                              className={classes.styledCheckbox}
                              checked={selection["HQN083E"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN083E", value);
                              }}
                            />
                            Neoplasm (Neoformation)
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN083B"
                              className={classes.styledCheckbox}
                              checked={selection["HQN083B"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN083B", value);
                              }}
                            />
                            Ulceration Or White Spots
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN083D"
                              className={classes.styledCheckbox}
                              checked={selection["HQN083D"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN083D", value);
                              }}
                            />
                            Ulcers That Are Difficult To Heal
                          </label>
                        </Box>


                        <Paragraph color="#219B8E" bold="600" margin="2rem 0 1rem">
                          Dermatology
                        </Paragraph>

                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            75. Does your skin have any abnormalities?
                          </Paragraph>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN084A"
                              className={classes.styledCheckbox}
                              checked={selection["HQN084A"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN084A", value);
                              }}
                            />
                            No
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN084B"
                              className={classes.styledCheckbox}
                              checked={selection["HQN084B"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN084B", value);
                              }}
                            />
                            Enlarged Mole
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN084C"
                              className={classes.styledCheckbox}
                              checked={selection["HQN084C"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN084C", value);
                              }}
                            />
                            Lumps
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN084D"
                              className={classes.styledCheckbox}
                              checked={selection["HQN084D"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN084D", value);
                              }}
                            />
                            Unhealed Wounds
                          </label>
                        </Box>


                        <Paragraph color="#219B8E" bold="600" margin="2rem 0 1rem">
                          Cardiology / Chest
                        </Paragraph>

                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            76. Do you feel chest tightness or chest pain?
                          </Paragraph>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN085A"
                              className={classes.styledCheckbox}
                              checked={selection["HQN085A"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN085A", value);
                              }}
                            />
                            No
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN085B"
                              className={classes.styledCheckbox}
                              checked={selection["HQN085B"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN085B", value);
                              }}
                            />
                            Yes When Exercising
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN085C"
                              className={classes.styledCheckbox}
                              checked={selection["HQN085C"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN085C", value);
                              }}
                            />
                            Yes When At Rest
                          </label>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            77. Do you feel breathless?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN086", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN086}
                              padding="8px 20px"
                              mb="0"
                              name="HQN086"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                              <MenuItem value="Y">Yes, within the past month</MenuItem>
                              <MenuItem value="T">Yes, within the past three months</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            78. Have you experienced palpitation recently?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN087", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN087}
                              padding="8px 20px"
                              mb="0"
                              name="HQN087"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                              <MenuItem value="Y">Yes, within the past month</MenuItem>
                              <MenuItem value="T">Yes, within the past three months</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <div className={classes.theButtonFlex}>
                          <div className={classes.theButton}>
                            <Button
                              onClick={handleSaveComment}
                              label="Save"
                              color="#fff"
                              hvbg="#3ADEBB"
                              bg="#3ADEBB"
                              size="16px"
                              bold="700"
                              pd="10px 23px"
                              br="3px"
                              boxshadow="0px 3px 15px rgba(33, 155, 142, 0.25)"
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {tabs == 6 && (
                      <>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "100%" }}
                          >
                            79. Have you experienced any gastrointestinal abnormality over the past three months? (select all that apply)
                          </Paragraph>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN088A"
                              className={classes.styledCheckbox}
                              checked={selection["HQN088A"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN088A", value);
                              }}
                            />
                            No
                          </label>
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN088F"
                              className={classes.styledCheckbox}
                              checked={selection["HQN088F"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN088F", value);
                              }}
                            />
                            Flatulance / Gastric Discomfort
                          </label>
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN088C"
                              className={classes.styledCheckbox}
                              checked={selection["HQN088C"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN088C", value);
                              }}
                            />
                            Gastric Pain When In Hunger
                          </label>
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN088D"
                              className={classes.styledCheckbox}
                              checked={selection["HQN088D"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN088D", value);
                              }}
                            />
                            Gastric Pain After Meals
                          </label>
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN088B"
                              className={classes.styledCheckbox}
                              checked={selection["HQN088B"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN088B", value);
                              }}
                            />
                            Loss Of Appetite
                          </label>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkBoxLabel5}>
                            <input
                              type="checkbox"
                              name="HQN088E"
                              className={classes.styledCheckbox}
                              checked={selection["HQN088E"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN088E", value);
                              }}
                            />
                            Frequent Nausea
                          </label>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "100%" }}
                          >
                            80. Have you experienced any abnormality of your bowel movement over the past three months? (select all that apply)
                          </Paragraph>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN089A"
                              className={classes.styledCheckbox}
                              checked={selection["HQN089A"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN089A", value);
                              }}
                            />
                            No
                          </label>
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN089C"
                              className={classes.styledCheckbox}
                              checked={selection["HQN089C"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN089C", value);
                              }}
                            />
                            Black Stool
                          </label>
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN089F"
                              className={classes.styledCheckbox}
                              checked={selection["HQN089F"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN089F", value);
                              }}
                            />
                            Change In Bowel Habits
                          </label>
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN089D"
                              className={classes.styledCheckbox}
                              checked={selection["HQN089D"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN089D", value);
                              }}
                            />
                            Constipation
                          </label>
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN089E"
                              className={classes.styledCheckbox}
                              checked={selection["HQN089E"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN089E", value);
                              }}
                            />
                            Diarrhoea
                          </label>
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN089G"
                              className={classes.styledCheckbox}
                              checked={selection["HQN089G"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN089G", value);
                              }}
                            />
                            Flatulance
                          </label>
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN089B"
                              className={classes.styledCheckbox}
                              checked={selection["HQN089B"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN089B", value);
                              }}
                            />
                            Stool With Blood
                          </label>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkBoxLabel5}>
                            <input
                              type="checkbox"
                              name="HQN089H"
                              className={classes.styledCheckbox}
                              checked={selection["HQN089H"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN089H", value);
                              }}
                            />
                            Stool Colour Becomes Lighter
                          </label>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "100%" }}
                          >
                            81. Have you experienced any abnormality of urination over the past three months? (select all that apply)
                          </Paragraph>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN090A"
                              className={classes.styledCheckbox}
                              checked={selection["HQN090A"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN090A", value);
                              }}
                            />
                            No
                          </label>
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN090C"
                              className={classes.styledCheckbox}
                              checked={selection["HQN090C"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN090C", value);
                              }}
                            />
                            Frequent Urination
                          </label>
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN090E"
                              className={classes.styledCheckbox}
                              checked={selection["HQN090E"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN090E", value);
                              }}
                            />
                            Leakage When Exerting Force
                          </label>
                          <label className={classes.checkBoxLabel3}>
                            <input
                              type="checkbox"
                              name="HQN090D"
                              className={classes.styledCheckbox}
                              checked={selection["HQN090D"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN090D", value);
                              }}
                            />
                            Urinary Urgency
                          </label>
                          <label className={classes.checkBoxLabel5}>
                            <input
                              type="checkbox"
                              name="HQN090G"
                              className={classes.styledCheckbox}
                              checked={selection["HQN090G"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN090G", value);
                              }}
                            />
                            Dribbling Of Urine
                          </label>
                          <label className={classes.checkBoxLabel5}>
                            <input
                              type="checkbox"
                              name="HQN090H"
                              className={classes.styledCheckbox}
                              checked={selection["HQN090H"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN090H", value);
                              }}
                            />
                            Foamy Urine
                          </label>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkBoxLabel5}>
                            <input
                              type="checkbox"
                              name="HQN090B"
                              className={classes.styledCheckbox}
                              checked={selection["HQN090B"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN090B", value);
                              }}
                            />
                            Bloody Urine
                          </label>
                          <label className={classes.checkBoxLabel5}>
                            <input
                              type="checkbox"
                              name="HQN090F"
                              className={classes.styledCheckbox}
                              checked={selection["HQN090F"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN090F", value);
                              }}
                            />
                            Pain During Urination
                          </label>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            82. Do you experience oedema in your lower limbs?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN091", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN091}
                              padding="8px 20px"
                              mb="0"
                              name="HQN091"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                              <MenuItem value="Ocassionally">Ocassionally</MenuItem>
                              <MenuItem value="Usually">Usually</MenuItem>
                              <MenuItem value="Everyday">Every day</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            83. Do you have to get up to urinate during middle of the night?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN092", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN092}
                              padding="8px 20px"
                              mb="0"
                              name="HQN092"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="1">0-1 time</MenuItem>
                              <MenuItem value="2">2 times</MenuItem>
                              <MenuItem value="3">3 times</MenuItem>
                              <MenuItem value="4">4 times</MenuItem>
                              <MenuItem value="more">5 times or more</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "100%" }}
                          >
                            84. Is there any changes in your external genitals?
                          </Paragraph>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN093A"
                              className={classes.styledCheckbox}
                              checked={selection["HQN093A"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN093A", value);
                              }}
                            />
                            No
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN093C"
                              className={classes.styledCheckbox}
                              checked={selection["HQN093C"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN093C", value);
                              }}
                            />
                            Asymmetrical Swelling
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN093B"
                              className={classes.styledCheckbox}
                              checked={selection["HQN093B"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN093B", value);
                              }}
                            />
                            Itchiness
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN093D"
                              className={classes.styledCheckbox}
                              checked={selection["HQN093D"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN093D", value);
                              }}
                            />
                            Pain
                          </label>
                        </Box>


                        <Paragraph color="#219B8E" bold="600" margin="2rem 0 1rem">
                          Obstetrics And Gynecology
                        </Paragraph>

                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            85. Are you menopause (no menstruation within the past year?)
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN094", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN094}
                              padding="8px 20px"
                              mb="0"
                              name="HQN094"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                              <MenuItem value="Y">Yes</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            margin="0 0 0 2rem"
                            style={{ width: "37.5%" }}
                          >
                            a. Yes, menopause at the age of
                          </Paragraph>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            margin: "0 0 .4rem 2rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            41
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            42
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            43
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            44
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            45
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            46
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            47
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            48
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            49
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            50
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            51
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            52
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            53
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            54
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            55
                          </label>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            margin: "0 0 .4rem 2rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            56
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            57
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            58
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            59
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            60
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Others
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Unfilled
                          </label>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            margin="0 0 0 2rem"
                            style={{ width: "37.5%" }}
                          >
                            b. No, are you pregnant?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN091", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN091}
                              padding="8px 20px"
                              mb="0"
                              name="HQN091"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                              <MenuItem value="Y">Yes</MenuItem>
                              <MenuItem value="D">Don't Know</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            margin="0 0 0 3rem"
                            style={{ width: "36.5%" }}
                          >
                            The date of the last menstrual?
                          </Paragraph>
                          <Box sx={{ width: "10%" }}>
                            <FormInput
                              className={classes.theBorderRadius}
                              name="replaced_name"
                              placeholder="dd"
                              type="number"
                              bg="#fff"
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              padding="8px 20px"
                              mb="0"
                            />
                          </Box>
                          <Paragraph
                            color="#211D4E"
                            margin="0 .5rem 0 .5rem"
                          >
                            -
                          </Paragraph>
                          <Box sx={{ width: "10%" }}>
                            <FormInput
                              className={classes.theBorderRadius}
                              name="replaced_name"
                              placeholder="mm"
                              type="number"
                              bg="#fff"
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              padding="8px 20px"
                              mb="0"
                            />
                          </Box>
                          <Paragraph
                            color="#211D4E"
                            margin="0 .5rem 0 .5rem"
                          >
                            -
                          </Paragraph>
                          <Box sx={{ width: "10%" }}>
                            <FormInput
                              className={classes.theBorderRadius}
                              name="replaced_name"
                              placeholder="yy"
                              type="number"
                              bg="#fff"
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              padding="8px 20px"
                              mb="0"
                            />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            86. The amount of your menstrual blood flow is
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN095", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN095}
                              padding="8px 20px"
                              mb="0"
                              name="HQN095"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="Normal">Normal</MenuItem>
                              <MenuItem value="Heavy">Heavy</MenuItem>
                              <MenuItem value="Less">Very less</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            87. The number of days of your menstrual cycle is
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN096", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN096}
                              padding="8px 20px"
                              mb="0"
                              name="HQN096"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="1">Less than 3 days</MenuItem>
                              <MenuItem value="2">3-7 days</MenuItem>
                              <MenuItem value="3">More than 7 days</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            88. Is your menstrual cycle regular?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN097", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN097}
                              padding="8px 20px"
                              mb="0"
                              name="HQN097"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="1">Normal</MenuItem>
                              <MenuItem value="2">Too short (less than 21 days)</MenuItem>
                              <MenuItem value="3">Too long (more than 42 days)</MenuItem>
                              <MenuItem value="4">Irregular</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            89. When is your last menstrual Period (LMP)?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN097A", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN097A}
                              padding="8px 20px"
                              mb="0"
                              name="HQN097A"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="1">- Days</MenuItem>
                              <MenuItem value="2">1-3 Days</MenuItem>
                              <MenuItem value="3">14-17 Days</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            90. Have you ever experienced pained during menstruation?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN098", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN098}
                              padding="8px 20px"
                              mb="0"
                              name="HQN098"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                              <MenuItem value="Y">Yes</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            91. Number of pregnancies?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN099", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN099}
                              padding="8px 20px"
                              mb="0"
                              name="HQN099"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="0">0</MenuItem>
                              <MenuItem value="1">1</MenuItem>
                              <MenuItem value="2">2</MenuItem>
                              <MenuItem value="3">3</MenuItem>
                              <MenuItem value="4">4</MenuItem>
                              <MenuItem value="5">5 (inclusive or more)</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            92. Number of births?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN100", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN100}
                              padding="8px 20px"
                              mb="0"
                              name="HQN100"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="0">0</MenuItem>
                              <MenuItem value="1">1</MenuItem>
                              <MenuItem value="2">2</MenuItem>
                              <MenuItem value="3">3</MenuItem>
                              <MenuItem value="4">4</MenuItem>
                              <MenuItem value="5">5 (inclusive or more)</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            93. Have you ever breastfed?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN101", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN101}
                              padding="8px 20px"
                              mb="0"
                              name="HQN101"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                              <MenuItem value="Y">Yes</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            94. Do you use any contraceptive for birth control?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN102", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN102}
                              padding="8px 20px"
                              mb="0"
                              name="HQN102"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="No">No birth control</MenuItem>
                              <MenuItem value="Condoms">Condoms</MenuItem>
                              <MenuItem value="Oral">Oral contraceptives</MenuItem>
                              <MenuItem value="IUD">IUD</MenuItem>
                              <MenuItem value="Vasectomy">Vasectomy</MenuItem>
                              <MenuItem value="Other">Other</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            95. Do you usually experience abnormal vaginal bleeding or after sexual intercourse?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN103", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN103}
                              padding="8px 20px"
                              mb="0"
                              name="HQN103"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                              <MenuItem value="Y">Yes</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            96. Do you have breast tenderness or lumps in your breast?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN104", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN104}
                              padding="8px 20px"
                              mb="0"
                              name="HQN104"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                              <MenuItem value="Left">Yes, on the left</MenuItem>
                              <MenuItem value="Right">Yes, on the right</MenuItem>
                              <MenuItem value="Both">Lumps, on both breasts</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            97. Do you experience nipple discharge or breast deformity?
                          </Paragraph>
                          <Box sx={{ width: "30%" }}>
                            <FormInput
                              onChange={(e) =>
                                handleChange("HQN105", e.target.value)
                              }
                              fontWeight="600"
                              textColor={themePlate().primaryThree}
                              border="5px"
                              bg="#fff"
                              select
                              value={selection.HQN105}
                              padding="8px 20px"
                              mb="0"
                              name="HQN105"
                            ><MenuItem value="-">-</MenuItem>
                              <MenuItem value="N">No</MenuItem>
                              <MenuItem value="Left">Yes, on the left</MenuItem>
                              <MenuItem value="Right">Yes, on the right</MenuItem>
                              <MenuItem value="Both">On both breasts</MenuItem>
                            </FormInput>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            margin: "0 0 .4rem 2rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN112A"
                              className={classes.styledCheckbox}
                              checked={selection["HQN112A"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN112A", value);
                              }}
                            />
                            No
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN112B"
                              className={classes.styledCheckbox}
                              checked={selection["HQN112B"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN112B", value);
                              }}
                            />
                            Thick and Cheesy
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN112C"
                              className={classes.styledCheckbox}
                              checked={selection["HQN112C"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN112C", value);
                              }}
                            />
                            Bloody or Brown
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN112D"
                              className={classes.styledCheckbox}
                              checked={selection["HQN112D"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN112D", value);
                              }}
                            />
                            Yellow or Green
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN112E"
                              className={classes.styledCheckbox}
                              checked={selection["HQN112E"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN112E", value);
                              }}
                            />
                            Gray or Yellow with Fish Odor
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN112F"
                              className={classes.styledCheckbox}
                              checked={selection["HQN112F"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN112F", value);
                              }}
                            />
                            Pink
                          </label>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            98. Have you had euromuscular problems over the past three months? (Select all that apply)
                          </Paragraph>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            margin: "0 0 .4rem 2rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN106A"
                              className={classes.styledCheckbox}
                              checked={selection["HQN106A"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN106A", value);
                              }}
                            />
                            No
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN106D"
                              className={classes.styledCheckbox}
                              checked={selection["HQN106D"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN106D", value);
                              }}
                            />
                            Numbness in Limbs
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN106C"
                              className={classes.styledCheckbox}
                              checked={selection["HQN106C"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN106C", value);
                              }}
                            />
                            Lower Back Pain
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN106B"
                              className={classes.styledCheckbox}
                              checked={selection["HQN106B"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN106B", value);
                              }}
                            />
                            Upper Back Pain
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN106E"
                              className={classes.styledCheckbox}
                              checked={selection["HQN106E"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN106E", value);
                              }}
                            />
                            Weakness Of Limbs
                          </label>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            99. Have you had join pain problems over the past three months?
                          </Paragraph>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            margin: "0 0 .4rem 2rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN107A"
                              className={classes.styledCheckbox}
                              checked={selection["HQN107A"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN107A", value);
                              }}
                            />
                            No
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN107B"
                              className={classes.styledCheckbox}
                              checked={selection["HQN107B"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN107B", value);
                              }}
                            />
                            Elbows
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN107E"
                              className={classes.styledCheckbox}
                              checked={selection["HQN107E"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN107E", value);
                              }}
                            />
                            Femurs
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN107D"
                              className={classes.styledCheckbox}
                              checked={selection["HQN107D"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN107D", value);
                              }}
                            />
                            Knees
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN107C"
                              className={classes.styledCheckbox}
                              checked={selection["HQN107C"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN107C", value);
                              }}
                            />
                            Shoulders
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN107E"
                              className={classes.styledCheckbox}
                              checked={selection["HQN107E"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN107E", value);
                              }}
                            />
                            Soles
                          </label>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            100. How is your walking condition? (Select all that apply)
                          </Paragraph>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            margin: "0 0 .4rem 2rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN108A"
                              className={classes.styledCheckbox}
                              checked={selection["HQN108A"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN108A", value);
                              }}
                            />
                            No Symptoms
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN108B"
                              className={classes.styledCheckbox}
                              checked={selection["HQN108B"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN108B", value);
                              }}
                            />
                            Unbalanced
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN108C"
                              className={classes.styledCheckbox}
                              checked={selection["HQN108C"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN108C", value);
                              }}
                            />
                            Frequent Fails
                          </label>
                          <label className={classes.checkBoxLabel2}>
                            <input
                              type="checkbox"
                              name="HQN108D"
                              className={classes.styledCheckbox}
                              checked={selection["HQN108D"] === "Yes"}
                              onChange={(e) => {
                                const value = e.target.checked ? "Yes" : "No";
                                handleChange("HQN108D", value);
                              }}
                            />
                            Shorter Steps Length
                          </label>
                        </Box>
                        <div className={classes.theButtonFlex}>
                          <div className={classes.theButton}>
                            <Button
                              onClick={handleSaveComment}
                              label="Save"
                              color="#fff"
                              hvbg="#3ADEBB"
                              bg="#3ADEBB"
                              size="16px"
                              bold="700"
                              pd="10px 23px"
                              br="3px"
                              boxshadow="0px 3px 15px rgba(33, 155, 142, 0.25)"
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {/* {tabs == 7 && (
                      <>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                          >
                            101. Have you ever had one of the following confirmed diseases, are under medication, or had surgery from this disease? (Check all that apply)
                          </Paragraph>
                        </Box>
                        <Box
                          sx={{ display: "flex", width: "100%" }}
                        >
                          <Paragraph
                            center
                            color="#211D4E"
                            bold="600"
                            style={{ width: "25%" }}
                          >
                            Type
                          </Paragraph>
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            center
                            style={{ width: "25%" }}
                          >
                            Associated Disease(s) Or Symptoms
                          </Paragraph>
                          <Paragraph
                            center
                            color="#211D4E"
                            bold="600"
                            style={{ width: "25%" }}
                          >
                            You Are Under Medication
                          </Paragraph>
                          <Paragraph
                            center
                            color="#211D4E"
                            bold="600"
                            style={{ width: "25%" }}
                          >
                            You Had Surgery
                          </Paragraph>
                        </Box>
                        <div className={classes.theLine}></div>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "25%" }}
                          >
                            1. Brain and Neurological Diseases
                          </Paragraph>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0",
                              width: "25%",
                            }}
                          >
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Dementia
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Epilepsy
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Migraine
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Multiple Sclerosis
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Parkinson's Disease
                            </label>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                        </Box>
                        <div className={classes.theLine}></div>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "25%" }}
                          >
                            2. Eye Disorder
                          </Paragraph>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0",
                              width: "25%",
                            }}
                          >
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Cataract
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Floaters
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Glaucoma
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Retina-Associated Disorders
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Vision Problems
                            </label>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                        </Box>
                        <div className={classes.theLine}></div>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "25%" }}
                          >
                            3. Ear, Nose and Throat Disorders
                          </Paragraph>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0",
                              width: "25%",
                            }}
                          >
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Chronic Pharyngitis
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Deviated Nasal Septum
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Dizziness
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Otitis Media
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Sinusitis
                            </label>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                        </Box>
                        <div className={classes.theLine}></div>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "25%" }}
                          >
                            4. Skin Abnormalities
                          </Paragraph>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0",
                              width: "25%",
                            }}
                          >
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Atopic Dermatitis
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Fungal Infection
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Onychomycosis
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Psoriasis
                            </label>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                        </Box>
                        <div className={classes.theLine}></div>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "25%" }}
                          >
                            5. Chronic Pulmonary Diseases
                          </Paragraph>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0",
                              width: "25%",
                            }}
                          >
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Asthma
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Chronic Bronchitis
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Chronic Obstructive Pulmonary Disease (COPD)
                            </label>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                        </Box>
                        <div className={classes.theLine}></div>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "25%" }}
                          >
                            6. Heart, Blood and Blood Vessel Disorders
                          </Paragraph>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0",
                              width: "25%",
                            }}
                          >
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Anaemia
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Arrhythmia
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Cararomyopathy (hypertrophic or dilated)
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Congenital Heart Disorder
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Coronary Artery Disease
                            </label>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                        </Box>
                        <div className={classes.theLine}></div>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "25%" }}
                          >
                            7. High Blood Pressure, High Blood Cholestrol and High Blood Glucose
                          </Paragraph>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0",
                              width: "25%",
                            }}
                          >
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Hypertension
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Hyperlipidemia
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Hyperglycemia (Diabetes)
                            </label>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                        </Box>
                        <div className={classes.theLine}></div>
                        <TableContainer>
                          <Table>
                            <TableHead className={classes.theHead}>
                              <TableRow>
                                <TableCell className={classes.theCenter}>
                                  Type
                                </TableCell>
                                <TableCell className={classes.theCenter}>
                                  Associated Disease(s) or Symptom(s)
                                </TableCell>
                                <TableCell className={classes.theCenter}>
                                  You Are Under Medication
                                </TableCell>
                                <TableCell className={classes.theCenter}>
                                  You Had Surgery
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  1. Brain and Neurological Diseases
                                </TableCell>
                                <TableCell>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Dementia
                                  </label>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Epilepsy
                                  </label>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Migraine
                                  </label>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Multiple Sclerosis
                                  </label>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Parkinson's Disease
                                  </label>
                                </TableCell>
                                <TableCell>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                </TableCell>
                                <TableCell>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  2. Eye Disorder
                                </TableCell>
                                <TableCell>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Cataract
                                  </label>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Floaters
                                  </label>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Glaucoma
                                  </label>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Retina-Associated Disorders
                                  </label>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Vision Problems
                                  </label>
                                </TableCell>
                                <TableCell>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                </TableCell>
                                <TableCell>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  3. Ear, Nose and Throat Disorders
                                </TableCell>
                                <TableCell>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Chronic Pharyngitis
                                  </label>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Deviated Nasal Septum
                                  </label>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Dizziness
                                  </label>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Otitis Media
                                  </label>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Sinusitis
                                  </label>
                                </TableCell>
                                <TableCell>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                </TableCell>
                                <TableCell>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  4. Skin Abnormalities
                                </TableCell>
                                <TableCell>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Atopic Dermatitis
                                  </label>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Fungal Infection
                                  </label>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Onychomycosis
                                  </label>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Psoriasis
                                  </label>
                                </TableCell>
                                <TableCell>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                </TableCell>
                                <TableCell>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  5. Chronic Pulmonary Diseases
                                </TableCell>
                                <TableCell>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Asthma
                                  </label>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Chronic Bronchitis
                                  </label>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Chronic Obstructive Pulmonary Disease (COPD)
                                  </label>
                                </TableCell>
                                <TableCell>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                </TableCell>
                                <TableCell>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  6. Heart, Blood and Blood Vessel Disorders
                                </TableCell>
                                <TableCell>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Anaemia
                                  </label>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Arrhythmia
                                  </label>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Cararomyopathy (hypertrophic or dilated)
                                  </label>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Congenital Heart Disorder
                                  </label>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Coronary Artery Disease
                                  </label>
                                </TableCell>
                                <TableCell>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                </TableCell>
                                <TableCell>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  7. High Blood Pressure, High Blood Cholestrol and High Blood Glucose
                                </TableCell>
                                <TableCell>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Hypertension
                                  </label>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Hyperlipidemia
                                  </label>
                                  <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                                    <input
                                      type="checkbox"
                                      name="category_ids"
                                      className={classes.styledCheckbox}
                                    />
                                    Hyperglycemia (Diabetes)
                                  </label>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="1">Type 1 - Diabetes</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                </TableCell>
                                <TableCell>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                </TableCell>
                                <TableCell>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                  <FormInput
                                    // onChange={(e) =>
                                    //   handleChange("HQN103", e.target.value)
                                    // }
                                    fontWeight="600"
                                    textColor={themePlate().primaryThree}
                                    border="5px"
                                    bg="#fff"
                                    select
                                    // value={selection.HQN103}
                                    padding="8px 20px"
                                    mb="0"
                                  // name="HQN103"
                                  >
                                    <MenuItem value="N">No</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                  </FormInput>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <div className={classes.theButtonFlex}>
                          <div className={classes.theButton}>
                            <Button
                              // onClick={handleSaveComment}
                              label="Save"
                              color="#fff"
                              hvbg="#3ADEBB"
                              bg="#3ADEBB"
                              size="16px"
                              bold="700"
                              pd="10px 23px"
                              br="3px"
                              boxshadow="0px 3px 15px rgba(33, 155, 142, 0.25)"
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {tabs == 8 && (
                      <>
                        <Box
                          sx={{ display: "flex", width: "100%" }}
                        >
                          <Paragraph
                            center
                            color="#211D4E"
                            bold="600"
                            style={{ width: "25%" }}
                          >
                            Type
                          </Paragraph>
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            center
                            style={{ width: "25%" }}
                          >
                            Associated Disease(s) Or Symptoms
                          </Paragraph>
                          <Paragraph
                            center
                            color="#211D4E"
                            bold="600"
                            style={{ width: "25%" }}
                          >
                            You Are Under Medication
                          </Paragraph>
                          <Paragraph
                            center
                            color="#211D4E"
                            bold="600"
                            style={{ width: "25%" }}
                          >
                            You Had Surgery
                          </Paragraph>
                        </Box>
                        <div className={classes.theLine}></div>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "25%" }}
                          >
                            8. Hepatobiliary
                          </Paragraph>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0",
                              width: "25%",
                            }}
                          >
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Hepatitis B
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Hepatitis C
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Liver cirrhosis
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Liver and/or Gallstones
                            </label>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                        </Box>
                        <div className={classes.theLine}></div>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "25%" }}
                          >
                            9. Gastrointestinal Tract Diseases
                          </Paragraph>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0",
                              width: "25%",
                            }}
                          >
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Peptic Ulser
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Gastroesophageal Reflux
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Haemorrhoids
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Irritable Bowel Syndrome
                            </label>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                        </Box>
                        <div className={classes.theLine}></div>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "25%" }}
                          >
                            10. Kidney Diseases
                          </Paragraph>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0",
                              width: "25%",
                            }}
                          >
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Chronic Nephritis
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Kidney Failure
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Kidney Oedema
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Kidney Stones
                            </label>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                        </Box>
                        <div className={classes.theLine}></div>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "25%" }}
                          >
                            11. Urinary Tract Diseases
                          </Paragraph>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0",
                              width: "25%",
                            }}
                          >
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Prostate Hypertrophy
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Urinary Calculus
                            </label>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                        </Box>
                        <div className={classes.theLine}></div>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "25%" }}
                          >
                            12. Gynecological Diseases
                          </Paragraph>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0",
                              width: "25%",
                            }}
                          >
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Endometiriosis
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Menstrual Disorders
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Ovarian / Fallopian Cyst
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Uterine Fibroids
                            </label>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                        </Box>
                        <div className={classes.theLine}></div>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "25%" }}
                          >
                            13. Bone, Joint Diseases
                          </Paragraph>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0",
                              width: "25%",
                            }}
                          >
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Rheumatoid Arthritis
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Degenerative Arthritis
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Gout
                            </label>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                        </Box>
                        <div className={classes.theLine}></div>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "25%" }}
                          >
                            14. Breast / Thyroid Diseases
                          </Paragraph>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0",
                              width: "25%",
                            }}
                          >
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Fibrocystic Breast
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Thyroid Nodules
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Hyperthyroidism / Hypothyroidism
                            </label>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                        </Box>
                        <div className={classes.theLine}></div>

                        <div className={classes.theButtonFlex}>
                          <div className={classes.theButton}>
                            <Button
                              // onClick={handleSaveComment}
                              label="Save"
                              color="#fff"
                              hvbg="#3ADEBB"
                              bg="#3ADEBB"
                              size="16px"
                              bold="700"
                              pd="10px 23px"
                              br="3px"
                              boxshadow="0px 3px 15px rgba(33, 155, 142, 0.25)"
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {tabs == 9 && (
                      <>
                        <Box
                          sx={{ display: "flex", width: "100%" }}
                        >
                          <Paragraph
                            center
                            color="#211D4E"
                            bold="600"
                            style={{ width: "25%" }}
                          >
                            Type
                          </Paragraph>
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            center
                            style={{ width: "25%" }}
                          >
                            Associated Disease(s) Or Symptoms
                          </Paragraph>
                          <Paragraph
                            center
                            color="#211D4E"
                            bold="600"
                            style={{ width: "25%" }}
                          >
                            You Are Under Medication
                          </Paragraph>
                          <Paragraph
                            center
                            color="#211D4E"
                            bold="600"
                            style={{ width: "25%" }}
                          >
                            You Had Surgery
                          </Paragraph>
                        </Box>
                        <div className={classes.theLine}></div>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "25%" }}
                          >
                            15. Cancers
                          </Paragraph>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0",
                              width: "25%",
                            }}
                          >
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Bladder Cancer
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Breast Cancer
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Cervical Cancer / Corpus Uteri Cancer
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Colorectal Cancer
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Esophageal Cancer
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Nasopharyngeal Carcinoma
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Gastic Cancer
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Liver Cancer / Cholangicarcinoma
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Lung Cancer / Trachea Cancer
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Lymphoma
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Oral Cancer
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Ovarian Cancer
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Pancreatic Cancer
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Prostate Cancer
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Skin Cancer
                            </label>
                            <label className={classes.checkBoxLabel1} style={{ margin: "0 1rem 0 0" }}>
                              <input
                                type="checkbox"
                                name="category_ids"
                                className={classes.styledCheckbox}
                              />
                              Thyroid Cancer
                            </label>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              margin: "0 0 .4rem 0rem",
                              width: "25%",
                            }}
                          >
                            <Box sx={{ width: "90%" }}>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                              <FormInput
                                // onChange={(e) =>
                                //   handleChange("HQN103", e.target.value)
                                // }
                                fontWeight="600"
                                textColor={themePlate().primaryThree}
                                border="5px"
                                bg="#fff"
                                select
                                // value={selection.HQN103}
                                padding="8px 20px"
                                mb="0"
                              // name="HQN103"
                              >
                                <MenuItem value="N">No</MenuItem>
                                <MenuItem value="Y">Yes</MenuItem>
                              </FormInput>
                            </Box>
                          </Box>
                        </Box>
                        <div className={classes.theLine}></div>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            margin: "0 0 .4rem 0"
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            16. Other Medications
                          </Paragraph>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            margin: "0 0 .4rem 2rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Hormonal Drugs
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Psychiatric Drugs
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Steroids
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Traditional Chinese Herbs
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Tranquilizers or Sleeping Pills
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Uric Acid Drugs
                          </label>
                        </Box>
                        <div className={classes.theLine}></div>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            marginBottom: ".4rem"
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="600"
                            style={{ width: "40%" }}
                          >
                            17. Have your family member (grandparents, parents and siblings) suffered from this disease? (Check all that apply)
                          </Paragraph>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            margin: "0 0 .4rem 2rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Atopic Anemia
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Asthma
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Bladder Cancer
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Breast Cancer
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Cardiovascular Diseases
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Cerebrovascular Disease (Stroke)
                          </label>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            margin: "0 0 .4rem 2rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Colorectal Cancer
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Dementia
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Dizziness
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Familial Anemia
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Multiple Sclerosis
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Gastic Cancer
                          </label>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            margin: "0 0 .4rem 2rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Gout
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Hepatitis B
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Hepatitis C
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Hyperglycemia (Diabetes)
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Hypertension
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Hyperlipidemia
                          </label>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            margin: "0 0 .4rem 2rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Kidney Failure
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Lung Cancer / Trachea Cancer
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Lymphoma
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Migraine
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Oral Cancer
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Ovarian Cancer
                          </label>
                        </Box>
                        <Box
                          sx={{
                            alignItems: "baseline",
                            display: "flex",
                            margin: "0 0 .4rem 2rem",
                            width: "100%",
                          }}
                        >
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Pancreatic Cancer
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Parkinson's Disease
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Prostate Cancer
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Rheumatoid Arthritis
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Skin Cancer
                          </label>
                          <label className={classes.checkboxLabel} style={{ margin: "0 1rem 0 0" }}>
                            <input
                              type="checkbox"
                              name="category_ids"
                              className={classes.styledCheckbox}
                            />
                            Noner of the above
                          </label>
                        </Box>
                        <div className={classes.theButtonFlex}>
                          <div className={classes.theButton}>
                            <Button
                              // onClick={handleSaveComment}
                              label="Save"
                              color="#fff"
                              hvbg="#3ADEBB"
                              bg="#3ADEBB"
                              size="16px"
                              bold="700"
                              pd="10px 23px"
                              br="3px"
                              boxshadow="0px 3px 15px rgba(33, 155, 142, 0.25)"
                            />
                          </div>
                        </div>
                      </>
                    )} */}

                  </Box>
                </>
              </Container>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
