import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Box,
} from "@mui/material";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import Loader from "react-js-loader";
import toast from "react-simple-toasts";
import Header from "../../components/Header/Header";
import Paragraph from "../../components/Typography/Paragraph";
import FormInput from "../../components/FormInput/FormInput";
import Button from "../../components/Button/Button";
import { themePlate } from "../../components/Theme/Theme";
import useStyles from "./styles";
import "./styles.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SearchIcon from "../../assets/images/Search Icon.svg";
import CheckoutIcon from "../../assets/images/Checkout Icon.svg";
import { get_reception_list, random_appointment } from "../../api/API";

export default function LabTest() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [receptionList, setReceptionList] = useState([]);

  //Pagination
  const [page, setPage] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState(1);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handlegetData = async () => {
    setLoading(true);

    var resReceptionList = await get_reception_list({ page: page });
    if (resReceptionList.status) {
      setLoading(false);
      setReceptionList(resReceptionList.data);
      setTotalCount(resReceptionList.totalCount);
    }
  };

  useEffect(() => {
    handlegetData();
  }, [page]);

  useEffect(() => {
    if (!window.location.hash) {
      window.location = window.location + "#loaded";
      window.location.reload();
    }
  }, []);

  const [showPassword3, setShowPassword3] = useState(false);
  const [passwordState, setPasswordState] = useState(false);

  const handleGenerate = async () => {
    setLoading(true);

    var resGenerate = await random_appointment({});
    if (resGenerate.status) {
      setLoading(false);
      handlegetData();
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <div style={{ margin: "auto" }}>
          <Loader
            type="bubble-loop"
            bgColor={themePlate().secondaryThree}
            size={50}
          />
        </div>
      ) : (
        <React.Fragment>
          <Header />
          <div className={classes.theReceptionCounter}>
            <div className={classes.theReceptionCounterHeader}>
              <Container maxWidth="xl" className="customContainer">
                <div className={classes.thSHFlex}>
                  <div
                    className={classes.thSHLabel}
                    style={{ width: "auto" }}
                    // onClick={() => navigate("../dashboard")}
                  >
                    {/* <div className={classes.theSHIcon}>
													<ChevronLeftIcon
															fontSize="34px" />
											</div>
											<div className={classes.theSHName}>
													<Paragraph
															size="18px"
															color={themePlate().primaryThree}
															bold="500"
															textTransform="capitalize"
															margin="0"
													>
															Back
													</Paragraph>
											</div> */}
                    <Box
                      sx={{
                        background: "#5A567B",
                        color: "#FFF",
                        fontSize: "16px",
                        fontWeight: "500",
                        padding: "14px 27px",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className={classes.theIcon20}>
                        <img src={CheckoutIcon} alt={CheckoutIcon} />
                      </div>
                      <Paragraph margin="0 0 0 10px">Check Out</Paragraph>
                    </Box>
                  </div>
                  <div style={{ marginLeft: "33%", marginRight: "auto" }}>
                    <Paragraph
                      size="24px"
                      color={themePlate().primaryTwo}
                      bold="600"
                      textTransform="upperCase"
                      margin="0"
                    >
                      Lab Test
                    </Paragraph>
                  </div>
                  <div className={classes.theSHFField}>
                    <div className={classes.theField}></div>
                  </div>
                </div>
              </Container>
            </div>
            <div className={classes.theReceptionCounterBody}>
              <Container maxWidth="xl">
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    marginBottom: "1.5rem",
                  }}
                >
                  <Box sx={{ display: "flex", width: "100%" }}>
                    {/* <Box
                      sx={{
                        background: "#FFF",
                        padding: ".5rem 1rem",
                        borderRadius: "6px",
                        marginRight: "14px",
                        width: "11%",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Paragraph
                        size="24px"
                        color="#3adebb"
                        bold="600"
                        margin="0 0 .4rem 0"
                        center
                      >
                        18
                      </Paragraph>
                      <Paragraph color="#211d4e" bold="600" center>
                        Vegetarian
                      </Paragraph>
                    </Box> */}
                    <Button
                      color="#fff"
                      hvbg="linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90deg, #3ADEBB -0.18%, #00AEE9 99.82%);"
                      bg="linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90deg, #3ADEBB -0.18%, #00AEE9 99.82%);"
                      label="Single Items"
                      size="16px"
                      bold="500"
                      pd="14px 27px"
                      br="5px"
                      style={{ margin: "0 1rem 0 0" }}
                    />

                    <Button
                      color="#fff"
                      hvbg="#DE4E3A"
                      bg="#DE4E3A"
                      label="Outsource Test"
                      size="16px"
                      bold="500"
                      pd="14px 27px"
                      br="5px"
                    />
                  </Box>
                  <div className={classes.theSHFField} style={{ width: "33%" }}>
                    <div className={classes.theField}>
                      <FormInput
                        className={classes.theBorderRadius}
                        name="search"
                        placeholder="Search..."
                        // value={passwordFormData.confirm_password}
                        // onChange={handleChangePassword}
                        fontWeight="500"
                        textColor={themePlate().secondaryTwo}
                        border="1px solid #eeeeee"
                        bg="#fff"
                        padding="12px 25px 12px 65px"
                        mb="0"
                        size="18px"
                      />
                      <div className={classes.theAbsoluteIcon}>
                        <div className={classes.theIcon20}>
                          <img src={SearchIcon} alt={SearchIcon} />
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
                {/* {receptionList.length > 0 ? (
                 
                ) : (
                  <React.Fragment>
                    <Paragraph
                      center
                      size="18px"
                      color={themePlate().primaryTwo}
                      bold="600"
                      textTransform="upperCase"
                      margin="0"
                    >
                      No Data Yet
                    </Paragraph>
                  </React.Fragment>
                )} */}
                <div className={classes.theTable}>
                  <TableContainer>
                    <Table
                      sx={
                        {
                          // minWidth: 650
                        }
                      }
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell className="">
                            <Paragraph
                              center
                              size="14px"
                              bold="500"
                              color="#FFFFFF"
                            >
                              Seq No.
                            </Paragraph>
                          </TableCell>
                          <TableCell>
                            <Paragraph size="14px" bold="500" color="#FFFFFF">
                              Name
                            </Paragraph>
                          </TableCell>
                          <TableCell>
                            <Paragraph size="14px" bold="500" color="#FFFFFF">
                              Age
                            </Paragraph>
                          </TableCell>
                          <TableCell>
                            <Paragraph
                              center
                              size="14px"
                              bold="500"
                              color="#FFFFFF"
                            >
                              Gender
                            </Paragraph>
                          </TableCell>
                          <TableCell>
                            <Paragraph
                              center
                              size="14px"
                              bold="500"
                              color="#FFFFFF"
                            >
                              Package
                            </Paragraph>
                          </TableCell>
                          <TableCell>
                            <Paragraph
                              center
                              size="14px"
                              bold="500"
                              color="#FFFFFF"
                            >
                              Sampling Collected
                            </Paragraph>
                          </TableCell>
                          <TableCell>
                            <Paragraph
                              center
                              size="14px"
                              bold="500"
                              color="#FFFFFF"
                            >
                              Biochemistry
                            </Paragraph>
                          </TableCell>
                          <TableCell>
                            <Paragraph
                              center
                              size="14px"
                              bold="500"
                              color="#FFFFFF"
                            >
                              Blood Test
                            </Paragraph>
                          </TableCell>
                          <TableCell className="">
                            <Paragraph
                              center
                              size="14px"
                              bold="500"
                              color="#FFFFFF"
                            >
                              Urine Test
                            </Paragraph>
                          </TableCell>
                          <TableCell className="">
                            <Paragraph
                              center
                              size="14px"
                              bold="500"
                              color="#FFFFFF"
                            >
                              Stool Test
                            </Paragraph>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          key="123"
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                          // onClick={() => navigate("../reception-member-profile/" + row.appointment_id)}
                          style={{ cursor: "pointer" }}
                        >
                          <TableCell>
                            <Paragraph
                              center
                              color="#211D4E"
                              size="16px"
                              bold="500"
                            >
                              123
                            </Paragraph>
                          </TableCell>
                          <TableCell>
                            <Paragraph
                              left
                              color="#211D4E"
                              size="16px"
                              bold="600"
                            >
                              Siew Wen Hui
                            </Paragraph>
                          </TableCell>
                          <TableCell>
                            <Paragraph color="#211D4E" size="16px" bold="500">
                              28
                            </Paragraph>
                          </TableCell>
                          <TableCell>
                            <Paragraph
                              center
                              color="#211D4E"
                              size="16px"
                              bold="500"
                            >
                              Female
                            </Paragraph>
                          </TableCell>
                          <TableCell>
                            <Paragraph
                              center
                              color="#211D4E"
                              size="16px"
                              bold="500"
                            >
                              12
                            </Paragraph>
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <Box
                                sx={{
                                  background: "#3ADEBB",
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50px",
                                  marginRight: "10px",
                                }}
                              />
                              <Paragraph
                                center
                                size="16px"
                                bold="600"
                                letterSpacing="0.05em"
                              >
                                Complete
                              </Paragraph>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <div
                            // className={
                            //   row.check_in_at != "-"
                            //     ? classes.theSuccessTime
                            //     : classes.theFailTime
                            // }
                            >
                              <Paragraph
                                center
                                // color={
                                //   row.check_in_at != "-"
                                //     ? themePlate().primaryTwo
                                //     : "#A8A8A8"
                                // }
                                size="16px"
                                bold="600"
                                letterSpacing="0.05em"
                              >
                                {/* {row.check_in_at != "-" ? row.checkin : "-"} */}
                                -
                              </Paragraph>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div
                            // className={
                            //   row.check_in_at != "-"
                            //     ? classes.theSuccessTime
                            //     : classes.theFailTime
                            // }
                            >
                              <Paragraph
                                center
                                // color={
                                //   row.check_in_at != "-"
                                //     ? themePlate().primaryTwo
                                //     : "#A8A8A8"
                                // }
                                size="16px"
                                bold="600"
                                letterSpacing="0.05em"
                              >
                                {/* {row.check_in_at != "-" ? row.checkin : "-"} */}
                                -
                              </Paragraph>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div
                            // className={
                            //   row.check_in_at != "-"
                            //     ? classes.theSuccessTime
                            //     : classes.theFailTime
                            // }
                            >
                              <Paragraph
                                center
                                // color={
                                //   row.check_in_at != "-"
                                //     ? themePlate().primaryTwo
                                //     : "#A8A8A8"
                                // }
                                size="16px"
                                bold="600"
                                letterSpacing="0.05em"
                              >
                                {/* {row.check_in_at != "-" ? row.checkin : "-"} */}
                                -
                              </Paragraph>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div
                            // className={
                            //   row.check_in_at != "-"
                            //     ? classes.theSuccessTime
                            //     : classes.theFailTime
                            // }
                            >
                              <Paragraph
                                center
                                // color={
                                //   row.check_in_at != "-"
                                //     ? themePlate().primaryTwo
                                //     : "#A8A8A8"
                                // }
                                size="16px"
                                bold="600"
                                letterSpacing="0.05em"
                              >
                                {/* {row.check_in_at != "-" ? row.checkin : "-"} */}
                                -
                              </Paragraph>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                {/* {totalCount != 0 && (
                  <div className={classes.thePagination}>
                    <div>
                      <Pagination
                        count={totalCount}
                        variant="outlined"
                        page={page}
                        onChange={handleChangePage}
                      />
                    </div>
                  </div>
                )} */}
              </Container>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
