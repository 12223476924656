import React, {
    useState,
    useEffect,
} from 'react'
import {
    useMediaQuery,
    Container,
    Grid,
} from "@mui/material";
import { HashLink as Link } from 'react-router-hash-link';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Paragraph from "../../components/Typography/Paragraph";
import { useTheme } from "@mui/material/styles";
import Loader from "react-js-loader";
import useStyles from "./styles";
import "./styles.css";
import {
    themePlate
} from "../../components/Theme/Theme";
import Logo from "../../assets/images/Header Logo.png"
import HeaderMoreIcon from "../../assets/images/Header More Icon.svg"
import HeaderSettingIcon from "../../assets/images/Header Setting Icon.svg"
import HeaderLogoutIcon from "../../assets/images/Header Logout Icon.svg"
import {
    header_badge
} from "../../api/API"
import useUser from '../../hooks/useUser';
import { use } from 'i18next';

const drawerWidth = "100%";
const navItems = ['Home', 'About', 'Contact'];

export default function Header(props) {
    const classes = useStyles();
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const {
        set_user,
        get_user
    } = useUser();

    // console.log(location.pathname.replace(/[^A-Za-z]+/g, ''))

    const { windows } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const [loading, setLoading] = useState(false)
    const [badge, setBadge] = useState(null);
    const [headerState, setHeaderState] = useState(false)

    const getData = async () => {
        setLoading(true)
        var resBadge = await header_badge({});
        if (resBadge.status) {
            setBadge(resBadge.data);
            setLoading(false)
        }
    }

    //Logout
    const handleLogout = () => {
        set_user(null);
        window.localStorage.removeItem("token");
        navigate("/");
    }

    //Header State
    const handleChangeHeader = () => {
        setHeaderState(!headerState)
    }

    useEffect(() => {
        getData()
    }, [])

    const container = windows !== undefined ? () => windows().document.body : undefined;
    return (
        <React.Fragment>
            {loading ?
                <div style={{ margin: 'auto' }}>
                    <Loader type="bubble-loop" bgColor={themePlate().secondaryThree} size={50} />
                </div>
                :
                <React.Fragment>
                    <div className={classes.theNavbar}>
                        <Container maxWidth="xl" className='customContainer'>
                            <div className={classes.theNbFlex}>
                                <div className={classes.theLogo}
                                    onClick={() => navigate("../dashboard")}
                                >
                                    <img src={Logo} />
                                </div>
                                {isMobile ?
                                    <div className={classes.theCountMob}>

                                        {headerState == true &&
                                            <React.Fragment>
                                                <div className={classes.theCountBox}>
                                                    <Paragraph
                                                        color="#fff"
                                                        bold="600"
                                                        margin="0 0 10px"
                                                        center
                                                        size="18px"
                                                    >
                                                        {badge != null && badge.today_appointment}
                                                    </Paragraph>
                                                    <Paragraph
                                                        color="#fff"
                                                        bold="500"
                                                        center
                                                        textTransform="capitalize"
                                                        size="14px"
                                                    >
                                                        Today<br />
                                                        Appointment
                                                    </Paragraph>
                                                </div>
                                                <div className={classes.theCountBox}>
                                                    <Paragraph
                                                        color="#fff"
                                                        bold="600"
                                                        margin="0 0 10px"
                                                        center
                                                        size="18px"
                                                    >
                                                        {badge != null && badge.today_checked_in}
                                                    </Paragraph>
                                                    <Paragraph
                                                        color="#fff"
                                                        bold="500"
                                                        center
                                                        textTransform="capitalize"
                                                        size="14px"
                                                    >
                                                        Total<br />
                                                        Checked In
                                                    </Paragraph>
                                                </div>
                                                <div className={classes.theCountBox}>
                                                    <Paragraph
                                                        color="#fff"
                                                        bold="600"
                                                        margin="0 0 10px"
                                                        center
                                                        size="18px"
                                                    >
                                                        {badge != null && badge.today_registration}
                                                    </Paragraph>
                                                    <Paragraph
                                                        color="#fff"
                                                        bold="500"
                                                        center
                                                        textTransform="capitalize"
                                                        size="14px"
                                                    >
                                                        Total<br />
                                                        Registration
                                                    </Paragraph>
                                                </div>
                                                <div className={classes.theCountBox}>
                                                    <Paragraph
                                                        color="#fff"
                                                        bold="600"
                                                        margin="0 0 10px"
                                                        center
                                                        size="18px"
                                                    >
                                                        {badge != null && badge.canceled_appointment}
                                                    </Paragraph>
                                                    <Paragraph
                                                        color="#fff"
                                                        bold="500"
                                                        center
                                                        textTransform="capitalize"
                                                        size="14px"
                                                    >
                                                        Canceled<br />
                                                        Appointment
                                                    </Paragraph>
                                                </div>
                                                <div className={classes.theCountBox}>
                                                    <Paragraph
                                                        color="#fff"
                                                        bold="600"
                                                        margin="0 0 10px"
                                                        center
                                                        size="18px"
                                                    >
                                                        {badge != null && badge.completed_appointment}
                                                    </Paragraph>
                                                    <Paragraph
                                                        color="#fff"
                                                        bold="500"
                                                        center
                                                        textTransform="capitalize"
                                                        size="14px"
                                                    >
                                                        Completed<br />
                                                        Appointment
                                                    </Paragraph>
                                                </div>
                                            </React.Fragment>
                                        }
                                    </div>
                                    :

                                    <div className={classes.theCount}>

                                        <div className={classes.theCountBox}>
                                            <Paragraph
                                                color="#fff"
                                                bold="600"
                                                margin="0 0 10px"
                                                center
                                                size="18px"
                                            >
                                                {badge != null && badge.today_appointment}
                                            </Paragraph>
                                            <Paragraph
                                                color="#fff"
                                                bold="500"
                                                center
                                                textTransform="capitalize"
                                                size="14px"
                                            >
                                                Today<br />
                                                Appointment
                                            </Paragraph>
                                        </div>
                                        <div className={classes.theCountBox}>
                                            <Paragraph
                                                color="#fff"
                                                bold="600"
                                                margin="0 0 10px"
                                                center
                                                size="18px"
                                            >
                                                {badge != null && badge.today_checked_in}
                                            </Paragraph>
                                            <Paragraph
                                                color="#fff"
                                                bold="500"
                                                center
                                                textTransform="capitalize"
                                                size="14px"
                                            >
                                                Total<br />
                                                Checked In
                                            </Paragraph>
                                        </div>
                                        <div className={classes.theCountBox}>
                                            <Paragraph
                                                color="#fff"
                                                bold="600"
                                                margin="0 0 10px"
                                                center
                                                size="18px"
                                            >
                                                {badge != null && badge.today_registration}
                                            </Paragraph>
                                            <Paragraph
                                                color="#fff"
                                                bold="500"
                                                center
                                                textTransform="capitalize"
                                                size="14px"
                                            >
                                                Total<br />
                                                Registration
                                            </Paragraph>
                                        </div>
                                        <div className={classes.theCountBox}>
                                            <Paragraph
                                                color="#fff"
                                                bold="600"
                                                margin="0 0 10px"
                                                center
                                                size="18px"
                                            >
                                                {badge != null && badge.canceled_appointment}
                                            </Paragraph>
                                            <Paragraph
                                                color="#fff"
                                                bold="500"
                                                center
                                                textTransform="capitalize"
                                                size="14px"
                                            >
                                                Canceled<br />
                                                Appointment
                                            </Paragraph>
                                        </div>
                                        <div className={classes.theCountBox}>
                                            <Paragraph
                                                color="#fff"
                                                bold="600"
                                                margin="0 0 10px"
                                                center
                                                size="18px"
                                            >
                                                {badge != null && badge.completed_appointment}
                                            </Paragraph>
                                            <Paragraph
                                                color="#fff"
                                                bold="500"
                                                center
                                                textTransform="capitalize"
                                                size="14px"
                                            >
                                                Completed<br />
                                                Appointment
                                            </Paragraph>
                                        </div>
                                    </div>
                                }
                                <div className={classes.theButton}>
                                    {isMobile ?
                                        <div className={classes.theIcon20}
                                            onClick={() => handleChangeHeader()}
                                        >
                                            <img
                                                src={HeaderMoreIcon}
                                                alt={HeaderMoreIcon}
                                            />
                                        </div>
                                        :
                                        null
                                    }
                                    {headerState == false &&
                                        <React.Fragment>
                                            <div
                                                className={classes.theHeaderButton}
                                                onClick={() => navigate("../setting")}
                                            >
                                                <div className={classes.theIcon20}>
                                                    <img
                                                        src={HeaderSettingIcon}
                                                        alt={HeaderSettingIcon}
                                                    />
                                                </div>
                                                <Paragraph
                                                    size="18px"
                                                    color={themePlate().primaryOne}
                                                    bold="500"
                                                    lineHeight="1.5"
                                                    style={{ margin: "0 0 0 6px" }}
                                                >
                                                    Setting
                                                </Paragraph>
                                            </div>
                                            <div
                                                className={classes.theHeaderButton2}
                                                onClick={() => handleLogout()}
                                            >
                                                <div className={classes.theIcon20}>
                                                    <img
                                                        src={HeaderLogoutIcon}
                                                        alt={HeaderLogoutIcon}
                                                    />
                                                </div>
                                                <Paragraph
                                                    size="18px"
                                                    color={themePlate().primaryTwo}
                                                    bold="500"
                                                    lineHeight="1.5"
                                                    style={{ margin: "0 0 0 6px" }}
                                                >
                                                    Logout
                                                </Paragraph>
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </Container>
                    </div>
                </React.Fragment >
            }
        </React.Fragment >
    )
}