export const themePlate = () => {
    var themeColor = {
        primaryOne: '#f9f9f9',
        primaryTwo: '#219b8e',
        primaryThree: '#211D4E',
        secondaryOne: '#ededed',
        secondaryTwo: '#5a567b',
        secondaryThree: '#3adebb',
    }
    return themeColor;
}