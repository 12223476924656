import React from "react";
import { Routes as Router, Route } from "react-router-dom";
import Loader from "react-js-loader";
import MealSetupPage from "./pages/DinningRoom/DinningRoomList/MealSetup";
import LabTestPage from "./pages/LabTest/LabTest";
import LabTestDetailPage from "./pages/LabTest/LabTestDetail";
import QuestionairePage from "./pages/ScreeningRoom/Questionaire/Questionaire";
import Audiometry from "./pages/ScreeningRoom/Audiometry/Audiometry";
import BoneMineralDensity from "./pages/ScreeningRoom/BoneMineralDensity/BoneMineralDensity";
import AudiometryDetail from "./pages/ScreeningRoom/Audiometry/AudiometryDetail";
import BoneMineralDensityDetail from "./pages/ScreeningRoom/BoneMineralDensity/BoneMineralDensityDetail";
import Xray from "./pages/ScreeningRoom/Xray/Xray";
import Xraydetail from "./pages/ScreeningRoom/Xray/Xraydetail";
import VisionOcular from "./pages/ScreeningRoom/VisionOcular/VisionOcular";
import VisionOcularDetail from "./pages/ScreeningRoom/VisionOcular/VisionOcularDetail";
import Retinography from "./pages/ScreeningRoom/Retinography/Retinography";
import RetinographyDetail from "./pages/ScreeningRoom/Retinography/RetinographyDetail";
import PapSmear from "./pages/ScreeningRoom/PapSmear/PapSmear";
import PapSmearDetail from "./pages/ScreeningRoom/PapSmear/PapSmearDetail";
import CTScan from "./pages/ScreeningRoom/CTScan/CTScan";
import CTScanDetail from "./pages/ScreeningRoom/CTScan/CTScanDetail";
import Electrocardiogram from "./pages/ScreeningRoom/Electrocardiogram/Electrocardiogram";
import ElectrocardiogramDetail from "./pages/ScreeningRoom/Electrocardiogram/ElectrocardiogramDetail";
import ENTExamination from "./pages/ScreeningRoom/ENTExamination/ENTExamination";
import ENTExaminationDetail from "./pages/ScreeningRoom/ENTExamination/ENTExaminationDetail";
import GeneralMeasurement from "./pages/ScreeningRoom/GeneralMeasurement/GeneralMeasurement";
import GeneralMeasurementDetail from "./pages/ScreeningRoom/GeneralMeasurement/GeneralMeasurementDetail";
import LungFunctionTest from "./pages/ScreeningRoom/LungFunctionTest/LungFunctionTest";
import LungFunctionTestDetail from "./pages/ScreeningRoom/LungFunctionTest/LungFunctionTestDetail";
import Mammography from "./pages/ScreeningRoom/Mammography/Mammography";
import MammographyDetail from "./pages/ScreeningRoom/Mammography/MammographyDetail";
import PeripheralVascular from "./pages/ScreeningRoom/PeripheralVascular/PeripheralVascular";
import PeripheralVascularDetail from "./pages/ScreeningRoom/PeripheralVascular/PeripheralVascularDetail";
import Sonography from "./pages/ScreeningRoom/Sonography/Sonography";
import SonographyDetail from "./pages/ScreeningRoom/Sonography/SonographyDetail";
import Monitor from "./pages/Monitor/Monitor";

const LoginPage = React.lazy(() => import("./pages/Login/Login"));
const DashboardPage = React.lazy(() => import("./pages/Dashboard/Dashboard"));
const SettingPage = React.lazy(() => import("./pages/Setting/Setting"));
const KioskPage = React.lazy(() => import("./pages/Kiosk/Kiosk"));
const ReceptionCounterPage = React.lazy(() =>
  import("./pages/ReceptionCounter/ReceptionCounter/ReceptionCounter")
);
const RCMemberProfilePage = React.lazy(() =>
  import("./pages/ReceptionCounter/RCMemberProfile/RCMemberProfile")
);
const QueueSelectionPage = React.lazy(() =>
  import("./pages/Queue/QueueSelection/QueueSelection")
);
const QueueListPage = React.lazy(() =>
  import("./pages/Queue/QueueLists/QueueLists")
);
const PrintStickerListPage = React.lazy(() =>
  import("./pages/PrintSticker/PrintStickerList/PrintStickerList")
);
const DinningRoomListPage = React.lazy(() =>
  import("./pages/DinningRoom/DinningRoomList/DinningRoomList")
);
const DinningRoomDetailPage = React.lazy(() =>
  import("./pages/DinningRoom/DinningRoomDetail/DinningRoomDetail")
);
const ScreeningRoomListPage = React.lazy(() =>
  import("./pages/ScreeningRoom/ScreeningRoomList/ScreeningRoomList")
);
const ScreeningRoomInterviewPage = React.lazy(() =>
  import("./pages/ScreeningRoom/ScreeningRoomInterview/ScreeningRoomInterview")
);
const ConsultationListPage = React.lazy(() =>
  import("./pages/ConsultationRoom/ConsultationList/ConsultationList")
);
const ConsultationInterviewPage = React.lazy(() =>
  import("./pages/ConsultationRoom/ConsultationInterview/ConsultationInterview")
);
const ConsultationPhysicalListPage = React.lazy(() =>
  import("./pages/ConsultationPhysical/ConsultationList/ConsultationList")
);
const ConsultationPhysicalInterviewPage = React.lazy(() =>
  import(
    "./pages/ConsultationPhysical/ConsultationInterview/ConsultationInterview"
  )
);
const ConsultationHealthListPage = React.lazy(() =>
  import("./pages/ConsultationHealth/ConsultationList/ConsultationList")
);
const ConsultationHealthInterviewPage = React.lazy(() =>
  import(
    "./pages/ConsultationHealth/ConsultationInterview/ConsultationInterview"
  )
);
const RoomManagementPage = React.lazy(() =>
  import("./pages/RoomManagement/RoomManagement")
);
export default function Routes() {
  return (
    <React.Suspense
      fallback={
        <div style={{}}>
          <Loader type="bubble-loop" bgColor={"#15AAA0"} size={50} />
        </div>
      }
    >
      <Router>
        <Route path="/" element={<LoginPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/setting" element={<SettingPage />} />
        <Route path="/kiosk" element={<KioskPage />} />
        <Route
          path="/reception-counter-table"
          element={<ReceptionCounterPage />}
        />
        <Route
          path="/reception-member-profile/:appointment_id"
          element={<RCMemberProfilePage />}
        />
        <Route path="/queue-selection" element={<QueueSelectionPage />} />
        <Route path="/queue-list/:room_id" element={<QueueListPage />} />
        <Route
          path="/printing-sticker-list"
          element={<PrintStickerListPage />}
        />
        <Route
          path="/screening-room-list/:room_id"
          element={<ScreeningRoomListPage />}
        />
        <Route
          path="/screening-room-interview/:room_id/:appointment_id/:member_id"
          element={<ScreeningRoomInterviewPage />}
        />
        {/* <Route path="/dinning-room-list" element={<DinningRoomListPage />} /> */}
        <Route
          path="/dinning-room-list/:room_id"
          element={<DinningRoomListPage />}
        />
        <Route
          path="/dinning-room-detail/:room_id/:appointment_id/:member_id"
          element={<DinningRoomDetailPage />}
        />
        <Route
          path="/consultation-room-list/:room_id"
          element={<ConsultationListPage />}
        />
        <Route
          path="/consultation-room-interview/:room_id/:appointment_id/:member_id"
          element={<ConsultationInterviewPage />}
        />
        <Route
          path="/consultation-physical-room-list"
          element={<ConsultationPhysicalListPage />}
        />
        <Route
          path="/consultation-physical-room-interview/:appointment_id/:member_id"
          element={<ConsultationPhysicalInterviewPage />}
        />
        <Route
          path="/consultation-health-room-list"
          element={<ConsultationHealthListPage />}
        />
        <Route
          path="/consultation-health-room-interview/:appointment_id/:member_id"
          element={<ConsultationHealthInterviewPage />}
        />
        <Route path="/room-management" element={<RoomManagementPage />} />
        <Route path="/meal-setup" element={<MealSetupPage />} />
        <Route path="/lab-test" element={<LabTestPage />} />
        <Route path="/lab-test-detail" element={<LabTestDetailPage />} />
        <Route path="/questionnaire/:appointment_id/:member_id" element={<QuestionairePage />} />
        <Route path="/audiometry" element={<Audiometry />} />
        <Route path="/audiometrydetail" element={<AudiometryDetail />} />
        <Route path="/bonemineraldensity" element={<BoneMineralDensity />} />
        <Route
          path="/bonemineraldensitydetail"
          element={<BoneMineralDensityDetail />}
        />
        <Route path="/xray" element={<Xray />} />
        <Route path="/xraydetail" element={<Xraydetail />} />
        <Route path="/visionocular" element={<VisionOcular />} />
        <Route path="/visionoculardetail" element={<VisionOcularDetail />} />
        <Route path="/retinography" element={<Retinography />} />
        <Route path="/retinographydetail" element={<RetinographyDetail />} />
        <Route path="/papsmear" element={<PapSmear />} />
        <Route path="/papsmeardetail" element={<PapSmearDetail />} />
        <Route path="/ctscan" element={<CTScan />} />
        <Route path="/ctscandetail" element={<CTScanDetail />} />
        <Route path="/electrocardiogram" element={<Electrocardiogram />} />
        <Route
          path="/electrocardiogramdetail"
          element={<ElectrocardiogramDetail />}
        />
        <Route path="/entexamination" element={<ENTExamination />} />
        <Route
          path="/entexaminationdetail"
          element={<ENTExaminationDetail />}
        />
        <Route path="/generalmeasurement" element={<GeneralMeasurement />} />
        <Route
          path="/generalmeasurementdetail"
          element={<GeneralMeasurementDetail />}
        />
        <Route path="/lungfunctiontest" element={<LungFunctionTest />} />
        <Route
          path="/lungfunctiontestdetail"
          element={<LungFunctionTestDetail />}
        />
        <Route path="/mammography" element={<Mammography />} />
        <Route path="/mammographydetail" element={<MammographyDetail />} />
        <Route path="/peripheralvascular" element={<PeripheralVascular />} />
        <Route
          path="/peripheralvasculardetail"
          element={<PeripheralVascularDetail />}
        />
        <Route path="/sonography" element={<Sonography />} />
        <Route path="/sonographydetail" element={<SonographyDetail />} />
        <Route path="/monitor" element={<Monitor />} />
        {/* <Route path="/listing/:room_type_id/:category_id" element={<ListingPage />} /> */}
      </Router>
    </React.Suspense>
  );
}
