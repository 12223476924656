import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import { FormControl, Input } from "@mui/material";
import { width } from "@mui/system";
import { BorderBottom } from "@mui/icons-material";

export const CustomFormControl = styled(Input)`
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #1e1e1e;
  background-color: #eeeeee;
  background-clip: padding-box;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  @media screen and (min-width: 992px) {
    width: 100%;
  }

  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
`;

export const CustomRadio = styled("span")`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: #219b8e;
  border: 1px solid #e9e9e9;
`;

export const CustomRadioChecked = styled("span")`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: #e9e9e9;
  border: 1px solid #e9e9e9;
`;

export const BpIcon = styled("span")`
  border-radius: 5px;
  width: 20px;
  height: 20px;
  background-color: red;
  border: 1.5px solid #ededed;
`;

export const BpCheckedIcon = styled(BpIcon)`
  border: 1.5px solid var(--secondary-color3);
  &::before {
    display: block;
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%233ADEBB'/%3E%3C/svg%3E");
    content: "";
  }
`;

export const BpIcon2 = styled("span")`
  border-radius: 5px;
  width: 20px;
  height: 20px;
  background-color: #eeeeee;
  border: 1.5px solid #dddddd;
`;

export const BpCheckedIcon2 = styled(BpIcon)`
  border: 1.5px solid var(--secondary-color3);
  &::before {
    display: block;
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%233ADEBB'/%3E%3C/svg%3E");
    content: "";
  }
`;

const config = {
  theReceptionCounter: {
    // padding: "40px 0",
  },
  theReceptionCounterHeader: {
    padding: "10px 0",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.05)",
  },
  thSHFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  thSHLabel: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    // width: "20%",
    cursor: "pointer",
    // width: "100%",
  },
  theSHName: {
    // width: "fit-content",
    width: "50%",
  },
  theSHIcon: {
    fontSize: "34px",
    display: "flex",
    color: "##211D4E",
  },
  theReceptionCounterBody: {
    margin: "30px 0 190px",
  },
  theField: {
    position: "relative",
    zIndex: "1",
  },
  theAbsoluteIcon: {
    position: "absolute",
    left: "25px",
    top: "50%",
    transform: "translate(0, -50%)",
    zIndex: "2",
  },
  theIcon20: {
    width: "20px",
    height: "20px",
    "& img": {
      width: "100%",
      height: "20px",
    },
  },
  theSuccessTime: {
    backgroundColor: "#88FFE6",
    width: "100px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "3px",
    margin: "0 auto",
  },
  theFailTime: {
    backgroundColor: "#F9F9F9",
    width: "100px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "3px",
    margin: "0 auto",
  },
  thePagination: {
    margin: "20px 0 0",
  },
  theIcon20: {
    width: "20px",
    height: "20px",
    "& img": {
      width: "100%",
      height: "20px",
    },
  },
  theActive: {
    padding: "1rem",
    borderRadius: "6px",
    width: "100%",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    color: "#FFF",
    // boxShadow: "1px 4px 6px 0px rgba(0,0,0,0.10)",
    background: "#219B8E",
    marginRight: "14px",
  },
  theNoActive: {
    padding: "1rem",
    borderRadius: "6px",
    width: "100%",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    color: "#5A567B",
    background: "#EDEDED",
    // boxShadow: "unset",
    marginRight: "14px",
  },
  theTable:{
    display: "table",
    width: "100%",
    borderCollapse: "collapse",
    borderSpacing: 0,
  },
  theTableCell:{
    display: "table-cell",
    fontSize: "18px",
    lineHeight: 1.43,
    letterSpacing: ".01071em",
    padding: "16px 0",
    marginRight: "16px"
  },
  theTableCell2:{
    // display: "table-cell",
    fontSize: "18px",
    lineHeight: 1.43,
    letterSpacing: ".01071em",
    // margin: "0 16px",
    verticalAlign: "middle",
    width: "350px"
  },

  _theTableCell2:{
    // display: "table-cell",
    fontSize: "18px",
    lineHeight: 1.43,
    letterSpacing: ".01071em",
    // margin: "0 16px",
    verticalAlign: "middle",
    // width: "350px"
  },


  ///

  thePricing: {
    display: "table",
    width: "100%",
    borderCollapse: "collapse",
    borderSpacing: 0,
  },

  thePriceBox: {
    display: "table-cell",
    fontSize: ".875rem",
    lineHeight: 1.43,
    letterSpacing: ".01071em",
    padding: "16px",
    border: "1px solid #eaeaea",
    background: "#fff",
    verticalAlign: "middle",
    minWidth: "180px",
  },

  thePriceBox_: {
    display: "table-cell",
    fontSize: ".875rem",
    lineHeight: 1.43,
    letterSpacing: ".01071em",
    padding: "16px",
    // border: "1px solid #eaeaea",
    // background: "#fff"
  },

  thePriceBoxA: {
    display: "table-cell",
    // fontSize: ".875rem",
    lineHeight: 1.43,
    letterSpacing: ".01071em",
    // padding: "16px",
    // border: "1px solid #eaeaea",
    background: "#DDD",
  },

  thePriceBoxB: {
    display: "table-cell",
    fontSize: ".875rem",
    lineHeight: 1.43,
    letterSpacing: ".01071em",
    padding: "16px",
    // border: "1px solid #eaeaea",
    background: "#DDD",
  },

  thePriceBoxC: {
    display: "table-cell",
    fontSize: ".875rem",
    lineHeight: 1.43,
    letterSpacing: ".01071em",
    padding: "16px",
    // border: "1px solid #eaeaea",
    background: "#DDD",
  },
};

export default makeStyles(config);
