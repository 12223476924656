import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import { FormControl, Input } from "@mui/material";
import { width } from "@mui/system";
import { BorderBottom } from "@mui/icons-material";

export const CustomFormControl = styled(Input)`
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #1e1e1e;
  background-color: #eeeeee;
  background-clip: padding-box;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  @media screen and (min-width: 992px) {
    width: 100%;
  }

  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
`;

const config = {
  theReceptionCounter: {
    // padding: "40px 0",
  },
  theReceptionCounterHeader: {
    padding: "10px 0",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.05)",
  },
  thSHFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  thSHLabel: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "20%",
    cursor: "pointer",
    // width: "100%",
  },
  theSHName: {
    // width: "fit-content",
    width: "50%",
  },
  theSHIcon: {
    fontSize: "34px",
    display: "flex",
    color: "##211D4E",
  },
  theReceptionCounterBody: {
    margin: "30px 0 190px",
  },
  theField: {
    position: "relative",
    zIndex: "1",
  },
  theAbsoluteIcon: {
    position: "absolute",
    left: "25px",
    top: "50%",
    transform: "translate(0, -50%)",
    zIndex: "2",
  },
  theIcon20: {
    width: "20px",
    height: "20px",
    "& img": {
      width: "100%",
      height: "20px",
    },
  },
  theSuccessTime: {
    backgroundColor: "#88FFE6",
    width: "100px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "3px",
    margin: "0 auto",
  },
  theFailTime: {
    background: "rgba(136, 255, 230, 0.2)",
    width: "100px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "3px",
    margin: "0 auto",
  },
  thePagination: {
    margin: "20px 0 0",
  },
  theHeaderButton: {
    width: "fit-content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid #fff",
    padding: "10px 22px",
    borderRadius: "4px",
    margin: "0 20px",
    cursor: "pointer",
    backgroundColor: "#5A567B",
  },
  theCheckIn: {
    backgroundColor: "#3ADEBB",
    width: "100px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "3px",
    margin: "0 auto",
    padding: "6px 26px",
  },
  theInCheckIn: {
    backgroundColor: "#5A567B",
    width: "100px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "3px",
    margin: "0 auto",
    padding: "6px 26px",
  },
  theTable: {
    margin: "0 0 40px",
  },

  theActive: {
    background: "#FFF",
    padding: "1rem",
    borderRadius: "6px",
    width: "15%",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    boxShadow: "1px 4px 6px 0px rgba(0,0,0,0.10)",
    marginRight: "14px"

  },
  theNoActive: {
    background: "#ededed",
    padding: "1rem",
    borderRadius: "6px",
    width: "15%",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    boxShadow: "unset",
    marginRight: "14px"

  },

  theTable:{
    display: "table",
    width: "100%",
    borderCollapse: "collapse",
    borderSpacing: 0,
  },
  theTableCell:{
    display: "table-cell",
    fontSize: "18px",
    lineHeight: 1.43,
    letterSpacing: ".01071em",
    padding: "16px",
  },
  theTableCell2:{
    display: "table-cell",
    fontSize: "18px",
    lineHeight: 1.43,
    letterSpacing: ".01071em",
    padding: "0 16px",
    verticalAlign: "middle"
  },

  theBtn: {

  },

  theActive: {
    padding: "1rem",
    borderRadius: "6px",
    width: "100%",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    color: "#FFF",
    // boxShadow: "1px 4px 6px 0px rgba(0,0,0,0.10)",
    background: "#219B8E",
    marginRight: "14px",
  },
  theNoActive: {
    padding: "1rem",
    borderRadius: "6px",
    width: "100%",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    color: "#5A567B",
    background: "#EDEDED",
    // boxShadow: "unset",
    marginRight: "14px",
  },

};

export default makeStyles(config);
