import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Box,
  Dialog,
  MenuItem,
} from "@mui/material";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import Loader from "react-js-loader";
import Header from "../../components/Header/Header";
import Paragraph from "../../components/Typography/Paragraph";
import FormInput from "../../components/FormInput/FormInput";
import { themePlate } from "../../components/Theme/Theme";
import useStyles from "./styles";
import "./styles.css";
import SearchIcon from "../../assets/images/Search Icon.svg";
import {
  get_reception_list,
  get_waiting_list_by_room,
  get_checkout_list_by_room,
  checkin_room,
  get_room_detail,
  get_monitor_area,
  get_monitor_system,
} from "../../api/API";
import Button from "../../components/Button/Button";
import { Close } from "@mui/icons-material";

export default function Monitor() {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [waitingList, setWaitingList] = useState([]);
  const [checkoutList, setCheckoutList] = useState([]);

  //Pagination
  const [page, setPage] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState(1);
  const [roomDetail, setRoomDetail] = React.useState(null);
  const [rooms, setRooms] = React.useState([]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  // const handlegetData = async () => {
  //   setLoading(true);

  //   var resWaiting = await get_waiting_list_by_room({
  //     room_id: params.room_id,
  //   });
  //   if (resWaiting.status) {
  //     setLoading(false);
  //     setWaitingList(resWaiting.data);
  //     // setTotalCount(resWaiting.totalCount)
  //   }

  //   var resCheckoutList = await get_checkout_list_by_room({
  //     room_id: params.room_id,
  //   });
  //   if (resCheckoutList.status) {
  //     setLoading(false);
  //     setCheckoutList(resCheckoutList.data);
  //     // setTotalCount(resCheckoutList.totalCount)
  //   }

  //   var resDetail = await get_room_detail({
  //     // page: page,
  //     room_id: params.room_id,
  //   });
  //   if (resDetail.status) {
  //     setLoading(false);
  //     setRoomDetail(resDetail.data);
  //     // setTotalCount(resWaiting.totalCount)
  //   }
  // };

  useEffect(() => {
    // handlegetData();
  }, [page]);

  useEffect(() => {
    get_data();

  }, []);

  const [areas, setAreas] = useState([]);
  const [showPassword3, setShowPassword3] = useState(false);
  const [passwordState, setPasswordState] = useState(false);
  const timer = useRef(null);
  const get_data = async () => {
    var response = await get_monitor_area({
      outlet_id: 1
    });

    if (response.status) {
      setAreas(response.data);
      setTabs(response.data[0]['area_id']);
      get_room_data(response.data[0]['area_id']);
    }
  };

  const get_room_data = async (area_id) => {
    var response = await get_monitor_system({
      area_id: area_id
    });

    if (response.status) {
      setRooms(response.data);
      clearInterval(timer.current);
      timer.current = null;
      timer.current = setInterval(() => {
        timerStart(response.data);
      }, 1000);
    }
  };
  const handleCheckin = async (appointment_id, member_id) => {
    setLoading(true);
    var resCheckIn = await checkin_room({
      appointment_id: appointment_id,
      room_id: params.room_id,
    });
    if (resCheckIn.status) {
      setLoading(false);
      navigate(
        "../dinning-room-detail/" +
        params.room_id +
        "/" +
        appointment_id +
        "/" +
        member_id
      );
    }
  };

  const [tabs, setTabs] = React.useState(0);

  const handleChangeTabs = (txt) => {
    setTabs(txt);
    get_room_data(txt);
  };

  const timerStart = (rooms) => {

    var _rooms = [...rooms];
    var nowDate = new Date();
    var now = nowDate.getTime();
    for (var i = 0; i < rooms.length; i++) {
      var d2 = new Date(_rooms[i].check_in_at);
      console.log(d2);
      var sec = Math.floor(now - d2.getTime());
      _rooms[i].serving_min = Math.floor(sec / 60);
      _rooms[i].serving_sec = sec - (_rooms[i].serving_min * 60);
    }

    setRooms(_rooms);
  };

  const [openStation, setOpenStation] = React.useState(false);
  const [openFlow, setOpenFlow] = React.useState(false);

  return (
    <React.Fragment>
      {loading ? (
        <div style={{ margin: "auto" }}>
          <Loader
            type="bubble-loop"
            bgColor={themePlate().secondaryThree}
            size={50}
          />
        </div>
      ) : (
        <React.Fragment>
          <Header />
          <div className={classes.theReceptionCounter}>
            <div className={classes.theReceptionCounterHeader}>
              <Container maxWidth="xl" className="customContainer">
                <div className={classes.thSHFlex}>
                  <div
                    className={classes.thSHLabel}
                  // onClick={() => navigate("../dashboard")}
                  ></div>
                  <div>
                    <Paragraph
                      size="24px"
                      color={themePlate().primaryTwo}
                      bold="600"
                      textTransform="upperCase"
                      margin="0"
                    >
                      MONITOR SYSTEM
                    </Paragraph>
                  </div>
                  <div className={classes.theSHFField}>
                    <div className={classes.theField}>
                      <FormInput
                        className={classes.theBorderRadius}
                        name="search"
                        placeholder="Search Member Seq No..."
                        // value={passwordFormData.confirm_password}
                        // onChange={handleChangePassword}
                        fontWeight="500"
                        textColor={themePlate().secondaryTwo}
                        border="1px solid #eeeeee"
                        bg="transparent"
                        padding="12px 25px 12px 65px"
                        mb="0"
                        size="18px"
                      />
                      <div className={classes.theAbsoluteIcon}>
                        <div className={classes.theIcon20}>
                          <img src={SearchIcon} alt={SearchIcon} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
            <div className={classes.theReceptionCounterBody}>
              <Container maxWidth="xl">
                <Box sx={{ display: "flex", width: "100%" }}>

                  {
                    areas.map(r => (
                      <a
                        className={
                          tabs == r.area_id ? classes.theActive : classes.theNoActive
                        }
                        onClick={() => handleChangeTabs(r.area_id)}
                      >
                        <Paragraph bold="600" center>
                          {r.name}
                        </Paragraph>
                      </a>
                    ))
                  }
                </Box>
                <br />
                <br />
                <>
                  <div className={classes.theTable}>
                    <TableContainer>
                      <Table
                        sx={
                          {
                            // minWidth: 650
                          }
                        }
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell className="">
                              <Paragraph
                                center
                                size="14px"
                                bold="500"
                                color="#FFFFFF"
                              >
                                Room No.
                              </Paragraph>
                            </TableCell>
                            <TableCell>
                              <Paragraph
                                size="14px"
                                bold="500"
                                color="#FFFFFF"
                              >
                                Exam Room
                              </Paragraph>
                            </TableCell>
                            <TableCell>
                              <Paragraph
                                size="14px"
                                bold="500"
                                color="#FFFFFF"
                              >
                                Seq No.
                              </Paragraph>
                            </TableCell>
                            <TableCell>
                              <Paragraph
                                center
                                size="14px"
                                bold="500"
                                color="#FFFFFF"
                              >
                                Current Serving
                              </Paragraph>
                            </TableCell>
                            <TableCell>
                              <Paragraph
                                center
                                size="14px"
                                bold="500"
                                color="#FFFFFF"
                              >
                                Doctor
                              </Paragraph>
                            </TableCell>
                            <TableCell>
                              <Paragraph
                                center
                                size="14px"
                                bold="500"
                                color="#FFFFFF"
                              >
                                Serving Time
                              </Paragraph>
                            </TableCell>
                            {/* <TableCell>
                              <Paragraph
                                center
                                size="14px"
                                bold="500"
                                color="#FFFFFF"
                              >
                                Waiting
                              </Paragraph>
                            </TableCell>
                            <TableCell>
                              <Paragraph
                                center
                                size="14px"
                                bold="500"
                                color="#FFFFFF"
                              >
                                Completed
                              </Paragraph>
                            </TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>

                          {
                            areas.length && (
                              <>
                                {
                                  rooms.map(r => (
                                    <TableRow
                                      key="123"
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                      // onClick={() => navigate("../reception-member-profile/" + row.appointment_id)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <TableCell>
                                        <Paragraph
                                          center
                                          color="#211D4E"
                                          size="16px"
                                          bold="500"
                                        >
                                          {r.room_no}
                                        </Paragraph>
                                      </TableCell>
                                      <TableCell>
                                        <Paragraph
                                          left
                                          color="#211D4E"
                                          size="16px"
                                          bold="600"
                                        >
                                          {r.name}
                                        </Paragraph>
                                      </TableCell>
                                      <TableCell>
                                        <Paragraph
                                          color="#211D4E"
                                          size="16px"
                                          bold="500"
                                        >
                                          {r.seq_no}
                                        </Paragraph>
                                      </TableCell>
                                      <TableCell>
                                        <Paragraph
                                          center
                                          color="#211D4E"
                                          size="16px"
                                          bold="500"
                                        >
                                          {r.patient}
                                        </Paragraph>
                                      </TableCell>
                                      <TableCell>
                                        <Paragraph
                                          center
                                          color="#211D4E"
                                          size="16px"
                                          bold="500"
                                        >
                                          {r.doctor}
                                        </Paragraph>
                                      </TableCell>
                                      <TableCell>
                                        <Paragraph
                                          center
                                          size="16px"
                                          bold="600"
                                          letterSpacing="0.05em"
                                          color="#3ADEBB"
                                        >
                                          {r.serving_min + ":" + r.serving_sec}
                                        </Paragraph>
                                      </TableCell>
                                      {/* <TableCell>
                                        <div>
                                          <Paragraph
                                            center
                                            size="16px"
                                            bold="600"
                                            letterSpacing="0.05em"
                                          >
                                            {r.waiting}
                                          </Paragraph>
                                        </div>
                                      </TableCell>
                                      <TableCell>
                                        <div>
                                          <Paragraph
                                            color="#3ADEBB"
                                            center
                                            size="16px"
                                            bold="600"
                                            letterSpacing="0.05em"
                                          >
                                            {r.completed}
                                          </Paragraph>
                                        </div>
                                      </TableCell> */}
                                    </TableRow>
                                  ))
                                }
                              </>
                            )
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <br />
                  <br />
                  <div className={classes.theTable}>
                    <TableContainer>
                      <Table
                        sx={
                          {
                            // minWidth: 650
                          }
                        }
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell className="">
                              <Paragraph
                                center
                                size="14px"
                                bold="500"
                                color="#FFFFFF"
                              >
                                Seq No.
                              </Paragraph>
                            </TableCell>
                            <TableCell>
                              <Paragraph
                                size="14px"
                                bold="500"
                                color="#FFFFFF"
                              >
                                Name
                              </Paragraph>
                            </TableCell>
                            <TableCell>
                              <Paragraph
                                size="14px"
                                bold="500"
                                color="#FFFFFF"
                              >
                                Age
                              </Paragraph>
                            </TableCell>
                            <TableCell>
                              <Paragraph
                                center
                                size="14px"
                                bold="500"
                                color="#FFFFFF"
                              >
                                Gender
                              </Paragraph>
                            </TableCell>
                            <TableCell>
                              <Paragraph
                                center
                                size="14px"
                                bold="500"
                                color="#FFFFFF"
                              >
                                Package
                              </Paragraph>
                            </TableCell>
                            <TableCell>
                              <Paragraph
                                center
                                size="14px"
                                bold="500"
                                color="#FFFFFF"
                              >
                                Status
                              </Paragraph>
                            </TableCell>
                            <TableCell>
                              <Paragraph
                                center
                                size="14px"
                                bold="500"
                                color="#FFFFFF"
                              >
                                Current Station
                              </Paragraph>
                            </TableCell>
                            <TableCell>
                              <Paragraph
                                center
                                size="14px"
                                bold="500"
                                color="#FFFFFF"
                              >
                                Next Station
                              </Paragraph>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {1 == 2 && (
                            <TableRow
                              key="123"
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              // onClick={() => navigate("../reception-member-profile/" + row.appointment_id)}
                              style={{ cursor: "pointer" }}
                            >
                              <TableCell>
                                <Paragraph
                                  center
                                  color="#211D4E"
                                  size="16px"
                                  bold="500"
                                >
                                  123
                                </Paragraph>
                              </TableCell>
                              <TableCell>
                                <Paragraph
                                  left
                                  color="#211D4E"
                                  size="16px"
                                  bold="600"
                                >
                                  Siew Wen Hui
                                </Paragraph>
                              </TableCell>
                              <TableCell>
                                <Paragraph
                                  color="#211D4E"
                                  size="16px"
                                  bold="500"
                                >
                                  28
                                </Paragraph>
                              </TableCell>
                              <TableCell>
                                <Paragraph
                                  center
                                  color="#211D4E"
                                  size="16px"
                                  bold="500"
                                >
                                  Female
                                </Paragraph>
                              </TableCell>
                              <TableCell>
                                <Paragraph
                                  center
                                  color="#211D4E"
                                  size="16px"
                                  bold="500"
                                >
                                  12
                                </Paragraph>
                              </TableCell>
                              <TableCell>
                                <Paragraph
                                  center
                                  size="16px"
                                  bold="600"
                                  letterSpacing="0.05em"
                                  color="#3ADEBB"
                                >
                                  Checking
                                </Paragraph>
                              </TableCell>
                              <TableCell>
                                <div>
                                  <Paragraph
                                    center
                                    size="16px"
                                    bold="600"
                                    letterSpacing="0.05em"
                                  >
                                    A-01 Interview
                                  </Paragraph>
                                </div>
                              </TableCell>
                              <TableCell>
                                <div>
                                  <Paragraph
                                    center
                                    size="16px"
                                    bold="600"
                                    letterSpacing="0.05em"
                                  >
                                    A-02 General Measurement
                                  </Paragraph>
                                </div>
                              </TableCell>
                            </TableRow>
                          )}

                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <br />
                  <br />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div
                      className={classes.theButton}
                      style={{ marginRight: "20px" }}
                    >
                      <Button
                        onClick={() => setOpenFlow(true)}
                        label="Flow Modifier"
                        color="#FFF"
                        hvbg="#219B8E"
                        bg="#219B8E"
                        size="16px"
                        bold="700"
                        pd="14px 68px"
                        br="5px"
                      />
                    </div>
                    <div
                      className={classes.theButton}
                    // style={{ marginRight: "30px" }}
                    >
                      <Button
                        onClick={() => setOpenStation(true)}
                        label="Change Station"
                        color="#fff"
                        hvbg="#3ADEBB"
                        bg="#3ADEBB"
                        size="16px"
                        bold="700"
                        pd="14px 60px"
                        br="3px"
                      />
                    </div>
                  </div>
                </>
              </Container>
            </div>
          </div>
        </React.Fragment>
      )}
      <Dialog
        onClose={() => setOpenStation(false)}
        open={openStation}
        fullWidth={true}
      // maxWidth=""
      >
        <Container maxWidth="xxl">
          <Box
            sx={{
              borderTopRightRadius: "4px",
              borderTopLeftRadius: "4px",
              padding: "2rem",
              background: "#fff",
            }}
          >
            <Close
              sx={{ float: "right" }}
              onClick={() => setOpenStation(false)}
            />
            <Paragraph
              color="#211D4E"
              bold="700"
              size="24px"
              margin="0 0 2rem 0"
              center
            >
              Change Station
            </Paragraph>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1rem",
                justifyContent: "space-between",
              }}
            >
              <Paragraph color="#211d4e" bold="600" margin="0 1rem 0 0">
                Seq No:
              </Paragraph>
              <Box sx={{ width: "60%" }}>
                <FormInput
                  bg="#F2F2F2"
                  number
                  textcolor="#211d4e"
                  fontWeight="600"
                  textalign="center"
                  name="seq_no"
                  boxshadow="unset"
                  border="1px solid #EDEDED"
                  padding="1rem"
                  mb="0"
                  disabled
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1rem",
                justifyContent: "space-between",
              }}
            >
              <Paragraph color="#211d4e" bold="600" margin="0 1rem 0 0">
                Name:
              </Paragraph>
              <Box sx={{ width: "60%" }}>
                <FormInput
                  bg="#F2F2F2"
                  number
                  textcolor="#211d4e"
                  fontWeight="600"
                  textalign="center"
                  name="name"
                  boxshadow="unset"
                  border="1px solid #EDEDED"
                  padding="1rem"
                  mb="0"
                  disabled
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1rem",
                justifyContent: "space-between",
              }}
            >
              <Paragraph color="#211d4e" bold="600" margin="0 1rem 0 0">
                Next Station:
              </Paragraph>
              <Box sx={{ width: "60%" }}>
                <FormInput
                  bg="#F2F2F2"
                  number
                  textcolor="#211d4e"
                  fontWeight="600"
                  textalign="center"
                  boxshadow="unset"
                  border="1px solid #EDEDED"
                  padding="1rem"
                  mb="0"
                  disabled
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1rem",
                justifyContent: "space-between",
              }}
            >
              <Paragraph color="#211d4e" bold="600" margin="0 1rem 0 0">
                New Next Station:
              </Paragraph>
              <Box sx={{ width: "60%" }}>
                <FormInput
                  fontWeight="600"
                  textColor={themePlate().primaryThree}
                  border="5px"
                  bg="#fff"
                  select
                  value="Normal"
                >
                  <MenuItem value="Normal">A-01 General Measurement</MenuItem>
                  <MenuItem value="Normalx">A-02 Briefing</MenuItem>
                  <MenuItem value="Normalx">A-03 Electrocradiogram</MenuItem>
                  <MenuItem value="Normalx">A-04 Retinographt</MenuItem>
                  <MenuItem value="Normalx">A-05 Sonography</MenuItem>
                  <MenuItem value="Normalx">A-06 Vision Test</MenuItem>
                </FormInput>
              </Box>
            </Box>

            <Button
              onClick={() => setOpenStation(true)}
              label="SAVE"
              color="#fff"
              hvbg="#3ADEBB"
              bg="#3ADEBB"
              size="16px"
              bold="700"
              pd="14px 60px"
              br="3px"
            />
          </Box>
        </Container>
      </Dialog>

      <Dialog
        onClose={() => setOpenFlow(false)}
        open={openFlow}
        fullWidth={true}
        maxWidth="xl"
      >
        <Box
          padding="2rem 3rem"
          sx={{
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90deg, #3ADEBB -0.18%, #00AEE9 99.82%);",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Box sx={{ marginLeft: "auto", marginRight: "auto" }}>
              <Paragraph color="#FFF" bold="600" size="24px" center>
                Flow Modifier
              </Paragraph>
            </Box>
            <Close sx={{ color: "#FFF" }} onClick={() => setOpenFlow(false)} />
          </Box>
        </Box>
        <Container maxWidth="xxl" sx={{ background: "#F9F9F9" }}>
          <Box
            sx={{
              borderTopRightRadius: "4px",
              borderTopLeftRadius: "4px",
              padding: "2rem",
              background: "#F9F9F9",
            }}
          >
            {/* <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={2}>
                <FormInput
                  fontWeight="600"
                  textColor={themePlate().primaryThree}
                  border="5px"
                  select
                  value="-"
                  label="Interview"
                  boxshadow="none"
                  bg="#FFF"
                >
                  <MenuItem value="-">-</MenuItem>
                  <MenuItem value="Normal">Completed</MenuItem>
                  <MenuItem value="Incomplete" sx={{ color: "#DE4E3A" }}>
                    Incomplete
                  </MenuItem>
                  <MenuItem value="Additional Item">Additional Item</MenuItem>
                </FormInput>
              </Grid>
							<Grid item xs={12} sm={12} md={2}>
							<FormInput
                    fontWeight="600"
                    textColor={themePlate().primaryThree}
                    border="5px"
                    select
                    value="-"
                    label="Briefing Room"
                    boxshadow="none"
                    bg="#FFF"
                  >
                    <MenuItem value="-">-</MenuItem>
                    <MenuItem value="Normal">Completed</MenuItem>
                    <MenuItem value="Incomplete" sx={{ color: "#DE4E3A" }}>
                      Incomplete
                    </MenuItem>
                    <MenuItem value="Additional Item">Additional Item</MenuItem>
                  </FormInput>
              </Grid>
            </Grid> */}
            <Box sx={{ marginBottom: "3rem" }}>
              <Paragraph bold="600" color="#219B8E" size="18px">
                Preparation Area
              </Paragraph>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "20%", marginRight: "1rem" }}>
                  <FormInput
                    fontWeight="600"
                    textColor={themePlate().primaryThree}
                    border="5px"
                    select
                    value="-"
                    label="Interview"
                    boxshadow="none"
                    bg="#FFF"
                    mb="0"
                    textLabelColor="#5A567B"
                    bold="600"
                  >
                    <MenuItem value="-">-</MenuItem>
                    <MenuItem value="Normal">Completed</MenuItem>
                    <MenuItem value="Incomplete" sx={{ color: "#DE4E3A" }}>
                      Incomplete
                    </MenuItem>
                    <MenuItem value="Additional Item">Additional Item</MenuItem>
                  </FormInput>
                </Box>
                <Box sx={{ width: "20%", marginRight: "1rem" }}>
                  <FormInput
                    fontWeight="600"
                    textColor={themePlate().primaryThree}
                    border="5px"
                    select
                    value="-"
                    label="Briefing Room"
                    boxshadow="none"
                    bg="#FFF"
                    mb="0"
                    textLabelColor="#5A567B"
                    bold="600"
                  >
                    <MenuItem value="-">-</MenuItem>
                    <MenuItem value="Normal">Completed</MenuItem>
                    <MenuItem value="Incomplete" sx={{ color: "#DE4E3A" }}>
                      Incomplete
                    </MenuItem>
                    <MenuItem value="Additional Item">Additional Item</MenuItem>
                  </FormInput>
                </Box>
              </Box>
            </Box>

            <Box sx={{ marginBottom: "3rem" }}>
              <Paragraph bold="600" color="#219B8E">
                Area 1
              </Paragraph>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "20%", marginRight: "1rem" }}>
                  <FormInput
                    fontWeight="600"
                    textColor={themePlate().primaryThree}
                    border="5px"
                    select
                    value="-"
                    label="Sonography"
                    boxshadow="none"
                    bg="#FFF"
                    mb="0"
                    textLabelColor="#5A567B"
                    bold="600"
                  >
                    <MenuItem value="-">-</MenuItem>
                    <MenuItem value="Normal">Completed</MenuItem>
                    <MenuItem value="Incomplete" sx={{ color: "#DE4E3A" }}>
                      Incomplete
                    </MenuItem>
                    <MenuItem value="Additional Item">Additional Item</MenuItem>
                  </FormInput>
                </Box>
                <Box sx={{ width: "20%", marginRight: "1rem" }}>
                  <FormInput
                    fontWeight="600"
                    textColor={themePlate().primaryThree}
                    border="5px"
                    select
                    value="-"
                    label="General Measurement"
                    boxshadow="none"
                    bg="#FFF"
                    mb="0"
                    textLabelColor="#5A567B"
                    bold="600"
                  >
                    <MenuItem value="-">-</MenuItem>
                    <MenuItem value="Normal">Completed</MenuItem>
                    <MenuItem value="Incomplete" sx={{ color: "#DE4E3A" }}>
                      Incomplete
                    </MenuItem>
                    <MenuItem value="Additional Item">Additional Item</MenuItem>
                  </FormInput>
                </Box>
                <Box sx={{ width: "20%", marginRight: "1rem" }}>
                  <FormInput
                    fontWeight="600"
                    textColor={themePlate().primaryThree}
                    border="5px"
                    select
                    value="-"
                    label="ECG"
                    boxshadow="none"
                    bg="#FFF"
                    mb="0"
                    textLabelColor="#5A567B"
                    bold="600"
                  >
                    <MenuItem value="-">-</MenuItem>
                    <MenuItem value="Normal">Completed</MenuItem>
                    <MenuItem value="Incomplete" sx={{ color: "#DE4E3A" }}>
                      Incomplete
                    </MenuItem>
                    <MenuItem value="Additional Item">Additional Item</MenuItem>
                  </FormInput>
                </Box>
                <Box sx={{ width: "20%", marginRight: "1rem" }}>
                  <FormInput
                    fontWeight="600"
                    textColor={themePlate().primaryThree}
                    border="5px"
                    select
                    value="-"
                    label="Retinography"
                    boxshadow="none"
                    bg="#FFF"
                    mb="0"
                    textLabelColor="#5A567B"
                    bold="600"
                  >
                    <MenuItem value="-">-</MenuItem>
                    <MenuItem value="Normal">Completed</MenuItem>
                    <MenuItem value="Incomplete" sx={{ color: "#DE4E3A" }}>
                      Incomplete
                    </MenuItem>
                    <MenuItem value="Additional Item">Additional Item</MenuItem>
                  </FormInput>
                </Box>
                <Box sx={{ width: "20%", marginRight: "1rem" }}>
                  <FormInput
                    fontWeight="600"
                    textColor={themePlate().primaryThree}
                    border="5px"
                    select
                    value="-"
                    label="Pap's Smear"
                    boxshadow="none"
                    bg="#FFF"
                    mb="0"
                    textLabelColor="#5A567B"
                    bold="600"
                  >
                    <MenuItem value="-">-</MenuItem>
                    <MenuItem value="Normal">Completed</MenuItem>
                    <MenuItem value="Incomplete" sx={{ color: "#DE4E3A" }}>
                      Incomplete
                    </MenuItem>
                    <MenuItem value="Additional Item">Additional Item</MenuItem>
                  </FormInput>
                </Box>
              </Box>
              <br />
              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "20%", marginRight: "1rem" }}>
                  <FormInput
                    fontWeight="600"
                    textColor={themePlate().primaryThree}
                    border="5px"
                    select
                    value="-"
                    label="Lung Function Test"
                    boxshadow="none"
                    bg="#FFF"
                    mb="0"
                    textLabelColor="#5A567B"
                    bold="600"
                  >
                    <MenuItem value="-">-</MenuItem>
                    <MenuItem value="Normal">Completed</MenuItem>
                    <MenuItem value="Incomplete" sx={{ color: "#DE4E3A" }}>
                      Incomplete
                    </MenuItem>
                    <MenuItem value="Additional Item">Additional Item</MenuItem>
                  </FormInput>
                </Box>
                <Box sx={{ width: "20%", marginRight: "1rem" }}>
                  <FormInput
                    fontWeight="600"
                    textColor={themePlate().primaryThree}
                    border="5px"
                    select
                    value="-"
                    label="Peripheral Vascular"
                    boxshadow="none"
                    bg="#FFF"
                    mb="0"
                    textLabelColor="#5A567B"
                    bold="600"
                  >
                    <MenuItem value="-">-</MenuItem>
                    <MenuItem value="Normal">Completed</MenuItem>
                    <MenuItem value="Incomplete" sx={{ color: "#DE4E3A" }}>
                      Incomplete
                    </MenuItem>
                    <MenuItem value="Additional Item">Additional Item</MenuItem>
                  </FormInput>
                </Box>
                <Box sx={{ width: "20%", marginRight: "1rem" }}>
                  <FormInput
                    fontWeight="600"
                    textColor={themePlate().primaryThree}
                    border="5px"
                    select
                    value="-"
                    label="Vision & Ocular Tension Test"
                    boxshadow="none"
                    bg="#FFF"
                    mb="0"
                    textLabelColor="#5A567B"
                    bold="600"
                  >
                    <MenuItem value="-">-</MenuItem>
                    <MenuItem value="Normal">Completed</MenuItem>
                    <MenuItem value="Incomplete" sx={{ color: "#DE4E3A" }}>
                      Incomplete
                    </MenuItem>
                    <MenuItem value="Additional Item">Additional Item</MenuItem>
                  </FormInput>
                </Box>
              </Box>
            </Box>

            <Box sx={{ marginBottom: "3rem" }}>
              <Paragraph bold="600" color="#219B8E" size="18px">
                Area 2
              </Paragraph>

              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "20%", marginRight: "1rem" }}>
                  <FormInput
                    fontWeight="600"
                    textColor={themePlate().primaryThree}
                    border="5px"
                    select
                    value="-"
                    label="ENT Examination"
                    boxshadow="none"
                    bg="#FFF"
                    mb="0"
                    textLabelColor="#5A567B"
                    bold="600"
                  >
                    <MenuItem value="-">-</MenuItem>
                    <MenuItem value="Normal">Completed</MenuItem>
                    <MenuItem value="Incomplete" sx={{ color: "#DE4E3A" }}>
                      Incomplete
                    </MenuItem>
                    <MenuItem value="Additional Item">Additional Item</MenuItem>
                  </FormInput>
                </Box>
                <Box sx={{ width: "20%", marginRight: "1rem" }}>
                  <FormInput
                    fontWeight="600"
                    textColor={themePlate().primaryThree}
                    border="5px"
                    select
                    value="-"
                    label="Pulmonary Function"
                    boxshadow="none"
                    bg="#FFF"
                    mb="0"
                    textLabelColor="#5A567B"
                    bold="600"
                  >
                    <MenuItem value="-">-</MenuItem>
                    <MenuItem value="Normal">Completed</MenuItem>
                    <MenuItem value="Incomplete" sx={{ color: "#DE4E3A" }}>
                      Incomplete
                    </MenuItem>
                    <MenuItem value="Additional Item">Additional Item</MenuItem>
                  </FormInput>
                </Box>
                <Box sx={{ width: "20%", marginRight: "1rem" }}>
                  <FormInput
                    fontWeight="600"
                    textColor={themePlate().primaryThree}
                    border="5px"
                    select
                    value="-"
                    label="BMD"
                    boxshadow="none"
                    bg="#FFF"
                    mb="0"
                    textLabelColor="#5A567B"
                    bold="600"
                  >
                    <MenuItem value="-">-</MenuItem>
                    <MenuItem value="Normal">Completed</MenuItem>
                    <MenuItem value="Incomplete" sx={{ color: "#DE4E3A" }}>
                      Incomplete
                    </MenuItem>
                    <MenuItem value="Additional Item">Additional Item</MenuItem>
                  </FormInput>
                </Box>
                <Box sx={{ width: "20%", marginRight: "1rem" }}>
                  <FormInput
                    fontWeight="600"
                    textColor={themePlate().primaryThree}
                    border="5px"
                    select
                    value="-"
                    label="Auidiometry"
                    boxshadow="none"
                    bg="#FFF"
                    mb="0"
                    textLabelColor="#5A567B"
                    bold="600"
                  >
                    <MenuItem value="-">-</MenuItem>
                    <MenuItem value="Normal">Completed</MenuItem>
                    <MenuItem value="Incomplete" sx={{ color: "#DE4E3A" }}>
                      Incomplete
                    </MenuItem>
                    <MenuItem value="Additional Item">Additional Item</MenuItem>
                  </FormInput>
                </Box>
                <Box sx={{ width: "20%", marginRight: "1rem" }}>
                  <FormInput
                    fontWeight="600"
                    textColor={themePlate().primaryThree}
                    border="5px"
                    select
                    value="-"
                    label="X-Ray"
                    boxshadow="none"
                    bg="#FFF"
                    mb="0"
                    textLabelColor="#5A567B"
                    bold="600"
                  >
                    <MenuItem value="-">-</MenuItem>
                    <MenuItem value="Normal">Completed</MenuItem>
                    <MenuItem value="Incomplete" sx={{ color: "#DE4E3A" }}>
                      Incomplete
                    </MenuItem>
                    <MenuItem value="Additional Item">Additional Item</MenuItem>
                  </FormInput>
                </Box>
              </Box>
              <br />
              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "20%", marginRight: "1rem" }}>
                  <FormInput
                    fontWeight="600"
                    textColor={themePlate().primaryThree}
                    border="5px"
                    select
                    value="-"
                    label="Mammography"
                    boxshadow="none"
                    bg="#FFF"
                    mb="0"
                    textLabelColor="#5A567B"
                    bold="600"
                  >
                    <MenuItem value="-">-</MenuItem>
                    <MenuItem value="Normal">Completed</MenuItem>
                    <MenuItem value="Incomplete" sx={{ color: "#DE4E3A" }}>
                      Incomplete
                    </MenuItem>
                    <MenuItem value="Additional Item">Additional Item</MenuItem>
                  </FormInput>
                </Box>
                <Box sx={{ width: "20%", marginRight: "1rem" }}>
                  <FormInput
                    fontWeight="600"
                    textColor={themePlate().primaryThree}
                    border="5px"
                    select
                    value="-"
                    label="ENT Examination"
                    boxshadow="none"
                    bg="#FFF"
                    mb="0"
                    textLabelColor="#5A567B"
                    bold="600"
                  >
                    <MenuItem value="-">-</MenuItem>
                    <MenuItem value="Normal">Completed</MenuItem>
                    <MenuItem value="Incomplete" sx={{ color: "#DE4E3A" }}>
                      Incomplete
                    </MenuItem>
                    <MenuItem value="Additional Item">Additional Item</MenuItem>
                  </FormInput>
                </Box>
              </Box>
            </Box>

            <Box sx={{ marginBottom: "1rem" }}>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "20%", marginRight: "1rem" }}>
                  <Paragraph bold="600" color="#219B8E" size="18px">
                    Dining
                  </Paragraph>
                  <FormInput
                    fontWeight="600"
                    textColor={themePlate().primaryThree}
                    border="5px"
                    select
                    value="-"
                    label="Dining Room"
                    boxshadow="none"
                    bg="#FFF"
                    textLabelColor="#5A567B"
                    bold="600"
                  >
                    <MenuItem value="-">-</MenuItem>
                    <MenuItem value="Normal">Completed</MenuItem>
                    <MenuItem value="Incomplete" sx={{ color: "#DE4E3A" }}>
                      Incomplete
                    </MenuItem>
                    <MenuItem value="Additional Item">Additional Item</MenuItem>
                  </FormInput>
                </Box>

                <Box sx={{ width: "20%", marginRight: "1rem" }}>
                  <Paragraph bold="600" color="#219B8E" size="18px">
                    Physician Conclusion
                  </Paragraph>
                  <FormInput
                    fontWeight="600"
                    textColor={themePlate().primaryThree}
                    border="5px"
                    select
                    value="-"
                    label="Physician Conclusion"
                    boxshadow="none"
                    bg="#FFF"
                    textLabelColor="#5A567B"
                    bold="600"
                  >
                    <MenuItem value="-">-</MenuItem>
                    <MenuItem value="Normal">Completed</MenuItem>
                    <MenuItem value="Incomplete" sx={{ color: "#DE4E3A" }}>
                      Incomplete
                    </MenuItem>
                    <MenuItem value="Additional Item">Additional Item</MenuItem>
                  </FormInput>
                </Box>

                <Box sx={{ width: "20%", marginRight: "1rem" }}>
                  <Paragraph bold="600" color="#219B8E" size="18px">
                    Life Style Consultation
                  </Paragraph>
                  <FormInput
                    fontWeight="600"
                    textColor={themePlate().primaryThree}
                    border="5px"
                    select
                    value="-"
                    label="Health Management Consultation"
                    boxshadow="none"
                    bg="#FFF"
                    textLabelColor="#5A567B"
                    bold="600"
                  >
                    <MenuItem value="-">-</MenuItem>
                    <MenuItem value="Normal">Completed</MenuItem>
                    <MenuItem value="Incomplete" sx={{ color: "#DE4E3A" }}>
                      Incomplete
                    </MenuItem>
                    <MenuItem value="Additional Item">Additional Item</MenuItem>
                  </FormInput>
                </Box>
              </Box>
            </Box>
          </Box>


          <br />
        </Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderTop: "1px solid #EAEAEA",
            alignItems: "center",
            padding: "1rem 3rem",
            background: "#FFF",
            position: "sticky",
            bottom: "0"
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Paragraph color="#211D4E" bold="500" margin="0 4rem 0 0">
              Seq No: <span style={{ fontWeight: "700", margin: "0 0 0 1rem" }}>123</span>
            </Paragraph>
            <Paragraph color="#211D4E" bold="500">
              Name: <span style={{ fontWeight: "700", margin: "0 0 0 1rem" }}>Siew Wen Hui</span>
            </Paragraph>
          </Box>
          <Button
            label="SAVE"
            color="#fff"
            hvbg="#3ADEBB"
            bg="#3ADEBB"
            size="16px"
            bold="700"
            pd="14px 60px"
            br="3px"
          />
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
