import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Radio,
  RadioGroup,
  Checkbox,
  Box,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import Loader from "react-js-loader";
import toast from "react-simple-toasts";
import Header from "../../components/Header/Header";
import Paragraph from "../../components/Typography/Paragraph";
import FormInput from "../../components/FormInput/FormInput";
import Button from "../../components/Button/Button";
import { themePlate } from "../../components/Theme/Theme";
import useStyles, {
  CustomRadio,
  CustomRadioChecked,
  BpIcon,
  BpCheckedIcon,
  BpIcon2,
  BpCheckedIcon2,
} from "./styles";
import "./styles.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SearchIcon from "../../assets/images/Search Icon.svg";
import CheckoutIcon from "../../assets/images/Checkout Icon.svg";
import { get_reception_list, random_appointment } from "../../api/API";
import { CheckCircle, ErrorRounded } from "@mui/icons-material";

export default function LabTestDetail() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [receptionList, setReceptionList] = useState([]);

  //Pagination
  const [page, setPage] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState(1);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handlegetData = async () => {
    setLoading(true);

    var resReceptionList = await get_reception_list({ page: page });
    if (resReceptionList.status) {
      setLoading(false);
      setReceptionList(resReceptionList.data);
      setTotalCount(resReceptionList.totalCount);
    }
  };

  useEffect(() => {
    handlegetData();
  }, [page]);

  useEffect(() => {
    if (!window.location.hash) {
      window.location = window.location + "#loaded";
      window.location.reload();
    }
  }, []);

  const [showPassword3, setShowPassword3] = useState(false);
  const [passwordState, setPasswordState] = useState(false);

  const handleGenerate = async () => {
    setLoading(true);

    var resGenerate = await random_appointment({});
    if (resGenerate.status) {
      setLoading(false);
      handlegetData();
    }
  };

  //

  const [tabs, setTabs] = React.useState(0);

  const handleChangeTabs = (txt) => {
    setTabs(txt);
  };

  const CustomRadioButton = (props) => {
    return (
      <Radio
        sx={{
          "&:hover": {
            background: "transparent",
          },
        }}
        disableRipple
        // color="default"
        checkedIcon={<CustomRadio />}
        icon={<CustomRadioChecked />}
        {...props}
      />
    );
  };

  const CustomCheckBox = (props) => {
    return (
      <Checkbox
        sx={{
          "&:hover": { background: "transparent" },
        }}
        disableRipple
        // color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        inputProps={{ "aria-label": "Checkbox demo" }}
        {...props}
      />
    );
  };

  const CustomCheckBox2 = (props) => {
    return (
      <Checkbox
        sx={{
          "&:hover": { bgcolor: "transparent" },
        }}
        disableRipple
        // color="default"
        checkedIcon={<BpCheckedIcon2 />}
        icon={<BpIcon2 />}
        inputProps={{ "aria-label": "Checkbox demo" }}
        {...props}
      />
    );
  };

  const [fasting, setFasting] = React.useState(0);
  const [urinetest, setUrineTest] = React.useState(0);
  const [hivtest, setHivTest] = React.useState(0);
  const [blood, setBlood] = React.useState(0);
  const [punturedSite, setPunturedSite] = React.useState("RT");

  return (
    <React.Fragment>
      {loading ? (
        <div style={{ margin: "auto" }}>
          <Loader
            type="bubble-loop"
            bgColor={themePlate().secondaryThree}
            size={50}
          />
        </div>
      ) : (
        <React.Fragment>
          <Header />
          <Box sx={{ background: "#211D4E", padding: "1rem" }}>
            <Container
              maxWidth="xl"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  display: "flex",
                  width: "50%",
                }}
              >
                <Paragraph color="#FFF" bold="700">
                  001
                </Paragraph>
                <Paragraph color="#FFF" bold="700">
                  Siew Wen Hui
                </Paragraph>
                <Paragraph color="#FFF" bold="700">
                  1995-05-02
                </Paragraph>
                <Paragraph color="#FFF" bold="700">
                  28/F
                </Paragraph>
                <Paragraph color="#FFF" bold="700">
                  950502-06-1234
                </Paragraph>
              </Box>
              <Box
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  display: "flex",
                  width: "30%",
                }}
              >
                <Paragraph color="#FFF" bold="700">
                  0401 1010 0600 0001
                </Paragraph>
                <Paragraph color="#FFF" bold="700">
                  K2
                </Paragraph>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ borderRight: "3px solid #FFF" }}>
                    <Paragraph color="#FFF" bold="700" margin="0 8px 0 0">
                      BM
                    </Paragraph>
                  </Box>

                  <Box sx={{ borderRight: "3px solid #FFF" }}>
                    <Paragraph color="#FFF" bold="700" margin="0 8px">
                      EN
                    </Paragraph>
                  </Box>
                  <Box>
                    <Paragraph color="#FFF" bold="700" margin="0 0 0 8px">
                      CH
                    </Paragraph>
                  </Box>
                </Box>
                <ErrorRounded sx={{ color: "#F9C234" }} />
              </Box>
            </Container>
          </Box>
          <div className={classes.theReceptionCounter}>
            <div className={classes.theReceptionCounterHeader}>
              <Container
                maxWidth="xl"
                className="customContainer"
                sx={{ display: "flex" }}
              >
                <div
                  className={classes.thSHFlex}
                  style={{ marginRight: "100px" }}
                >
                  <div
                    className={classes.thSHLabel}
                    onClick={() => navigate("../dashboard")}
                  >
                    <div className={classes.theSHIcon}>
                      <ChevronLeftIcon fontSize="34px" />
                    </div>
                    <div>
                      <Paragraph
                        size="18px"
                        color={themePlate().primaryThree}
                        bold="500"
                        textTransform="capitalize"
                        margin="0"
                      >
                        Back
                      </Paragraph>
                    </div>
                  </div>
                </div>
                <Box sx={{ display: "flex", width: "80%" }}>
                  <a
                    className={
                      tabs == 0 ? classes.theActive : classes.theNoActive
                    }
                    onClick={() => handleChangeTabs(0)}
                  >
                    <Paragraph bold="600" center>
                      Blood Test
                    </Paragraph>
                  </a>
                  <a
                    className={
                      tabs == 1 ? classes.theActive : classes.theNoActive
                    }
                    onClick={() => handleChangeTabs(1)}
                  >
                    <Paragraph bold="600" center>
                      Immunology
                    </Paragraph>
                  </a>
                  <a
                    className={
                      tabs == 2 ? classes.theActive : classes.theNoActive
                    }
                    onClick={() => handleChangeTabs(2)}
                  >
                    <Paragraph bold="600" center>
                      Biochemistry
                    </Paragraph>
                  </a>
                  <a
                    className={
                      tabs == 3 ? classes.theActive : classes.theNoActive
                    }
                    onClick={() => handleChangeTabs(3)}
                  >
                    <Paragraph bold="600" center>
                      Urine & Faeces
                    </Paragraph>
                  </a>
                  <a
                    className={
                      tabs == 4 ? classes.theActive : classes.theNoActive
                    }
                    onClick={() => handleChangeTabs(4)}
                  >
                    <Paragraph bold="600" center>
                      MPA
                    </Paragraph>
                  </a>
                  <a
                    className={
                      tabs == 5 ? classes.theActive : classes.theNoActive
                    }
                    onClick={() => handleChangeTabs(5)}
                  >
                    <Paragraph bold="600" center>
                      Other Items
                    </Paragraph>
                  </a>
                </Box>
              </Container>
            </div>
            <div className={classes.theReceptionCounterBody}>
              <Container maxWidth="xl">
                {tabs == 0 && (
                  <>
                    <Box
                      sx={{
                        padding: ".6rem 3rem",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        background:
                          "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(90deg, #3ADEBB -0.18%, #00AEE9 99.82%)",
                      }}
                    >
                      <Paragraph color="#FFF" bold="600" size="24px">
                        Attempt:
                      </Paragraph>
                    </Box>
                    <Box
                      sx={{
                        padding: "1rem 3rem",
                        background: "#FFF",
                        borderBottom: "2px solid #E9E9E9",
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={6}>
                          <RadioGroup
                            row
                            aria-label="fasting"
                            disabled
                            value={fasting}
                            onChange={(e) => setFasting(e.target.value)}
                            name="display-member-status"
                            sx={{ alignItems: "center" }}
                          >
                            <Box sx={{ width: "30%" }}>
                              <Paragraph
                                color="#211D4E"
                                bold="600"
                                margin="0 3rem 0 0"
                              >
                                Fasting:
                              </Paragraph>
                            </Box>
                            <Box
                              sx={{
                                width: "55%",
                                justifyContent: "space-between",
                                display: "flex",
                              }}
                            >
                              <Box sx={{ width: "50%" }}>
                                <FormControlLabel
                                  sx={{
                                    color: fasting == 1 ? "#333333" : "#5A567B",
                                    "& .MuiFormControlLabel-label": {
                                      fontWeight: "500",
                                    },
                                  }}
                                  value="1"
                                  control={<CustomRadioButton />}
                                  label="Yes"
                                />
                              </Box>
                              <Box sx={{ width: "50%" }}>
                                <FormControlLabel
                                  sx={{
                                    color: fasting == 0 ? "#333333" : "#5A567B",
                                    "& .MuiFormControlLabel-label": {
                                      fontWeight: "500",
                                    },
                                  }}
                                  value="0"
                                  control={<CustomRadioButton />}
                                  label="No"
                                />
                              </Box>
                            </Box>
                          </RadioGroup>
                          <RadioGroup
                            row
                            aria-label="urinetest"
                            disabled
                            value={urinetest}
                            onChange={(e) => setUrineTest(e.target.value)}
                            name="display-member-status"
                            sx={{ alignItems: "center" }}
                          >
                            <Box sx={{ width: "30%" }}>
                              <Paragraph
                                color="#211D4E"
                                bold="600"
                                margin="0 3rem 0 0"
                              >
                                Urine Test:
                              </Paragraph>
                            </Box>
                            <Box
                              sx={{
                                width: "55%",
                                justifyContent: "space-between",
                                display: "flex",
                              }}
                            >
                              <Box sx={{ width: "50%" }}>
                                <FormControlLabel
                                  sx={{
                                    color:
                                      urinetest == 0 ? "#333333" : "#5A567B",
                                    "& .MuiFormControlLabel-label": {
                                      fontWeight: "500",
                                    },
                                  }}
                                  value="0"
                                  control={<CustomRadioButton />}
                                  label="Not Taken"
                                />
                              </Box>
                              <Box sx={{ width: "50%" }}>
                                <FormControlLabel
                                  sx={{
                                    color:
                                      urinetest == 1 ? "#333333" : "#5A567B",
                                    "& .MuiFormControlLabel-label": {
                                      fontWeight: "500",
                                    },
                                  }}
                                  value="1"
                                  control={<CustomRadioButton />}
                                  label="Taken"
                                />
                              </Box>
                            </Box>
                          </RadioGroup>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <RadioGroup
                            row
                            aria-label="hivtest"
                            disabled
                            value={hivtest}
                            onChange={(e) => setHivTest(e.target.value)}
                            name="display-member-status"
                            sx={{ alignItems: "center" }}
                          >
                            <Box sx={{ width: "30%" }}>
                              <Paragraph
                                color="#211D4E"
                                bold="600"
                                margin="0 3rem 0 0"
                              >
                                HIV Test:
                              </Paragraph>
                            </Box>

                            <Box
                              sx={{
                                width: "55%",
                                justifyContent: "space-between",
                                display: "flex",
                              }}
                            >
                              <Box sx={{ width: "50%" }}>
                                <FormControlLabel
                                  sx={{
                                    color: hivtest == 1 ? "#333333" : "#5A567B",
                                    "& .MuiFormControlLabel-label": {
                                      fontWeight: "500",
                                    },
                                  }}
                                  value="1"
                                  control={<CustomRadioButton />}
                                  label="Agree"
                                />
                              </Box>
                              <Box sx={{ width: "50%" }}>
                                <FormControlLabel
                                  sx={{
                                    color: hivtest == 0 ? "#333333" : "#5A567B",
                                    "& .MuiFormControlLabel-label": {
                                      fontWeight: "500",
                                    },
                                  }}
                                  value="0"
                                  control={<CustomRadioButton />}
                                  label="Disagree"
                                />
                              </Box>
                            </Box>
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box
                      sx={{
                        padding: "1rem 3rem",
                        background: "#F9F9F9",
                        borderBottom: "2px solid #E9E9E9",
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={6}>
                          <RadioGroup
                            row
                            aria-label="blood"
                            disabled
                            value={blood}
                            onChange={(e) => setBlood(e.target.value)}
                            name="display-member-status"
                            sx={{ alignItems: "center" }}
                          >
                            <Box sx={{ width: "30%" }}>
                              <Paragraph
                                color="#211D4E"
                                bold="600"
                                margin="0 3rem 0 0"
                              >
                                Blood:
                              </Paragraph>
                            </Box>
                            <Box
                              sx={{
                                width: "55%",
                                justifyContent: "space-between",
                                display: "flex",
                              }}
                            >
                              <Box sx={{ width: "50%" }}>
                                <FormControlLabel
                                  sx={{
                                    color: blood == 0 ? "#333333" : "#5A567B",
                                    "& .MuiFormControlLabel-label": {
                                      fontWeight: "500",
                                    },
                                  }}
                                  value="0"
                                  control={<CustomRadioButton />}
                                  label="Not Taken"
                                />
                              </Box>
                              <Box sx={{ width: "50%" }}>
                                <FormControlLabel
                                  sx={{
                                    color: blood == 1 ? "#333333" : "#5A567B",
                                    "& .MuiFormControlLabel-label": {
                                      fontWeight: "500",
                                    },
                                  }}
                                  value="1"
                                  control={<CustomRadioButton />}
                                  label="Taken"
                                />
                              </Box>
                            </Box>
                          </RadioGroup>
                          <RadioGroup
                            row
                            aria-label="urinetest"
                            disabled
                            value={punturedSite}
                            onChange={(e) => setPunturedSite(e.target.value)}
                            name="display-member-status"
                            sx={{ alignItems: "baseline" }}
                          >
                            <Box sx={{ width: "30%" }}>
                              <Paragraph
                                color="#211D4E"
                                bold="600"
                                margin="0 3rem 0 0"
                              >
                                Puntured Site:
                              </Paragraph>
                            </Box>

                            <Box sx={{ width: "70%" }}>
                              <Box
                                sx={{
                                  width: "79%",
                                  justifyContent: "space-between",
                                  display: "flex",
                                }}
                              >
                                <Box sx={{ width: "50%" }}>
                                  <FormControlLabel
                                    sx={{
                                      color:
                                        punturedSite == "RT"
                                          ? "#333333"
                                          : "#5A567B",
                                      "& .MuiFormControlLabel-label": {
                                        fontWeight: "500",
                                      },
                                    }}
                                    value="RT"
                                    control={<CustomRadioButton />}
                                    label="RT"
                                  />
                                </Box>
                                <Box sx={{ width: "50%" }}>
                                  <FormControlLabel
                                    sx={{
                                      color:
                                        punturedSite == "LT"
                                          ? "#333333"
                                          : "#5A567B",
                                      "& .MuiFormControlLabel-label": {
                                        fontWeight: "500",
                                      },
                                    }}
                                    value="LT"
                                    control={<CustomRadioButton />}
                                    label="LT"
                                  />
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  width: "79%",
                                  justifyContent: "space-between",
                                  display: "flex",
                                }}
                              >
                                <Box sx={{ width: "50%" }}>
                                  <FormControlLabel
                                    sx={{
                                      color:
                                        punturedSite == "RH"
                                          ? "#333333"
                                          : "#5A567B",
                                      "& .MuiFormControlLabel-label": {
                                        fontWeight: "500",
                                      },
                                    }}
                                    value="RH"
                                    control={<CustomRadioButton />}
                                    label="RH"
                                  />
                                </Box>
                                <Box sx={{ width: "50%" }}>
                                  <FormControlLabel
                                    sx={{
                                      color:
                                        punturedSite == "LH"
                                          ? "#333333"
                                          : "#5A567B",
                                      "& .MuiFormControlLabel-label": {
                                        fontWeight: "500",
                                      },
                                    }}
                                    value="LH"
                                    control={<CustomRadioButton />}
                                    label="LH"
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </RadioGroup>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={6}>
                              <FormInput
                                label="Facilitated by:"
                                bold="600"
                                placeholder="Staff ID..."
                                border="1px solid #ededed"
                                bg="#f9f9f9"
                                textcolor="#211d4e"
                                fontWeight="600"
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                              <Box sx={{ width: "80%", margin: "0 auto" }}>
                                <Button
                                  label="Successful"
                                  bg="#3ADEBB"
                                  color="#FFF"
                                  br="6px"
                                  size="24px"
                                  bold="600"
                                  pd=".8rem 2rem"
                                  hvbg="#3ADEBB"
                                  style={{ marginBottom: "10px" }}
                                />

                                <Button
                                  label="Failed"
                                  bg="#DE4E3A"
                                  color="#FFF"
                                  br="6px"
                                  size="24px"
                                  bold="600"
                                  pd=".8rem 2rem"
                                  hvbg="#DE4E3A"
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box
                      sx={{
                        padding: "1rem 3rem",
                        background: "#FFF",
                        borderBottom: "2px solid #E9E9E9",
                      }}
                    >
                      <Box
                        sx={{
                          // justifyContent: "space-between",
                          display: "flex",
                        }}
                      >
                        <Box sx={{ display: "flex", marginRight: "2rem" }}>
                          <Box
                            sx={{
                              background: "#F9F9F9",
                              padding: ".6rem 1.4rem",
                              borderTopLeftRadius: "6px",
                              borderBottomLeftRadius: "6px",
                            }}
                          >
                            <Paragraph color="#211D4E" bold="600">
                              MYJB-0001
                            </Paragraph>
                          </Box>

                          <Box
                            sx={{
                              background: "#DE4E3A",
                              padding: ".6rem 1.4rem",
                              borderTopRightRadius: "6px",
                              borderBottomRightRadius: "6px",
                            }}
                          />
                        </Box>
                        <Box sx={{ display: "flex", marginRight: "2rem" }}>
                          <Box
                            sx={{
                              background: "#F9F9F9",
                              padding: ".6rem 1.4rem",
                              borderTopLeftRadius: "6px",
                              borderBottomLeftRadius: "6px",
                            }}
                          >
                            <Paragraph color="#211D4E" bold="600">
                              MYJB-0001
                            </Paragraph>
                          </Box>

                          <Box
                            sx={{
                              background: "#DE4E3A",
                              padding: ".6rem 1.4rem",
                              borderTopRightRadius: "6px",
                              borderBottomRightRadius: "6px",
                            }}
                          />
                        </Box>
                        <Box sx={{ display: "flex", marginRight: "2rem" }}>
                          <Box
                            sx={{
                              background: "#F9F9F9",
                              padding: ".6rem 1.4rem",
                              borderTopLeftRadius: "6px",
                              borderBottomLeftRadius: "6px",
                            }}
                          >
                            <Paragraph color="#211D4E" bold="600">
                              MYJB-0001
                            </Paragraph>
                          </Box>

                          <Box
                            sx={{
                              background: "#3ADEBB",
                              padding: ".6rem 1.4rem",
                              borderTopRightRadius: "6px",
                              borderBottomRightRadius: "6px",
                            }}
                          />
                        </Box>
                        <Box sx={{ display: "flex", marginRight: "2rem" }}>
                          <Box
                            sx={{
                              background: "#F9F9F9",
                              padding: ".6rem 1.4rem",
                              borderTopLeftRadius: "6px",
                              borderBottomLeftRadius: "6px",
                            }}
                          >
                            <Paragraph color="#211D4E" bold="600">
                              -
                            </Paragraph>
                          </Box>

                          <Box
                            sx={{
                              background: "#EDEDED",
                              padding: ".6rem 1.4rem",
                              borderTopRightRadius: "6px",
                              borderBottomRightRadius: "6px",
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        padding: "1rem 3rem",
                        background: "#FFF",
                        borderBottom: "2px solid #E9E9E9",
                      }}
                    >
                      <Box>
                        <Box
                          sx={{
                            display: "table",
                            width: "100%",
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                          }}
                        >
                          <Box sx={{ display: "table-row-group" }}>
                            <Box className={classes.theTableCell}>
                              <Box>
                                <Paragraph
                                  color="#219B8E"
                                  bold="600"
                                  margin="0 0 1rem 0"
                                >
                                  Blood Test
                                </Paragraph>
                                <Box
                                  sx={{
                                    padding: "16px 0",
                                    position: "relative",
                                    height: "1.4375em",
                                    boxSizing: "content-box",
                                    display: "block",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <Paragraph color="#211D4E" bold="700">
                                    WBC
                                  </Paragraph>
                                </Box>
                                <Box
                                  sx={{
                                    padding: "16px 0",
                                    position: "relative",
                                    height: "1.4375em",
                                    boxSizing: "content-box",
                                    display: "block",
                                    marginBottom: ".8rem",
                                  }}
                                >
                                  <Paragraph color="#211D4E" bold="700">
                                    RBC
                                  </Paragraph>
                                </Box>
                                <Box
                                  sx={{
                                    padding: "16px 0",
                                    position: "relative",
                                    height: "1.4375em",
                                    boxSizing: "content-box",
                                    display: "block",
                                    marginBottom: ".8rem",
                                  }}
                                >
                                  <Paragraph color="#211D4E" bold="700">
                                    Hb
                                  </Paragraph>
                                </Box>
                                <Box
                                  sx={{
                                    padding: "16px 0",
                                    position: "relative",
                                    height: "1.4375em",
                                    boxSizing: "content-box",
                                    display: "block",
                                    marginBottom: ".8rem",
                                  }}
                                >
                                  <Paragraph color="#211D4E" bold="700">
                                    Hematocrit
                                  </Paragraph>
                                </Box>
                                <Box
                                  sx={{
                                    padding: "16px 0",
                                    position: "relative",
                                    height: "1.4375em",
                                    boxSizing: "content-box",
                                    display: "block",
                                    marginBottom: ".8rem",
                                  }}
                                >
                                  <Paragraph color="#211D4E" bold="700">
                                    MCV
                                  </Paragraph>
                                </Box>
                                <Box
                                  sx={{
                                    padding: "16px 0",
                                    position: "relative",
                                    height: "1.4375em",
                                    boxSizing: "content-box",
                                    display: "block",
                                    marginBottom: ".8rem",
                                  }}
                                >
                                  <Paragraph color="#211D4E" bold="700">
                                    MCH
                                  </Paragraph>
                                </Box>
                                <Box
                                  sx={{
                                    padding: "16px 0",
                                    position: "relative",
                                    height: "1.4375em",
                                    boxSizing: "content-box",
                                    display: "block",
                                    marginBottom: ".8rem",
                                  }}
                                >
                                  <Paragraph color="#211D4E" bold="700">
                                    MCHC
                                  </Paragraph>
                                </Box>
                                <Box
                                  sx={{
                                    padding: "16px 0",
                                    position: "relative",
                                    height: "1.4375em",
                                    boxSizing: "content-box",
                                    display: "block",
                                    marginBottom: ".8rem",
                                  }}
                                >
                                  <Paragraph color="#211D4E" bold="700">
                                    RDW
                                  </Paragraph>
                                </Box>
                                <Box
                                  sx={{
                                    padding: "16px 0",
                                    position: "relative",
                                    height: "1.4375em",
                                    boxSizing: "content-box",
                                    display: "block",
                                    marginBottom: ".8rem",
                                  }}
                                >
                                  <Paragraph color="#211D4E" bold="700">
                                    PLT
                                  </Paragraph>
                                </Box>
                                <Box sx={{ paddingTop: "1rem" }}>
                                  <Paragraph
                                    color="#219B8E"
                                    bold="600"
                                    margin="0 0 2rem 0"
                                  >
                                    WBC Differential Count
                                  </Paragraph>
                                  <Box
                                    sx={{
                                      padding: "16px 0",
                                      position: "relative",
                                      height: "1.4375em",
                                      boxSizing: "content-box",
                                      display: "block",
                                      marginBottom: "1rem",
                                    }}
                                  >
                                    <Paragraph color="#211D4E" bold="700">
                                      Neutrophils
                                    </Paragraph>
                                  </Box>
                                  <Box
                                    sx={{
                                      padding: "16px 0",
                                      position: "relative",
                                      height: "1.4375em",
                                      boxSizing: "content-box",
                                      display: "block",
                                      marginBottom: ".8rem",
                                    }}
                                  >
                                    <Paragraph color="#211D4E" bold="700">
                                      Lymphocytes
                                    </Paragraph>
                                  </Box>
                                  <Box
                                    sx={{
                                      padding: "16px 0",
                                      position: "relative",
                                      height: "1.4375em",
                                      boxSizing: "content-box",
                                      display: "block",
                                      marginBottom: ".8rem",
                                    }}
                                  >
                                    <Paragraph color="#211D4E" bold="700">
                                      Monocytes
                                    </Paragraph>
                                  </Box>
                                  <Box
                                    sx={{
                                      padding: "16px 0",
                                      position: "relative",
                                      height: "1.4375em",
                                      boxSizing: "content-box",
                                      display: "block",
                                      marginBottom: ".8rem",
                                    }}
                                  >
                                    <Paragraph color="#211D4E" bold="700">
                                      Eosinophils
                                    </Paragraph>
                                  </Box>
                                  <Box
                                    sx={{
                                      padding: "16px 0",
                                      position: "relative",
                                      height: "1.4375em",
                                      boxSizing: "content-box",
                                      display: "block",
                                      marginBottom: ".8rem",
                                    }}
                                  >
                                    <Paragraph color="#211D4E" bold="700">
                                      Basophilis
                                    </Paragraph>
                                  </Box>
                                </Box>

                                <Box sx={{ paddingTop: "1rem" }}>
                                  <Paragraph
                                    color="#219B8E"
                                    bold="600"
                                    margin="0 0 1.7rem 0"
                                  >
                                    ABO Blood Type
                                  </Paragraph>
                                  <Box
                                    sx={{
                                      padding: "16px 0",
                                      position: "relative",
                                      height: "1.4375em",
                                      boxSizing: "content-box",
                                      display: "block",
                                      marginBottom: "1rem",
                                    }}
                                  >
                                    <Paragraph color="#211D4E" bold="700">
                                      ABO Blood Type
                                    </Paragraph>
                                  </Box>
                                  <Box
                                    sx={{
                                      padding: "16px 0",
                                      position: "relative",
                                      height: "1.4375em",
                                      boxSizing: "content-box",
                                      display: "block",
                                      marginBottom: ".8rem",
                                    }}
                                  >
                                    <Paragraph color="#211D4E" bold="700">
                                      Rhesus Typing
                                    </Paragraph>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>

                            <Box
                              sx={{
                                position: "relative",
                                width: "1000px",
                                overflowX: "scroll",
                                display: "flex",
                              }}
                            >
                              <Box
                                sx={{
                                  position: "relative",

                                  display: "flex",
                                }}
                              >
                                <Box
                                  className={classes.theTableCell}
                                  sx={{ width: "auto" }}
                                >
                                  <Box
                                    sx={{ background: "#DDD", padding: "16px" }}
                                  >
                                    <Paragraph
                                      color="#211d4e"
                                      bold="600"
                                      margin="0 0 1rem 0"
                                      center
                                    >
                                      Current
                                    </Paragraph>

                                    <Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="wbc"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                x10^3/uL
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="rbc"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                x10^6/uL
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="hb"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                g/dl
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="hematocrit"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="mcv"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                fL
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="mch"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                pg
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="mchc"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                g/dl
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="rdw"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="plt"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                x10^4/uL
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                    </Box>

                                    <Box sx={{ paddingTop: "1rem" }}>
                                      <Box
                                        className={classes.theTableCell2}
                                        sx={{ marginTop: "3.3rem" }}
                                      >
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="neutrophils"
                                          boxshadow="unset"
                                          // mb="2rem"
                                          style={{ marginTop: "2rem" }}
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="lymphocytes"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="monocytes"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="eosinophils"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="basophilis"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                    </Box>

                                    <Box sx={{ paddingTop: "1rem" }}>
                                      <Box
                                        className={classes.theTableCell2}
                                        sx={{ marginTop: "3.3rem" }}
                                      >
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="blood_type"
                                          boxshadow="unset"
                                          style={{ marginTop: "2rem" }}
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                Type A
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="rhesus_typing"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                Positive
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box
                                  className={classes.theTableCell}
                                  sx={{ width: "auto" }}
                                >
                                  <Box
                                    sx={{ background: "#DDD", padding: "16px" }}
                                  >
                                    <Paragraph
                                      color="#211d4e"
                                      bold="600"
                                      margin="0 0 1rem 0"
                                      center
                                    >
                                      Current
                                    </Paragraph>

                                    <Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="wbc"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                x10^3/uL
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="rbc"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                x10^6/uL
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="hb"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                g/dl
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="hematocrit"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="mcv"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                fL
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="mch"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                pg
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="mchc"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                g/dl
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="rdw"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="plt"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                x10^4/uL
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                    </Box>

                                    <Box sx={{ paddingTop: "1rem" }}>
                                      <Box
                                        className={classes.theTableCell2}
                                        sx={{ marginTop: "3.3rem" }}
                                      >
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="neutrophils"
                                          boxshadow="unset"
                                          // mb="2rem"
                                          style={{ marginTop: "2rem" }}
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="lymphocytes"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="monocytes"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="eosinophils"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="basophilis"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                    </Box>

                                    <Box sx={{ paddingTop: "1rem" }}>
                                      <Box
                                        className={classes.theTableCell2}
                                        sx={{ marginTop: "3.3rem" }}
                                      >
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="blood_type"
                                          boxshadow="unset"
                                          style={{ marginTop: "2rem" }}
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                Type A
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="rhesus_typing"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                Positive
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box
                                  className={classes.theTableCell}
                                  sx={{ width: "auto" }}
                                >
                                  <Box
                                    sx={{ background: "#DDD", padding: "16px" }}
                                  >
                                    <Paragraph
                                      color="#211d4e"
                                      bold="600"
                                      margin="0 0 1rem 0"
                                      center
                                    >
                                      Current
                                    </Paragraph>

                                    <Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="wbc"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                x10^3/uL
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="rbc"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                x10^6/uL
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="hb"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                g/dl
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="hematocrit"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="mcv"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                fL
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="mch"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                pg
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="mchc"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                g/dl
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="rdw"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="plt"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                x10^4/uL
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                    </Box>

                                    <Box sx={{ paddingTop: "1rem" }}>
                                      <Box
                                        className={classes.theTableCell2}
                                        sx={{ marginTop: "3.3rem" }}
                                      >
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="neutrophils"
                                          boxshadow="unset"
                                          // mb="2rem"
                                          style={{ marginTop: "2rem" }}
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="lymphocytes"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="monocytes"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="eosinophils"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="basophilis"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                    </Box>

                                    <Box sx={{ paddingTop: "1rem" }}>
                                      <Box
                                        className={classes.theTableCell2}
                                        sx={{ marginTop: "3.3rem" }}
                                      >
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="blood_type"
                                          boxshadow="unset"
                                          style={{ marginTop: "2rem" }}
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                Type A
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="rhesus_typing"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                Positive
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box
                                  className={classes.theTableCell}
                                  sx={{ width: "auto" }}
                                >
                                  <Box
                                    sx={{ background: "#DDD", padding: "16px" }}
                                  >
                                    <Paragraph
                                      color="#211d4e"
                                      bold="600"
                                      margin="0 0 1rem 0"
                                      center
                                    >
                                      Current
                                    </Paragraph>

                                    <Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="wbc"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                x10^3/uL
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="rbc"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                x10^6/uL
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="hb"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                g/dl
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="hematocrit"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="mcv"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                fL
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="mch"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                pg
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="mchc"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                g/dl
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="rdw"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="plt"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                x10^4/uL
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                    </Box>

                                    <Box sx={{ paddingTop: "1rem" }}>
                                      <Box
                                        className={classes.theTableCell2}
                                        sx={{ marginTop: "3.3rem" }}
                                      >
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="neutrophils"
                                          boxshadow="unset"
                                          // mb="2rem"
                                          style={{ marginTop: "2rem" }}
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="lymphocytes"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="monocytes"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="eosinophils"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="basophilis"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                    </Box>

                                    <Box sx={{ paddingTop: "1rem" }}>
                                      <Box
                                        className={classes.theTableCell2}
                                        sx={{ marginTop: "3.3rem" }}
                                      >
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="blood_type"
                                          boxshadow="unset"
                                          style={{ marginTop: "2rem" }}
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                Type A
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="rhesus_typing"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                Positive
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box
                                  className={classes.theTableCell}
                                  sx={{ width: "auto" }}
                                >
                                  <Box
                                    sx={{ background: "#DDD", padding: "16px" }}
                                  >
                                    <Paragraph
                                      color="#211d4e"
                                      bold="600"
                                      margin="0 0 1rem 0"
                                      center
                                    >
                                      Current
                                    </Paragraph>

                                    <Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="wbc"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                x10^3/uL
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="rbc"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                x10^6/uL
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="hb"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                g/dl
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="hematocrit"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="mcv"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                fL
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="mch"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                pg
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="mchc"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                g/dl
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="rdw"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="plt"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                x10^4/uL
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                    </Box>

                                    <Box sx={{ paddingTop: "1rem" }}>
                                      <Box
                                        className={classes.theTableCell2}
                                        sx={{ marginTop: "3.3rem" }}
                                      >
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="neutrophils"
                                          boxshadow="unset"
                                          // mb="2rem"
                                          style={{ marginTop: "2rem" }}
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="lymphocytes"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="monocytes"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="eosinophils"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="basophilis"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                    </Box>

                                    <Box sx={{ paddingTop: "1rem" }}>
                                      <Box
                                        className={classes.theTableCell2}
                                        sx={{ marginTop: "3.3rem" }}
                                      >
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="blood_type"
                                          boxshadow="unset"
                                          style={{ marginTop: "2rem" }}
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                Type A
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="rhesus_typing"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                Positive
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box
                                  className={classes.theTableCell}
                                  sx={{ width: "auto" }}
                                >
                                  <Box
                                    sx={{ background: "#DDD", padding: "16px" }}
                                  >
                                    <Paragraph
                                      color="#211d4e"
                                      bold="600"
                                      margin="0 0 1rem 0"
                                      center
                                    >
                                      Current
                                    </Paragraph>

                                    <Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="wbc"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                x10^3/uL
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="rbc"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                x10^6/uL
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="hb"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                g/dl
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="hematocrit"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="mcv"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                fL
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="mch"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                pg
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="mchc"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                g/dl
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="rdw"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="plt"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                x10^4/uL
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                    </Box>

                                    <Box sx={{ paddingTop: "1rem" }}>
                                      <Box
                                        className={classes.theTableCell2}
                                        sx={{ marginTop: "3.3rem" }}
                                      >
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="neutrophils"
                                          boxshadow="unset"
                                          // mb="2rem"
                                          style={{ marginTop: "2rem" }}
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="lymphocytes"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="monocytes"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="eosinophils"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="basophilis"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                %
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                    </Box>

                                    <Box sx={{ paddingTop: "1rem" }}>
                                      <Box
                                        className={classes.theTableCell2}
                                        sx={{ marginTop: "3.3rem" }}
                                      >
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="blood_type"
                                          boxshadow="unset"
                                          style={{ marginTop: "2rem" }}
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                Type A
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                      <Box className={classes.theTableCell2}>
                                        <FormInput
                                          bg="#FFF"
                                          number
                                          textcolor="#211d4e"
                                          fontWeight="600"
                                          textalign="center"
                                          name="rhesus_typing"
                                          boxshadow="unset"
                                          InputProps={{
                                            endAdornment: (
                                              <Paragraph
                                                style={{
                                                  fontWeight: "400",

                                                  width: "25%",
                                                  textAlign: "right",
                                                }}
                                              >
                                                Positive
                                              </Paragraph>
                                            ),
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <br />
                        <br />
                        <Grid
                          container
                          spacing={3}
                          sx={{ justifyContent: "space-between" }}
                        >
                          <Grid item xs={12} sm={12} md={5}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={12} md={6}>
                                <Button
                                  label="Next"
                                  bg="#DDD"
                                  color="#211D4E"
                                  br="6px"
                                  size="24px"
                                  bold="600"
                                  pd=".8rem 2rem"
                                  hvbg="#DDD"
                                  // style={{ width: "100%" }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={6}>
                                <Button
                                  label="Forward"
                                  bg="#DDD"
                                  color="#211D4E"
                                  br="6px"
                                  size="24px"
                                  bold="600"
                                  pd=".8rem 2rem"
                                  hvbg="#DDD"
                                  // style={{ width: "100%" }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={12} md={5}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={12} md={6}>
                                <Button
                                  label="Save"
                                  bg="#219B8E"
                                  color="#FFF"
                                  br="6px"
                                  size="24px"
                                  bold="600"
                                  pd=".8rem 2rem"
                                  hvbg="#219B8E"
                                  // style={{ width: "100%" }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={6}>
                                <Button
                                  label="Confirm"
                                  bg="#3ADEBB"
                                  color="#FFF"
                                  br="6px"
                                  size="24px"
                                  bold="600"
                                  pd=".8rem 2rem"
                                  hvbg="#3ADEBB"
                                  // style={{ width: "100%" }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </>
                )}

                {tabs == 1 && (
                  <Box
                    sx={{
                      display: "table",
                      width: "100%",
                      borderCollapse: "collapse",
                      borderSpacing: 0,
                    }}
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th>
                          {" "}
                          <Paragraph
                            color="#211d4e"
                            bold="600"
                            margin="0 0 1rem 0"
                            center
                          >
                            Current
                          </Paragraph>
                        </th>
                        <th>Header</th>
                        <th>Header</th>
                        <th>Header</th>
                        <th>Header</th>
                        <th>Header</th>
                        <th>Header</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>
                          <Paragraph color="#211D4E" bold="700">
                            HBsAG
                          </Paragraph>
                          <Paragraph color="#211D4E" bold="700">
                            HBsAG
                          </Paragraph>
                        </th>
                        <td
                          style={{
                            background: "#DDD",
                            padding: "16px",
                          }}
                        >
                          <FormInput
                            bg="#FFF"
                            number
                            textcolor="#211d4e"
                            fontWeight="600"
                            textalign="center"
                            name="wbc"
                            boxshadow="unset"
                            InputProps={{
                              endAdornment: (
                                <Paragraph
                                  style={{
                                    fontWeight: "400",

                                    width: "25%",
                                    textAlign: "right",
                                  }}
                                >
                                  qwe
                                </Paragraph>
                              ),
                            }}
                          />
                          <FormInput
                            bg="#FFF"
                            number
                            textcolor="#211d4e"
                            fontWeight="600"
                            textalign="center"
                            name="wbc"
                            boxshadow="unset"
                            InputProps={{
                              endAdornment: (
                                <Paragraph
                                  style={{
                                    fontWeight: "400",

                                    width: "25%",
                                    textAlign: "right",
                                  }}
                                >
                                  qwe
                                </Paragraph>
                              ),
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <Paragraph color="#211D4E" bold="700">
                            Anti-HBs
                          </Paragraph>
                        </th>
                       
                      </tr>

                    </tbody>
                  </Box>
                  // <div style={{ overflow: "scroll" }}>
                  //   <div className={classes.thePricing}>
                  //     <div style={{ display: "table-row-group" }}>
                  //       <div className={classes.thePriceBox_}></div>
                  //       <div className={classes.thePriceBoxA}>
                  //         <Paragraph
                  //           color="#211d4e"
                  //           bold="600"
                  //           margin="0 0 1rem 0"
                  //           center
                  //         >
                  //           Current
                  //         </Paragraph>
                  //       </div>
                  //       <div className={classes.thePriceBoxB}>
                  //         <Paragraph
                  //           style={{ padding: ".6rem" }}
                  //           size="24px"
                  //           color="#fff"
                  //           bold="600"
                  //           center
                  //         >
                  //           Pro
                  //         </Paragraph>
                  //       </div>
                  //       <div className={classes.thePriceBoxC}>
                  //         <Paragraph
                  //           style={{ padding: ".6rem" }}
                  //           size="24px"
                  //           color="#fff"
                  //           bold="600"
                  //           center
                  //         >
                  //           VIP
                  //         </Paragraph>
                  //       </div>
                  //     </div>
                  //     <div style={{ display: "table-row-group" }}>
                  //       <div className={classes.thePriceBox}>
                  //         <Paragraph color="#211D4E" bold="700">
                  //           HBsAG
                  //         </Paragraph>
                  //       </div>
                  //       <div className={classes.thePriceBox}>
                  //         <FormInput
                  //           border="1px solid"
                  //           bg="#FFF"
                  //           number
                  //           textcolor="#211d4e"
                  //           fontWeight="600"
                  //           textalign="center"
                  //           name="rbc"
                  //           boxshadow="unset"
                  //           InputProps={{
                  //             endAdornment: (
                  //               <Paragraph
                  //                 style={{
                  //                   fontWeight: "400",

                  //                   width: "25%",
                  //                   textAlign: "right",
                  //                 }}
                  //               >
                  //                 x10^6/uL
                  //               </Paragraph>
                  //             ),
                  //           }}
                  //         />
                  //         <FormInput
                  //           border="1px solid"
                  //           bg="#FFF"
                  //           number
                  //           textcolor="#211d4e"
                  //           fontWeight="600"
                  //           textalign="center"
                  //           name="rbc"
                  //           boxshadow="unset"
                  //           InputProps={{
                  //             endAdornment: (
                  //               <Paragraph
                  //                 style={{
                  //                   fontWeight: "400",

                  //                   width: "25%",
                  //                   textAlign: "right",
                  //                 }}
                  //               >
                  //                 x10^6/uL
                  //               </Paragraph>
                  //             ),
                  //           }}
                  //         />
                  //       </div>
                  //       <div className={classes.thePriceBox}>
                  //         <Paragraph color="#265EA1" center>
                  //           $100
                  //         </Paragraph>
                  //       </div>
                  //       <div className={classes.thePriceBox}>
                  //         <Paragraph color="#265EA1" center>
                  //           $100
                  //         </Paragraph>
                  //       </div>
                  //     </div>
                  //     <div style={{ display: "table-row-group" }}>
                  //       <div className={classes.thePriceBox}>
                  //         <Paragraph>Account Currency</Paragraph>
                  //       </div>
                  //       <div className={classes.thePriceBox}>
                  //         <Paragraph color="#265EA1" center>
                  //           USD
                  //         </Paragraph>
                  //       </div>
                  //       <div className={classes.thePriceBox}>
                  //         <Paragraph color="#265EA1" center>
                  //           USD
                  //         </Paragraph>
                  //       </div>
                  //       <div className={classes.thePriceBox}>
                  //         <Paragraph color="#265EA1" center>
                  //           USD
                  //         </Paragraph>
                  //       </div>
                  //     </div>
                  //     <div style={{ display: "table-row-group" }}>
                  //       <div className={classes.thePriceBox}>
                  //         <Paragraph>Leverage</Paragraph>
                  //       </div>
                  //       <div className={classes.thePriceBox}>
                  //         <Paragraph color="#265EA1" center>
                  //           Up to 1:500
                  //         </Paragraph>
                  //       </div>
                  //       <div className={classes.thePriceBox}>
                  //         <Paragraph color="#265EA1" center>
                  //           Up to 1:500
                  //         </Paragraph>
                  //       </div>
                  //       <div className={classes.thePriceBox}>
                  //         <Paragraph color="#265EA1" center>
                  //           Up to 1:500
                  //         </Paragraph>
                  //       </div>
                  //     </div>
                  //     <div style={{ display: "table-row-group" }}>
                  //       <div className={classes.thePriceBox}>
                  //         <Paragraph>Min/Max Trade Size</Paragraph>
                  //       </div>
                  //       <div className={classes.thePriceBox}>
                  //         <Paragraph color="#265EA1" center>
                  //           0.01 lot/100lots
                  //         </Paragraph>
                  //       </div>
                  //       <div className={classes.thePriceBox}>
                  //         <Paragraph color="#265EA1" center>
                  //           0.01 lot/100lots
                  //         </Paragraph>
                  //       </div>
                  //       <div className={classes.thePriceBox}>
                  //         <Paragraph color="#265EA1" center>
                  //           0.01 lot/100lots
                  //         </Paragraph>
                  //       </div>
                  //     </div>
                  //     <div style={{ display: "table-row-group" }}>
                  //       <div className={classes.thePriceBox}>
                  //         <Paragraph>Commission Per Lot</Paragraph>
                  //       </div>
                  //       <div className={classes.thePriceBox}>
                  //         <Paragraph color="#265EA1" center>
                  //           $0
                  //         </Paragraph>
                  //       </div>
                  //       <div className={classes.thePriceBox}>
                  //         <Paragraph color="#265EA1" center>
                  //           $9
                  //         </Paragraph>
                  //       </div>
                  //       <div className={classes.thePriceBox}>
                  //         <Paragraph color="#265EA1" center>
                  //           $4
                  //         </Paragraph>
                  //       </div>
                  //     </div>
                  //     <div style={{ display: "table-row-group" }}>
                  //       <div className={classes.thePriceBox}>
                  //         <Paragraph>Spreads</Paragraph>
                  //       </div>
                  //       <div className={classes.thePriceBox}>
                  //         <Paragraph color="#265EA1" center>
                  //           From 1.5 floating spreads
                  //         </Paragraph>
                  //       </div>
                  //       <div className={classes.thePriceBox}>
                  //         <Paragraph color="#265EA1" center>
                  //           From 0.1 floating spreads
                  //         </Paragraph>
                  //       </div>
                  //       <div className={classes.thePriceBox}>
                  //         <Paragraph color="#265EA1" center>
                  //           From 0.0 floating spreads
                  //         </Paragraph>
                  //       </div>
                  //     </div>
                  //     <div style={{ display: "table-row-group" }}>
                  //       <div className={classes.thePriceBox}>
                  //         <Paragraph>Hedging</Paragraph>
                  //       </div>
                  //       <div className={classes.thePriceBox}>
                  //         <CheckCircle
                  //           sx={{
                  //             color: "#265EA1",
                  //             display: "flex",
                  //             alignItems: "center",
                  //             justifyContent: "center",
                  //             margin: "0 auto",
                  //           }}
                  //         />
                  //       </div>
                  //       <div className={classes.thePriceBox}>
                  //         <CheckCircle
                  //           sx={{
                  //             color: "#265EA1",
                  //             display: "flex",
                  //             alignItems: "center",
                  //             justifyContent: "center",
                  //             margin: "0 auto",
                  //           }}
                  //         />
                  //       </div>
                  //       <div className={classes.thePriceBox}>
                  //         <CheckCircle
                  //           sx={{
                  //             color: "#265EA1",
                  //             display: "flex",
                  //             alignItems: "center",
                  //             justifyContent: "center",
                  //             margin: "0 auto",
                  //           }}
                  //         />
                  //       </div>
                  //     </div>
                  //     <div style={{ display: "table-row-group" }}>
                  //       <div className={classes.thePriceBox}>
                  //         <Paragraph>Swaps</Paragraph>
                  //       </div>
                  //       <div className={classes.thePriceBox}>
                  //         <CheckCircle
                  //           sx={{
                  //             color: "#265EA1",
                  //             display: "flex",
                  //             alignItems: "center",
                  //             justifyContent: "center",
                  //             margin: "0 auto",
                  //           }}
                  //         />
                  //       </div>
                  //       <div className={classes.thePriceBox}>
                  //         <CheckCircle
                  //           sx={{
                  //             color: "#265EA1",
                  //             display: "flex",
                  //             alignItems: "center",
                  //             justifyContent: "center",
                  //             margin: "0 auto",
                  //           }}
                  //         />
                  //       </div>
                  //       <div className={classes.thePriceBox}>
                  //         <CheckCircle
                  //           sx={{
                  //             color: "#265EA1",
                  //             display: "flex",
                  //             alignItems: "center",
                  //             justifyContent: "center",
                  //             margin: "0 auto",
                  //           }}
                  //         />
                  //       </div>
                  //     </div>

                  //     <div style={{ display: "table-row-group" }}>
                  //       <div className={classes.thePriceBox_}></div>
                  //       <div className={classes.thePriceBox}>
                  //         <Button
                  //           label="Open An Account"
                  //           color="#fff"
                  //           br="6px"
                  //           bg="#265EA1"
                  //           pd=".4rem 1rem"
                  //           center
                  //           style={{ margin: "1rem 0" }}
                  //           href="/login"
                  //         />
                  //         {/* <Paragraph color="#265EA1" margin="1rem 0" center>
                  //         <a>Try Demo Account</a>
                  //       </Paragraph> */}
                  //       </div>
                  //       <div className={classes.thePriceBox}>
                  //         <Button
                  //           label="Open An Account"
                  //           color="#fff"
                  //           br="6px"
                  //           bg="#265EA1"
                  //           pd=".4rem 1rem"
                  //           center
                  //           style={{ margin: "1rem 0" }}
                  //           href="/login"
                  //         />
                  //         {/* <Paragraph color="#265EA1" margin="1rem 0" center>
                  //         <a>Try Demo Account</a>
                  //       </Paragraph> */}
                  //       </div>
                  //       <div className={classes.thePriceBox}>
                  //         <Button
                  //           label="Open An Account"
                  //           color="#fff"
                  //           br="6px"
                  //           bg="#265EA1"
                  //           pd=".4rem 1rem"
                  //           center
                  //           style={{ margin: "1rem 0" }}
                  //           href="/login"
                  //         />
                  //         {/* <Paragraph color="#265EA1" margin="1rem 0" center>
                  //         <a>Try Demo Account</a>
                  //       </Paragraph> */}
                  //       </div>
                  //     </div>
                  //   </div>
                  // </div>
                )}
              </Container>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
