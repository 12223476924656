// import axios from "axios";
// const host = "http://localhost/mj_api/"
const host = "http://178.128.120.86/mj/admin/"
const access_endpoint_link = host + "API/";

const format_request = (postparam) => {
    //   if (postparam) {
    //     let token = window.localStorage.getItem("token");
    //     if (token) {
    //       postparam["token"] = token;
    //     }
    //   }
    let token = 0;
    let token_res = window.localStorage.getItem("token");
    if (token_res) {
        token = token_res;
    }
    // if (!postparam['token']) {
    //   postparam['token'] = token;
    // }

    let body = "";
    for (let k in postparam) {
        body += encodeURI(k) + "=" + encodeURI(postparam[k]) + "&";
    }
    var postOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            'authorization': `Bearer ${token}`
        },
        body: body,
    };
    return postOptions;
};

const api_post = async (postparam = {}, end_point) => {
    var response = await fetch(
        access_endpoint_link + end_point,
        format_request(postparam)
    );

    var json = await response.json();
    if (json.status) {
        return json;
    } else {
        // if (json.message == "Please login again") {
        //     window.localStorage.clear();
        //     //alert(json.message);
        //     window.location.href = "/login";
        //     return json;
        // } else {
        //     //   alert(json.message);
        // }
        return json;
    }
}

export const formatNewJson = (filter, origininalState) => {
    filter.map((row, index) => (
        delete origininalState[row]
    ));
    return origininalState;
}

export const header_badge = async (postparam) => {
    return api_post(postparam, 'header_badge');
};

export const login = async (postparam) => {
    return api_post(postparam, 'login');
};

export const edit_contact = async (postparam) => {
    return api_post(postparam, 'edit_contact');
};

export const change_password = async (postparam) => {
    return api_post(postparam, 'change_password');
};

export const get_profile = async (postparam) => {
    return api_post(postparam, 'get_profile');
};

export const checkin_appointment = async (postparam) => {
    return api_post(postparam, 'checkin_appointment');
};

export const get_reception_list = async (postparam) => {
    return api_post(postparam, 'get_reception_list');
};

export const member_detail = async (postparam) => {
    return api_post(postparam, 'member_detail');
};

export const get_package = async (postparam) => {
    return api_post(postparam, 'get_package');
};

export const get_diet = async (postparam) => {
    return api_post(postparam, 'get_diet');
};

export const get_stool_sampling_status = async (postparam) => {
    return api_post(postparam, 'get_stool_sampling_status');
};

export const get_printing_language = async (postparam) => {
    return api_post(postparam, 'get_printing_language');
};

export const get_special_conditions = async (postparam) => {
    return api_post(postparam, 'get_special_conditions');
};

export const get_optional_items = async (postparam) => {
    return api_post(postparam, 'get_optional_items');
};
export const add_optional_items = async (postparam) => {
    return api_post(postparam, 'add_optional_items');
};

export const remove_optional_items = async (postparam) => {
    return api_post(postparam, 'remove_optional_items');
};

export const cancal_appointment = async (postparam) => {
    return api_post(postparam, 'cancal_appointment');
};

export const save_member = async (postparam) => {
    return api_post(postparam, 'save_member');
};

export const get_room = async (postparam) => {
    return api_post(postparam, 'get_room');
};

export const get_dinning_room = async (postparam) => {
    return api_post(postparam, 'get_dinning_room');
};

export const get_counselling_room = async (postparam) => {
    return api_post(postparam, 'get_counselling_room');
};

export const get_printing_list = async (postparam) => {
    return api_post(postparam, 'get_printing_list');
};

export const get_checkout_list_by_room = async (postparam) => {
    return api_post(postparam, 'get_checkout_list_by_room');
};

export const get_checkout_list_by_room_2 = async (postparam) => {
    return api_post(postparam, 'get_checkout_list_by_room_2');
};

export const get_waiting_list_by_room = async (postparam) => {
    return api_post(postparam, 'get_waiting_list_by_room');
};

export const appointment_start = async (postparam) => {
    return api_post(postparam, 'appointment_start');
};

export const get_comment = async (postparam) => {
    return api_post(postparam, 'get_comment');
};

export const get_member_comment = async (postparam) => {
    return api_post(postparam, 'get_member_comment');
};

export const add_comment = async (postparam) => {
    return api_post(postparam, 'add_comment');
};

export const get_command = async (postparam) => {
    return api_post(postparam, 'get_command');
};

export const get_room_comment = async (postparam) => {
    return api_post(postparam, 'get_room_comment');
};

export const save_room_comment = async (postparam) => {
    return api_post(postparam, 'save_room_comment');
};

export const delete_room_comment = async (postparam) => {
    return api_post(postparam, 'delete_room_comment');
};

export const get_checkout_room_member = async (postparam) => {
    return api_post(postparam, 'get_checkout_room_member');
};

export const checkout_room = async (postparam) => {
    return api_post(postparam, 'checkout_room');
};

export const save_member_comment = async (postparam) => {
    return api_post(postparam, 'save_member_comment');
};

export const get_waiting_list_by_dinning = async (postparam) => {
    return api_post(postparam, 'get_waiting_list_by_dinning');
};

export const get_checkout_list_by_dinning = async (postparam) => {
    return api_post(postparam, 'get_checkout_list_by_dinning');
};

export const checkout_dinning_room = async (postparam) => {
    return api_post(postparam, 'checkout_dinning_room');
};

export const get_waiting_list_by_physical = async (postparam) => {
    return api_post(postparam, 'get_waiting_list_by_physical');
};

export const get_checkout_list_by_physical = async (postparam) => {
    return api_post(postparam, 'get_checkout_list_by_physical');
};

export const get_waiting_list_by_health = async (postparam) => {
    return api_post(postparam, 'get_waiting_list_by_health');
};

export const get_checkout_list_by_health = async (postparam) => {
    return api_post(postparam, 'get_checkout_list_by_health');
};

export const get_physical_consult_room_comment = async (postparam) => {
    return api_post(postparam, 'get_physical_consult_room_comment');
};

export const save_physical_consult_room_comment = async (postparam) => {
    return api_post(postparam, 'save_physical_consult_room_comment');
};

export const get_health_consult_room_comment = async (postparam) => {
    return api_post(postparam, 'get_health_consult_room_comment');
};

export const save_health_consult_room_comment = async (postparam) => {
    return api_post(postparam, 'save_health_consult_room_comment');
};

export const get_checkout_dining_member = async (postparam) => {
    return api_post(postparam, 'get_checkout_dining_member');
};

export const get_checkout_physical_member = async (postparam) => {
    return api_post(postparam, 'get_checkout_physical_member');
};

export const get_checkout_health_member = async (postparam) => {
    return api_post(postparam, 'get_checkout_health_member');
};

export const checkout_physical_consult_room = async (postparam) => {
    return api_post(postparam, 'checkout_physical_consult_room');
};

export const checkout_health_consult_room = async (postparam) => {
    return api_post(postparam, 'checkout_health_consult_room');
};

export const checkin_room = async (postparam) => {
    return api_post(postparam, 'checkin_room');
};

export const checkin_dinning_room = async (postparam) => {
    return api_post(postparam, 'checkin_dinning_room');
};

export const checkin_physical_consult_room = async (postparam) => {
    return api_post(postparam, 'checkin_physical_consult_room');
};

export const checkin_health_consult_room = async (postparam) => {
    return api_post(postparam, 'checkin_health_consult_room');
};

export const get_room_detail = async (postparam) => {
    return api_post(postparam, 'get_room_detail');
};

export const random_appointment = async (postparam) => {
    return api_post(postparam, 'random_appointment');
};

export const get_monitor_area = async (postparam) => {
    return api_post(postparam, 'get_monitor_area');
}

export const get_monitor_system = async (postparam) => {
    return api_post(postparam, 'get_monitor_system');
}

export const get_medical_history = async (postparam) => {
    return api_post(postparam, "get_medical_history")
}
export const add_medical_history = async (postparam) => {
    return api_post(postparam, "add_medical_history")
}
export const delete_medical_history = async (postparam) => {
    return api_post(postparam, "delete_medical_history")
}
export const get_previous_surgery = async (postparam) => {
    return api_post(postparam, "get_previous_surgery")
}
export const add_previous_surgery = async (postparam) => {
    return api_post(postparam, "add_previous_surgery")
}
export const delete_previous_surgery = async (postparam) => {
    return api_post(postparam, "delete_previous_surgery")
}

export const get_family_medical_history = async (postparam) => {
    return api_post(postparam, "get_family_medical_history")
}
export const add_family_medical_history = async (postparam) => {
    return api_post(postparam, "add_family_medical_history")
}
export const delete_family_medical_history = async (postparam) => {
    return api_post(postparam, "delete_family_medical_history")
}

export const get_medication = async (postparam) => {
    return api_post(postparam, "get_medication")
}
export const add_medication = async (postparam) => {
    return api_post(postparam, "add_medication")
}
export const delete_medication = async (postparam) => {
    return api_post(postparam, "delete_medication")
}

export const get_allergy_history = async (postparam) => {
    return api_post(postparam, "get_allergy_history")
}
export const add_allergy_history = async (postparam) => {
    return api_post(postparam, "add_allergy_history")
}
export const delete_allergy_history = async (postparam) => {
    return api_post(postparam, "delete_allergy_history")
}

export const get_health_condition = async (postparam) => {
    return api_post(postparam, "get_health_condition")
}
export const add_health_condition = async (postparam) => {
    return api_post(postparam, "add_health_condition")
}
export const delete_health_condition = async (postparam) => {
    return api_post(postparam, "delete_health_condition")
}

export const get_previous_health_screening_summary = async (postparam) => {
    return api_post(postparam, "get_previous_health_screening_summary")
}
export const add_previous_health_screening_summary = async (postparam) => {
    return api_post(postparam, "add_previous_health_screening_summary")
}
export const delete_previous_health_screening_summary = async (postparam) => {
    return api_post(postparam, "delete_previous_health_screening_summary")
}

export const get_personal_complaint = async (postparam) => {
    return api_post(postparam, "get_personal_complaint")
}
export const add_personal_complaint = async (postparam) => {
    return api_post(postparam, "add_personal_complaint")
}
export const delete_personal_complaint = async (postparam) => {
    return api_post(postparam, "delete_personal_complaint")
}

export const add_questionnaire_1 = async (postparam) => {
    return api_post(postparam, "add_questionnaire_1");
}

export const get_appointment_detail = async (postparam) => {
    return api_post(postparam, "get_appointment_detail");
}
export const get_appointment_optional_items = async (postparam) => {
    return api_post(postparam, "get_appointment_optional_items");
}
// export const get_upload_path = async (postparam) => {
//     let res = await axios.post(access_endpoint_link + "get_upload_path", postparam);
//     if (res) {
//         return res.data;
//     } else {
//         alert(res.message);
//         // return false;
//     }
// };