import React from "react";
import classNames from "classnames";
import { ButtonTag, Label } from "./styles";

export default function Button({
  color,
  bold,
  center,
  mb,
  textDecoration,
  style,
  children,
  pd,
  pv,
  bg,
  hvbg,
  br,
  label,
  onLinkPress,
  size,
  flex,
  box,
  boxshadow,
  className,
  ...others
}) {
  return (
    <ButtonTag
      flex={flex}
      style={style}
      mb={mb}
      pv={pv}
      bg={bg}
      hvbg={hvbg}
      br={br}
      pd={pd}
      box={box}
      boxshadow={boxshadow}
      className={classNames(className)}
      {...others}
    >
      <Label
        size={size}
        bold={bold}
        center={center}
        color={color}
        textDecoration={textDecoration}
      >
        {label}
      </Label>
    </ButtonTag>
  );
}
