import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Box,
} from "@mui/material";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import Loader from "react-js-loader";
import toast from "react-simple-toasts";
import Header from "../../../components/Header/Header";
import Paragraph from "../../../components/Typography/Paragraph";
import FormInput from "../../../components/FormInput/FormInput";
import Button from "../../../components/Button/Button";
import { themePlate } from "../../../components/Theme/Theme";
import useStyles from "./styles";
import "./styles.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SearchIcon from "../../../assets/images/Search Icon.svg";
import CheckoutIcon from "../../../assets/images/Checkout Icon.svg";
import {
  get_reception_list,
  get_waiting_list_by_room,
  get_checkout_list_by_room,
  checkin_room,
  get_room_detail,
} from "../../../api/API";
import { Add, AddCircle, ArrowBackIos, Cancel } from "@mui/icons-material";

export default function MealSetup() {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(false);
  // const [waitingList, setWaitingList] = useState([]);
  // const [checkoutList, setCheckoutList] = useState([]);

  // //Pagination
  // const [page, setPage] = React.useState(1);
  // const [totalCount, setTotalCount] = React.useState(1);
  // const [roomDetail, setRoomDetail] = React.useState(null);

  // const handleChangePage = (event, value) => {
  //   setPage(value);
  // };

  // const handlegetData = async () => {
  //   setLoading(true);

  //   var resWaiting = await get_waiting_list_by_room({
  //     room_id: params.room_id,
  //   });
  //   if (resWaiting.status) {
  //     setLoading(false);
  //     setWaitingList(resWaiting.data);
  //     // setTotalCount(resWaiting.totalCount)
  //   }

  //   var resCheckoutList = await get_checkout_list_by_room({
  //     room_id: params.room_id,
  //   });
  //   if (resCheckoutList.status) {
  //     setLoading(false);
  //     setCheckoutList(resCheckoutList.data);
  //     // setTotalCount(resCheckoutList.totalCount)
  //   }

  //   var resDetail = await get_room_detail({
  //     // page: page,
  //     room_id: params.room_id,
  //   });
  //   if (resDetail.status) {
  //     setLoading(false);
  //     setRoomDetail(resDetail.data);
  //     // setTotalCount(resWaiting.totalCount)
  //   }
  // };

  // useEffect(() => {
  //   handlegetData();
  // }, [page]);

  // const [showPassword3, setShowPassword3] = useState(false);
  // const [passwordState, setPasswordState] = useState(false);

  // const handleCheckin = async (appointment_id, member_id) => {
  //   setLoading(true);
  //   var resCheckIn = await checkin_room({
  //     appointment_id: appointment_id,
  //     room_id: params.room_id,
  //   });
  //   if (resCheckIn.status) {
  //     setLoading(false);
  //     navigate(
  //       "../dinning-room-detail/" +
  //         params.room_id +
  //         "/" +
  //         appointment_id +
  //         "/" +
  //         member_id
  //     );
  //   }
  // };

  const [tabs, setTabs] = React.useState(0);

  const handleChnageTabs = (txt) => {
    setTabs(txt);
  };

  const [openEdit, setOpenEdit] = React.useState(false);

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  //

  const [inputField, setInputField] = React.useState([
    {
      simple_meal: "",
      weight: "",
      energy_calories: "",
    },
  ]);

  const handleAddNewInputField = () => {
    setInputField([
      ...inputField,
      { simple_meal: "", weight: "", energy_calories: "" },
    ]);
  };

  const handleChangeInputField = (index, e) => {
    const { name, value } = e.target;
    var x = [...inputField];
    x[index][name] = value;
    setInputField(x);
  };

  const handleRemoveInputField = (index) => {
    const x = [...inputField];
    x.splice(index, 1);
    setInputField(x);
    window.localStorage.setItem("meal", JSON.stringify(x));
  };

  const handleSave = () => {
    // Remove empty fields
    let checkEmptyField = inputField.filter(
      (item) =>
        item.simple_meal !== "" ||
        item.weight !== "" ||
        item.energy_calories !== ""
    );

    window.localStorage.setItem("meal", JSON.stringify(checkEmptyField));
    setInputField(checkEmptyField);
    setOpenEdit(false);
  };

  const handleCancel = () => {
    // window.localStorage.removeItem("meal");
    // setInputField([
    //   {
    //     simple_meal: "",
    //     weight: "",
    //     energy_calories: "",
    //   },
    // ]);
    let checkEmptyField = inputField.filter(
      (item) =>
        item.simple_meal !== "" ||
        item.weight !== "" ||
        item.energy_calories !== ""
    );

    window.localStorage.setItem("meal", JSON.stringify(checkEmptyField));
    setInputField(checkEmptyField);
    setOpenEdit(false);
  };

  React.useEffect(() => {
    const storedData = window.localStorage.getItem("meal");
    if (storedData) {
      setInputField(JSON.parse(storedData));
    }
  }, []);

  return (
    <React.Fragment>
      {loading ? (
        <div style={{ margin: "auto" }}>
          <Loader
            type="bubble-loop"
            bgColor={themePlate().secondaryThree}
            size={50}
          />
        </div>
      ) : (
        <React.Fragment>
          <Header />
          <div className={classes.theReceptionCounter}>
            <div className={classes.theReceptionCounterHeader}>
              <Container maxWidth="xl" className="customContainer">
                <div>
                  <Box sx={{ margin: "auto", display: "flex" }}>
                    <a style={{ display: "flex" }} onClick={() => navigate(-1)}>
                      <ArrowBackIos sx={{ marginRight: "14px" }} />
                      <Paragraph color="#211d4e" bold="600" size="24px">
                        Back
                      </Paragraph>
                    </a>
                    <Paragraph
                      size="24px"
                      color={themePlate().primaryTwo}
                      bold="600"
                      textTransform="upperCase"
                      // margin="0"
                      style={{ margin: "auto" }}
                    >
                      {/* {roomDetail != null && roomDetail.room_no} */}
                      MEAL SETUP
                    </Paragraph>
                  </Box>
                  <div className={classes.theSHFField}></div>
                </div>
              </Container>
            </div>
          </div>
          <br />
          <Box sx={{ padding: "2rem 0 4rem 0" }}>
            <Container maxWidth="lg">
              <Box
                sx={{
                  background: "#FFF",
                  padding: "1rem",
                  borderRadius: "6px",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <a
                    className={
                      tabs == 0 ? classes.theActive : classes.theNoActive
                    }
                    onClick={() => handleChnageTabs(0)}
                  >
                    <Paragraph color="#211d4e" bold="600" center>
                      Vegetarian
                    </Paragraph>
                  </a>
                  <a
                    className={
                      tabs == 1 ? classes.theActive : classes.theNoActive
                    }
                    onClick={() => handleChnageTabs(1)}
                  >
                    <Paragraph color="#211d4e" bold="600" center>
                      Non-Vegetarian
                    </Paragraph>
                  </a>
                </Box>
                {tabs == 0 && (
                  <>
                    {!openEdit ? (
                      <>
                        <Box
                          sx={{
                            background: "#ededed",
                            width: "100%",
                            height: "2px",
                            margin: "1rem 0 1.5rem 0",
                          }}
                        />
                        <Box
                          sx={{
                            border: "1px solid #ededed",
                            boxShadow: "1px 4px 6px 0px rgba(0,0,0,0.10)inset",
                            padding: "1rem 2rem",
                            borderRadius: "6px",
                          }}
                        >
                          <Box>
                            <Box
                              sx={{
                                display: "table",
                                width: "100%",
                                borderCollapse: "collapse",
                                borderSpacing: 0,
                              }}
                            >
                              <Box sx={{ display: "table-row-group" }}>
                                <Box className={classes.theTableCell}>
                                  <Paragraph color="#219B8E" bold="600">
                                    Simple Meal
                                  </Paragraph>
                                </Box>
                                <Box className={classes.theTableCell}>
                                  <Paragraph color="#211d4e" bold="600" center>
                                    Weight (gram)
                                  </Paragraph>
                                </Box>
                                <Box className={classes.theTableCell}>
                                  <Paragraph color="#211d4e" bold="600" center>
                                    Energy / Calories
                                  </Paragraph>
                                </Box>
                              </Box>
                              {inputField.length > 0 &&
                                inputField.map((item) => (
                                  <Box sx={{ display: "table-row-group" }}>
                                    <Box className={classes.theTableCell2}>
                                      <Paragraph color="#211d4e" bold="600">
                                        {item.simple_meal}
                                      </Paragraph>
                                    </Box>
                                    <Box className={classes.theTableCell2}>
                                      <FormInput
                                        border="1px solid #ededed"
                                        bg="#f9f9f9"
                                        number
                                        textcolor="#211d4e"
                                        fontWeight="600"
                                        textalign="center"
                                        value={item.weight}
                                        // disabled
                                      />
                                      {/* <Paragraph
                                      color="#211d4e"
                                      bold="600"
                                      center
                                    >
                                      {item.weight}
                                    </Paragraph> */}
                                    </Box>
                                    <Box className={classes.theTableCell2}>
                                      <FormInput
                                        border="1px solid #ededed"
                                        bg="#f9f9f9"
                                        number
                                        textcolor="#211d4e"
                                        fontWeight="600"
                                        textalign="center"
                                        value={item.energy_calories}
                                      />
                                      {/* <Paragraph color="#211d4e" bold="600" center>
                                      {item.energy_calories}
                                    </Paragraph> */}
                                    </Box>
                                  </Box>
                                ))}
                            </Box>
                          </Box>
                          <br />
                          <Box
                            sx={{
                              background: "#eaeaea",
                              width: "100%",
                              height: "2px",
                            }}
                          />
                          <br />
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "0 16px",
                            }}
                          >
                            <Paragraph size="20px" color="#211d4e" bold="600">
                              Total Energy / Calories:
                            </Paragraph>
                            <Paragraph size="28px" color="#3adebb" bold="700">
                              4.72
                            </Paragraph>
                          </Box>
                          <br />
                          <br />
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              label="Edit"
                              bg="#5a567b"
                              color="#FFF"
                              br="6px"
                              size="24px"
                              bold="600"
                              pd=".8rem 4rem"
                              hvbg="#211D4E"
                              onClick={() => handleOpenEdit()}
                            />
                          </Box>
                          <br />
                          <br />
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box
                          sx={{
                            background: "#ededed",
                            width: "100%",
                            height: "2px",
                            margin: "1rem 0 1.5rem 0",
                          }}
                        />
                        <Box
                          sx={{
                            border: "1px solid #ededed",
                            boxShadow: "1px 4px 6px 0px rgba(0,0,0,0.10)inset",
                            padding: "1rem 2rem",
                            borderRadius: "6px",
                          }}
                        >
                          <Box>
                            <Box
                              sx={{
                                display: "table",
                                width: "100%",
                                borderCollapse: "collapse",
                                borderSpacing: 0,
                              }}
                            >
                              <Box sx={{ display: "table-row-group" }}>
                                <Box className={classes.theTableCell}>
                                  <Paragraph color="#219B8E" bold="600">
                                    Simple Meal
                                  </Paragraph>
                                </Box>
                                <Box className={classes.theTableCell}>
                                  <Paragraph color="#211d4e" bold="600" center>
                                    Weight (gram)
                                  </Paragraph>
                                </Box>
                                <Box className={classes.theTableCell}>
                                  <Paragraph color="#211d4e" bold="600" center>
                                    Energy / Calories
                                  </Paragraph>
                                </Box>
                              </Box>

                              {inputField.map((item, index) => (
                                <Box sx={{ display: "table-row-group" }}>
                                  <Box className={classes.theTableCell2}>
                                    <FormInput
                                      border="1px solid #ededed"
                                      bg="#f9f9f9"
                                      textcolor="#211d4e"
                                      fontWeight="600"
                                      value={item.simple_meal}
                                      name="simple_meal"
                                      onChange={(e) =>
                                        handleChangeInputField(index, e)
                                      }
                                    />
                                  </Box>
                                  <Box className={classes.theTableCell2}>
                                    <FormInput
                                      border="1px solid #ededed"
                                      bg="#f9f9f9"
                                      number
                                      textcolor="#211d4e"
                                      fontWeight="600"
                                      textalign="center"
                                      value={item.weight}
                                      name="weight"
                                      onChange={(e) =>
                                        handleChangeInputField(index, e)
                                      }
                                    />
                                  </Box>
                                  <Box className={classes.theTableCell2}>
                                    <FormInput
                                      border="1px solid #ededed"
                                      bg="#f9f9f9"
                                      number
                                      textcolor="#211d4e"
                                      fontWeight="600"
                                      textalign="center"
                                      value={item.energy_calories}
                                      name="energy_calories"
                                      onChange={(e) =>
                                        handleChangeInputField(index, e)
                                      }
                                    />
                                  </Box>
                                  <Box className={classes.theTableCell2}>
                                    <Cancel
                                      sx={{
                                        color: "#de4e3a",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleRemoveInputField(index)
                                      }
                                    />
                                  </Box>
                                </Box>
                              ))}
                            </Box>
                          </Box>
                          <br />
                          <Box
                            sx={{
                              background: "#eaeaea",
                              width: "100%",
                              height: "2px",
                            }}
                          />
                          <br />
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "0 16px",
                            }}
                          >
                            {/* <Button
                              label="Edit"
                              bg="#5a567b"
                              color="#FFF"
                              br="6px"
                              size="24px"
                              bold="600"
                              pd=".8rem 4rem"
                              hvbg="#211D4E"
                              onClick={() => handleOpenEdit()}
                            /> */}
                            <a
                              style={{
                                background: "#FFF",
                                padding: "1rem",
                                borderRadius: "6px",
                                cursor: "pointer",
                                display: "flex",
                                // justifyContent: "center",
                                alignItems: "center",
                                textDecoration: "none",
                                boxShadow: "1px 4px 6px 0px rgba(0,0,0,0.10)",
                                width: "26%",
                              }}
                              onClick={() => handleAddNewInputField()}
                            >
                              <AddCircle
                                sx={{ color: "#3adebb", marginRight: "1rem" }}
                              />
                              <Paragraph color="#211d4e" bold="600">
                                Add New
                              </Paragraph>
                            </a>
                          </Box>
                          <br />
                          <br />
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              label="Cancel"
                              bg="#eeeeee"
                              color="#918fa7"
                              br="6px"
                              size="24px"
                              bold="600"
                              pd=".8rem 4rem"
                              hvbg="#eeeeee"
                              style={{ marginRight: "14px" }}
                              onClick={() => handleCancel()}
                            />
                            <Button
                              label="Save"
                              bg="#3adebb"
                              color="#FFF"
                              br="6px"
                              size="24px"
                              bold="600"
                              pd=".8rem 4rem"
                              hvbg="#3adebb"
                              onClick={() => handleSave()}
                            />
                          </Box>
                          <br />
                          <br />
                        </Box>
                      </>
                    )}
                  </>
                )}
              </Box>
            </Container>
          </Box>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
