import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Dialog,
  MenuItem,
  Select,
  Box,
} from "@mui/material";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import Loader from "react-js-loader";
import toast from "react-simple-toasts";
import Header from "../../../components/Header/Header";
import Paragraph from "../../../components/Typography/Paragraph";
import FormInput from "../../../components/FormInput/FormInput";
import Button from "../../../components/Button/Button";
import { themePlate } from "../../../components/Theme/Theme";
import useStyles, {
  CustomFormControl,
  FormInputSelect,
  TableCellBorder,
  TableCellBorderLeft,
  TableCellBorderRight,
} from "../styles";
import "../styles.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DoctorImg from "../../../assets/images/doctor.jpg";
import SearchIcon from "../../../assets/images/Search Icon.svg";

import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import Questionaire from "../Questionaire/Questionaire";
import { ErrorRounded } from "@mui/icons-material";

export default function BoneMineralDensityDetail() {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState(0);

  const handleChangeTab = (txt) => {
    setTabs(txt);
  };

  return (
    <React.Fragment>
      {loading ? (
        <div style={{ margin: "auto" }}>
          <Loader
            type="bubble-loop"
            bgColor={themePlate().secondaryThree}
            size={50}
          />
        </div>
      ) : (
        <React.Fragment>
          <Header />
          <Box sx={{ background: "#211D4E", padding: "1rem" }}>
            <Container
              maxWidth="xl"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  display: "flex",
                  width: "50%",
                }}
              >
                <Paragraph color="#FFF" bold="700">
                  001
                </Paragraph>
                <Paragraph color="#FFF" bold="700">
                  Siew Wen Hui
                </Paragraph>
                <Paragraph color="#FFF" bold="700">
                  1995-05-02
                </Paragraph>
                <Paragraph color="#FFF" bold="700">
                  28/F
                </Paragraph>
                <Paragraph color="#FFF" bold="700">
                  950502-06-1234
                </Paragraph>
              </Box>
              <Box
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  display: "flex",
                  width: "30%",
                }}
              >
                <Paragraph color="#FFF" bold="700">
                  0401 1010 0600 0001
                </Paragraph>
                <Paragraph color="#FFF" bold="700">
                  K2
                </Paragraph>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ borderRight: "3px solid #FFF" }}>
                    <Paragraph color="#FFF" bold="700" margin="0 8px 0 0">
                      BM
                    </Paragraph>
                  </Box>

                  <Box sx={{ borderRight: "3px solid #FFF" }}>
                    <Paragraph color="#FFF" bold="700" margin="0 8px">
                      EN
                    </Paragraph>
                  </Box>
                  <Box>
                    <Paragraph color="#FFF" bold="700" margin="0 0 0 8px">
                      CH
                    </Paragraph>
                  </Box>
                </Box>
                <ErrorRounded sx={{ color: "#F9C234" }} />
              </Box>
            </Container>
          </Box>

          <div className={classes.theReceptionCounter}>
            <div className={classes.theReceptionCounterHeader}>
              <Container maxWidth="xl" className="customContainer">
                <div className={classes.thSHFlex}>
                  <div
                    className={classes.thSHLabel}
                    style={{ width: "auto" }}
                    // onClick={() => navigate("../dashboard")}
                  >
                    <div
                      className={classes.thSHLabel}
                      onClick={() => navigate(-1)}
                    >
                      <div className={classes.theSHIcon}>
                        <ChevronLeftIcon fontSize="34px" />
                      </div>
                      <div>
                        <Paragraph
                          size="18px"
                          color={themePlate().primaryThree}
                          bold="500"
                          textTransform="capitalize"
                          margin="0"
                        >
                          Back
                        </Paragraph>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <Paragraph
                      size="24px"
                      color={themePlate().primaryTwo}
                      bold="600"
                      textTransform="upperCase"
                      margin="0"
                    >
                      BONE MINERAL DENSITY
                    </Paragraph>
                  </div>
                  <div className={classes.theSHFField}>
                    <Button
                      label="Exit Station"
                      color="#fff"
                      bg="#3ADEBB"
                      br="4px"
                    />
                  </div>
                </div>
              </Container>
            </div>
          </div>
          <br />
          <Container maxWidth="xxl">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={2}>
                <Box
                  sx={{
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
                    borderRadius: "5px",
                    padding: "1rem",
                    background: "#fff",
                    width: "60%",
                    margin: "0 auto",
                    cursor: "pointer",
                  }}
                >
                  <Paragraph color="#211D4E" bold="600" center>
                    Medical
                    <br />
                    History
                  </Paragraph>
                </Box>
                <br />
                <Box
                  sx={{
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
                    borderRadius: "5px",
                    padding: "1rem",
                    background: "#fff",
                    width: "60%",
                    margin: "0 auto",
                    cursor: "pointer",
                  }}
                >
                  <Paragraph color="#211D4E" bold="600" center>
                    Allergy / Drug
                    <br />
                    Hx
                  </Paragraph>
                </Box>
                <br />
                <Box
                  sx={{
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
                    borderRadius: "5px",
                    padding: "1rem",
                    background: "#fff",
                    width: "60%",
                    margin: "0 auto",
                    cursor: "pointer",
                  }}
                >
                  <Paragraph color="#211D4E" bold="600" center>
                    Personal
                    <br />
                    Complaint
                  </Paragraph>
                </Box>
                <br />
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <Box
                  sx={{
                    borderTopRightRadius: "4px",
                    borderTopLeftRadius: "4px",
                    padding: "1rem 2rem",
                    background: "#fff",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <div
                      className={
                        tabs == 0 ? classes.theBSBoxActive : classes.theBSBox
                      }
                      onClick={() => handleChangeTab(0)}
                    >
                      Exam. Report
                    </div>
                    <div
                      className={
                        tabs == 1 ? classes.theBSBoxActive : classes.theBSBox
                      }
                      onClick={() => handleChangeTab(1)}
                    >
                      Add On Service
                    </div>
                  </Box>
                  <Box
                    sx={{
                      height: "2px",
                      width: "100%",
                      backgroundColor: "#EDEDED",
                    }}
                  />
                  <Box>
                    {tabs == 0 && (
                      <Box>
                        <Box
                          sx={{
                            display: "table",
                            width: "100%",
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                          }}
                        >
                          <Box sx={{ display: "table-row-group" }}>
                            <Box className={classes.theTableCell}>
                              <Box
                                sx={{ padding: "0 1rem", width: "max-content" }}
                              >
                                <Paragraph
                                  color="#219B8E"
                                  bold="600"
                                  margin="0 0 1rem 0"
                                >
                                  Items
                                </Paragraph>
                                <Box
                                  sx={{
                                    padding: "16px 0",
                                    position: "relative",
                                    height: "1.4375em",
                                    boxSizing: "content-box",
                                    display: "block",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <Paragraph color="#211D4E" bold="700">
                                    T-score
                                  </Paragraph>
                                </Box>
                                <Box
                                  sx={{
                                    padding: "16px 0",
                                    position: "relative",
                                    height: "1.4375em",
                                    boxSizing: "content-box",
                                    display: "block",
                                    marginBottom: ".8rem",
                                  }}
                                >
                                  <Paragraph color="#211D4E" bold="700">
                                    T-score
                                  </Paragraph>
                                </Box>
                                <Box
                                  sx={{
                                    padding: "16px 0",
                                    position: "relative",
                                    height: "1.4375em",
                                    boxSizing: "content-box",
                                    display: "block",
                                    marginBottom: ".8rem",
                                  }}
                                >
                                  <Paragraph color="#211D4E" bold="700">
                                    T-score
                                  </Paragraph>
                                </Box>

                                <Box
                                  sx={{
                                    padding: "1rem 0 0 0",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      padding: "16px 0",
                                      position: "relative",
                                      height: "1.4375em",
                                      boxSizing: "content-box",
                                      display: "block",
                                      marginBottom: ".8rem",
                                    }}
                                  >
                                    <Paragraph color="#211D4E" bold="700">
                                      Z-score
                                    </Paragraph>
                                  </Box>
                                  <Box
                                    sx={{
                                      padding: "16px 0",
                                      position: "relative",
                                      height: "1.4375em",
                                      boxSizing: "content-box",
                                      display: "block",
                                      marginBottom: ".8rem",
                                    }}
                                  >
                                    <Paragraph color="#211D4E" bold="700">
                                      Z-score
                                    </Paragraph>
                                  </Box>
                                  <Box
                                    sx={{
                                      padding: "16px 0",
                                      position: "relative",
                                      height: "1.4375em",
                                      boxSizing: "content-box",
                                      display: "block",
                                      marginBottom: ".8rem",
                                    }}
                                  >
                                    <Paragraph color="#211D4E" bold="700">
                                      Z-score
                                    </Paragraph>
                                  </Box>
                                </Box>

                                <Box
                                  sx={{
                                    padding: "1rem 0 0 0",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      padding: "16px 0",
                                      position: "relative",
                                      height: "1.4375em",
                                      boxSizing: "content-box",
                                      display: "block",
                                      marginBottom: ".8rem",
                                    }}
                                  >
                                    <Paragraph color="#211D4E" bold="700">
                                      Android
                                    </Paragraph>
                                  </Box>
                                  <Box
                                    sx={{
                                      padding: "16px 0",
                                      position: "relative",
                                      height: "1.4375em",
                                      boxSizing: "content-box",
                                      display: "block",
                                      marginBottom: ".8rem",
                                    }}
                                  >
                                    <Paragraph color="#211D4E" bold="700">
                                      Gynoid
                                    </Paragraph>
                                  </Box>
                                  <Box
                                    sx={{
                                      padding: "16px 0",
                                      position: "relative",
                                      height: "1.4375em",
                                      boxSizing: "content-box",
                                      display: "block",
                                      marginBottom: ".8rem",
                                    }}
                                  >
                                    <Paragraph color="#211D4E" bold="700">
                                      A/G Ratio
                                    </Paragraph>
                                  </Box>
                                  <Box
                                    sx={{
                                      padding: "16px 0",
                                      position: "relative",
                                      height: "1.4375em",
                                      boxSizing: "content-box",
                                      display: "block",
                                      marginBottom: ".8rem",
                                    }}
                                  >
                                    <Paragraph color="#211D4E" bold="700">
                                      Total Body
                                    </Paragraph>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>

                            <Box
                              sx={{
                                position: "relative",
                                width: "1000px",
                                overflowX: "scroll",
                                display: "flex",
                              }}
                            >
                              <Box
                                sx={{
                                  position: "relative",

                                  display: "flex",
                                }}
                              >
                                <Box
                                  className={classes.theTableCell}
                                  sx={{ width: "auto" }}
                                >
                                  <Box
                                    sx={{ background: "#DDD", padding: "16px" }}
                                  >
                                    <Paragraph
                                      color="#211d4e"
                                      bold="600"
                                      margin="0 0 1rem 0"
                                      center
                                    >
                                      Current
                                    </Paragraph>

                                    <Box>
                                      <Box className={classes.theTableCell2}>
                                        <Box sx={{ display: "flex" }}>
                                          <FormInput
                                            bg="#FFF"
                                            number
                                            textcolor="#211d4e"
                                            fontWeight="600"
                                            textalign="center"
                                            name="hb"
                                            boxshadow="unset"
                                            style={{ marginRight: "1rem" }}
                                          />

                                          <FormInput
                                            fontWeight="600"
                                            textColor={
                                              themePlate().primaryThree
                                            }
                                            border="5px"
                                            bg="#fff"
                                            select
                                            value="Lumbar Spine"
                                            // padding="8px 20px"
                                            // mb="0"
                                          >
                                            <MenuItem value="Lumbar Spine">
                                              Lumbar Spine
                                            </MenuItem>
                                            <MenuItem value="Lumbar Spinee">
                                              Lumbar Spinee
                                            </MenuItem>
                                          </FormInput>
                                        </Box>
                                        <Box sx={{ display: "flex" }}>
                                          <FormInput
                                            bg="#FFF"
                                            number
                                            textcolor="#211d4e"
                                            fontWeight="600"
                                            textalign="center"
                                            name="hb"
                                            boxshadow="unset"
                                            style={{ marginRight: "1rem" }}
                                          />

                                          <FormInput
                                            fontWeight="600"
                                            textColor={
                                              themePlate().primaryThree
                                            }
                                            border="5px"
                                            bg="#fff"
                                            select
                                            value="Lumbar Spine"
                                            // padding="8px 20px"
                                            // mb="0"
                                          >
                                            <MenuItem value="Lumbar Spine">
                                              Lumbar Spine
                                            </MenuItem>
                                            <MenuItem value="Lumbar Spinee">
                                              Lumbar Spinee
                                            </MenuItem>
                                          </FormInput>
                                        </Box>
                                        <Box sx={{ display: "flex" }}>
                                          <FormInput
                                            bg="#FFF"
                                            number
                                            textcolor="#211d4e"
                                            fontWeight="600"
                                            textalign="center"
                                            name="hb"
                                            boxshadow="unset"
                                            style={{ marginRight: "1rem" }}
                                          />

                                          <FormInput
                                            fontWeight="600"
                                            textColor={
                                              themePlate().primaryThree
                                            }
                                            border="5px"
                                            bg="#fff"
                                            select
                                            value="Lumbar Spine"
                                            // padding="8px 20px"
                                            // mb="0"
                                          >
                                            <MenuItem value="Lumbar Spine">
                                              Lumbar Spine
                                            </MenuItem>
                                            <MenuItem value="Lumbar Spinee">
                                              Lumbar Spinee
                                            </MenuItem>
                                          </FormInput>
                                        </Box>
                                        <Box sx={{ paddingTop: "1rem" }}>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ display: "flex" }}>
                                              <FormInput
                                                bg="#FFF"
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="hb"
                                                boxshadow="unset"
                                                style={{ marginRight: "1rem" }}
                                              />

                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="-"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="-">-</MenuItem>
                                                <MenuItem value="--">
                                                  -
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                            <Box sx={{ display: "flex" }}>
                                              <FormInput
                                                bg="#FFF"
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="hb"
                                                boxshadow="unset"
                                                style={{ marginRight: "1rem" }}
                                              />

                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="-"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="-">-</MenuItem>
                                                <MenuItem value="--">
                                                  -
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                            <Box sx={{ display: "flex" }}>
                                              <FormInput
                                                bg="#FFF"
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="hb"
                                                boxshadow="unset"
                                                style={{ marginRight: "1rem" }}
                                              />

                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="-"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="-">-</MenuItem>
                                                <MenuItem value="--">
                                                  -
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                        </Box>

                                        <Box sx={{ paddingTop: "1rem" }}>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <FormInput
                                              bg="#FFF"
                                              number
                                              textcolor="#211d4e"
                                              fontWeight="600"
                                              textalign="center"
                                              name="android"
                                              boxshadow="unset"
                                              // style={{ marginTop: "2rem" }}
                                              InputProps={{
                                                endAdornment: (
                                                  <Paragraph
                                                    style={{
                                                      fontWeight: "400",

                                                      width: "25%",
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    %Fat
                                                  </Paragraph>
                                                ),
                                              }}
                                            />
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <FormInput
                                              bg="#FFF"
                                              number
                                              textcolor="#211d4e"
                                              fontWeight="600"
                                              textalign="center"
                                              name="gynoid"
                                              boxshadow="unset"
                                              // style={{ marginTop: "2rem" }}
                                              InputProps={{
                                                endAdornment: (
                                                  <Paragraph
                                                    style={{
                                                      fontWeight: "400",

                                                      width: "25%",
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    %Fat
                                                  </Paragraph>
                                                ),
                                              }}
                                            />
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <FormInput
                                              bg="#FFF"
                                              number
                                              textcolor="#211d4e"
                                              fontWeight="600"
                                              textalign="center"
                                              name="agratio"
                                              boxshadow="unset"
                                              // style={{ marginTop: "2rem" }}
                                              InputProps={{
                                                endAdornment: (
                                                  <Paragraph
                                                    style={{
                                                      fontWeight: "400",

                                                      width: "25%",
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    %Fat
                                                  </Paragraph>
                                                ),
                                              }}
                                            />
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <FormInput
                                              bg="#FFF"
                                              number
                                              textcolor="#211d4e"
                                              fontWeight="600"
                                              textalign="center"
                                              name="total_body"
                                              boxshadow="unset"
                                              // style={{ marginTop: "2rem" }}
                                              InputProps={{
                                                endAdornment: (
                                                  <Paragraph
                                                    style={{
                                                      fontWeight: "400",

                                                      width: "25%",
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    %Fat
                                                  </Paragraph>
                                                ),
                                              }}
                                            />
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box
                                  className={classes.theTableCell}
                                  sx={{ width: "auto" }}
                                >
                                  <Box
                                    sx={{ background: "#DDD", padding: "16px" }}
                                  >
                                    <Paragraph
                                      color="#211d4e"
                                      bold="600"
                                      margin="0 0 1rem 0"
                                      center
                                    >
                                      Current
                                    </Paragraph>

                                    <Box>
                                      <Box className={classes.theTableCell2}>
                                        <Box sx={{ display: "flex" }}>
                                          <FormInput
                                            bg="#FFF"
                                            number
                                            textcolor="#211d4e"
                                            fontWeight="600"
                                            textalign="center"
                                            name="hb"
                                            boxshadow="unset"
                                            style={{ marginRight: "1rem" }}
                                          />

                                          <FormInput
                                            fontWeight="600"
                                            textColor={
                                              themePlate().primaryThree
                                            }
                                            border="5px"
                                            bg="#fff"
                                            select
                                            value="Lumbar Spine"
                                            // padding="8px 20px"
                                            // mb="0"
                                          >
                                            <MenuItem value="Lumbar Spine">
                                              Lumbar Spine
                                            </MenuItem>
                                            <MenuItem value="Lumbar Spinee">
                                              Lumbar Spinee
                                            </MenuItem>
                                          </FormInput>
                                        </Box>
                                        <Box sx={{ display: "flex" }}>
                                          <FormInput
                                            bg="#FFF"
                                            number
                                            textcolor="#211d4e"
                                            fontWeight="600"
                                            textalign="center"
                                            name="hb"
                                            boxshadow="unset"
                                            style={{ marginRight: "1rem" }}
                                          />

                                          <FormInput
                                            fontWeight="600"
                                            textColor={
                                              themePlate().primaryThree
                                            }
                                            border="5px"
                                            bg="#fff"
                                            select
                                            value="Lumbar Spine"
                                            // padding="8px 20px"
                                            // mb="0"
                                          >
                                            <MenuItem value="Lumbar Spine">
                                              Lumbar Spine
                                            </MenuItem>
                                            <MenuItem value="Lumbar Spinee">
                                              Lumbar Spinee
                                            </MenuItem>
                                          </FormInput>
                                        </Box>
                                        <Box sx={{ display: "flex" }}>
                                          <FormInput
                                            bg="#FFF"
                                            number
                                            textcolor="#211d4e"
                                            fontWeight="600"
                                            textalign="center"
                                            name="hb"
                                            boxshadow="unset"
                                            style={{ marginRight: "1rem" }}
                                          />

                                          <FormInput
                                            fontWeight="600"
                                            textColor={
                                              themePlate().primaryThree
                                            }
                                            border="5px"
                                            bg="#fff"
                                            select
                                            value="Lumbar Spine"
                                            // padding="8px 20px"
                                            // mb="0"
                                          >
                                            <MenuItem value="Lumbar Spine">
                                              Lumbar Spine
                                            </MenuItem>
                                            <MenuItem value="Lumbar Spinee">
                                              Lumbar Spinee
                                            </MenuItem>
                                          </FormInput>
                                        </Box>
                                        <Box sx={{ paddingTop: "1rem" }}>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <Box sx={{ display: "flex" }}>
                                              <FormInput
                                                bg="#FFF"
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="hb"
                                                boxshadow="unset"
                                                style={{ marginRight: "1rem" }}
                                              />

                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="-"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="-">-</MenuItem>
                                                <MenuItem value="--">
                                                  -
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                            <Box sx={{ display: "flex" }}>
                                              <FormInput
                                                bg="#FFF"
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="hb"
                                                boxshadow="unset"
                                                style={{ marginRight: "1rem" }}
                                              />

                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="-"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="-">-</MenuItem>
                                                <MenuItem value="--">
                                                  -
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                            <Box sx={{ display: "flex" }}>
                                              <FormInput
                                                bg="#FFF"
                                                number
                                                textcolor="#211d4e"
                                                fontWeight="600"
                                                textalign="center"
                                                name="hb"
                                                boxshadow="unset"
                                                style={{ marginRight: "1rem" }}
                                              />

                                              <FormInput
                                                fontWeight="600"
                                                textColor={
                                                  themePlate().primaryThree
                                                }
                                                border="5px"
                                                bg="#fff"
                                                select
                                                value="-"
                                                // padding="8px 20px"
                                                // mb="0"
                                              >
                                                <MenuItem value="-">-</MenuItem>
                                                <MenuItem value="--">
                                                  -
                                                </MenuItem>
                                              </FormInput>
                                            </Box>
                                          </Box>
                                        </Box>

                                        <Box sx={{ paddingTop: "1rem" }}>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <FormInput
                                              bg="#FFF"
                                              number
                                              textcolor="#211d4e"
                                              fontWeight="600"
                                              textalign="center"
                                              name="android"
                                              boxshadow="unset"
                                              // style={{ marginTop: "2rem" }}
                                              InputProps={{
                                                endAdornment: (
                                                  <Paragraph
                                                    style={{
                                                      fontWeight: "400",

                                                      width: "25%",
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    %Fat
                                                  </Paragraph>
                                                ),
                                              }}
                                            />
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <FormInput
                                              bg="#FFF"
                                              number
                                              textcolor="#211d4e"
                                              fontWeight="600"
                                              textalign="center"
                                              name="gynoid"
                                              boxshadow="unset"
                                              // style={{ marginTop: "2rem" }}
                                              InputProps={{
                                                endAdornment: (
                                                  <Paragraph
                                                    style={{
                                                      fontWeight: "400",

                                                      width: "25%",
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    %Fat
                                                  </Paragraph>
                                                ),
                                              }}
                                            />
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <FormInput
                                              bg="#FFF"
                                              number
                                              textcolor="#211d4e"
                                              fontWeight="600"
                                              textalign="center"
                                              name="agratio"
                                              boxshadow="unset"
                                              // style={{ marginTop: "2rem" }}
                                              InputProps={{
                                                endAdornment: (
                                                  <Paragraph
                                                    style={{
                                                      fontWeight: "400",

                                                      width: "25%",
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    %Fat
                                                  </Paragraph>
                                                ),
                                              }}
                                            />
                                          </Box>
                                          <Box
                                            className={classes.theTableCell2}
                                          >
                                            <FormInput
                                              bg="#FFF"
                                              number
                                              textcolor="#211d4e"
                                              fontWeight="600"
                                              textalign="center"
                                              name="total_body"
                                              boxshadow="unset"
                                              // style={{ marginTop: "2rem" }}
                                              InputProps={{
                                                endAdornment: (
                                                  <Paragraph
                                                    style={{
                                                      fontWeight: "400",

                                                      width: "25%",
                                                      textAlign: "right",
                                                    }}
                                                  >
                                                    %Fat
                                                  </Paragraph>
                                                ),
                                              }}
                                            />
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <br />
                        <Box
                          sx={{
                            height: "2px",
                            width: "100%",
                            background: "#EDEDED",
                          }}
                        />
                        <br />
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "1rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="700"
                            margin="0 73px 0 0"
                          >
                            Height
                          </Paragraph>
                          <FormInput
                            number
                            textcolor="#211d4e"
                            fontWeight="600"
                            textalign="center"
                            name="height"
                            boxshadow="unset"
                            bg="#F9F9F9"
                            mb="0"
                            InputProps={{
                              endAdornment: (
                                <Paragraph
                                  style={{
                                    fontWeight: "400",

                                    width: "35%",
                                    textAlign: "right",
                                  }}
                                >
                                  Meter (s)
                                </Paragraph>
                              ),
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "1rem",
                          }}
                        >
                          <Paragraph
                            color="#211D4E"
                            bold="700"
                            margin="0 73px 0 0"
                          >
                            Weight
                          </Paragraph>
                          <FormInput
                            number
                            textcolor="#211d4e"
                            fontWeight="600"
                            textalign="center"
                            name="weight"
                            boxshadow="unset"
                            bg="#F9F9F9"
                            mb="0"
                            InputProps={{
                              endAdornment: (
                                <Paragraph
                                  style={{
                                    fontWeight: "400",

                                    width: "45%",
                                    textAlign: "right",
                                  }}
                                >
                                  Kilogram (s)
                                </Paragraph>
                              ),
                            }}
                          />
                        </Box>
                        <br />
                        <Box
                          sx={{
                            height: "2px",
                            width: "100%",
                            background: "#EDEDED",
                          }}
                        />
                        <br />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            label="Save"
                            bg="#3adebb"
                            color="#FFF"
                            br="6px"
                            size="24px"
                            bold="600"
                            pd=".8rem 4rem"
                            hvbg="#3adebb"
                          />
                        </Box>
                      </Box>
                    )}

                    {tabs == 1 && (
                      <Box sx={{ padding: "1rem 0" }}>
                        <Paragraph
                          bold="600"
                          color="#219b8e"
                          margin="0 0 1rem 0"
                        >
                          Add On Service
                        </Paragraph>

                        <Box
                          sx={{
                            borderRadius: "5px",
                            border: "2px solid #EDEDED",
                            // padding: "0 1rem",
                          }}
                        >
                          <Box
                            sx={{
                              background: "#FBFBFB",
                              padding: "1rem",
                            }}
                          >
                            <Paragraph color="#5A567B" bold="600">
                              Optional Items
                            </Paragraph>
                          </Box>
                          <Box sx={{ padding: "1rem" }}>
                            <div className={classes.theField}>
                              <FormInput
                                className={classes.theBorderRadius}
                                name="search"
                                placeholder="Search..."
                                fontWeight="500"
                                textColor={themePlate().secondaryTwo}
                                border="1px solid #eeeeee"
                                bg="transparent"
                                padding="12px 25px 12px 65px"
                                mb="0"
                                size="18px"
                              />
                              <div className={classes.theAbsoluteIcon}>
                                <div className={classes.theIcon20}>
                                  <img src={SearchIcon} alt={SearchIcon} />
                                </div>
                              </div>
                            </div>
                            <br />
                            <TableContainer style={{ height: "400px" }}>
                              <Table>
                                <TableHead sx={{ background: "transparent" }}>
                                  <TableRow>
                                    <TableCell sx={{ color: "#5A567B" }}>
                                      Items
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        textAlign: "center",
                                        color: "#5A567B",
                                      }}
                                    >
                                      Price
                                    </TableCell>

                                    <TableCell
                                      sx={{ textAlign: "center" }}
                                    ></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>
                                      <div
                                        style={{ display: "flex" }}
                                        className={classes.theWidth}
                                      >
                                        <Paragraph
                                          margin="0 0 .2rem 0"
                                          bold="600"
                                          size="14px"
                                          className="theEllipsis"
                                        >
                                          Gynecological Sonography
                                        </Paragraph>
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        textAlign: "center",
                                        fontWeight: "600",
                                      }}
                                    >
                                      120
                                    </TableCell>
                                    <TableCell>
                                      <a
                                        style={{
                                          color: "#219B8E",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Delete
                                      </a>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <div
                                        style={{ display: "flex" }}
                                        className={classes.theWidth}
                                      >
                                        <Paragraph
                                          margin="0 0 .2rem 0"
                                          bold="600"
                                          size="14px"
                                          className="theEllipsis"
                                        >
                                          Body Composition Test
                                        </Paragraph>
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        textAlign: "center",
                                        fontWeight: "600",
                                      }}
                                    >
                                      150
                                    </TableCell>
                                    <TableCell>
                                      <a
                                        style={{
                                          color: "#219B8E",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Delete
                                      </a>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <div
                                        style={{ display: "flex" }}
                                        className={classes.theWidth}
                                      >
                                        <Paragraph
                                          margin="0 0 .2rem 0"
                                          bold="600"
                                          size="14px"
                                          className="theEllipsis"
                                        >
                                          CA15-3
                                        </Paragraph>
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        textAlign: "center",
                                        fontWeight: "600",
                                      }}
                                    >
                                      60
                                    </TableCell>
                                    <TableCell>
                                      <a
                                        style={{
                                          color: "#219B8E",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Delete
                                      </a>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <div
                                        style={{ display: "flex" }}
                                        className={classes.theWidth}
                                      >
                                        <Paragraph
                                          margin="0 0 .2rem 0"
                                          bold="600"
                                          size="14px"
                                          className="theEllipsis"
                                        >
                                          CA125
                                        </Paragraph>
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        textAlign: "center",
                                        fontWeight: "600",
                                      }}
                                    >
                                      60
                                    </TableCell>
                                    <TableCell>
                                      <a
                                        style={{
                                          color: "#219B8E",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Delete
                                      </a>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <div
                                        style={{ display: "flex" }}
                                        className={classes.theWidth}
                                      >
                                        <Paragraph
                                          margin="0 0 .2rem 0"
                                          bold="600"
                                          size="14px"
                                          className="theEllipsis"
                                        >
                                          Homocysteine
                                        </Paragraph>
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        textAlign: "center",
                                        fontWeight: "600",
                                      }}
                                    >
                                      78
                                    </TableCell>
                                    <TableCell>
                                      <a
                                        style={{
                                          color: "#219B8E",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Delete
                                      </a>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <div
                                        style={{ display: "flex" }}
                                        className={classes.theWidth}
                                      >
                                        <Paragraph color="211D4E" bold="700">
                                          Total Item Selected: 6
                                        </Paragraph>
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <div
                                        style={{ display: "flex" }}
                                        className={classes.theWidth}
                                      >
                                        <Paragraph color="211D4E" bold="700">
                                          Total Amount: 500
                                        </Paragraph>
                                      </div>
                                    </TableCell>
                                    <TableCell></TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </Box>

                        <br />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            label="Save"
                            bg="#3adebb"
                            color="#FFF"
                            br="6px"
                            size="24px"
                            bold="600"
                            pd=".8rem 4rem"
                            hvbg="#3adebb"
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={2}></Grid>
            </Grid>
          </Container>
          <br />
          <br />
          <br />
          <br />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
