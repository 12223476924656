import Routes from './Routes';
import { BrowserRouter } from "react-router-dom";
import UserContextProvider from "./contexts/UserContext";

function App() {
  return (
    <div className="App">
      <UserContextProvider>
        <BrowserRouter>
          <Routes></Routes>
        </BrowserRouter>
      </UserContextProvider>
    </div>
  );
}

export default App;
